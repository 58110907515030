import React, {Component, Fragment} from 'react';
import { render } from 'react-dom';
import {Stage, Layer, Rect, Text, Transformer, Group, Label, Circle} from 'react-konva';
import Konva from 'konva';
import Seat from "./Seat";
import {generateSeatId} from "./constants";
import {SeatEdit} from "./SeatEdit";

export class TableCircle extends React.Component {
    state = {
        id: null,
        name: null,
        radius: 4,
        seatsNumber: 6,
        isRotateEnabled: false,
        isRemoveEnabled: false,
        seatsData: []
    };
    snapSizePx = 20;
    layerPaddingPx = 40;
    isEditMode = false;
    group;
    circle;
    shadowRect;
    rotateTransformer;

    constructor(props) {
        super(props);
        this.isEditMode = props.editMode;
        this.snapSizePx = props.snapSize;
        this.layerPaddingPx = 2 * props.snapSize;

        if (props.id) {
            this.state.id = props.id;
        }
        if (props.name) {
            this.state.name = props.name;
        }
        if (props.seatsNumber) {
            this.state.seatsNumber = props.seatsNumber;
        }

        this.state.seatsData = this.props.seatsData || this.generateSeats();
        // this.state.isRotateEnabled = this.isEditMode;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.seatsNumber !== nextProps.seatsNumber || this.props.seatsData !== nextProps.seatsData) {
            this.setState({
                seatsNumber: nextProps.seatsNumber,
                seatsData: nextProps.seatsData || this.generateSeats(nextProps.seatsNumber)
            }, () => {
                if (this.isEditMode) {
                    this.changeUp();
                }
            })
        }
    }

    componentDidMount() {
        if (this.props.onDataChange) {
            this.changeUp();
        }
        this.shadowRect.hide();

        if (this.state.isRotateEnabled) {
            this.setRotateTransformer();
        }
    }

    setRotateTransformer() {
        this.rotateTransformer.setNode(this.group);

        this.rotateTransformer.getLayer().batchDraw();
    }

    getRadius() {
        const multiplier = this.state.seatsNumber > 10 ? 0.45 : 0.5;

        return this.state.seatsNumber * multiplier;
    }

    getRadiusPx() {
        return this.getRadius() * this.snapSizePx;
    }

    generateSeats(seatsNumber = this.state.seatsNumber) {
        const seats = [];
        [...Array(seatsNumber)]
            .forEach((val, index) => seats.push({ id: 'circle_' + index, position: 'circle', index: index, disabled: false }));

        return seats;
    }

    changeUp() {
        this.props.onDataChange({
            radius: this.state.radius,
            seats: this.state.seatsData,
        });
    }

    onSeatDisableStateChange(seat, disabled) {
        this.state.seatsData.find((foundSeat) => foundSeat.position === seat.position && foundSeat.index === seat.index)
            .disabled = disabled;
        this.changeUp();
    }

    removeTable() {
        if (window.confirm('Czy na pewno chcesz usunąć ten stół wraz z wszystkimi przypisaniami?')) {
            this.props.onTableRemoveClick(this.props.id);
        }
    }

    calculateTableSize(size) {
        return this.snapSizePx * size;
    }

    renderSeats() {
        let seats = this.state.seatsData.map((seat) => {
            const seatToRender = {
                ...seat,
            };

            switch (seat.position) {
                case 'circle':
                    const radius = this.getRadiusPx();
                    seatToRender.x = radius + calcX(radius, seat.index, this.state.seatsData.length) - this.snapSizePx;
                    seatToRender.y = radius + calcY(radius, seat.index, this.state.seatsData.length) - this.snapSizePx;
                    break;
            }

            return seatToRender;
        });

        if (!this.isEditMode) {
            seats = seats.filter((seat) => !seat.disabled);
        }

        function calcX(r, index, length) {
            return r * Math.cos(2 * Math.PI / length * index);
        }

        function calcY(r, index, length) {
            return r * Math.sin(2 * Math.PI / length * index);
        }

        return seats;
    }

    render() {
        const layerPadding = this.layerPaddingPx;
        return (
            <Fragment>
                <Circle // shadow grid rect
                    ref={(ref) => this.shadowRect = ref}
                    radius={this.getRadiusPx()}
                    x={0}
                    y={0}
                    fill={'#e0b1c3'}
                />

                <Group
                    ref={(ref) => this.group = ref}
                    x={this.props.x || (this.props.stage.width() / 2) - this.getRadiusPx()}
                    y={this.props.y || (this.props.stage.height() / 2) - this.getRadiusPx()}
                    draggable={!this.isEditMode}
                    onMouseEnter={() => {
                        if (this.isEditMode) {
                            return;
                        }
                        this.setState({
                            isRemoveEnabled: true,
                        }, () => this.props.stage.batchDraw());
                    }}
                    onMouseLeave={() => {
                        if (this.isEditMode) {
                            return;
                        }
                        this.setState({
                            isRemoveEnabled: false,
                        }, () => this.props.stage.batchDraw());
                    }}
                    onTransformEnd={e => {
                        if (this.props.tableRotationChange) {
                            this.props.tableRotationChange(this.props.id, this.group.attrs.rotation);
                        }
                    }}
                    onDragMove={() => {
                        this.shadowRect.show();
                        this.shadowRect.position({
                            x: this.getRadiusPx() + Math.round(this.group.x() / this.snapSizePx) * this.snapSizePx,
                            y: this.getRadiusPx() + Math.round(this.group.y() / this.snapSizePx) * this.snapSizePx
                        });
                        this.props.stage.batchDraw();
                    }}
                    onDragEnd={(e) => {
                        this.group.position({
                            x: Math.round(this.group.x() / this.snapSizePx) * this.snapSizePx,
                            y: Math.round(this.group.y() / this.snapSizePx) * this.snapSizePx
                        });
                        this.shadowRect.hide();
                        this.props.stage.batchDraw();

                        if (this.props.tablePositionChange) {
                            this.props.tablePositionChange(this.props.id, this.group.attrs.x, this.group.attrs.y);
                        }
                    }}
                >

                    <Circle // main shape
                        ref={(ref) => this.circle = ref}
                        x={this.getRadiusPx()}
                        y={this.getRadiusPx()}
                        fill={'#fff'}
                        radius={this.getRadiusPx()}
                        strokeWidth={2}
                        stroke={'#999'}
                        centeredScaling={true}
                    />

                    {this.renderSeats().map((seat) => this.props.editMode ? (<SeatEdit
                        snapSize={this.snapSizePx}
                        x={seat.x}
                        y={seat.y}
                        onDisableStateChange={this.onSeatDisableStateChange.bind(this, seat)}/>) : (
                        !seat.disabled ? (<Seat
                            key={seat.id}
                            seatId={seat.id}
                            tables={this.props.tables}
                            onSeatClick={this.props.onSeatClick}
                            tableId={this.props.id}
                            snapSize={this.snapSizePx}
                            assignment={this.props.seatAssignments.find((assignment) => assignment.id_seat === seat.id && assignment.id_table === this.props.id)}
                            x={seat.x}
                            y={seat.y} />) : null
                    ))}

                    <Group
                        x={this.getRadiusPx() - (this.nameRef ? this.nameRef.textWidth : 0) / 2}
                        y={this.getRadiusPx() - (this.nameRef ? this.nameRef.textHeight : 0) / 2}>
                        <Text ref={(ref) => this.nameRef = ref}
                              verticalAlign={'middle'}
                              align={'center'}
                              text={this.props.name}/>

                        <Text fontFamily={'Material Icons'}
                              text={'edit'}
                              fontSize={18}
                              x={(this.nameRef ? this.nameRef.textWidth : 0) / 2 - 9}
                              y={20}
                              fill={this.state.isRemoveEnabled ? '#444' : '#ccc'}
                              verticalAlign={'middle'}
                              onTap={(e) => this.props.onActionsClick({
                                  id: this.state.id,
                                  x: e.evt.clientX,
                                  y: e.evt.clientY,
                                  rotateEnabled: false,
                                  restoreSeatsEnabled: this.state.seatsData.some((seat) => seat.disabled)
                              })}
                              onClick={(e) => this.props.onActionsClick({
                                  id: this.state.id,
                                  x: e.evt.clientX,
                                  y: e.evt.clientY,
                                  rotateEnabled: false,
                                  restoreSeatsEnabled: this.state.seatsData.some((seat) => seat.disabled)
                              })}
                              align={'center'}
                        />

                    </Group>

                </Group>

                {this.state.isRotateEnabled ? (<Transformer
                    ref={(ref) => this.rotateTransformer = ref}
                    rotateEnabled={true}
                    resizeEnabled={false}
                    anchorStroke={'red'}
                    anchorFill={'yellow'}
                    anchorSize={20}
                    borderStroke={'transparent'}
                    rotateAnchorOffset={100}
                    borderDash={[3, 3]}
                    rotationSnaps={[0, 15, 30, 45, 60, 75, 90, 180, 270]}
                />) : null}
            </Fragment>
        );
    }
}
