import {CONTENTS} from "../../../../constants";
import api from "../../../../api";
import FileSaver from 'file-saver';

const config = {
    [CONTENTS.PAGE_GENERAL]: {
        page: CONTENTS.PAGE_GENERAL,
        text: 'guest.menu.main',
        to: '/' + CONTENTS.PAGE_GENERAL,
        icon: 'wc',
        preferIcon: false,
        tooltip: null,
        disabled: false,
        zIndex: 5,
    },
    [CONTENTS.PAGE_MAP]: {
        page: CONTENTS.PAGE_MAP,
        text: 'guest.menu.directions',
        to: '/map',
        icon: 'directions_car',
        preferIcon: false,
        disabled: false,
        zIndex: 4,
    },
};

config[CONTENTS.PAGE_ACCOMMODATION] = {
    page: CONTENTS.PAGE_ACCOMMODATION,
    text: 'guest.menu.accommodation',
    to: '/map',
    icon: 'hotel',
    preferIcon: false,
    tooltip: '',
    disabled: false,
    zIndex: 2,
};

config[CONTENTS.PAGE_PHOTOS] = {
    page: CONTENTS.PAGE_PHOTOS,
    text: 'guest.menu.photos',
    to: '/gallery',
    icon: 'photo_camera',
    preferIcon: false,
    tooltip: 'Tutaj znajdą się zdjęcia',
    disabled: true,
    zIndex: 3,
};

config[CONTENTS.PAGE_GIFTS] = {
    page: CONTENTS.PAGE_GIFTS,
    text: 'guest.menu.gifts',
    to: '/gifts',
    icon: 'card_giftcard',
    preferIcon: true,
    disabled: false,
    zIndex: 4,
};

config['DOWNLOAD_PDF_SUMMARY'] = {
    name: 'DOWNLOAD_PDF_SUMMARY',
    type: 'link',
    text: 'guest.menu.summary',
    to: '/gifts',
    icon: 'print',
    preferIcon: true,
    disabled: false,
    zIndex: 4,
    tooltip: 'Pobierz najważniejsze informacje w formie jednego pliku PDF',
    onClick: () => {
        api.request({
            url: api.routes.downloadPDFSummary,
            method: "GET",
            responseType: 'blob'
        }).then((response) => {
            FileSaver.saveAs(response, 'informacje-o-weselu.pdf');
        });
    }
};

export default { config: config };
