import React, { Component, Fragment } from 'react';
import styled, { keyframes } from 'styled-components';
import Icon from '@material-ui/core/Icon';
import {StyledHeader} from './commons';
import MediaSizes from '../../../../constants/media-sizes.constants';
import {connect} from "react-redux";

const StyledIcon = styled(Icon)`
    && {
        font-size: 4.5rem;
        vertical-align: middle;
       
        @media (max-width: ${MediaSizes.phone}px) {
            font-size: 3rem;
        }
    }
`;

const StyledLabel = styled.span`
    font-size: 2rem;
    vertical-align: middle;
    margin-left: 15px;
    display: inline-block;
    
    @media (max-width: ${MediaSizes.phone}px) {
        font-size: 1.2rem;
        width: calc(100% - 110px);
    }
`;

const StyledSubLabel = styled.span`
    display: block;
    font-size: 1rem;
    
    @media (max-width: ${MediaSizes.phone}px) {
        font-size: 0.8rem;
        margin: 0;
    }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-30px);
    }
    
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const StyledSummaryRow = styled.div`
    opacity: 0;
    animation: ${fadeIn} 1s ease-in forwards;
    animation-delay: ${props => (props.index -1) * 1000}ms;
    margin-bottom: 15px;
`;

class MainFormSectionSummary extends Component {

    render() {
        const props = this.props,
            formProps = props.formProps,
            values = props.formProps.values;
        let index = 0;

        if (!props.isActive) {
            return null;
        }

        return (
            <div>
                <StyledHeader>
                    A więc podsumowując...
                </StyledHeader>
                <div className="font-primary">
                    { values.present_first_day ? (
                        <StyledSummaryRow index={++index}>
                            <StyledIcon>sentiment_very_satisfied</StyledIcon>
                            <StyledLabel>będę na weselu
                                {this.props.instance.is_accommodation_available &&  this.props.user.allow_selecting_accommodation ? (
                                    values.accommodation ? <StyledSubLabel>
                                        z noclegiem
                                    </StyledSubLabel> : null
                                ) : null}
                            </StyledLabel>
                        </StyledSummaryRow>
                    ) : (
                        <StyledSummaryRow index={++index}>
                            <StyledIcon>sentiment_dissatisfied</StyledIcon>
                            <StyledLabel>nie będzie mnie na weselu</StyledLabel>
                        </StyledSummaryRow>
                    )}

                    {this.props.instance.is_two_day_party && this.props.user.allow_be_present_second_day ?
                        values.present_second_day ? (
                            <StyledSummaryRow index={++index}>
                                <StyledIcon>sentiment_very_satisfied</StyledIcon>
                                <StyledLabel>będę na poprawinach</StyledLabel>
                            </StyledSummaryRow>
                        ) : (
                            <StyledSummaryRow index={++index}>
                                <StyledIcon>sentiment_dissatisfied</StyledIcon>
                                <StyledLabel>nie będzie mnie na poprawinach</StyledLabel>
                            </StyledSummaryRow>
                        )
                        : null}

                    { values.present_first_day || values.present_second_day ? (
                        <Fragment>
                            { values.is_with_partner ? (values.partner_first_name && values.partner_last_name ? (
                                // if partner name
                                <StyledSummaryRow index={++index}>
                                    <StyledIcon>wc</StyledIcon>
                                    <StyledLabel>moim partnerem będzie <strong>{values.partner_first_name} {values.partner_last_name}</strong></StyledLabel>
                                </StyledSummaryRow>
                            ) : (
                                // if partner name
                                <StyledSummaryRow index={++index}>
                                    <StyledIcon>wc</StyledIcon>
                                    <StyledLabel>
                                        będę z partnerem
                                        <StyledSubLabel>później podam jego imię i nazwisko</StyledSubLabel>
                                    </StyledLabel>
                                </StyledSummaryRow>
                            )) : (
                                <StyledSummaryRow index={++index}>
                                    <StyledIcon>wc</StyledIcon>
                                    <StyledLabel>będę <strong>bez partnera</strong></StyledLabel>
                                </StyledSummaryRow>
                            )}

                            { values.is_with_kids && values.kids.length > 0 ? (
                                <StyledSummaryRow index={++index}>
                                    <StyledIcon>child_care</StyledIcon>
                                    <StyledLabel>
                                        <span>
                                            { values.kids.length > 1 ? 'będę z dziećmi' : 'będę z dzieckiem'}
                                            <br />
                                            <StyledSubLabel>
                                                {values.kids
                                                    .filter((kid) => kid.kid_first_name && kid.kid_last_name)
                                                    .map(kid => `${kid.kid_first_name} ${kid.kid_last_name}`)
                                                    .join(', ')}
                                            </StyledSubLabel>
                                        </span>
                                    </StyledLabel>
                                </StyledSummaryRow>
                            ) : null}

                            {this.props.instance.special_menus && this.props.instance.special_menus.length && (values.main_special_menu || (values.is_with_partner && values.partner_special_menu)) ? (
                                <StyledSummaryRow index={++index}>
                                    <StyledIcon>local_dining</StyledIcon>
                                    <StyledLabel>
                                        <span>
                                            poproszę inne menu
                                            <br />
                                            <StyledSubLabel>
                                                {values.main_special_menu ? (
                                                    <span>Dla mnie: {values.main_special_menu}</span>
                                                ) : null}
                                                {values.is_with_partner && values.partner_special_menu ? (
                                                    <span>{values.main_special_menu ? ' / ' : null}Dla partnera: {values.partner_special_menu}</span>
                                                ) : null}
                                            </StyledSubLabel>
                                        </span>
                                    </StyledLabel>
                                </StyledSummaryRow>
                            ) : null}

                            <br />
                            {/*{ WEDDING_CONFIG.allowToLeaveEmail ? (
                                <div>
                                    <TextField
                                        margin="dense"
                                        name="email"
                                        key="email"
                                        label="Twój adres e-mail"
                                        placeholder="Twój adres e-mail"
                                        type="email"
                                        onChange={formProps.handleChange}
                                        value={values.email}
                                        fullWidth
                                    />
                                    <p>
                                        Możesz zostawić nam swój adres e-mail,
                                        jeżeli życzysz sobie abyśmy wysłali Ci np. link do zdjęć z imprezy.
                                    </p>
                                </div>
                            ) : ''}*/}
                        </Fragment>
                    ) : ''}

                    <p>
                        Jeśli wszystko się zgadza, zatwierdź poniżej a my automatycznie dowiemy się o Twojej decyzji.
                    </p>

                </div>
            </div>
        )
    }

}



const mapStateToProps = (store) => {
    return {
        instance: store.auth.instance,
        user: store.auth.user
    }
};

export default connect(mapStateToProps)(MainFormSectionSummary);
