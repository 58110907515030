import React, {Fragment, useState} from 'react';
import { useForm } from 'react-hook-form';
import WEDDING_CONFIG from '../../../../wedding-config';
import TextInput from "../../partials/TextInput";
import IncrementField from "../../partials/IncrementField";
import SimpleDialog from "../../../../common/SimpleDialog";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import {addRoom} from "../../../../actions/accommodation.actions";
import store from "../../../../store";

const colors = WEDDING_CONFIG.colors;

const AccommodationRoomDialog = ({ placeId, handleClose }) => {
    const { register, handleSubmit, errors, getValues, setValue, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            beds_number: 2
        }
    }); // initialise the hook
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = data => {
        data.beds_number = parseInt(data.beds_number);
        setIsLoading(true);
        store.dispatch(addRoom(placeId, data))
            .then(() => {
                setIsLoading(false);
                handleClose();
            });
    };
    let formRef;

    return (
        <SimpleDialog
            open={true}
            title={'Dodaj pokój'}
            subTitle={<div>Podaj krótką nazwę i określ ilość dostępnych łóżek.</div>}
            actions={<Fragment>
                <CommonOutlinedButton onClick={() => handleClose()} color="secondary">
                    Anuluj
                </CommonOutlinedButton>
                <CommonOutlinedButton className={'fill-primary'} disabled={!formState.isValid || isLoading} onClick={() => formRef.dispatchEvent(new Event('submit', { cancelable: true }))} color="primary">
                    Zapisz
                </CommonOutlinedButton>
            </Fragment>}
          handleClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)} ref={(ref) => formRef = ref}>
                <TextInput
                    inputRef={register({required: true})}
                    name="name"
                    key="name"
                    autoFocus
                    label={'Nazwa pokoju'}
                    maxLength={16}
                    placeholder={'Podaj numer lub krótką nazwę'}
                />
                <p>
                    Określ ilość miejsc:
                </p>
                <IncrementField
                    inputRef={register({required: true})}
                    name={`beds_number`}
                    isHookForm={true}
                    min={1}
                    max={50}
                    onChange={(e) => {
                        if (e.target.value < 1) {
                            e.target.value = 1;
                        }
                        setValue(`beds_number`, e.target.value);
                    }}
                    autoComplete="false">
                </IncrementField>
            </form>
        </SimpleDialog>
    )
};

export default AccommodationRoomDialog;
