import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../../../../wedding-config';
import {darken, transparentize} from 'polished';
import { connect } from 'react-redux';
import store from '../../../../store';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import {setActivePage} from '../../../../actions';
import menuService from '../../../../service/menu.service';
import mainMenuConfig from './MainMenuConfig';
import {withTranslation} from "react-i18next";
const colors = WEDDING_CONFIG.colors;

const StyledMenuList = styled.div`
  display: flex;
    flex-wrap:wrap;
    flex-direction:row;
    padding: 0;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(206,81,114,0.8);
  box-shadow: 1px 2px 2px 1px #9d325670;
`;

const StyledBlock = styled(Button)`
  && {flex: 50%;
  flex-grow: 0;
  display: inline-block;
  text-align: center;
  font-size: 1.1rem;
  background: ${colors.primary};
  font-weight: 100;
  color: white;
  padding: 30px 0;
  text-transform: none;
  border-radius: 0;
  
  .icon {
    font-size: 2rem;
  }
  ${props => `
    &, &:focus, &:hover {
        background: ${transparentize(0.0, darken(0.05 * props.index, colors.primary))};
    }
    ${props.disabled ? `
        background: ${colors.gray}!important;
            pointer-events: none;
            
            &:hover, &:focus {
                background: ${colors.gray};
            }
    ` : ''}
  `}}
`;

class MainMenuMobile extends Component {

    state = {
        menuItems: []
    };

    componentDidMount() {
        const menuItems = menuService.getMenuItems()
            .filter(page => page !== 'DOWNLOAD_PDF_SUMMARY')
            .map((page) => mainMenuConfig.config[page])
            .filter(page => page);
        // TODO add placeholder for empty space
        // if (menuItems.length % 2) {
        //     menuItems.push({
        //         name: 'DOWNLOAD_PDF_SUMMARY',
        //         type: 'link',
        //         text: 'guest.menu.summary',
        //         to: '#',
        //         icon: 'event',
        //         preferIcon: true,
        //         disabled: false,
        //         zIndex: 5,
        //         onClick: () => {}
        //     })
        // }


        this.setState({
            menuItems: menuItems
        })
    }

    menuItemClick(page) {
        store.dispatch(setActivePage(page));
    }

    render() {
        return (
            <Fragment>
                <StyledMenuList>
                    {this.state.menuItems.map((item, i) => (
                        <StyledBlock index={i} onClick={() => this.menuItemClick(item.page)}>
                            <div>
                                <Icon className="icon" fontSize="large">{item.icon}</Icon>
                            </div>
                            <div>
                                {this.props.t(item.text)}
                            </div>
                        </StyledBlock>
                    ))}
                    {/*{this.props.instance.is_pdf_download_enabled ? (*/}
                        {/*<StyledBlock index={5} onClick={() => {*/}
                            {/*store.dispatch(setPDFDownloaded(true));*/}

                            {/*mainMenuConfig.config['DOWNLOAD_PDF_SUMMARY'].onClick();*/}
                        {/*}}*/}
                                     {/*disabled={this.props.app.wasPDFDownloaded}>*/}
                            {/*<div>*/}
                                {/*<Icon className="icon" fontSize="large">print</Icon>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                                {/*Drukuj*/}
                            {/*</div>*/}
                        {/*</StyledBlock>*/}
                    {/*) : ''}*/}
                </StyledMenuList>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        instance: state.auth.instance
    }
};

export default withTranslation()(connect(mapStateToProps)(MainMenuMobile));
