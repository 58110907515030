import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import ActivateForm from "../components/activate-premium-form/ActivateForm";
import api from "../../../api";
import {StyledCard} from "../../../common/StyledCard";
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import Grid from "@material-ui/core/Grid";
import styled from 'styled-components';
import NavLink from "react-router-dom/NavLink";
import {DEFAULT_GRID_SPACING} from "../../../constants";
import {Loader} from "../../../common/Loader";
import WEDDING_CONFIG from "../../../wedding-config";

const StyledMessage = styled.div`
  max-width: 600px;
  margin: 0 auto;
  line-height: normal;
  text-align: center;
  font-size: 2.4rem;
  
  .bigger {
    font-size: 2.5rem;
    color: ${WEDDING_CONFIG.colors.primary};
  }
  
  .icon {
    color: ${WEDDING_CONFIG.colors.primary};
  }
`;

class BuyPremiumPage extends Component {
    state = {
        loading: true,
        hasFilled: false,
        hasJustFilled: false,
        invoiceData: {}
    };

    componentDidMount() {
        this.getInvoiceData().then((response) => {
            this.setState({
                invoiceData: response.data || {},
                hasFilled: !!this.props.instance.is_premium_creation_in_progress,
                loading: false,
            });
        });
    }

    getInvoiceData() {
        return api.request({
            url: api.suRoutes.getInvoiceData,
            method: 'get',
        });
    }

    toggleForm(value, data) {
        const stateChange = {
            hasFilled: value,
            hasJustFilled: value,
        };
        if (data) {
            stateChange.invoiceData = data;
        }
        this.setState(stateChange);
    }

    render() {
        const weddingDate = moment(this.props.instance.initial_wedding_date);

        if (this.state.loading) return <Loader />;

        return (
            <div>
                <Grid container spacing={DEFAULT_GRID_SPACING}>
                    {this.state.hasFilled ? (
                        <Fragment>
                            <Grid item xs={12}>
                                <StyledMessage>
                                    <p>
                                        <div>
                                            <Icon className={'icon'} style={{fontSize: '5rem'}}>{ this.state.hasJustFilled ? 'check_circle_outline' : 'hourglass_empty' }</Icon>
                                        </div>
                                        {this.state.hasJustFilled ? (
                                            <div>
                                                <div className={'bigger'}>Dziękujemy za zakup!</div>
                                                <br />
                                                <p>
                                                    <br/>
                                                    Sprawdź swoją pocztę e-mail - za chwilę powinieneś otrzymać fakturę pro-forma z instrukcją płatności.
                                                    <br />
                                                    Po otrzymaniu wpłaty i utworzeniu nowego adresu WWW poinformujemy Cię mailowo.
                                                </p>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className={'bigger'}>Oczekujemy na wpłatę i działamy!</div>

                                                <br />
                                                <p>
                                                    Jeśli w ciągu godziny od zamówienia nie otrzymałeś faktury pro-forma, koniecznie <NavLink to={'/admin/settings/help'}>daj nam znać.</NavLink>
                                                    <br />Jeśli już dokonałeś(aś) wpłaty, prosimy o jeszcze chwilę cierpliwości. Dziękujemy!
                                                </p>
                                            </div>
                                        )}
                                    </p>
                                </StyledMessage>
                            </Grid>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Grid item xs={12} sm={8} md={6} style={{margin: '0 auto'}}>
                                <StyledCard>
                                    <ActivateForm data={this.state.invoiceData}
                                              toggleForm={this.toggleForm.bind(this)}/>
                                </StyledCard>
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user,
        instance: state.auth.instance,
    }
};

export default connect(mapStateToProps)(BuyPremiumPage);
