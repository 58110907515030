import React, {Fragment, useEffect, useRef, useState} from 'react';
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import WEDDING_CONFIG from "../../../../wedding-config";
import SimpleDialog, {
    SimpleDialogTitle,
    SimpleDialogModalLikeOverlay,
    SimpleDialogModalLikeTitle, SimpleDialogModalLikeActions
} from "../../../../common/SimpleDialog";
import {useFormik} from "formik-newest";
import store from "../../../../store";
import {setSuccess} from "../../../../actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";
import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";
import IncrementField from "../../partials/IncrementField";
import CheckboxBlock from "../../partials/CheckboxBlock";
import {useSelector} from "react-redux";

const colors = WEDDING_CONFIG.colors;

const StyledBox = styled.div`
  width: 300px;
  max-width: 100%;
`;

export default function UserNonGuestEditDialog(props) {
    const data = props.data;
    const isBride = props.data ? props.data.is_bride_row : false;
    const isMobile = useMediaQuery(MediaRule.tablet);
    const instance = useSelector(state => state.auth.instance);
    const form = useFormik({
        initialValues: data ? {
            accommodation: instance.is_accommodation_available ? data.accommodation : undefined,
            present_first_day: data.present_first_day,
            present_second_day: instance.is_two_day_party ? data.present_second_day : undefined,
            people: data.people,
            name: data.name,
            is_active: true
        } : {
            accommodation: instance.is_accommodation_available ? false : undefined,
            present_first_day: true,
            present_second_day: instance.is_two_day_party ? true : undefined,
            people: 1,
            name: '',
            is_active: true
        },
        onSubmit: values => {
            data ? props.updateRecord(values, isBride) : props.addRecord(values);
        },
    });

    return (
        <Fragment>
            <SimpleDialog
                open={true}
                title={data ? data.name : 'Dodaj uczestnika'}
                actions={
                    <Fragment>
                        <CommonOutlinedButton onClick={() => props.onClose()} color="secondary">
                            Zamknij
                        </CommonOutlinedButton>
                        <CommonOutlinedButton disabled={!form.dirty} className="fill-primary"
                                              onClick={() => form.submitForm()} color="secondary">
                            Zapisz
                        </CommonOutlinedButton>
                    </Fragment>
                }
                {...props}>
                <StyledBox>
                    {!props.data ? <p>
                        Dodaj uczestnika, który będzie na weselu, a który nie jest gościem - np. zespół, fotograf lub animator dla dzieci.
                    </p> : null}
                    <div>
                        <TextField
                            name={`name`}
                            key="name"
                            fullWidth={true}
                            value={form.values.name}
                            onChange={form.handleChange}
                            disabled={isBride}
                            label="Nazwa" />
                    </div>
                    <br />
                    {!isBride ? (<div>
                        <div style={{display: 'inline-block'}}>Ilość osób:</div>
                        <IncrementField
                            name={`people`}
                            value={form.values.people}
                            min={1}
                            onChange={(e) => {
                                if (e.target.value < 1) {
                                    e.target.value = 1;
                                }
                                form.setFieldValue(`people`, e.target.value);
                            }}
                            autoComplete="false">
                        </IncrementField>
                    </div>) : null}
                    <br />
                    <div>
                        Obecność:
                    </div>
                    <div>
                        <CheckboxBlock
                            small={true}
                            mainLabel="Wesele"
                            name="present_first_day"
                            key="present_first_day"
                            disabled={isBride}
                            checked={form.values.present_first_day}
                            value="1"
                            onChange={form.handleChange}/>
                    </div>
                    {instance.is_two_day_party ? <div>
                        <CheckboxBlock
                            small={true}
                            mainLabel="Poprawiny"
                            name="present_second_day"
                            key="present_second_day"
                            disabled={isBride}
                            checked={form.values.present_second_day}
                            value="1"
                            onChange={form.handleChange}/>
                    </div> : null}
                    {instance.is_accommodation_available ? (<div>
                        <CheckboxBlock
                            small={true}
                            mainLabel="Nocleg"
                            name="accommodation"
                            key="accommodation"
                            checked={form.values.accommodation}
                            value="1"
                            onChange={form.handleChange}/>
                    </div>) : null}
                </StyledBox>
            </SimpleDialog>
        </Fragment>
    )
};
