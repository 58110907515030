import React from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {StyledTable} from "../../../common/StyledTable";
import {calculateNetPrice} from "../../../utilities";

export default function PremiumBuyItem({ extended, priceGross, hasDiscount }) {
    const priceNet = calculateNetPrice(priceGross);

    return (
        <div>
            <StyledTable size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa</TableCell>
                        {extended ? <TableCell>Netto</TableCell> : null}
                        <TableCell>Brutto</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>1x fancy.wedding premium</TableCell>
                        {extended ? <TableCell style={{color: hasDiscount ? 'green' : 'inherit'}}>{priceNet.toFixed(2)}zł</TableCell> : null}
                        <TableCell style={{color: hasDiscount ? 'green' : 'inherit'}}>{priceGross.toFixed(2)}zł</TableCell>
                    </TableRow>
                </TableBody>
            </StyledTable>
        </div>
    )
}
