const defaultOpts = {
    onlyFirst: true,
    paramScope: '',
};
const TRANSLATE = {
    'error': 'Wystąpił błąd',
    'forbidden': 'Błąd dostępu',
    'processing.error': 'Błąd przetwarzania. Spróbuj ponownie.',
    'instance.password.wrong_code': 'Niepoprawny kod logowania',
    'instance.password.wrong_old_password': 'Niepoprawne hasło',
    'instance.password.change_failed': 'Nie udało się',
    'operation.failed': 'Wystąpił błąd (102)',
    'wrong.credentials.user': 'Nieprawidłowy kod',
    'wrong.credentials.password': 'Nieprawidłowe hasło',
    'generic.page.next.accommodation': 'Nocleg',
    'validate.participant.wrong.data.partner.name.last.name': 'Błędne dane partnera',
    'auth.wrong_credentials': 'Niepoprawne dane',
    'wrong.credentials': 'Niepoprawne dane',
    'validate.content.no.title': 'Proszę podać tytuł',
    'validate.content.no.content': 'Proszę podać jakąkolwiek treść',
    'validate.content.wrong.html': 'Wystąpił błąd podczas przetwarzania treści.',
    'instance.inactive': 'Aplikacja nieaktywna, spróbuj później',
    'unknown.error': 'Wystąpił błąd',
    'bad.request': 'Nie udało się przetworzyć tego żądania.',
    'instance.error': 'Wystąpił błąd logowania',
    'wrong.token': 'Twoja sesja logowania wygasła. Prosimy zalogować się ponownie.',
    'participant.not.exist': 'Użytkownik nie istnieje',
    'validate.participant.create.names.required': 'Pola imię oraz nazwisko są wymagane.',
    'declaration.not.possible': 'Przepraszamy, nie można już składać deklaracji.',
    'instance.trial.not.started': 'Twoja instancja jest przygotowywana.',
    'instance.not.alive': 'Instancja nieaktywna.',
    'instance.trial.ended': 'Instancja nieaktywna - okres próbny zakończył się.',
    'user.not.found': 'Wystąpił błąd (E2)',
    'user.inactive': 'Konto nieaktywne.',
    'wrong.data': 'Niepoprawne lub brakujące dane.',
    'instance.password.demo': 'Nie można zmienić hasła dla tej instancji.',
    'lack.accept': 'Wszystkie zgody są wymagane.',

    'error.unknown': 'Nieznany błąd',
    'error.entity.too.large': 'Żądanie jest zbyt duże do przetworzenia.',
    'error.validation.field_empty': 'Niewypełnione pola!',
    'error.validation_error': 'Wystąpił błąd walidacji. Sprawdź czy poprawnie wypełniłeś(aś) formularz.',
    'generic.error.validation.field_empty': 'Brakujące pole: {{param}}.',
    'generic.error.validation.field_length': 'Przekroczono maksymalny limit znaków.',
    'generic.error.validation.field_too_long': 'Zbyt długie pole {{param}}!',
    'generic.error.validation.field_too_short': 'Zbyt krótkie pole {{param}}!',

    'error.login_standard.instance_is_premium': 'Użyj swojej instancji Premium',
    'error.login_standard.wrong_credentials': 'Nieprawidłowy login lub hasło',
    'error.login_standard.instance_is_inactive': 'Twoje konto jest nieaktywne',

    'error.domain_check.name_too_short': 'Za krótka nazwa (min 3 znaki)',
    'error.domain_check.name_too_long': 'Za długa nazwa (max 26 znaków)',
    'error.domain_check.name_forbidden': 'Nieprawidłowa nazwa, prosimy wybrać inną',
    'error.domain_check.name_exists': 'Nazwa jest już zajęta.',
    'error.domain_check.error_while_checking_name': 'Błąd podczas sprawdzania nazwy',
    'error.domain_check.name_wrong_characters': 'Nieprawiłowe znaki (możliwe a-z oraz -)',
    'error.domain_check.domain_forbidden': 'Błąd domeny',

    'error.table.not_exists': 'Stół nie istnieje. Spróbuj odświeżyć stronę.',
    'error.table.seat_not_exists': 'To miejsce już nie istnieje. Spróbuj odświeżyć stronę.',

    'error.order_premium.instance_creation_in_progress': 'Instancja jest już w trakcie tworzenia. Skontaktuj się z nami poprzez kontakt@fancy.wedding.',

    'error.change_password.password_length': 'Nowe hasło powinno zawierać 6-36 znaków.',
    'error.change_password.password_not_equal': 'Powtórzone hasło nie pasuje.',
    'error.change_password.wrong_password': 'Nieprawidłowe hasło.',
    'error.change_password.change_error': 'Wystąpił nieznany błąd podczas zmiany hasła.',

    'error.participant_update.wrong_special_menu': 'Wystąpił błąd podczas zapisywania informacji. Prosimy odświeżyć stronę i spróbować ponownie.',

    'error.accommodation_room.limit_exceeded': 'Przekroczono limit gości w pokoju.',

    'error.order_premium.promo_code_invalid': 'Kod jest nieprawidłowy lub wygasł.',
    'error.login_premium.wrong_credentials': 'Niepoprawne dane.',

    'after.wedding.no.access': 'Zostałeś wylogowany.',

    commonField: {
        'present_first_day': 'Obecność pierwszy dzień',
        'present_second_day': 'Obecność drugi dzień',
        'accommodation': 'Nocleg',
        'people': 'Ilość',
        'message': 'Wiadomość',
        'name': 'Nazwa',
    },

    activateForm: {
        'invoice.is_company': 'Nazwa firmy',
        'invoice.nip': 'NIP',
        'invoice.first_name': 'Imię',
        'invoice.last_name': 'Nazwisko',
        'invoice.address': 'Adres',
        'invoice.post_code': 'Kod pocztowy',
        'invoice.city': 'Miejscowość',
        'check_terms': 'Zgoda - regulamin',
        'check_email': 'Zgoda - korespondencja',
        'check_data': 'Zgoda - przetwarzanie',
        'first_name': 'Twoje imię',
        'partner_first_name': 'Imię partnera',
        'place': 'Miejsce zaślubin',
        'instance_name': 'Nazwa domeny',
    },
    accommodationPlace: {
        'name': 'nazwa',
    },
    accommodationSet: {
        'name': 'nazwa',
    },
    accommodationRoom: {
        'name': 'nazwa',
        'beds_number': 'ilość miejsc',
    },

    now: function(value, opts = {}) {
        opts = { ...defaultOpts, ...opts };
        if (value instanceof Array) {
            const trans = value
                .map((t) => this.returnValue(t, opts.paramScope))
                .filter((t) => !!t);

            if (opts.onlyFirst) {
                return trans[0];
            }

            return trans.join(' ');
        }
        const translated = this.returnValue(value, opts.paramScope);

        if (translated) {
            return translated;
        }

        return value;
    },
    returnValue: function(value, scope) {
        let key = value;
        let param;
        if (value.includes(';')) {
            value = value.split(';');
            key = `generic.${value[0]}`;
            param = value[1];
        }

        if (param) {
            if (scope) {
                param = this.scoped(scope, param);
            } else if (this.commonField[param]) {
                param = this.commonField[param];
            }
            return this[key] ? this[key].replace('{{param}}', param) : value;
        }

        return this[value];
    },
    scoped: function(scope, value) {
        return this[scope] ? this[scope][value] : value;
    }
};

export default TRANSLATE;
