import api from '../api';


const getTableSets = () => {
    return api.request({
        url: api.suRoutes.tableSetList,
        method: 'get',
    });
};

const addTableSet = (data) => {
    return api.request({
        url: api.suRoutes.tableSetCreate,
        method: 'post',
        data,
    });
};

const removeTableSet = (id) => {
    return api.request({
        url: api.suRoutes.tableSetDelete.replace(':id_set', id),
        method: 'delete',
    });
};

const getTables = (idSet) => {
    return api.request({
        url: api.suRoutes.tableList.replace(':id_set', idSet),
        method: 'get',
        data: {}
    });
};

const addTable = (idSet, data) => {
    return api.request({
        url: api.suRoutes.tableCreate.replace(':id_set', idSet),
        method: 'post',
        data,
    });
};

const removeTable = (idSet, id) => {
    return api.request({
        url: api.suRoutes.tableDelete.replace(':id_set', idSet).replace(':id', id),
        method: 'delete',
    });
};

const updateRotation = (idSet, id, rotation) => {
    return api.request({
        url: api.suRoutes.tableUpdateRotation.replace(':id_set', idSet).replace(':id', id),
        method: 'put',
        data: {
            rotation
        },
    });
};

const updatePosition = (idSet, id, x, y) => {
    return api.request({
        url: api.suRoutes.tableUpdatePosition.replace(':id_set', idSet).replace(':id', id),
        method: 'put',
        data: {
            x, y,
        },
    });
};

const getAssignments = (idSet) => {
    return api.request({
        url: api.suRoutes.tableGetAssignments.replace(':id_set', idSet),
        method: 'get',
    });
};

const assignParticipant = (idSet, assignment) => {
    return api.request({
        url: api.suRoutes.tableAssignParticipant.replace(':id_set', idSet),
        method: 'post',
        data: assignment
    });
};

const swapParticipant = (idSet, data) => {
    return api.request({
        url: api.suRoutes.tableSwapParticipant.replace(':id_set', idSet),
        method: 'put',
        data,
    });
};

const removeParticipant = (idSet, assignment) => {
    return api.request({
        url: api.suRoutes.tableRemoveParticipant.replace(':id_set', idSet),
        method: 'put',
        data: assignment,
    });
};

const editTableName = (idSet, tableId, tableNewName) => {
    return api.request({
        url: api.suRoutes.tableEditName.replace(':id_set', idSet).replace(':id', tableId),
        method: 'put',
        data: {
            table_name: tableNewName
        },
    });
};

const restoreDisabledSeats = (idSet, tableId) => {
    return api.request({
        url: api.suRoutes.tableRestoreSeats.replace(':id_set', idSet).replace(':id', tableId),
        method: 'put'
    });
};

const disableTableSeat = (idSet, tableId, seatId) => {
    return api.request({
        url: api.suRoutes.tableDisableSeat.replace(':id_set', idSet).replace(':id', tableId),
        method: 'put',
        data: {
            seat_id: seatId
        }
    });
};

const exportList = (idSet) => {
    return api.request({
        url: api.suRoutes.tableExportList.replace(':id_set', idSet),
        method: 'post',
        responseType: 'blob'
    });
};

export default {
    getTableSets,
    addTableSet,
    removeTableSet,
    getTables,
    addTable,
    editTableName,
    disableTableSeat,
    restoreDisabledSeats,
    updatePosition,
    updateRotation,
    removeTable,
    getAssignments,
    assignParticipant,
    swapParticipant,
    removeParticipant,
    exportList,
};
