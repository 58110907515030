import api from '../api';

const getContent = (name) => {
    return api.request({
        url: api.routes.getContent.replace(':name', name),
        data: { name }
    });
};

const saveContent = (name, data) => {
    return api.request({
        url: api.routes.saveContent.replace(':name', name),
        method: 'put',
        data: {
            title: data.title,
            content: data.content
        }
    });
};

export default {
    getContent,
    saveContent
};