import useMediaQuery from "@material-ui/core/useMediaQuery";

const MediaSizes = {
    desktopLg: 1600,
    desktop: 1279,
    tabletPro: 1024,
    tablet: 959,
    phone: 599,
};

export const MediaHeights = {
    sm: 728
};

export default MediaSizes;

export const MediaRule = {
    phone: `(max-width: ${MediaSizes.phone}px)`,
    tablet: `(max-width: ${MediaSizes.tablet}px)`,
    tabletPro: `(max-width: ${MediaSizes.tabletPro}px)`,
};
