import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import {NavLink} from "react-router-dom";
import {CommonOutlinedButton} from "../../../common/StyledButton";
import {authService} from "../../../service";
import Icon from "@material-ui/core/Icon";

const StyledHeader = styled.div`
    top: 0;
    color: white;
    z-index: 110;
    display: flex;
    align-items: center;
    
    .logo {
      width: 120px;
      position: relative;
    }
    
    .logout-btn {
      position: relative;
      top: -4px;
    }
`;

const StyledMenuIcon = styled(Icon)`
  && {
    font-size: 2.3rem;
    position: relative;
    top: -5px;
  }
`;

export default function HeaderLogoPanel({ isMobile, mobileMenuExpanded, setMobileMenuExpanded }) {
    return (
        <StyledHeader>
            <NavLink to={'/'}><img className={'logo'} src={'/logo-transparent-text.png'}/></NavLink>
            <CommonOutlinedButton className={'size-small white link logout-btn'} onClick={authService.logOff}>wyloguj</CommonOutlinedButton>
            <span className={'flex-empty-space'}></span>
            {isMobile ?
                <StyledMenuIcon onClick={() => setMobileMenuExpanded(!mobileMenuExpanded)}>{mobileMenuExpanded ? 'close' : 'menu'}</StyledMenuIcon> :
                null}
        </StyledHeader>
    )
}
