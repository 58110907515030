import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectionTitle from "../partials/SectionTitle";
import api from "../../../api";
import {StyledCardContent} from "../../../common/StyledContentCard";
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";
import WEDDING_CONFIG from "../../../wedding-config";
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import ENV from "../../../config/environment";
import MediaSizes from "../../../constants/media-sizes.constants";

const colors = WEDDING_CONFIG.colors;

const StyledStatValue = styled.div`
  color: ${colors.primaryLight};
  font-size: 2rem;
  
  small {
    font-size: 1.2rem;
  }
`;

const StyledStatText = styled.div`
  
`;


const StyledStatOuter = styled.div`
  padding-left: 70px;
`;

const StyledIcon = styled(Icon)`
  && {
    font-size: 2.8rem;
    color: #ddd;
    position: absolute;
    left: 10px;
    top: 25px;
  }
`;

const StyledStatGrid = styled(Grid)`
  && {
    position: relative;
    padding: 20px;
    transition: all 0.2s ease-in;
    
    &.hoverable {
        &:hover {
          background: #fafafa;
          box-shadow: 1px 1px 3px #ddd;
          
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: 80px solid transparent;
            border-top-color: #eee;
            border-top-width: 15px;
          }
        }
        
        &:hover .tree-children {
          display: block;
        }
    }
  
    .tree-children {
      display: none;
      position: absolute;
      top: 100%;
      padding-top: 30px;
      width: 480px;
      z-index: 1;
      //max-width: 100%;
    }
    
    &.alt .tree-children {
      right: 0;
    }
    
    &:not(.alt) .tree-children {
      left: 0;
    }
    
    .child-grid {
      padding: 20px;
      background: #fafafa;
      box-shadow: 2px 2px 2px #ddd;
    }
    
    @media (max-width: ${MediaSizes.tablet}px) {
      ${StyledStatOuter} {
        padding-left: 55px;
      }
      
      &.hoverable:hover {
        background: transparent;
        box-shadow: none;
        
        &:before {
          content: none;
        }
      }
        .tree-children {
          display: block;
          position: static;
          padding-top: 0;
          width: auto;
          
          ${StyledIcon} {
            font-size: 2rem;
          }
          
          ${StyledStatValue} {
            font-size: 1.4rem;
            
            small {
                font-size: 1rem;
            }
          }
          
          ${StyledStatText} {
            font-size: 1rem;
          }
          
          ${StyledStatOuter} {
            padding-left: 40px;
          }
          
            .child-grid {
              padding: 10px;
              background: transparent;
              box-shadow: none;
            }
        }
    }
  }
`;

function Stat({ value, text, maxValue }) {
    return (
        <StyledStatOuter>
            <StyledStatValue>{value || 0}<small>{maxValue || maxValue === 0 ? ' / ' + maxValue : ''}</small></StyledStatValue>
            <StyledStatText>{text}</StyledStatText>
        </StyledStatOuter>
    )
}

class Statistics extends Component {
    state = {
        stats: {},
        mainTree: {},
    };

    componentDidMount() {
        this.fetchStatistics();
    }

    fetchStatistics() {
        api.request({
            url: api.suRoutes.getInstanceStatistics,
            method: 'get'
        }).then(response => {
            this.setState({
                stats: response.data,
                mainTree: this.generateStatisticsTree(response.data),
            });
        });
    }

    generateStatisticsTree(stats) {
        return {
            main: {
                participants_total_first_day: {
                    value: stats.participants_total_first_day,
                    text: 'będzie na weselu',
                    icon: 'local_bar',
                    children: {
                        participants_guests_confirmed_first_day: {
                            value: stats.participants_guests_confirmed_first_day,
                            maxValue: stats.participants_guests_total_active,
                            text: 'z listy gości',
                            icon: 'group'
                        },
                        participants_special_first_day: {
                            value: stats.participants_special_first_day,
                            text: 'spoza listy gości',
                            icon: 'group_add'
                        }
                    }
                },
                participants_total_second_day: {
                    visible: this.props.instance.is_two_day_party,
                    value: stats.participants_total_second_day,
                    text: 'będzie na poprawinach',
                    icon: 'local_bar',
                    children: {
                        participants_guests_confirmed_second_day: {
                            value: stats.participants_guests_confirmed_second_day,
                            maxValue: stats.participants_guests_total_active,
                            text: 'z listy gości',
                            icon: 'group'
                        },
                        participants_special_second_day: {
                            value: stats.participants_special_second_day,
                            text: 'spoza listy gości',
                            icon: 'group_add'
                        }
                    }
                },
                participants_total_accommodation: {
                    visible: this.props.instance.is_accommodation_available,
                    value: stats.participants_total_accommodation,
                    text: 'z noclegiem',
                    icon: 'hotel',
                    children: {
                        participants_guests_confirmed_accommodation: {
                            value: stats.participants_guests_confirmed_accommodation,
                            maxValue: stats.participants_guests_allowed_for_accommodation,
                            text: 'z listy gości',
                            icon: 'group'
                        },
                        participants_special_accommodation: {
                            value: stats.participants_special_accommodation,
                            text: 'spoza listy gości',
                            icon: 'group_add'
                        }
                    }
                },
                participants_total_special_menu: {
                    visible: true,
                    value: stats.participants_total_special_menu,
                    text: 'specjalne menu',
                    icon: 'local_dining',
                    children: {
                        participants_guests_confirmed_special_menu: {
                            value: stats.participants_guests_confirmed_special_menu,
                            maxValue: stats.participants_total_special_menu,
                            text: 'z listy gości',
                            icon: 'group'
                        },
                        // participants_special_vegan: {
                        //     value: stats.participants_special_vegan,
                        //     text: 'spoza listy gości',
                        //     icon: 'group_add'
                        // }
                    }
                },
            },
            others: {
                visits_total_unique: {
                    visible: ENV.IS_PREMIUM,
                    value: stats.visits_total_unique,
                    text: 'logowań',
                    icon: 'devices',
                    children: {
                        mobile_visits: {
                            value: stats.mobile_visits,
                            text: 'z telefonów',
                            icon: 'phone_iphone'
                        },
                        desktop_visits: {
                            value: stats.desktop_visits,
                            text: 'z komputerów',
                            icon: 'laptop'
                        }
                    }
                },
                // pdf_summary_downloads: {
                //     value: stats.pdf_summary_downloads,
                //     text: 'pobranych PDF',
                //     icon: 'insert_drive_file'
                // },
                number_of_filled_by_admin: {
                    value: stats.number_of_filled_by_admin,
                    text: 'deklaracji wypełnionych przez Parę Młodą',
                    icon: 'check_circle'
                },
            }
        };
    }

    renderTreePath(obj, isChild) {
        if (!obj) {
            return null;
        }
        return Object.keys(obj)
            .filter((key) => obj[key].visible !== false)
            .map((key, index) => {
            return (
                <StyledStatGrid key={key} item sm={isChild ? 6 : 3} className={"grid-item" + (obj[key].children ? ' hoverable' : '') + (index === 3 ? ' alt' : '')}>
                    {obj[key].icon ? (
                        <StyledIcon>{obj[key].icon}</StyledIcon>
                    ) : ''}

                    <Stat value={obj[key].value} text={obj[key].text} maxValue={obj[key].maxValue}></Stat>

                    {obj[key].children ? (
                        <div className="tree-children">
                            <Grid container className="child-grid">
                                {this.renderTreePath(obj[key].children, true)}
                            </Grid>
                        </div>) :
                        ''}
                </StyledStatGrid>
            )
        });
    }


    render() {
        const st = this.state.stats;
        return (
            <div>
                <StyledCard>
                    <StyledCardHeader>
                        Statystyki dotyczące potwierdzonych gości
                    </StyledCardHeader>
                    <StyledCardContent>
                        <Grid container>
                            {this.renderTreePath(this.state.mainTree.main)}
                        </Grid>
                    </StyledCardContent>
                </StyledCard>

                {ENV.IS_PREMIUM ? (
                    <StyledCard>
                        <StyledCardHeader>
                            Pozostałe
                        </StyledCardHeader>
                        <StyledCardContent>
                            <Grid container>
                                {this.renderTreePath(this.state.mainTree.others)}
                            </Grid>
                        </StyledCardContent>
                    </StyledCard>
                ) : null}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user,
        instance: state.auth.instance,
    }
};

export default connect(mapStateToProps)(Statistics);
