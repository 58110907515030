import React, {useRef, useState} from 'react';
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";
import Grid from "@material-ui/core/Grid";
import {DEFAULT_GRID_SPACING} from "../../../constants";
import {useSelector} from "react-redux";
import AdminChat from "../components/chat/AdminChat";
import ActionsPanel from "../partials/ActionsPanel";
import ActionsPanelButton from "../partials/ActionsPanelButton";
import DesktopActions, {DesktopActionsWrapper} from "../components/DesktopActions";
import {AdminChatNewMessage} from "../components/chat/AdminChatNewMessage";
import MobileActions from "../components/MobileActions";
import WEDDING_CONFIG from "../../../wedding-config";
import {StyledFancyButton} from "../../../common/StyledButton";
import NavLink from "react-router-dom/NavLink";
import Icon from "@material-ui/core/Icon";
import ENV from "../../../config/environment";

export function ChatPage() {
    const instance = useSelector(state => state.auth.instance);
    const [isAddingNewMessage, setIsAddingNewMessage] = useState(false);
    const adminChatRef = useRef();

    const actions = [
        {
            onClick: () => {
                setIsAddingNewMessage(true)
            },
            text: 'Nowa wiadomość',
            icon: 'chat'
        }
    ];

    if (!ENV.IS_PREMIUM || !instance.is_chat_enabled) {
        return (
            <div>
                <Grid container spacing={DEFAULT_GRID_SPACING}>
                    <Grid item sm={8}>
                        <StyledCard>
                            <StyledCardHeader>Komunikuj się z gośćmi poprzez wbudowany chat</StyledCardHeader>
                            <p>Przychodzimy do Was z kolejnym świetnym ułatwieniem w organizacji wesela. Tym razem stworzyliśmy dla Was chat!</p>
                            <img className={'max-100'} src={`${WEDDING_CONFIG.resourcesUrl}chat-example-screen.jpg`} />
                            <p>Nasze rozwiązanie działa na podobnej zasadzie jak znane komunikatory, z taką różnicą, że nie potrzeba osobnego konta. Wystarczy aplikacja fancy.wedding!</p>
                            <p>Gdy chat jest włączony, każdy z zaproszonych gości ma możliwość rozpoczęcia z Wami konwersacji.</p>
                            <p>Jako Młoda Para, możecie również rozpoczynać konwersacje, a dodatkowo - możecie wysyłać tzw. ogłoszenia - czyli wiadomości skierowane do wszystkich zaproszonych gości.</p>
                            <p>Chat dostępny jest dla Par Młodych, które wykupiły pakiet premium.</p>
                        </StyledCard>
                    </Grid>
                    <Grid item sm={4}>
                        <StyledCard>
                            <StyledCardHeader>Wypróbuj teraz</StyledCardHeader>
                            {ENV.IS_PREMIUM ?
                                <StyledFancyButton variant={'contained'} className={'fill-primary'} component={NavLink} to={'/admin/settings/instance-settings'}>
                                    <Icon className={'icon'}>build</Icon> <b>Przejdź do ustawień</b>
                                </StyledFancyButton> :
                                <StyledFancyButton variant={'contained'} className={'fill-primary'} component={NavLink} to={'/admin/premium'}>
                                    <Icon className={'icon'}>shopping_cart</Icon> <b>Sprawdź pakiet Premium</b>
                                </StyledFancyButton>}
                        </StyledCard>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return (
        <div>
            {isAddingNewMessage ? (
                <AdminChatNewMessage handleClose={(shouldRefresh) => {
                    setIsAddingNewMessage(false);
                    if (shouldRefresh) {
                        adminChatRef.current.refresh();
                    }
                }} />
            ) : null}
            <DesktopActionsWrapper>
                <DesktopActions actions={actions} />
            </DesktopActionsWrapper>
            <MobileActions actions={actions} />
            <AdminChat passedInRef={adminChatRef} />
        </div>
    )
}
