import {ACCOMMODATION_ACTION} from "../actions/accommodation.actions";

const initialState = {
    selectedAccommodationSetId: localStorage.getItem('selectedAccommodationSetId'),
    seatsCount: 0,
    setsLoaded: false,
    sets: [],
    placesLoaded: false,
    places: [],
    usersList: [],
    availableUsersList: [],
    assignments: [],
};

export const accommodation = (state = initialState, action) => {
    switch(action.type) {
        case ACCOMMODATION_ACTION.SET_SETS_DATA:
            state = {
                ...state,
                sets: action.sets,
                setsLoaded: true
            };
            break;
        case ACCOMMODATION_ACTION.SET_SELECTED_SET_ID:
            state = {
                ...state,
                selectedAccommodationSetId: action.id,
            };
            if (action.id) {
                localStorage.setItem('selectedAccommodationSetId', action.id);
            } else {
                localStorage.removeItem('selectedAccommodationSetId');
            }
            break;
        case ACCOMMODATION_ACTION.SET_USER_LIST:
            state = {
                ...state,
                usersList: action.usersList,
                availableUsersList: action.usersList,
            };
            break;
        case ACCOMMODATION_ACTION.SET_AVAILABLE_USERS_LIST:
            state = {
                ...state,
                availableUsersList: state.usersList.filter((user) => !state.assignments.find((assignment) =>
                    assignment.id_participant === user.id &&
                    assignment.participant_type === user.participant_type &&
                    assignment.participant_index === user.participant_index)),
            };
            break;
        case ACCOMMODATION_ACTION.SET_ASSIGNMENTS:
            state = {
                ...state,
                assignments: action.assignments,
            };
            break;
        case ACCOMMODATION_ACTION.ADD_PLACE:
            state.places.push(action.place);
            state = {
                ...state,
            };
            break;
        case ACCOMMODATION_ACTION.EDIT_PLACE:
            let findPlace = state.places.find((place) => place.id === action.placeId);
            if (findPlace) {
                findPlace.name = action.placeData.name;
            }
            state = {
                ...state,
                places: state.places
            };
            break;
        case ACCOMMODATION_ACTION.REMOVE_PLACE:
            const placeFound = state.places.find((place) => place.id === action.placeId);
            const roomIds = placeFound.rooms.map((room) => room.id);

            state = {
                ...state,
                places: state.places.filter((place) => place.id !== action.placeId),
                assignments: state.assignments.filter((assignment) => !roomIds.includes(assignment.id_accommodation_room))
            };
            break;
        case ACCOMMODATION_ACTION.ADD_ROOM:
            const place = state.places.find((place) => place.id === action.idPlace);

            action.room.isNew = true;

            if (place) {
                place.rooms.push(action.room);
            }
            state = {
                ...state,
            };
            break;
        case ACCOMMODATION_ACTION.EDIT_ROOM:
            findPlace = state.places.find((place) => place.rooms.map(room => room.id).includes(action.roomId));

            if (findPlace) {
                findPlace.rooms.find(room => room.id === action.roomId).name = action.roomData.name;
            }
            state = {
                ...state,
                places: state.places
            };
            break;
        case ACCOMMODATION_ACTION.REMOVE_ROOM:
            findPlace = state.places.find((place) => place.rooms.map(room => room.id).includes(action.room.id));

            if (findPlace) {
                findPlace.rooms = findPlace.rooms.filter(room => room.id !== action.room.id);

            }
            state = {
                ...state,
                places: state.places,
                assignments: state.assignments.filter(assignment => assignment.id_accommodation_room !== action.room.id)
            };
            break;
        case ACCOMMODATION_ACTION.ADD_ASSIGNMENT:
            const userOfAssignment = state.usersList.find((user) => user.id === action.assignment.id_participant &&
                user.participant_type === action.assignment.participant_type &&
                user.participant_index === action.assignment.participant_index);
            const mappedUsers = [].concat(
                [...state.assignments].filter((guest) => guest.id !== action.assignment.override),
                [{
                    ...action.assignment,
                    name: `${userOfAssignment.first_name} ${userOfAssignment.last_name || ''}`,
                }],
            );

            state = {
                ...state,
                assignments: mappedUsers,
            };
            break;
        case ACCOMMODATION_ACTION.REMOVE_ASSIGNMENT:
            const assignments = state.assignments.filter((assignment) => assignment !== action.assignment);
            state = {
                ...state,
                assignments: assignments,
            };
            break;
        case ACCOMMODATION_ACTION.SET_PLACES_DATA:
            state = {
                ...state,
                places: action.places,
                placesLoaded: true
            };
            break;
    }

    return state;
};
