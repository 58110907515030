import React, {Fragment} from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import {CommonOutlinedButton, StyledFancyButton} from "../../../common/StyledButton";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import TextInput from "./TextInput";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import SimpleDialog from "../../../common/SimpleDialog";

const colors = WEDDING_CONFIG.colors;

const StyledBlock = styled(ListItem)`
    && {
        padding: 10px 0;
        width: 100%;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin-bottom: 1rem;
        position: relative;
        
        &.inactive {
          opacity: 0.5;
        }
    }
`;

const StyledBlockLeft = styled.div`
    padding: 15px 80px 15px 15px;
`;

const StyledLabel = styled.div`
    font-size: 1.3rem;
    font-weight: 100;
    margin-bottom: 0.5rem;
`;

const StyledText = styled.div`
    font-size: 0.9rem;
    font-weight: 100;
`;

const StyledButton = styled(StyledFancyButton)`
    && {
    width: auto;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    }
`;

export default function SettingSpecialMenuBlock(props) {
    const { mainLabel, text, onClick, handleChange, menus, ...params } = props;
    const [dialogVisible, setDialogVisible] = React.useState(null);
    const [newName, setNewName] = React.useState(null);

    const handleClick = open => {
        setDialogVisible(open);
    };

    const handleClose = () => {
        setDialogVisible(false);
    };

    const handleDelete = chipToDelete => () => {
        handleChange(menus.filter(chip => chip !== chipToDelete))
    };

    const addMenu = newMenu => {
        handleChange([...(menus || []), newMenu]);
        setNewName('');
        handleClose();
    };

    const availableMenusSuggestions = ['bezglutenowe', 'wegetariańskie', 'wegańskie']
        .filter((name) => !menus.includes(name));

    return (
        <Fragment>
            <StyledBlock
                className={menus && menus.length ? '' : 'inactive'}
                button>
                <StyledBlockLeft>
                    <StyledLabel>{mainLabel}</StyledLabel>
                    {menus && menus.length ? (<StyledText>
                        {(menus || []).map(specialMenu => (<Chip
                            style={{marginRight: 5}}
                            key={specialMenu}
                            label={specialMenu}
                            onDelete={handleDelete(specialMenu)}
                        />))}
                    </StyledText>) : null}
                    <StyledButton
                        className={'size-small'}
                        onClick={() => handleClick(true)}>dodaj menu</StyledButton>
                </StyledBlockLeft>
            </StyledBlock>
            <SimpleDialog
                title={'Dodaj nowe menu'}
                open={dialogVisible}
                onClose={handleClose}
                scroll="paper"
                fullWidth={false}
                fullScreen={false}
                actions={<Fragment>
                    <CommonOutlinedButton onClick={handleClose}>
                        Anuluj
                    </CommonOutlinedButton>
                    <CommonOutlinedButton disabled={!newName} onClick={() => addMenu(newName)} className={'fill-primary'}>
                        Dodaj
                    </CommonOutlinedButton>
                </Fragment>}
            >
                <DialogContent>
                    {availableMenusSuggestions.length ? (<Fragment>
                        Wybierz z poniższych propozycji:
                        <div style={{margin: '15px 0'}}>
                            {availableMenusSuggestions.map((name) => (
                                <Chip style={{marginRight: 5}} label={name} onClick={() => addMenu(name)} />
                            ))}
                        </div>
                    </Fragment>) : null}
                    <TextInput
                        name="name"
                        key="name"
                        label={availableMenusSuggestions.length ? "... lub wpisz ręcznie" : "Wpisz nazwę"}
                        value={newName}
                        onChange={(e => {
                            setNewName(e.target.value);
                        })}
                        placeholder='Twoje menu...'
                    />
                </DialogContent>
            </SimpleDialog>
        </Fragment>
    )
}
