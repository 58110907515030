import { ACTION_NAMES } from '../actions';

export const auth = (state = {}, action) => {
    switch(action.type) {
        case ACTION_NAMES.SET_AUTHENTICATED:
            state = {
                ...state,
                user: action.user
            };
            break;
        case ACTION_NAMES.UPDATE_USER:
            state = {
                ...state,
                user: {
                    ...state.user,
                    ...action.user
                }
            };
            break;
        case ACTION_NAMES.UPDATE_INSTANCE:
            state = {
                ...state,
                instance: {
                    ...state.instance,
                    ...action.instance
                }
            };
            break;
        case ACTION_NAMES.UPDATE_USER_AND_INSTANCE:
            state = {
                ...state,
                user: action.user,
                instance: action.instance
            };
            break;
        case 'UNAUTHENTICATED':
            state = {
                ...state,
                user: null
            };
            break;
        case 'AUTHENTICATION_ERROR':
            state = {
                ...state,
                error: action.error
            };
            break;
    }

    return state;
};
