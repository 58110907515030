import React, { Component, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import store from '../store';
import { setError } from '../actions';
import TRANSLATE from '../translation';
import SimpleDialog from "./SimpleDialog";
import {CommonOutlinedButton} from "./StyledButton";

const StyledCircularProgress = styled(CircularProgress)`
    && {
        margin-bottom: 30px;
    }
`;

const StyledWrapper = styled.div`
    position: relative;
    top: 50vh;
    transform: translateY(-65%);
`;

const StyledDialogContent = styled(DialogContent)`
    width: 250px;
`;

class ErrorDialog extends Component {

    handleClose() {
        store.dispatch(setError(null));
    }

    getMessage() {
        if (typeof this.props.text !== 'string') {
            return '';
        }
        return TRANSLATE[this.props.text] || this.props.text;
    }

    render() {
        return (
            <SimpleDialog
                title={'Wystąpił błąd'}
                open={this.props.open}
                onClose={this.handleClose}
                fullWidth={false}
                fullScreen={false}
                actions={
                    <CommonOutlinedButton className={'fill-primary'} onClick={this.handleClose} color="primary">
                        OK
                    </CommonOutlinedButton>
                }
            >
                <StyledDialogContent className="font-primary">
                    {this.getMessage()}
                </StyledDialogContent>
            </SimpleDialog>
        )
    }
}

export default ErrorDialog;
