import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from 'styled-components';
import {StyledHeader, StyledBigCheckbox} from './commons';
import StyledCheckboxLabel from "../../../../common/StyledCheckboxLabel";
import {connect} from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";

const StyledTextArea = styled(TextField)`
    && {
        margin-top: 5px;
    }
`;

const StyledHeadText = styled.div`
    font-size: 1.2rem;
    text-align: center;
    margin: 0 auto 25px;
    width: 400px;
    max-width: 100%;
    line-height: 1.7rem;
`;

class MainFormSectionAdditional extends Component {

    render() {
        const { values, errors, touched, handleChange, handleBlur, submitForm, handleSubmit, isSubmitting, isValid }
            = this.props.formProps;

        if (!this.props.isActive) {
            return null;
        }

        return (
            <div>
                <StyledHeader>
                    Dodatkowo...
                </StyledHeader>

                {this.props.instance.special_menus && this.props.instance.special_menus.length ? (
                    <Fragment>
                        <Grid container spacing={0}>
                            <Grid item sm={6}>
                                <FormControlLabel
                                    labelPlacement={'start'}
                                    control={
                                        <Select
                                            style={{marginLeft: 10, padding: 10, fontSize: '1.6rem'}}
                                            displayEmpty
                                            name="main_special_menu"
                                            key="main_special_menu"
                                            value={values.main_special_menu || ''}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">zwykłe</MenuItem>
                                            {this.props.instance.special_menus.map((menu) => <MenuItem value={menu}>{menu}</MenuItem>)}
                                        </Select>
                                    }
                                    label={<StyledCheckboxLabel>Menu dla mnie:</StyledCheckboxLabel>}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                {values.is_with_partner ? (
                                    <FormControlLabel
                                        className="font-md"
                                        labelPlacement={'start'}
                                        control={
                                            <Select
                                                style={{marginLeft: 10, padding: 10, fontSize: '1.6rem'}}
                                                displayEmpty
                                                name="partner_special_menu"
                                                key="partner_special_menu"
                                                value={values.partner_special_menu || ''}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="">zwykłe</MenuItem>
                                                {this.props.instance.special_menus.map((menu) => <MenuItem value={menu}>{menu}</MenuItem>)}
                                            </Select>
                                        }
                                        label={<StyledCheckboxLabel>Menu dla partnera:</StyledCheckboxLabel>}
                                    />
                                ) : ''}
                            </Grid>
                        </Grid>
                        <div>

                        </div>

                    </Fragment>
                ) : ''}
                <div>
                    <p style={{ marginTop: '2rem', marginBottom: 0, fontSize: '1.2rem' }}>Chcesz nam coś jeszcze przekazać? Napisz poniżej.</p>
                    <StyledTextArea
                        key="participant_note"
                        name="participant_note"
                        multiline
                        rows="4"
                        fullWidth
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.participant_note || ''}
                    />
                </div>
            </div>
        );
    }

}

const mapStateToProps = (store) => {
    return {
        instance: store.auth.instance,
    }
};

export default connect(mapStateToProps)(MainFormSectionAdditional);
