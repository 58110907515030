import SimpleDialog from "../../../../common/SimpleDialog";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import React, {useEffect, useState, Fragment, useRef, useImperativeHandle} from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MediaSizes, {MediaRule} from "../../../../constants/media-sizes.constants";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {Badge, FormControlLabel, ListItemIcon, ListItemSecondaryAction, ListItemText, Switch} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import ChatService from '../../../../service/chat.service';
import LocalStorageService from '../../../../service/localstorage.service';
import {
    StyledChatBubbles, StyledChatMessageInput,
    StyledChatMessageLine,
    StyledMessagingArea, StyledMessagingBottomBar,
    StyledMessagingTopBar, StyledSendButton
} from "../../../../common/ChatMessages";
import WEDDING_CONFIG from "../../../../wedding-config";
import {StyledCard, StyledCardHeader} from "../../../../common/StyledCard";
import moment from "moment";
import {darken} from "polished";
import LocalStorageKeys from "../../../../constants/local-storage-key.constants";
import {useDispatch, useSelector} from "react-redux";

const colors = WEDDING_CONFIG.colors;

const StyledGridListContainer = styled(Grid)`
  //background: #feebf0;
  background: #fafafa;
  color: #c1758f;
  height: 100%;

  @media (min-width: ${MediaSizes.tabletPro}px) {
    overflow-y: auto;
  }
`;

const StyledNotSelectedChatArea = styled.div`
  text-align: center;
  padding: 10px;
  margin: 0 auto;
  max-width: 85%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const StyledNotSelectedChatInnerArea = styled.div`
  width: 100%;

  .icon {
    font-size: 2rem;
    color: #c1758f;
    margin-bottom: 20px;
  }

  .text {

  }
`;

const StyledList = styled(List)`

`;

const StyledListItem = styled(ListItem)`
  padding: 10px;

  &:not(:last-child) {
    //border-bottom: 1px solid #eee;
  }

  &.unread {
    background-color: rgba(189, 112, 137, 0.06);

    .MuiListItemText-primary, .MuiTypography-colorTextSecondary {
      font-weight: bold;
    }
  }

  &.is_announcement_chat {
    color: #458dff;
  }

  &.active, &.active:hover {
    background-color: #bd70892e;
    border-right: 2px solid;

    &.is_announcement_chat {
      background-color: ${colors.alternativeInfo};
      border-color: ${darken(0.1, colors.alternativeInfo)};
    }
  }
`;

const StyledNoChats = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  background: transparent;
  padding: 15px;
`;

const StyledFullCardContainer = styled(StyledCard)`
  && {
    @media (min-width: ${MediaSizes.tabletPro}px) {
      height: calc(100vh - 300px);
    }
  }
`;

const StyledTopSettings = styled.div`
  padding: 5px 15px;
  background-color: #fafafa;
  margin-bottom: 10px;
  .MuiTypography-root {
    font-size: 0.9rem;
  }
`;

const StyledSimpleDialog = styled(SimpleDialog)`
  .mobile-title {
    font-size: 1rem;
    line-height: normal;
    margin: 0;
  }

  .simpledialog-content.mobile {
    padding: 0 10px;
  }
`;

function AdminChat({ passedInRef }) {
    const isMobile = useMediaQuery(MediaRule.tablet);
    const appStore = useSelector(state => state.app);
    const [latestChats, setLatestChats] = useState(undefined);
    const [activeChat, setActiveChat] = useState(null); // participant id or announcement flag
    const prevActiveChat = usePrevious(activeChat); // participant id or announcement flag
    const [areMessagesDetailsExpanded, setAreMessagesDetailsExpanded] = useState(false);
    const [inputMessageValue, setInputMessageValue] = useState('');
    const [isValueValid, setIsValueValid] = useState(true);
    const [disableInput, setDisableInput] = useState(false);
    const [currentChatData, setCurrentChatData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const announcementCheckInLS = LocalStorageService.getFromLS(LocalStorageKeys.ChatAnnouncementVisible);
    const [isShowingAnnouncements, setIsShowingAnnouncements] = useState( typeof announcementCheckInLS === 'boolean' ? announcementCheckInLS : true);
    const messagesEndRef = useRef();
    const dispatch = useDispatch();
    const announcementFlag = 'announcement';

    function getChatsList() {
        setIsLoading(true);
        ChatService.getSuChatList().then((response) => {
            setLatestChats(response.data);
            setIsLoading(false);
        });
    }

    function getSingleChatMessages(participantId) {
        setDisableInput(true);
        setIsLoading(true);
        if (activeChat && prevActiveChat !== activeChat) {
            setCurrentChatData(undefined);
        }

        ChatService.getSuSingleChatMessages(participantId).then((response) => {
            setCurrentChatData(response.data);
            setDisableInput(false);
            setIsLoading(false);
            scrollChatToBottom();
        });
    }

    function getSingleChatAnnouncementMessages() {
        setDisableInput(true);
        setIsLoading(true);
        if (activeChat && prevActiveChat !== activeChat) {
            setCurrentChatData(undefined);
        }

        ChatService.getSuSingleChatAnnouncementMessages().then((response) => {
            setCurrentChatData(response.data);
            setDisableInput(false);
            setIsLoading(false);
            scrollChatToBottom();
        });
    }

    function onChatListItemClick(participantIdOrAnnouncement, item) {
        item.read = true;
        setActiveChat(participantIdOrAnnouncement);
    }

    function onSendButtonClick() {
        if (activeChat === announcementFlag) {
            if(!window.confirm('Uwaga! Wysyłasz ogłoszenie do wszystkich użytkowników. Kontynuować?')) {
                return;
            }
        }

        setDisableInput(true);

        const request = activeChat === announcementFlag ?
            ChatService.sendSuAnnouncementMessage(inputMessageValue) :
            ChatService.sendSuMessageToParticipant(activeChat, inputMessageValue);

        request.then((response) => {
            setCurrentChatData(response.data);
            setDisableInput(false);
            setInputMessageValue('');
            scrollChatToBottom();
            getChatsList();
        }).catch((e) => {
            setDisableInput(false);

            throw e;
        });
    }

    function scrollChatToBottom() {
        if (!messagesEndRef.current) {
            return;
        }
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }

    function loadSingleChat() {
        if (!activeChat) return;

        if (activeChat === announcementFlag) {
            getSingleChatAnnouncementMessages();
        } else {
            getSingleChatMessages(activeChat);
        }
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    useImperativeHandle(passedInRef, () => ({
        refresh: () => {
            getChatsList();
            loadSingleChat();
        }
    }));

    useEffect(() => {
        getChatsList();
    }, []);

    useEffect(() => {
        loadSingleChat();
        setInputMessageValue('');
    }, [activeChat]);

    useEffect(() => {
        setIsValueValid(inputMessageValue.length <= WEDDING_CONFIG.maxChatMessageLetters);
    }, [inputMessageValue]);

    useEffect(() => {
        if (typeof appStore.adminNewChatMessagesCount === 'number') {
            getChatsList();
            loadSingleChat();
        }
    }, [dispatch, appStore.adminNewChatMessagesCount]);

    const singleChatAreaContent = <Fragment>
        {currentChatData ? (
            <StyledMessagingArea>
                <StyledMessagingTopBar>
                    {!isMobile ? <h2 style={{ marginBottom: 10 }}>{activeChat === announcementFlag ? 'Ogłoszenia' : currentChatData.participantName}</h2> : null}
                    {activeChat === announcementFlag ?
                        <div style={{ marginBottom: 10 }} className={'info-box info-box--big'}>
                            Te wiadomości zobaczą wszyscy użytkownicy na swoich chatach.</div>
                        : null}
                    {activeChat && activeChat !== announcementFlag ?
                        <StyledTopSettings className={'info-box'}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isShowingAnnouncements}
                                        onChange={(e) => {
                                            LocalStorageService.setInLS(LocalStorageKeys.ChatAnnouncementVisible, e.target.checked);
                                            setIsShowingAnnouncements(e.target.checked);
                                            setTimeout(() => scrollChatToBottom());
                                        }}
                                        color="primary"
                                    />
                                }
                                label="Pokazuj ogłoszenia"
                            />
                        </StyledTopSettings>
                        : null}
                </StyledMessagingTopBar>
                <StyledChatBubbles ref={messagesEndRef}>
                    {currentChatData.history
                        .filter((item) => activeChat !== announcementFlag && item.is_announcement ? isShowingAnnouncements : true)
                        .map((chatMessage, i) => (
                            <StyledChatMessageLine
                                key={i}
                                className={`${chatMessage.sent_by_admin ? 'your' : 'guest'} ${chatMessage.is_announcement ? 'is_announcement' : ''} ${areMessagesDetailsExpanded ? 'expanded' : ''}`}>
                                <div className={'message'}
                                     onClick={() => setAreMessagesDetailsExpanded(!areMessagesDetailsExpanded)}>
                                    {chatMessage.text}

                                    {chatMessage.is_announcement ? <div className={'content'}>
                                        ogłoszenie, {moment(chatMessage.sent_at).format('lll')}
                                    </div> : null}

                                    {!chatMessage.is_announcement ? <div className={'content collapsed-content'}>
                                        {chatMessage.sent_by_admin ? <Fragment>
                                            {chatMessage.read_at ? <Fragment>
                                                przeczytano {moment(chatMessage.read_at).format('lll')}
                                            </Fragment> : <Fragment>
                                                nie przeczytano
                                            </Fragment>}
                                        </Fragment> : <Fragment>
                                            wysłano {moment(chatMessage.sent_at).format('lll')}
                                        </Fragment>}
                                    </div> : null}
                                </div>
                            </StyledChatMessageLine>
                        ))}
                </StyledChatBubbles>
                <StyledMessagingBottomBar>
                    <div style={{flex: '1'}}>
                        <StyledChatMessageInput
                            placeholder={i18next.t('chat.writeMessage')}
                            rows={1}
                            onChange={(e) => setInputMessageValue(e.target.value)}
                            value={inputMessageValue}
                            disabled={disableInput}
                            invalid={!isValueValid}></StyledChatMessageInput>
                    </div>
                    <div>
                        <StyledSendButton className={`fill-primary ${activeChat === announcementFlag ? 'is_announcement_chat' : ''}`} onClick={onSendButtonClick}
                                          color="primary" disabled={!inputMessageValue || disableInput}>
                            {i18next.t('chat.sendMessage')}
                        </StyledSendButton>
                    </div>
                </StyledMessagingBottomBar>
            </StyledMessagingArea>
        ) : null}
    </Fragment>;

    return <StyledFullCardContainer>
        {isMobile ? <Fragment>
            <StyledCardHeader>Chat</StyledCardHeader>
        </Fragment> : null}
        <Grid container style={{height: '100%'}}>
            <StyledGridListContainer item xs={12} sm={3}>
                {latestChats && latestChats.length ? (
                    <StyledList>
                        {latestChats.map((message, i) => (
                            <StyledListItem button
                                            key={i}
                                            className={`${!message.read ? 'unread' : null} ${(message.is_announcement_chat ? activeChat === announcementFlag : message.id_participant === activeChat) ? 'active' : ''} ${message.is_announcement_chat ? 'is_announcement_chat' : ''}`}
                                            onClick={() => onChatListItemClick(message.is_announcement_chat ? announcementFlag : message.id_participant, message)}>
                                <ListItemText
                                    primary={message.is_announcement_chat ? 'Twoje ogłoszenia' : message.from_name}
                                    secondary={message.preview_text}/>
                                {!message.read && !message.is_announcement_chat ? (
                                    <ListItemSecondaryAction>
                                        <Badge color="secondary" variant={'dot'}></Badge>
                                    </ListItemSecondaryAction>
                                ) : null}
                            </StyledListItem>
                        ))}
                    </StyledList>
                ) : <StyledNoChats>
                    <div style={{flex: 1}}>{isLoading ? 'Pobieram wiadomości...' : 'Brak wiadomości.'}</div>
                </StyledNoChats>}
            </StyledGridListContainer>
            <Grid item xs={12} sm={9}>
                {activeChat ? (
                    <Fragment>
                        {currentChatData && isMobile ? (
                            <StyledSimpleDialog
                                title={activeChat === announcementFlag ? 'Ogłoszenia' : currentChatData.participantName}
                                open={!!activeChat}
                                onClose={() => setActiveChat(null)}>
                                {singleChatAreaContent}
                            </StyledSimpleDialog>
                        ) : currentChatData ? singleChatAreaContent : null}
                    </Fragment>
                ) : (
                    !isMobile ? <StyledNotSelectedChatArea>
                        <StyledNotSelectedChatInnerArea>
                            <div><Icon className={'icon'}>question_answer</Icon></div>
                            <div className={'text'}>{i18next.t('chat.noChatSelectedMessage')}</div>
                        </StyledNotSelectedChatInnerArea>
                    </StyledNotSelectedChatArea> : null
                )}
            </Grid>
        </Grid>
    </StyledFullCardContainer>
}

export default withTranslation()(AdminChat);
