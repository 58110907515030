import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import WEDDING_CONFIG from '../../../wedding-config';
import api from '../../../api';
import MediaSizes from '../../../constants/media-sizes.constants';
import { animateScroll as scroll } from 'react-scroll';
import {CommonOutlinedButton} from "../../../common/StyledButton";
import {authService} from "../../../service";
import {withTranslation} from "react-i18next";
const colors = WEDDING_CONFIG.colors;

const StyledPage = styled.div`
  position: relative;
  top: 60px;
`;

const StyledPageTitle = styled.h1`
    text-align: center;
    font-size: 2rem;
    padding-bottom: 15px;
    color: ${colors.primary};
    position: relative;
    
`;

const StyledPageContent = styled.div`
    text-align: center;
    color: #555;
    
    &, p {
      font-size: 1rem;
    }
    
    h1, h2, h3, h4 {
        color: ${colors.primary};
    }
    
    p, ul, ol {
      margin: 5px 0;
    }
    
    @media (max-width: ${MediaSizes.tablet}px) {
        padding-bottom: 40px;
    }
`;

class ThankYouPage extends Component {
    state = {
        pageName: '',
        title: '',
        type: '',
        content: undefined
    };

    componentDidMount() {
        scroll.scrollToTop();
        this.loadPage('after_wedding');
    }

    loadPage(pageName) {
        api.request({
            url: api.routes.getContent.replace(':name', pageName),
            data: { pageName }
        }).then((response) => {
            let title = '';
            let content;
            let type = '';

            try {
                title = response.data.title;
            } catch(e) {}

            try {
                type = response.data.type;
            } catch(e) {}

            try {
                content = JSON.parse(response.data.content);
            } catch(e) {
                content = response.data.content;
            }

            this.setState({
                pageName: pageName,
                title: title,
                type: type,
                content: content
            })
        })
    }

    render() {
        return (
            <StyledPage>
                <StyledPageTitle className={'font-primary-narrow'}>{this.state.title}</StyledPageTitle>
                <StyledPageContent>
                    <div dangerouslySetInnerHTML={{__html: this.state.content || this.props.t('thankYouPage.defaultMessage') }}></div>
                    <div style={{marginTop: '3rem'}}>
                        <CommonOutlinedButton onClick={authService.logOff}>{this.props.t('common.logOut')}</CommonOutlinedButton>
                    </div>
                </StyledPageContent>
            </StyledPage>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app
    }
};

export default withTranslation()(connect(mapStateToProps)(ThankYouPage));
