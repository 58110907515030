import React, { Component } from 'react';
import { connect } from 'react-redux';
import WEDDING_CONFIG from "../../../wedding-config";
import {Stage, Layer, Rect, Text, Line, FastLayer, Group} from 'react-konva';
import {SNAP_SIZE} from "./tables-setup/constants";
import tableService from "../../../service/table.service";
import {TableCircle} from "./tables-setup/TableCircle";
import {UserSelectBox, UserSelectBoxType} from "./tables-setup/UserSelectBox";
import participantService from "../../../service/participant.service";
import {
    addAssignment,
    removeAssignment,
    setAssignments,
    setTablesData,
    setUsersList, updateTableInStore
} from "../../../actions/table.actions";
import store from '../../../store';
import {TableShapeActions} from "./tables-setup/TableShapeActions";
import TableNameDialog from "./tables-setup/TableNameDialog";
import {TableRectangle} from "./tables-setup/TableRectangle";

const colors = WEDDING_CONFIG.colors;

class TablesSetup extends Component {
    state = {
        stageWidth: 1400,
        userSelecting: null,
        tableEditingName: null,
        tableEditing: null,
        assignments: [],
        actionsTableData: null
    };
    stage;

    componentDidMount() {
        this.checkSize();

        if (this.props.tables.selectedTableSetId) {
            this.fetchData();
        }
        window.addEventListener("resize", this.checkSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkSize);
    }

    checkSize = () => {
        // this.setState({
            // stageWidth: this.container.offsetWidth
        // });
    };

    fetchData() {
        Promise.all([
            tableService.getTables(this.props.tables.selectedTableSetId),
            tableService.getAssignments(this.props.tables.selectedTableSetId),
            participantService.getParticipantsListForTablesSetup(),
        ]).then(([data, assignments, usersData]) => {
            const tablesData = data.data.map((table) => ({
                ...table,
                data: JSON.parse(table.data),
            }));
            store.dispatch(setTablesData(tablesData));
            store.dispatch(setUsersList(usersData.data));
            store.dispatch(setAssignments(assignments.data));
        });
    }

    tableRotationChange(id, rotation) {
        tableService.updateRotation(this.props.tables.selectedTableSetId, id, rotation);
    }

    tablePositionChange(id, x, y) {
        tableService.updatePosition(this.props.tables.selectedTableSetId, id, x / SNAP_SIZE, y / SNAP_SIZE);
    }

    onSeatClick(e, seatId, tableId, assignment) {
        const panelWidth = 330;
        const panelHeight = 530;
        let x = e.evt.pageX + panelWidth > window.innerWidth ? window.innerWidth - panelWidth : e.evt.pageX;
        let y = e.evt.pageY + panelHeight > window.innerHeight ? window.innerHeight - panelHeight : e.evt.pageY;
        this.setState({
            userSelecting: {
                id_table: tableId,
                id_seat: seatId,
                assignment: assignment,
                x,
                y,
            }
        })
    }

    onActionsClick(tableData) {
        this.setState({
            actionsTableData: tableData
        })
    }

    onUserSelect(user) {
        store.dispatch(addAssignment(this.props.tables.selectedTableSetId, {
            id_participant: user.id,
            participant_type: user.participant_type,
            participant_index: user.participant_index,
            name: user.first_name,
            id_seat: this.state.userSelecting.id_seat,
            id_table: this.state.userSelecting.id_table
        }));
        this.setState({
            userSelecting: null,
        });
    }

    onRemove(assignment) {
        store.dispatch(removeAssignment(this.props.tables.selectedTableSetId, assignment));

        this.setState({
            userSelecting: null,
        });
    }

    hideUserPanel(e) {
        if (e && e.target.id !== 'backdrop') {
            return false;
        }
        this.setState({
            userSelecting: null
        })
    }

    disableSeat(e) {
        const seatId = this.state.userSelecting.id_seat;
        const tableId = this.state.userSelecting.id_table;

        tableService.disableTableSeat(this.props.tables.selectedTableSetId, tableId, seatId)
            .then((response) => {
                this.handleCloseShapeActions();
                this.hideUserPanel();
                store.dispatch(updateTableInStore(tableId, response.data.updatedRecord));
            });
    }

    onTableRemoveClick(tableId) {
        if (!window.confirm('Czy na pewno chcesz usunąć ten stół?')) {
            return;
        }
        tableService.removeTable(this.props.tables.selectedTableSetId, tableId)
            .then(() => {
                this.handleCloseShapeActions();
                this.fetchData();
            });
    }

    onTableRotate(tableId, direction) {
        const currentRotation = this.props.tables.tables.find((table) => table.id === tableId).rotate || 0;
        const stepRotation = 90;
        tableService.updateRotation(this.props.tables.selectedTableSetId, tableId, calculateNewRotation())
            .then(() => {
                this.handleCloseShapeActions();
                this.fetchData();
            });

        function calculateNewRotation() {
            if (!currentRotation && direction === 'left') {
                return 270;
            }
            if (currentRotation >= 270 && direction === 'right') {
                return 0;
            }
            return direction === 'left' ? currentRotation - stepRotation : currentRotation + stepRotation;
        }
    }

    handleCloseShapeActions() {
        this.setState({
            actionsTableData: null
        });
    }

    handleTableEdit(id = null) {
        let name = null;
        if (id) {
            const table = this.props.tables.tables.find((tab) => tab.id === id);

            if (table) {
                name = table.table_name;
            }
        }
        this.setState({
            tableEditing: id,
            tableEditingName: name,
            actionsTableData: null
        });
    }

    handleRestoreSeats(tableId ) {
        if (window.confirm('Wszystkie ukryte miejsca przy tym stole zostaną przywrócone.')) {
            tableService.restoreDisabledSeats(this.props.tables.selectedTableSetId, tableId)
                .then(() => {
                    this.handleCloseShapeActions();
                    this.fetchData();
                });
        }
    }

    renderTables() {
        return this.props.tables.tables.map((table) => {
            const x = table.x * SNAP_SIZE || undefined;
            const y = table.y * SNAP_SIZE || 10;
            switch(table.type) {
                case 'rectangle': // legacy!
                case 'rect':
                    return (
                        <TableRectangle
                            isLegacy={table.type === 'rectangle'}
                            snapSize={SNAP_SIZE}
                            tableRotationChange={this.tableRotationChange.bind(this)}
                            tablePositionChange={this.tablePositionChange.bind(this)}
                            stage={this.stage}
                            x={x}
                            y={y}
                            rotation={table.rotate || 0}
                            key={table.id}
                            id={table.id}
                            onActionsClick={this.onActionsClick.bind(this)}
                            onSeatClick={this.onSeatClick.bind(this)}
                            onTableRemoveClick={this.onTableRemoveClick.bind(this)}
                            seatAssignments={this.props.tables.assignments}
                            tables={this.props.tables.tables}
                            width={table.data.width}
                            height={table.data.height}
                            seatsData={table.data.seats}
                            name={table.table_name} />
                    );
                    break;
                case 'circle':
                    return (
                        <TableCircle
                            snapSize={SNAP_SIZE}
                            tableRotationChange={this.tableRotationChange.bind(this)}
                            tablePositionChange={this.tablePositionChange.bind(this)}
                            stage={this.stage}
                            x={x}
                            y={y}
                            rotation={table.rotate || 0}
                            key={table.id}
                            tables={this.props.tables.tables}
                            id={table.id}
                            onActionsClick={this.onActionsClick.bind(this)}
                            onSeatClick={this.onSeatClick.bind(this)}
                            onTableRemoveClick={this.onTableRemoveClick.bind(this)}
                            seatAssignments={this.props.tables.assignments}
                            seatsNumber={table.data.seats.length}
                            seatsData={table.data.seats}
                            name={table.table_name} />
                    );
                    break;
            }
        });
    }

    render() {
        const width = this.state.stageWidth;
        const height = width * 1.41;
        const snapSize = SNAP_SIZE;
        return (
            <div style={{ maxWidth: '1400px', overflow: 'auto', margin: '0 auto'}} ref={node => {
                this.container = node;
            }}>
                {this.state.userSelecting ? (
                    <UserSelectBox
                        usersList={this.props.tables.availableUsersList}
                        assigned={this.state.userSelecting.assignment}
                        topActions={[
                            {
                                label: 'Ukryj to miejsce',
                                action: this.disableSeat.bind(this),
                                disabled: !!this.state.userSelecting.assignment
                            }
                        ]}
                        hideUserPanel={this.hideUserPanel.bind(this)}
                        handleSelect={this.onUserSelect.bind(this)}
                        handleRemove={this.onRemove.bind(this)}
                        type={UserSelectBoxType.Tables}
                        x={this.state.userSelecting.x}
                        y={this.state.userSelecting.y}/>
                    ) : null}
                {this.state.tableEditing ? (
                    <TableNameDialog
                        setId={this.props.tables.selectedTableSetId}
                        tableId={this.state.tableEditing}
                        tableName={this.state.tableEditingName}
                        handleClose={this.handleTableEdit.bind(this)} />
                ) : null}

                {this.state.actionsTableData ? (
                    <TableShapeActions
                        open={true}
                        handleRestoreSeats={this.handleRestoreSeats.bind(this)}
                        handleNameChange={this.handleTableEdit.bind(this)}
                        handleClose={this.handleCloseShapeActions.bind(this)}
                        handleRotate={this.onTableRotate.bind(this)}
                        handleDelete={this.onTableRemoveClick.bind(this)}
                        tableData={this.state.actionsTableData}/>
                ) : null}
                <Stage
                    ref={(ref) => this.stage = ref}
                    width={width}
                    height={height}>
                    <FastLayer hitGraphEnabled={false}>
                        {[...Array(Math.round(width / snapSize))].map((value, i) => (
                            <Line
                                key={`vertical-${i}`}
                                points={[snapSize * i, 0, snapSize * i, height]}
                                strokeWidth={0.5}
                                stroke={'#ddd'} />
                        ))}
                        {[...Array(Math.round(height / snapSize))].map((value, i) => (
                            <Line
                                key={`horizontal-${i}`}
                                points={[0,snapSize * i,width,snapSize * i]}
                                strokeWidth={0.5}
                                stroke={'#ddd'} />
                        ))}
                    </FastLayer>
                    <Layer>
                        {this.stage ? this.renderTables() : null}
                    </Layer>
                </Stage>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        instance: state.auth.instance,
        tables: state.tables
    }
};

export default connect(mapStateToProps, null, null,{ forwardRef: true })(TablesSetup);
