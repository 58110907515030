import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {StyledTooltip} from "../../../../common/TooltipTitle";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import UserEditAction from "./UserEditAction";
import UsersListParticipantCodeDialog from "./UsersListParticipantCodeDialog";
import ENV from "../../../../config/environment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";
import participantService from "../../../../service/participant.service";
import styled from "styled-components";
import {UserFormView} from "./UserEditForm";

const StyledIconButton = styled(IconButton)`
  &.mobile {
    position: relative;
    left: 8px;
    top: -8px;
  }
`;

export default function UsersListActions(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMobile = useMediaQuery(MediaRule.tablet);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    function updateUserActiveState(id, currentState) {
        participantService.updateParticipantActiveParam(id, {
            is_active: !currentState
        }).then(() => {
            props.onUserUpdate();
            handleClose();
        })
    }

    return (
        <div>
            <StyledIconButton className={isMobile ? 'mobile' : ''} onClick={handleClick}>
                <Icon>more_vert</Icon>
            </StyledIconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                tabIndex={-1}
            >
                <UserEditAction userId={props.user.id} instance={props.instance} onUserUpdate={() => props.onUserUpdate()} focusComponent={UserFormView.DECLARATION} handleClose={handleClose}>
                    <MenuItem>Deklaracja</MenuItem>
                </UserEditAction>
                <UserEditAction userId={props.user.id} instance={props.instance} onUserUpdate={() => props.onUserUpdate()} handleClose={handleClose}>
                    <MenuItem>Zmień dane gościa</MenuItem>
                </UserEditAction>
                {ENV.IS_PREMIUM ? (
                    <UsersListParticipantCodeDialog user={props.user}>
                        <MenuItem>Kod logowania</MenuItem>
                    </UsersListParticipantCodeDialog>
                ) : null}
                <MenuItem onClick={() => updateUserActiveState(props.user.id, props.user.is_active)}>{props.user.is_active ? 'Dezaktywuj' : 'Aktywuj'}</MenuItem>
                <MenuItem onClick={() => props.delete(() => handleClose())}>Usuń gościa</MenuItem>
            </Menu>
        </div>
    );
}
