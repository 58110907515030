import {Component, Fragment} from "react";
import React from "react";
import api from "../../../../api";
import {Formik} from "formik";
import { CommonOutlinedButton } from '../../../../common/StyledButton';
import {connect} from "react-redux";
import TextInput, {StyledInputBase} from "../../partials/TextInput";
import {withSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import appService from "../../../../service/app.service";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from 'styled-components';
import Checkbox from "@material-ui/core/Checkbox";
import {StyledCardContent} from "../../../../common/StyledContentCard";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TRANSLATE from "../../../../translation";
import store from "../../../../store";
import {setError, updateInstance} from "../../../../actions";
import ENV from "../../../../config/environment";
import {StyledCardHeader} from "../../../../common/StyledCard";
import PremiumBuyItem from "../PremiumBuyItem";
import {DEFAULT_GRID_SPACING} from "../../../../constants";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const StyledDomainField = styled.div`
  display: flex;
  position: relative;
    
    .MuiSelect-selectMenu {
      font-size: 1.2rem!important;
      padding-top: 20px!important;
    }
  
  .instance_name {
    width: 100%;
    
    input {
        text-align: right;
    }
  }
  
  .domain {
    width: 100%;
    margin-left: 10px;
  }
  
  .indicator {
    position: absolute;
    left: 0;
    bottom: 10px;

    &:before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #ccc;
      border-radius: 50%;
    }
    
    &.available:before {
      background: green;
    }
    
    &.unavailable:before {
      background: red;
    }
  }
  
  .error-msg {
    border-radius: 4px 4px 4px 0;
    position: absolute;
    top: 0;
    font-size: 0.8rem;
    color: white;
    background: black;
    padding: 1px 5px;
  }
`;

const StyledFormContainer = styled.div`
  max-width: 100%;
  width: 600px;
`;

const StyledPromoInput = styled.div`
  input {
      width: 200px;
      font-size: 0.9rem;
  }
  
  .msg {
    color: green;
      font-size: 0.8rem;
  }
  .error {
    color: red;
  }
`;

class ActivateForm extends Component {
    state = {
        invoiceData: null,
        domains: [],
        instanceNameAvailable: null,
        instanceNameErrorMsg: null,
        debounceInterval: null,
        showPromoCodeInput: false,
        price: this.props.app.premiumPrice,
        promoCodeResult: null,
        arePromoCodesAvailable: false
    };

    componentDidMount() {
        this.setState({
            invoiceData: this.props.data
        });
        this.getDomains();
        this.checkIfAnyPromoCodesAvailable();
    }

    getDomains() {
        api.request({
            url: api.suRoutes.getInstanceDomains,
            method: 'get',
        }).then((response) => {
            this.setState({
                domains: response.data.domains
            });
        });
    }

    checkIfAnyPromoCodesAvailable() {
        const referralPromoCode = this.props.instance.referral_promo_code;

        if (referralPromoCode) {
            this.setState({
                price: referralPromoCode.newPrice,
                promoCodeResult: referralPromoCode
            });
        }
        appService.arePromoCodesAvailable().then((response) => {
            this.setState({
                arePromoCodesAvailable: response.data.areAvailable
            })
        });
    }

    checkDomain(name, domain) {
        if (!name || !domain) {
            this.setState({
                instanceNameAvailable: null,
                instanceNameErrorMsg: null
            });
            return;
        }
        api.request({
            url: api.suRoutes.checkInstanceExistance.replace(':name', name).replace(':domain', domain),
            method: 'get',
        }).then((response) => {
            this.setState({
                instanceNameAvailable: response.status === 'success',
                instanceNameErrorMsg: null
            });
        }).catch((error) => {
            this.setState({
                instanceNameAvailable: false,
                instanceNameErrorMsg: error.response.data.message
            });
        })
    }

    checkPromoCode(promoCode) {
        if (!promoCode) {
            return;
        }
        api.request({
            url: api.suRoutes.checkPromoCode.replace(':code', promoCode.toUpperCase()),
            method: 'get',
        }).then((response) => {
            const result = response.data;

            if (result.isValid) {
                this.setState({
                    price: result.newPrice,
                    promoCodeResult: result
                });
            } else {
                this.setState({
                    price: this.props.app.premiumPrice,
                    promoCodeResult: result
                });
            }
        }).catch((error) => {
            this.removePromoCode();
        })
    }

    removePromoCode() {
        this.setState({
            price: this.props.app.premiumPrice,
            promoCodeResult: null,
            showPromoCodeInput: false
        });
    }

    render() {
        if (!this.state.invoiceData) {
            return null;
        }
        return (
            <Formik
                initialValues={{
                    instance_name: '',
                    domain: 'fancy.wedding',
                    first_name: this.props.user.first_name,
                    partner_first_name: '',
                    place: '',
                    invoice: {
                        is_company: false,
                        // is_company: this.state.invoiceData.is_company,
                        company: this.state.invoiceData.company,
                        nip: this.state.invoiceData.nip,
                        first_name: this.state.invoiceData.first_name,
                        last_name: this.state.invoiceData.last_name,
                        address: this.state.invoiceData.address,
                        post_code: this.state.invoiceData.post_code,
                        city: this.state.invoiceData.city,
                    },
                    promo_code: this.props.instance.referral_promo_code ? this.props.instance.referral_promo_code.name : null,
                    check_terms: false,
                    check_data: false,
                    // check_email: false,
                }}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    setSubmitting(true);
                    const data = { ...values };
                    if (this.state.promoCodeResult && !this.state.promoCodeResult.isValid) {
                        data.promo_code = null;
                    }
                    api.request({
                        url: api.suRoutes.orderPremium,
                        method: 'post',
                        data: {...data, name: values.instance_name}
                    }).then((response) => {
                        this.props.toggleForm(true, response.data);
                        store.dispatch(updateInstance({
                            ...this.props.instance,
                            is_premium_creation_in_progress: true,
                            is_account_deletion_enabled: false
                        }));
                    }).catch((e) => {
                        store.dispatch(setError(TRANSLATE.now(e.response.data.message, { paramScope: 'activateForm' })));
                    })
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, submitForm, handleSubmit, setFieldValue, isSubmitting, isValid, validateForm }) => {
                    return (
                        <Fragment>
                            <StyledCardHeader className={'text-center'}>
                                Zamów pakiet Premium fancy.wedding
                                <div className={'subtitle'}>
                                    Poniżej znajduje się krótki formularz zamówienia.
                                    <br />Potrzebujemy tych danych do stworzenia Twojej ślubnej strony.
                                </div>
                            </StyledCardHeader>
                            <StyledCardContent>
                                <br />
                                <br />
                                <StyledFormContainer>
                                    <StyledCardHeader>
                                        Dane aplikacji
                                        <div className={'subtitle'}>
                                            Wpisz nazwę rozpoczynającą Twój nowy adres WWW oraz wybierz domenę.
                                        </div>
                                    </StyledCardHeader>
                                    <StyledDomainField className={'form-group'}>
                                        <span className={`indicator ${this.state.instanceNameAvailable === true ? 'available' : (this.state.instanceNameAvailable === false ? 'unavailable' : '')}`}></span>
                                        {this.state.instanceNameErrorMsg ? (
                                            <div className={'error-msg'}>{TRANSLATE.now(this.state.instanceNameErrorMsg, { onlyFirst: true })}</div>) : null}
                                        <TextInput
                                            className={'instance_name'}
                                            name="instance_name"
                                            key="instance_name"
                                            placeholder={'np. monika-dawid'}
                                            value={values.instance_name}
                                            label=""
                                            onChange={(e) => {
                                                handleChange(e);
                                                const name = e.target.value;
                                                if (this.state.debounceInterval) {
                                                    clearTimeout(this.state.debounceInterval);
                                                }
                                                this.setState({
                                                    debounceInterval: setTimeout(() => {
                                                        this.checkDomain(name, values.domain);
                                                    }, 700)
                                                });
                                            }}
                                        />
                                        <Select
                                            className={'domain'}
                                            name="domain"
                                            key="domain"
                                            value={values.domain}
                                            onChange={(e) => {
                                                handleChange(e);
                                                this.checkDomain(values.instance_name, e.target.value);
                                            }}
                                        >
                                            {this.state.domains.map((domain) => <MenuItem value={domain}>.{domain}</MenuItem>)}
                                        </Select>
                                    </StyledDomainField>

                                    <Grid container className={'form-group'} spacing={DEFAULT_GRID_SPACING}>
                                        <Grid item xs={6}>
                                            <TextInput
                                                name="first_name"
                                                key="first_name"
                                                value={values.first_name}
                                                label={TRANSLATE.scoped('activateForm', 'first_name') + ' *'}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                name="partner_first_name"
                                                key="partner_first_name"
                                                value={values.partner_first_name}
                                                label={TRANSLATE.scoped('activateForm', 'partner_first_name') + ' *'}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'form-group'} spacing={DEFAULT_GRID_SPACING}>
                                        <Grid item xs={6}>
                                            <TextInput
                                                name="place"
                                                key="place"
                                                value={values.place}
                                                label={TRANSLATE.scoped('activateForm', 'place') + ' *'}
                                                placeholder={'np. Warszawa'}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                        </Grid>
                                    </Grid>
                                </StyledFormContainer>
                            </StyledCardContent>

                            <br />
                            <br />
                            <StyledCardHeader>
                                Dane do zamówienia
                                <div className={'subtitle'}>
                                    Dane zostaną użyte do wygenerowania faktury Proforma, a potem faktury VAT.
                                </div>
                            </StyledCardHeader>
                            <StyledCardContent>
                                <StyledFormContainer>
                                    {/*<RadioGroup
                                        name="invoice.is_company"
                                        key="is_company"
                                        value={!!values.invoice.is_company}
                                        onChange={(obj, value) => {
                                            setFieldValue('invoice.is_company', value === 'true');
                                        }}>
                                        <FormControlLabel value={false} checked={!values.invoice.is_company} control={<Radio />} label="Osoba prywatna" />
                                        <FormControlLabel value={true} checked={!!values.invoice.is_company} control={<Radio />} label="Firma" />
                                    </RadioGroup>
                                    <br />*/}
                                    {values.invoice.is_company ? (
                                        <Grid container className={'form-group'} spacing={DEFAULT_GRID_SPACING}>
                                            <Grid item xs={6}>
                                                <TextInput
                                                    name="invoice.company"
                                                    key="company"
                                                    value={values.invoice.company}
                                                    label={TRANSLATE.scoped('activateForm', 'invoice.company') + ' *'}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextInput
                                                    name="invoice.nip"
                                                    key="company"
                                                    value={values.invoice.nip}
                                                    label={TRANSLATE.scoped('activateForm', 'invoice.nip') + ' *'}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : ''}
                                    <Grid container className={'form-group'} spacing={DEFAULT_GRID_SPACING}>
                                        <Grid item xs={12} sm={6}>
                                            <TextInput
                                                name="invoice.first_name"
                                                key="first_name"
                                                value={values.invoice.first_name}
                                                label={TRANSLATE.scoped('activateForm', 'invoice.first_name') + " *"}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextInput
                                                name="invoice.last_name"
                                                key="last_name"
                                                value={values.invoice.last_name}
                                                label="Nazwisko *"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'form-group'} spacing={DEFAULT_GRID_SPACING}>
                                        <Grid item xs={12} sm={12}>
                                            <TextInput
                                                name="invoice.address"
                                                key="address"
                                                value={values.invoice.address}
                                                label={TRANSLATE.scoped('activateForm', 'invoice.address') + ' *'}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'form-group'} spacing={DEFAULT_GRID_SPACING}>
                                        <Grid item xs={12} sm={6}>
                                            <TextInput
                                                name="invoice.post_code"
                                                key="post_code"
                                                value={values.invoice.post_code}
                                                label={TRANSLATE.scoped('activateForm', 'invoice.post_code') + ' *'}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextInput
                                                name="invoice.city"
                                                key="city"
                                                value={values.invoice.city}
                                                label={TRANSLATE.scoped('activateForm', 'invoice.city') + ' *'}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>

                                    <br />
                                    <br />

                                    <StyledCardHeader>
                                        Podsumowanie
                                    </StyledCardHeader>

                                    {this.props.instance.referral ? <div className={'info-box'}>
                                        Twój polecający <strong>{this.props.instance.referral.ref_full_name}</strong> udostępnił Ci kod rabatowy.
                                    </div> : null}

                                    {this.state.arePromoCodesAvailable || this.state.promoCodeResult ? (<div>
                                        {(this.state.showPromoCodeInput || values.promo_code) ? <StyledPromoInput className={'flex'}>
                                                <StyledInputBase
                                                    placeholder={'Kod promocyjny'}
                                                    name="promo_code"
                                                    value={values.promo_code}
                                                    key="promo_code"
                                                    disabled={this.state.promoCodeResult && this.state.promoCodeResult.isValid}
                                                    onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            {this.state.promoCodeResult && this.state.promoCodeResult.isValid ? (
                                                                <Tooltip title={'Usuń'}>
                                                                    <IconButton
                                                                        size={'small'}
                                                                        onClick={() => {
                                                                            this.removePromoCode();
                                                                            setFieldValue('promo_code', null);
                                                                        }}
                                                                        edge="end"
                                                                    >
                                                                        <Icon>close</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                ) : (
                                                                    <Tooltip title={'Zatwierdź'}>
                                                                        <IconButton
                                                                            size={'small'}
                                                                            onClick={() => {
                                                                                this.checkPromoCode(values.promo_code);
                                                                            }}
                                                                            edge="end"
                                                                        >
                                                                            <Icon>tick</Icon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}

                                                        </InputAdornment>
                                                    }
                                                />
                                                {this.state.promoCodeResult ? <div className={'msg ' + (this.state.promoCodeResult.isValid === false ? 'error' : '')}>{TRANSLATE[this.state.promoCodeResult.message] || this.state.promoCodeResult.message}</div> : null}
                                            </StyledPromoInput> :
                                            <CommonOutlinedButton className={'size-small'} onClick={() => this.setState({ showPromoCodeInput: true })}>Mam kod promocyjny</CommonOutlinedButton>}
                                    </div>) : null}

                                    <br />
                                    <PremiumBuyItem extended={true} priceGross={this.state.price} hasDiscount={this.state.promoCodeResult?.isValid}></PremiumBuyItem>
                                    <br />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="check_terms"
                                                key="check_terms"
                                                checked={values.check_terms}
                                                value={true}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={
                                            <div>
                                                Wyrażam chęć zakupu i aktywacji aplikacji fancy.wedding oraz zapoznałem się z <a href="https://www.fancy.wedding/regulamin" target="_blank">regulaminem aplikacji</a> i akceptuje jego treść.*
                                            </div>
                                        }
                                    />
                                    <br />
                                    <br />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="check_data"
                                                key="check_data"
                                                checked={values.check_data}
                                                value={true}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={
                                            <div>
                                                Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w powyższym formularzu, w celu zapewnienia funkcjonalności
                                                aplikacji fancy.wedding, przez ElderCodes Rafał Radziszewski i oświadczam, iż podanie przeze mnie danych osobowych
                                                jest dobrowolne oraz iż zostałem poinformowany o prawie żądania dostępu do moich danych osobowych, ich zmiany oraz usunięcia (
                                                <a href={ENV.WEBSITE_URL + '/przetwarzanie-danych-osobowych'} target="_blank">przetwarzanie danych osobowych</a>).*
                                            </div>
                                        }
                                    />
                                    <br />
                                    <br />
                                    {/*<FormControlLabel
                                        control={
                                            <Checkbox
                                                name="check_email"
                                                key="check_email"
                                                checked={values.check_email}
                                                value={true}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={
                                            <div>
                                                Wyrażam zgodę na korespondencję poprzez adres e-mail w celu wysłania dokumentu płatności i komunikacji związanej z przebiegiem
                                                procesu zakupowego.*
                                            </div>
                                        }
                                    />*/}
                                    <div className={'text-center'}>
                                        <CommonOutlinedButton className={'size-large'} type="submit" onClick={handleSubmit} disabled={!isValid}>Zamawiam z obowiązkiem zapłaty</CommonOutlinedButton>
                                    </div>
                                </StyledFormContainer>
                            </StyledCardContent>
                        </Fragment>
                    )
                }}
            </Formik>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        instance: state.auth.instance,
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(withSnackbar(ActivateForm));
