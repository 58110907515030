import accommodationService from '../service/accommodation.service';
import store from "../store";
import {setError, setSuccess} from "./index";
import TRANSLATE from "../translation";

export const ACCOMMODATION_ACTION = {
    SET_SELECTED_SET_ID: 'SET_SELECTED_ACCOMMODATION_SET_ID',
    GET_SETS: 'GET_SETS',
    SET_SETS_DATA: 'SET_SETS_DATA',
    SET_PLACES_DATA: 'SET_PLACES_DATA',
    SET_TABLES_DATA: 'SET_TABLES_DATA',
    SET_USER_LIST: 'SET_USER_LIST',
    SET_AVAILABLE_USERS_LIST: 'SET_AVAILABLE_USERS_LIST',
    SET_ASSIGNMENTS: 'SET_ASSIGNMENTS',
    REMOVE_SET: 'REMOVE_SET',
    ADD_ROOM: 'ADD_ROOM',
    EDIT_ROOM: 'EDIT_ROOM',
    REMOVE_ROOM: 'REMOVE_ROOM',
    ADD_PLACE: 'ADD_PLACE',
    EDIT_PLACE: 'EDIT_PLACE',
    REMOVE_PLACE: 'REMOVE_PLACE',
    ADD_ASSIGNMENT: 'ADD_ASSIGNMENT',
    REMOVE_ASSIGNMENT: 'REMOVE_ASSIGNMENT',
};

const handleError = (e, scope) => {
    let msg;

    try {
        msg = e.response.data.message;
    } catch(e) {
        msg = '';
    }

    store.dispatch(setError(TRANSLATE.now(msg, scope ? { paramScope: scope } : undefined)));
    throw 'proceed';
};

const setSelectedSet = (id) => ({
    type: ACCOMMODATION_ACTION.SET_SELECTED_SET_ID,
    id
});

const setPlacesData = (places) => ({
    type: ACCOMMODATION_ACTION.SET_PLACES_DATA,
    places
});

export const setSelectedSetId = (idSet) => (dispatch) => {
    if (!idSet) {
        dispatch(setPlacesData([]));
        dispatch(setAssignments([]));
        dispatch(setSelectedSet(idSet));
        return;
    }
    dispatch(() => Promise.all([
        accommodationService.getPlaces(idSet).catch((e) => dispatch(setSelectedSet(null))),
        accommodationService.getAssignments(idSet),
    ]))
        .then(([placesResponse, assignmentsResponse]) => {
            dispatch(setPlacesData(placesResponse.data));
            dispatch(setAssignments(assignmentsResponse.data));
            dispatch(setSelectedSet(idSet));
        });
};

const setSetsListToStore = (sets) => ({
    type: ACCOMMODATION_ACTION.SET_SETS_DATA,
    sets
});

export const getSets = () => (dispatch) => {
    return dispatch(() => accommodationService.getSets()).then((response) => {
        store.dispatch(setSetsListToStore(response.data));
    }).catch((e) => handleError(e))
};

export const setUsersList = (usersList) => ({
    type: ACCOMMODATION_ACTION.SET_USER_LIST,
    usersList
});

export const setAvailableUsersList = () => ({
    type: ACCOMMODATION_ACTION.SET_AVAILABLE_USERS_LIST,
});

const setAssignmentsToStore = (assignments) => ({
    type: ACCOMMODATION_ACTION.SET_ASSIGNMENTS,
    assignments
});

export const setAssignments = (assignments) => (dispatch) => {
    dispatch(setAssignmentsToStore(assignments));
    dispatch(setAvailableUsersList());
};

export const addSet = (set) => (dispatch) => {
    return dispatch(() => accommodationService.addSet(set)).then((response) => {
        store.dispatch(getSets());
        store.dispatch(setSelectedSetId(response.data.id));
        store.dispatch(setSuccess('Plan został stworzony.'));
    }).catch((e) => handleError(e, 'accommmodationSet'))
};

const removeSetFromStore = (placeId) => ({
    type: ACCOMMODATION_ACTION.REMOVE_SET,
    placeId,
});

export const removeSet = (setId) => (dispatch) => {
    return dispatch(() => accommodationService.removeSet(setId)).then((response) => {
        store.dispatch(removeSetFromStore(setId));
        store.dispatch(getSets());
        if (setId === store.getState().accommodation.selectedAccommodationSetId) {
            store.dispatch(setSelectedSetId(null));
        }
        store.dispatch(setSuccess('Plan został usunięty.'));
    }).catch((e) => handleError(e))
};

const addNewPlaceToStore = (idSet, place) => ({
    type: ACCOMMODATION_ACTION.ADD_PLACE,
    place,
});

export const addPlace = (idSet, place) => (dispatch) => {
    return dispatch(() => accommodationService.addPlace(idSet, place)).then((response) => {
        store.dispatch(setSuccess('Miejsce zostało dodane'));
        return accommodationService.getPlaces(idSet).then((placesResponse) => {
            return dispatch(setPlacesData(placesResponse.data));
        })
    }).catch((e) => handleError(e, 'accommodationPlace'))
};

const editPlaceInStore = (placeId, placeData) => ({
    type: ACCOMMODATION_ACTION.EDIT_ROOM,
    placeId,
    placeData,
});

export const editPlace = (placeId, placeData) => (dispatch) => {
    return dispatch(() => accommodationService.editPlace(placeId, placeData)).then((response) => {
        store.dispatch(setSuccess('Miejsce zostało zmienione.'));
        store.dispatch(editPlaceInStore(placeId, placeData));
    }).catch((e) => handleError(e, 'accommodationPlace'))
};

const removePlaceFromStore = (placeId) => ({
    type: ACCOMMODATION_ACTION.REMOVE_PLACE,
    placeId,
});

export const removePlace = (placeId) => (dispatch) => {
    return dispatch(() => accommodationService.removePlace(placeId)).then((response) => {
        store.dispatch(removePlaceFromStore(placeId));
        store.dispatch(setAvailableUsersList());
        store.dispatch(setSuccess('Miejsce zostało usunięte.'));
    }).catch((e) => handleError(e, 'accommodationPlace'))
};

const addNewRoomToStore = (idPlace, room) => ({
    type: ACCOMMODATION_ACTION.ADD_ROOM,
    room,
    idPlace
});

export const addRoom = (idPlace, room) => (dispatch) => {
    return dispatch(() => accommodationService.addRoom(idPlace, room))
        .then((response) => {
            dispatch(addNewRoomToStore(idPlace, response.data));
            dispatch(setAvailableUsersList());
        }).catch((e) => handleError(e, 'accommodationRoom'))
};

const editRoomInStore = (roomId, roomData) => ({
    type: ACCOMMODATION_ACTION.EDIT_ROOM,
    roomId,
    roomData,
});

export const editRoom = (roomId, placeData) => (dispatch) => {
    return dispatch(() => accommodationService.editRoom(roomId, placeData)).then((response) => {
        store.dispatch(setSuccess('Pokój został zmieniony.'));
        store.dispatch(editRoomInStore(roomId, placeData));
    }).catch((e) => handleError(e, 'accommodationRoom'))
};

const removeRoomFromStore = (room) => ({
    type: ACCOMMODATION_ACTION.REMOVE_ROOM,
    room
});

export const removeRoom = (room) => (dispatch) => {
    return dispatch(() => accommodationService.removeRoom(room.id))
        .then(() => {
            dispatch(removeRoomFromStore(room));
            dispatch(setAvailableUsersList());
        });
};

const addAssignmentToStore = (assignment) => ({
    type: ACCOMMODATION_ACTION.ADD_ASSIGNMENT,
    assignment
});

export const addAssignment = (idSet, assignment) => (dispatch) => {
    dispatch(() => accommodationService.assignParticipant(idSet, assignment))
        .then((newAssignment) => {
            dispatch(addAssignmentToStore({...assignment, id: newAssignment.data.id }));
            dispatch(setAvailableUsersList());
        }).catch((e) => {
        store.dispatch(setError(TRANSLATE.now(e.response.data.message, { paramScope: 'accommodationPlace' })));
    })
};

const removeAssignmentFromStore = (assignment) => ({
    type: ACCOMMODATION_ACTION.REMOVE_ASSIGNMENT,
    assignment
});

export const removeAssignment = (idSet, assignment) => (dispatch) => {
    dispatch(() => accommodationService.removeParticipant(idSet, assignment.id))
        .then(() => {
            dispatch(removeAssignmentFromStore(assignment));
            dispatch(setAvailableUsersList());
        });
};
