import React, { Component, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled, {keyframes} from 'styled-components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import store from '../store';
import {setError, setSuccess} from '../actions';
import TRANSLATE from '../translation';
import SimpleDialog from "./SimpleDialog";
import {CommonOutlinedButton} from "./StyledButton";
import Icon from "@material-ui/core/Icon";
import WEDDING_CONFIG from "../wedding-config";
const colors = WEDDING_CONFIG.colors;

const StyledDialogContent = styled(DialogContent)`
    width: 250px;
    text-align: center;
    font-size: 1.2rem;
`;

const StyledSimpleDialog = styled(SimpleDialog)`
    && {
          &, .MuiPaper-root {
            overflow: visible!important;
          }
          .title {
            text-align: center;
            margin-top: 40px;
            padding-bottom: 10px;
          }
          
          .simpledialog-content {
            //padding-top: 65px;
          }
    }
`;


const animate = keyframes`
  0% {
  opacity: 1;
    transform: translate(-50%, -50%) scale(2);
  }
 
  85% {
  opacity: 1;
      transform: translate(-50%, -50%) scale(0.5);
  }
  
  100% {
  opacity: 1;
        transform: translate(-50%, -50%) scale(1);
  }
`;


const StyledIndicator = styled.div`
    position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      background: ${colors.primary};
      padding: 20px;
    border-radius: 50%;
    color: white;
    animation: ${animate} 0.3s ease-in forwards;
    animation-delay: 0.5s;
    opacity: 0;
      
      .icon {
        font-size: 3rem;
      }
`;

const StyledCommonOutlinedButton = styled(CommonOutlinedButton)`
    width: 100%;
`;

class SuccessDialog extends Component {

    handleClose() {
        store.dispatch(setSuccess(null));
    }

    getMessage() {
        if (typeof this.props.text !== 'string') {
            return '';
        }
        return TRANSLATE[this.props.text] || this.props.text;
    }

    render() {
        return (
            <StyledSimpleDialog
                style={{overflow: 'visible'}}
                title={'Udało się'}
                open={this.props.open}
                onClose={this.handleClose}
                fullWidth={false}
                fullScreen={false}
                noAppBar={true}
                actions={
                    <StyledCommonOutlinedButton className={'fill-primary'} onClick={this.handleClose} color="primary">
                        OK
                    </StyledCommonOutlinedButton>
                }
            >
                <StyledIndicator>
                    <Icon className={'icon'}>done</Icon>
                </StyledIndicator>
                <StyledDialogContent className="font-primary">
                    {this.getMessage()}
                </StyledDialogContent>
            </StyledSimpleDialog>
        )
    }
}

export default SuccessDialog;
