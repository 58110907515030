import React, { Component } from 'react';
import { connect } from 'react-redux';
import {CONTENTS, CONTENTS_INFO_TRANSLATIONS, CONTENTS_TRANSLATIONS} from '../../../constants';
import {StyledFancyButton} from '../../../common/StyledButton';
import NavLink from "react-router-dom/NavLink";
import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";

const StyledBlock = styled(ListItem)`
    && {
        padding: 10px 0;
        width: 100%;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin-bottom: 1rem;
        position: relative;
        
        &.inactive {
          opacity: 0.5;
        }
    }
`;

const StyledBlockLeft = styled.div`
    padding: 15px 80px 15px 15px;
`;

const StyledLabel = styled.div`
    font-size: 1.3rem;
    font-weight: 100;
    margin-bottom: 0.5rem;
`;

const StyledText = styled.div`
    font-size: 0.9rem;
    font-weight: 100;
`;

const StyledButton = styled(StyledFancyButton)`
    && {
    width: auto;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    }
`;

class Contents extends Component {
    render() {
        return (
            <StyledCard>
                <StyledCardHeader>Lista treści</StyledCardHeader>

                {Object.keys(CONTENTS).map((contentKey, i) => (
                    <StyledBlock
                        key={i}
                        component={NavLink} to={`/admin/contents/edit/${CONTENTS[contentKey]}`}
                        button>
                        <StyledBlockLeft>
                            <StyledLabel>{CONTENTS_TRANSLATIONS[CONTENTS[contentKey]]}</StyledLabel>
                            <StyledText>{CONTENTS_INFO_TRANSLATIONS[CONTENTS[contentKey]]}</StyledText>
                            <StyledButton
                                className={'size-small'}>edytuj</StyledButton>
                        </StyledBlockLeft>
                    </StyledBlock>
                ))}
            </StyledCard>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(Contents);
