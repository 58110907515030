import React, {Component, Fragment} from 'react';
import styled, {createGlobalStyle, keyframes} from 'styled-components';
import {lighten} from 'polished';
import GenericPage from './GenericPage';
import MainMenuInPage from '../components/menus/MainMenuInPage';
import Hello from '../components/Hello';
import {Details} from '../components/Details';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import ENV from '../../../config/environment';
import WEDDING_CONFIG from '../../../wedding-config';
import {connect} from 'react-redux';
import store from '../../../store';
import {setActivePage} from '../../../actions';
import MediaSizes from '../../../constants/media-sizes.constants';
import Icon from '@material-ui/core/Icon';
import {NavLink} from "react-router-dom";
import MainMenuFullscreen from "../components/menus/MainMenuFullscreen";
import mainMenuConfig from '../components/menus/MainMenuConfig';
import Hidden from "@material-ui/core/Hidden";
import MainMenuMobile from "../components/menus/MainMenuMobile";
import 'intro.js/introjs.css';
import 'shepherd.js/dist/css/shepherd-theme-default.css';
import tourService from '../../../service/tour.service';
import MainMenuAlt from "../components/menus/MainMenuAlt";
import DownloadPdf from "../components/DownloadPdf";
import {withTranslation} from "react-i18next";
import {ChatBlock} from "../components/ChatBlock";
import {StyledBlock, StyledBlockLabel} from "../components/partials/StyledBlock";
import {version} from "../../../../package.json";

const colors = WEDDING_CONFIG.colors;


const animateContentWrapper = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const ContentWrapper = styled.div`
  display: block;
  right: 0;
  bottom: 0;
  padding: 50px 60px;
  color: #c24364;
  z-index: 10;
  opacity: 0;
  animation: ${animateContentWrapper} 1s ease-out forwards;
  animation-delay: 0.5s;
  //border-top: 1px solid #ececec;;

  @media (max-width: ${MediaSizes.tablet}px) {
    position: static;
    padding: 16px 16px;
  }
  @media (max-width: ${MediaSizes.desktop}px) {
    padding: 30px 40px;
  }
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;

  &:before, &:after {
    position: absolute;
    pointer-events: none;
    left: 50%;
    top: calc(50% - 3px);
    content: '';
    width: 68px;
    height: 2px;
    background: ${colors.primary};
  }

  &:before {
    transform: translateX(-50%) rotate(45deg);
  }

  &:after {
    transform: translateX(-50%) rotate(-45deg);
  }

  &:hover {
    background: ${colors.primary};

    &:before, &:after {
      background: white;
    }
  }

  @media (max-width: ${MediaSizes.tablet}px) {
    width: 40px;
    height: 40px;

    &:before, &:after {
      width: 35px;
    }
  }
`;

const StyledClose = styled(Close)`
  width: 100px;
  height: 100px;
`;

const EditButton = styled(NavLink)`
  position: absolute;
  right: 10px;
  top: 130px;
  width: 100px;
  height: 100px;
  background: white;
  border-radius: 50%;
  border: 1px solid ${lighten(0.3, colors.primary)};
  cursor: pointer;
  z-index: 110;

  span {
    font-size: 4rem;
    position: relative;
    top: 15px;
  }

  &:hover {
    background: ${colors.primary};
    color: white;
  }
`;

const StyledPage = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 7px;
  display: inline-block;
  position: relative;

  overflow-y: auto;
  //background-color: white;

  background-color: #fff;
  //box-shadow: 0px 0px 4px 1px #afaaac inset;

  &:before {
    content: '';
    position: absolute;
    opacity: 0.2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/bride-3.png') no-repeat top right;
    background-size: cover;
    pointer-events: none;
  }

  @media (max-width: ${MediaSizes.phone}px) {
    border-radius: 0;

    &.menu-off {
      display: none;
    }
  }
`;

const StyledLeftPanel = styled.div`
  width: 350px;
  display: inline-block;
  min-height: 100vh;
  vertical-align: top;
  padding: 0 30px;
  box-sizing: border-box;

  @media (max-width: ${MediaSizes.phone}px) {
    padding: 0 15px 0 15px;
    width: 100%;

  }
`;

const StyledRightPanel = styled.div`
  width: calc(100vw - 365px);
  //height: calc(100vh - 30px);
  max-height: 100%;
  padding: 15px 15px 15px 0;
  display: inline-block;
  vertical-align: top;
  position: relative;

  @media (max-width: ${MediaSizes.phone}px) {
    width: 100%;
    height: 100vh;
    max-height: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;

    &.page-inactive {
      display: none;
    }
  }
`;

const StyledNames = styled.div`
  text-align: left;
  font-size: 3rem;
  color: ${colors.primary};
  padding: 25px 40px;

  &.hidden {
    display: none;
  }

  @media (max-width: ${MediaSizes.phone}px) {
    position: static;
    margin: 50px 0;
  }
`;

const StyledNamesInfo = styled.div`
  font-size: 1.6rem;
`;

const StyledDemoSign = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: ${colors.primary};

`;

const StyledCopyright = styled.div`
  position: absolute;
  bottom: 8px;
  right: 20px;

  @media (max-width: ${MediaSizes.tablet}px) {
    //position: static;
    //padding: 20px;
    ${props => props.activepage ? `
            display: none;
        ` : ''}
  }
`;

const StyledTopBar = styled.div`
  width: 100%;
  height: 60px;
  color: ${colors.primary};
  position: relative;
  z-index: 5;
  display: flex;
  //background: #fdf0f0;


  @media (max-width: ${MediaSizes.phone}px) {
    margin-top: env(safe-area-inset-top);
  }

  &:before {
    //content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 0;
    transform: translateY(100%);
    border-style: solid;
    border-width: 0 100vw 20px 0;
    border-color: transparent ${colors.primary} transparent transparent;
  }
`;

const StyledText = styled.div`
  && {
    color: #666;
    display: none;
    margin: 10px;
    text-align: left;
    padding-left: 30px;
    max-width: 650px;

    p {
      font-size: 1.2rem;
    }
  }
`;

const StyledTopBarIcon = styled(Icon)`
  && {
    font-size: 2.5rem;
    margin: 10px;
  }
`;

const StyledTopBarLabel = styled.div`
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 10px;
`;

const StyledPageBg = styled.div`
  background: #a73259;
  //background: -moz-linear-gradient(-45deg, #992652 0%, #992652 28%, #bf4664 48%, #c16e83 100%);
  //background: -webkit-linear-gradient(-45deg, #992652 0%, #992652 28%, #bf4664 48%, #c16e83 100%);
  //background: linear-gradient(135deg, #992652 0%, #992652 28%, #bf4664 48%, #c16e83 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#992652', endColorstr='#c16e83',GradientType=1);
  color: #ececec;
`;

const StyledPageBgImage = styled.div`
  background: #a73259;
  display: flex;

  @media (max-width: ${MediaSizes.phone}px) {
    background: url('/bride-3.png') no-repeat top right;
    background-position: center top;
    background-size: contain;
  }
`;

const StyledHello = styled(StyledBlock)`
  background: none;
  padding-top: 20px;
  padding-bottom: 5px;
  box-shadow: none;

  @media (min-width: ${MediaSizes.tablet}px) {
    padding-top: 40px;
  }
`;

const StyledMenuContainer = styled.div`
  max-width: 320px;
  margin: 0 auto 20px;
`;

const HtmlBg = createGlobalStyle`
  html {
    background: #a73259;
  }
`;

class Home extends Component {

    state = {
        pageActive: true,
        mainMenuFullscreenVisible: false
    };

    componentDidMount() {
    }

    closeAction() {
        store.dispatch(setActivePage(null));
    }

    mainMenuFullscreenToggle() {
        this.setState({
            mainMenuFullscreenVisible: !this.state.mainMenuFullscreenVisible,
        });
    }

    render() {
        let clientName = this.props.instance.client_name;
        let clientPlace = this.props.instance.client_short_place_information;

        if (!ENV.IS_PREMIUM) {
            clientName = 'Magda i Bartek';
            clientPlace = 'Warszawa, 30.08.2022r.';
        }

        if (this.props.app.adminPanelOn) {
            return null;
        }
        return (
            <BrowserRouter>
                <StyledPageBg>
                    <HtmlBg />
                    <StyledPageBgImage>
                        <StyledLeftPanel>

                            <Hidden smUp>
                                {!this.props.app.activePage ? (<Hello mobile={1}/>) : ''}
                            </Hidden>

                            <StyledHello>
                                <StyledBlockLabel>Witamy na naszej stronie!</StyledBlockLabel>
                                <div>
                                    Przygotowaliśmy dla Was najważniejsze informacje o naszym weselu, a także Deklarację Online - bardzo prosimy o wypełnienie.
                                </div>
                            </StyledHello>

                            <StyledMenuContainer style={{marginBottom: '20px'}}>
                                <Hidden smUp>
                                    <MainMenuMobile/>
                                </Hidden>
                            </StyledMenuContainer>

                            {!!this.props.app.activePage ? (
                                <Fragment>
                                    <Hidden xsDown>
                                        <Details/>
                                    </Hidden>
                                    <MainMenuInPage/>
                                </Fragment>
                                ) :
                                <Fragment>
                                    <Details/>
                                    {this.props.instance.is_chat_enabled ? (<ChatBlock/>) : ''}
                                    {this.props.instance.is_pdf_download_enabled ? (<DownloadPdf/>) : ''}
                                </Fragment>
                            }
                            <Hidden mdUp>
                                <div style={{ position: 'relative'}}>
                                    <div style={{ position: 'absolute', bottom: '2px', right: '0', color: '#b3b3b3', fontSize: 12}}>
                                        v{version}
                                    </div>
                                </div>
                            </Hidden>
                        </StyledLeftPanel>
                        <StyledRightPanel className={!!this.props.app.activePage ? 'page-active' : 'page-inactive'}>
                            <StyledPage id="main" className={!this.props.app.activePage ? 'menu-off' : ''}>
                                <Hidden smDown>
                                    <div style={{ position: 'absolute', bottom: '7px', left: '8px', color: '#b3b3b3', fontSize: 12}}>
                                        v{version}
                                    </div>
                                </Hidden>
                                {!this.props.app.activePage ? (<Hello/>) : ''}

                                {!this.props.app.activePage ? (
                                    <Fragment>
                                        <StyledNames
                                            className={(!!this.props.app.activePage ? 'hidden' : '') + ' font-secondary'}>
                                            {clientName}
                                            {clientPlace ? (
                                                <Fragment>
                                                    <StyledNamesInfo className="font-primary">
                                                        {clientPlace}
                                                    </StyledNamesInfo>
                                                </Fragment>
                                            ) : ''}
                                        </StyledNames>
                                    </Fragment>
                                ) : ''}

                                {!this.props.app.activePage ? (
                                    <Fragment>
                                        <Hidden xsDown>
                                            <div className={'text-left'}>
                                                <MainMenuAlt/>
                                            </div>
                                        </Hidden>
                                    </Fragment>
                                ) : ''}

                                {
                                    !!this.props.app.activePage ?
                                        <Fragment>
                                            <Hidden mdUp>
                                                <StyledTopBar>
                                                    <StyledTopBarIcon
                                                        onClick={this.mainMenuFullscreenToggle.bind(this)}>
                                                        menu
                                                    </StyledTopBarIcon>
                                                    <StyledTopBarLabel
                                                        onClick={this.mainMenuFullscreenToggle.bind(this)}>
                                                        {this.props.t(mainMenuConfig.config[this.props.app.activePage].text)}
                                                    </StyledTopBarLabel>
                                                    <Close onClick={this.closeAction.bind(this)}/>
                                                </StyledTopBar>
                                            </Hidden>
                                            <MainMenuFullscreen visible={this.state.mainMenuFullscreenVisible}
                                                                handleClose={this.mainMenuFullscreenToggle.bind(this)}/>
                                            <ContentWrapper>
                                                <Hidden smDown>
                                                    <StyledClose onClick={this.closeAction.bind(this)}/>
                                                </Hidden>
                                                {this.props.user.is_admin && 0 ? (
                                                    <EditButton
                                                        to={`/admin/contents/edit/${this.props.app.activePage}`}>
                                                        <Icon>edit</Icon>
                                                    </EditButton>
                                                ) : ''}
                                                <GenericPage/>
                                            </ContentWrapper>
                                        </Fragment>
                                        : ''
                                }

                                {!this.props.app.activePage ? (
                                    <StyledCopyright activepage={this.props.app.activePage ? '1' : false}>
                                        {this.props.instance.is_demo_instance ? (
                                            <StyledDemoSign>
                                                DEMO
                                            </StyledDemoSign>
                                        ) : ''}
                                        <a target="_blank" href={ENV.WEBSITE_URL}>
                                            <img src="/logo-fancywedding-sm.png" width="140"/>
                                        </a>
                                    </StyledCopyright>
                                ) : ''}

                            </StyledPage>
                        </StyledRightPanel>
                    </StyledPageBgImage>
                </StyledPageBg>
            </BrowserRouter>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user,
        instance: state.auth.instance,
    }
};

export default withTranslation()(connect(mapStateToProps)(Home));
