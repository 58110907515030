import api from '../api';

const getSuChatList = () => {
    return api.request({
        url: api.suRoutes.getChatList
    });
};

const getSuChatUsersList = () => {
    return api.request({
        url: api.suRoutes.getChatFormUsersList
    });
};

const getSuSingleChatMessages = (participantId) => {
    return api.request({
        url: api.suRoutes.getSingleChatMessages.replace(':id_participant', participantId),
        method: 'get'
    });
};

const getSuSingleChatAnnouncementMessages = () => {
    return api.request({
        url: api.suRoutes.getSingleChatAnnouncementMessages,
        method: 'get'
    });
};

const getSuChatNewMessagesCount = () => {
    return api.request({
        url: api.suRoutes.getNewMessagesCount,
        method: 'get'
    });
};

const getGuestChatNewMessagesCount = () => {
    return api.request({
        url: api.routes.getNewMessagesCount,
        method: 'get'
    });
};

const sendSuMessageToParticipant = (participantId, text) => {
    return api.request({
        url: api.suRoutes.sendChatMessageToParticipant.replace(':id_participant', participantId),
        method: 'post',
        data: {
            message: text
        }
    });
};

const sendSuAnnouncementMessage = (text) => {
    return api.request({
        url: api.suRoutes.sendChatAnnouncementMessage,
        method: 'post',
        data: {
            message: text
        }
    });
};

const getChatMessages = () => {
    return api.request({
        url: api.routes.getChatMessages
    });
};

const sendChatMessage = (text) => {
    return api.request({
        url: api.routes.sendMessageToAdmin,
        method: 'post',
        data: {
            message: text
        }
    });
};

export default {
    getChatMessages,
    sendChatMessage,
    getSuChatNewMessagesCount,
    getGuestChatNewMessagesCount,
    getSuChatList,
    getSuChatUsersList,
    getSuSingleChatMessages,
    getSuSingleChatAnnouncementMessages,
    sendSuMessageToParticipant,
    sendSuAnnouncementMessage
};
