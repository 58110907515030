import React, {Fragment} from 'react';
import {Rect, Text, Transformer, Group} from 'react-konva';
import Seat from "./Seat";
import {SeatEdit} from "./SeatEdit";

export class TableRectLegacy extends React.Component {
    state = {
        id: null,
        name: null,
        width: 8,
        height: 2,
        isRemoveEnabled: false
    };
    seatsData = [];
    snapSizePx = 20;
    layerPaddingPx = 40;
    isEditMode = false;
    group;
    rect;
    shadowRect;

    constructor(props) {
        super(props);
        this.isEditMode = props.editMode;
        this.snapSizePx = props.snapSize;
        this.layerPaddingPx = 2 * props.snapSize;

        if (props.id) {
            this.state.id = props.id;
        }
        if (props.name) {
            this.state.name = props.name;
        }
        if (props.width) {
            this.state.width = props.width;
        }
        if (props.height) {
            this.state.height = props.height;
        }

        this.seatsData = this.props.seatsData || this.generateSeats();
        this.state.isResizeEnabled = this.isEditMode;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.isEditMode) {
            if (this.state.width !== nextProps.width) {
                this.setState({
                    width: nextProps.width
                }, () => {
                    this.seatsData = this.generateSeats();
                    this.changeUp();
                })
            }
            if (this.state.height !== nextProps.height) {
                this.setState({
                    height: nextProps.height
                }, () => {
                    this.seatsData = this.generateSeats();
                    this.changeUp();
                })
            }
        }
    }

    componentDidMount() {
        if (this.props.onDataChange) {
            this.changeUp();
        }
        this.shadowRect.hide();
    }

    calculateTableSize(size) {
        return this.snapSizePx * size;
    }

    generateSeats() {
        const width = this.state.width;
        const height = this.state.height;
        const seatSize = 2;
        const horizontalSeatsNumber = Math.round(width / seatSize);
        const verticalSeatsNumber = Math.round(height / seatSize);
        const seats = [];
        [...Array(horizontalSeatsNumber)]
            .forEach((val, index) => seats.push({ id: 'top_' + index, position: 'top', index: index, disabled: false }));
        [...Array(horizontalSeatsNumber)]
            .forEach((val, index) => seats.push({ id: 'bottom_' + index, position: 'bottom', index: index, disabled: false }));

        [...Array(verticalSeatsNumber)]
            .forEach((val, index) => seats.push({ id: 'left_' + index, position: 'left', index: index, disabled: false }));
        [...Array(verticalSeatsNumber)]
            .forEach((val, index) => seats.push({ id: 'right_' + index, position: 'right', index: index, disabled: false }));

        return seats;
    }

    changeUp() {
        this.props.onDataChange({
            width: this.state.width,
            height: this.state.height,
            seats: this.seatsData,
        });
    }

    onSeatDisableStateChange(seat, disabled) {
        this.seatsData.find((foundSeat) => foundSeat.position === seat.position && foundSeat.index === seat.index)
            .disabled = disabled;
        this.changeUp();
    }

    removeTable() {
        if (window.confirm('Czy na pewno chcesz usunąć ten stół wraz z wszystkimi przypisaniami?')) {
            this.props.onTableRemoveClick(this.props.id);
        }
    }

    renderSeats() {
        const seatSize = 2;
        let seatsData = [...this.seatsData];

        let seats = seatsData.map((seat) => {
            const seatToRender = {
                ...seat,
            };
            let position = seat.position;
            let sizeXY = seat.index * this.snapSizePx * seatSize + this.snapSizePx * 2;

            switch (position) {
                case 'top':
                    seatToRender.x = sizeXY;
                    seatToRender.y = 0;
                    break;
                case 'bottom':
                    seatToRender.x = sizeXY;
                    seatToRender.y = this.calculateTableSize(this.state.height) + this.snapSizePx * 2;
                    break;
                case 'left':
                    seatToRender.x = 0;
                    seatToRender.y = sizeXY;
                    break;
                case 'right':
                    seatToRender.x = this.calculateTableSize(this.state.width) + this.snapSizePx * 2;
                    seatToRender.y = sizeXY;
                    break;
            }

            return seatToRender;
        });

        if (!this.isEditMode) {
            seats = seats.filter((seat) => !seat.disabled);
        }

        return seats;
    }

    render() {
        const layerPadding = this.layerPaddingPx;
        return (
            <Fragment>
                <Rect // shadow grid rect
                    ref={(ref) => this.shadowRect = ref}
                    x={0}
                    y={0}
                    width={this.calculateTableSize(this.state.width)}
                    height={this.calculateTableSize(this.state.height)}
                    fill={'#e0b1c3'}
                />

                <Group
                    ref={(ref) => this.group = ref}
                    x={this.props.x || (this.props.stage.width() / 2) - (this.state.width * this.snapSizePx + 4 * this.snapSizePx) / 2}
                    y={this.props.y || (this.props.stage.height() / 2) - (this.state.height * this.snapSizePx + 4 * this.snapSizePx) / 2}
                    draggable={!this.isEditMode}
                    onMouseEnter={() => {
                        if (this.isEditMode) {
                            return;
                        }
                        this.setState({
                            isRemoveEnabled: true,
                        }, () => this.props.stage.batchDraw());
                    }}
                    onMouseLeave={() => {
                        if (this.isEditMode) {
                            return;
                        }
                        this.setState({
                            isRemoveEnabled: false,
                        }, () => this.props.stage.batchDraw());
                    }}
                    onTransformEnd={e => {
                        if (this.props.tableRotationChange) {
                            this.props.tableRotationChange(this.props.id, this.group.attrs.rotation);
                        }
                    }}
                    onDragMove={() => {
                        this.shadowRect.show();
                        this.shadowRect.position({
                            x: layerPadding + Math.round(this.group.x() / this.snapSizePx) * this.snapSizePx,
                            y: layerPadding + Math.round(this.group.y() / this.snapSizePx) * this.snapSizePx
                        });
                        this.props.stage.batchDraw();
                    }}
                    onDragEnd={(e) => {
                        this.group.position({
                            x: Math.round(this.group.x() / this.snapSizePx) * this.snapSizePx,
                            y: Math.round(this.group.y() / this.snapSizePx) * this.snapSizePx
                        });
                        this.shadowRect.hide();
                        this.props.stage.batchDraw();

                        if (this.props.tablePositionChange) {
                            this.props.tablePositionChange(this.props.id, this.group.attrs.x, this.group.attrs.y);
                        }
                    }}
                >

                    <Rect // main rect
                        ref={(ref) => this.rect = ref}
                        x={this.snapSizePx * 2}
                        y={this.snapSizePx * 2}
                        fill={'#fff'}
                        cornerRadius={4}
                        width={this.calculateTableSize(this.state.width)}
                        height={this.calculateTableSize(this.state.height)}
                        strokeWidth={2}
                        stroke={'#999'}
                        centeredScaling={true}
                        onTransform={e => {
                            this.rect.setAttrs({
                                width: this.rect.width() * this.rect.scaleX(),
                                height: this.rect.height() * this.rect.scaleY(),
                                scaleX: 1,
                                scaleY: 1,
                            });
                        }}
                        onTransformEnd={e => {
                            this.setState({
                                width: this.rect.width() / this.snapSizePx,
                                height: this.rect.height() / this.snapSizePx,
                            }, () => {
                                this.seatsData = this.generateSeats();
                                this.changeUp();
                            });
                        }}
                    />

                    {this.renderSeats().map((seat) => this.props.editMode ? (<SeatEdit
                        snapSize={this.snapSizePx}
                        x={seat.x}
                        y={seat.y}
                        onDisableStateChange={this.onSeatDisableStateChange.bind(this, seat)}/>) : (
                        !seat.disabled ? (<Seat
                            key={seat.id}
                            seatId={seat.id}
                            onSeatClick={this.props.onSeatClick}
                            tableId={this.props.id}
                            tables={this.props.tables}
                            snapSize={this.snapSizePx}
                            assignment={this.props.seatAssignments.find((assignment) => assignment.id_seat === seat.id && assignment.id_table === this.props.id)}
                            x={seat.x}
                            y={seat.y} />) : null
                    ))}

                    <Group
                        // rotation={-this.props.rotation}
                        x={this.snapSizePx * 2}
                        y={this.snapSizePx * 2}>
                        <Text ref={(ref) => this.nameRef = ref}
                                verticalAlign={'middle'}
                              width={this.calculateTableSize(this.state.width)}
                              height={this.calculateTableSize(this.state.height)}
                              align={'center'}
                              text={this.props.name}/>

                        {this.state.isRemoveEnabled ? (
                            <Text fontFamily={'Material Icons'}
                                  text={'edit'}
                                  fontSize={18}
                                  x={this.calculateTableSize(this.state.width) - 20}
                                  y={3}
                                  fill={'#444'}
                                  verticalAlign={'middle'}
                                  onClick={() => this.props.onActionsClick({
                                      id: this.state.id,
                                      x: this.group.x() + this.calculateTableSize(this.state.width),
                                      y: this.group.y() + this.calculateTableSize(this.state.height) - 20,
                                      isLegacy: true
                                  })}
                                  align={'center'}
                            />
                        ) : null}
                    </Group>

                </Group>

            </Fragment>
        );
    }
}
