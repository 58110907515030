import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik } from 'formik';
import TextInput from "../partials/TextInput";
import tableService from "../../../service/table.service";
import SimpleDialog from "../../../common/SimpleDialog";
import {CommonOutlinedButton} from "../../../common/StyledButton";

const colors = WEDDING_CONFIG.colors;

class TablePlanDialog extends Component {
    state = {
        open: true
    };
    form;

    componentDidMount() {

    }

    submitForm() {
        this.form.submitForm();
    }

    render() {
        return (
            <SimpleDialog
                title={'Dodaj plan stołów'}
                subTitle={<div>Stwórz plan, na którym rozmieścisz stoły.</div>}
                open={this.props.open}
                onClose={this.handleClose}
                maxWidth={'md'}>
                <Formik
                    ref={node => (this.form = node)}
                    initialValues={{
                        name: this.props.isFirst ? 'Wesele' : ''
                    }}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        tableService.addTableSet(values).then((response) => {
                            this.props.handleClose(response);
                        })
                    }}>
                    {({values, handleChange, dirty, setFieldValue}) => {
                        return (
                            <Fragment>
                                <DialogContent>
                                    <TextInput
                                        name="name"
                                        label={'Nazwa'}
                                        key="name"
                                        autoFocus
                                        value={values.name}
                                        placeholder={'Podaj nazwę'}
                                        onChange={handleChange}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <CommonOutlinedButton onClick={() => this.props.handleClose()} color="secondary">
                                        Anuluj
                                    </CommonOutlinedButton>
                                    <CommonOutlinedButton disabled={!values.name} onClick={this.submitForm.bind(this)} className={'fill-primary'}>
                                        Zapisz
                                    </CommonOutlinedButton>
                                </DialogActions>
                            </Fragment>
                        )
                    }}
                </Formik>

            </SimpleDialog>
        )
    }
}

export default TablePlanDialog;
