import {TABLE_ACTION} from "../actions/table.actions";

const initialState = {
    selectedTableSetId: localStorage.getItem('selectedTableSetId'),
    seatsCount: 0,
    tables: [],
    usersList: [],
    availableUsersList: [],
    assignments: [],
};

function areTableAssignmentsSame(a1, a2) {
    return a1.id_participant === a2.id_participant &&
        a1.participant_type === a2.participant_type &&
        a1.participant_index === a2.participant_index;
}

export const tables = (state = initialState, action) => {
    switch(action.type) {
        case TABLE_ACTION.UPDATE_TABLE:
            let newTable = { ...action.newTableObject };

            if (typeof newTable.data === 'string') {
                try {
                    newTable.data = JSON.parse(newTable.data);
                } catch(e) {
                    newTable = null;
                }
            }
            const newTables = state.tables.map((table) => table.id === action.tableId && newTable ? newTable : table);

            state = {
                ...state,
                tables: newTables,
            };
            break;
        case TABLE_ACTION.EDIT_NAME:
            const table = state.tables.find((tab) => tab.id === action.tableId);
            table.table_name = action.tableNewName;
            state = {
                ...state,
                tables: state.tables,
            };
            break;
        case TABLE_ACTION.SET_SELECTED_SET_ID:
            state = {
                ...state,
                selectedTableSetId: action.id,
            };
            localStorage.setItem('selectedTableSetId', action.id);
            break;
        case TABLE_ACTION.SET_USER_LIST:
            state = {
                ...state,
                usersList: action.usersList,
                availableUsersList: action.usersList,
            };
            break;
        case TABLE_ACTION.SET_AVAILABLE_USERS_LIST:
            state = {
                ...state,
                availableUsersList: state.usersList.filter((user) => !state.assignments.find((assignment) =>
                    assignment.id_participant === user.id &&
                    assignment.participant_type === user.participant_type &&
                    assignment.participant_index === user.participant_index)),
            };
            break;
        case TABLE_ACTION.SET_ASSIGNMENTS:
            state = {
                ...state,
                assignments: action.assignments,
            };
            break;
        case TABLE_ACTION.ADD_ASSIGNMENT:
            const userOfAssignment = state.usersList.find((user) => user.id === action.assignment.id_participant &&
                user.participant_type === action.assignment.participant_type &&
                user.participant_index === action.assignment.participant_index);
            const mappedUsers = [].concat(
                state.assignments.filter((assignment) =>
                    !(assignment.id_table === action.assignment.id_table && assignment.id_seat === action.assignment.id_seat) && !areTableAssignmentsSame(action.assignment, assignment)),
                [{
                    ...action.assignment,
                    first_name: userOfAssignment.first_name,
                    last_name: userOfAssignment.last_name,
                    confirmed: userOfAssignment.confirmed,
                }],
            );
            state = {
                ...state,
                assignments: mappedUsers,
            };
            break;
        case TABLE_ACTION.REMOVE_ASSIGNMENT:
            const assignments = state.assignments.filter((assignment) => assignment !== action.assignment);
            state = {
                ...state,
                assignments: assignments,
            };
            break;
        case TABLE_ACTION.SET_TABLES_DATA:
            state = {
                ...state,
                tables: action.tables,
            };
            break;
        case TABLE_ACTION.SET_TABLES_INFO:
            let seatsCount = 0;

            state.tables.forEach((table) => {
                seatsCount += table.data.seats
                    .filter((foundSeat) => !foundSeat.disabled)
                    .length;
            });

            state = {
                ...state,
                seatsCount,
            };
            break;
    }

    return state;
};
