import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import NavLink from 'react-router-dom/NavLink';
import Icon from "@material-ui/core/Icon";
import PasswordChange from "../components/PasswordChange";

const StyledContainer = styled.div`
  position: relative;
  top: 40vh;
  transform: translateY(-50%);
  display: inline-block;
  max-width: 475px;
  
  .go-back {
    padding: 10px;
    text-align: left;
    
    * {
      vertical-align: middle;
    }
  }
`;

class ResetPasswordPage extends Component {
    render() {
        return (
            <StyledContainer>
                <div className={'go-back'}>
                    <NavLink to={'/'}>
                        <Icon>keyboard_arrow_left</Icon>
                        do ekranu logowania
                    </NavLink>
                </div>
                <PasswordChange></PasswordChange>
            </StyledContainer>
        )
    }
}

export default ResetPasswordPage;
