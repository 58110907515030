const WEDDING_CONFIG = {
    colors: {
        primary: '#ce5172',
        primaryLight: '#bd7089',
        secondary: '#d9b46d',
        alternativeInfo: '#ebf3ff',
        textRead: '#505050',
        white: '#fbfbfb',
        altWhite: '#fff',
        third: '#ddd',
        gray: '#aaa',
        bubbles: '#c24364',
        dummyBubbles: '#c24364', //'#d64873',
        lightPlaceholder: '#fff8e7'
    },
    resourcesUrl: 'http://resources.fancy.wedding/app/',
    chatNewMessageCheckInterval: 10000,
    maxChatMessageLetters: 1000
};

export default WEDDING_CONFIG;
