import {Component, Fragment} from "react";
import React from "react";
import api from "../../../api";
import {Formik} from "formik";
import {CommonOutlinedButton} from '../../../common/StyledButton';
import {connect} from "react-redux";
import store from "../../../store";
import {updateInstance} from "../../../actions";
/* eslint-disable */
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
// pick utils
import MomentUtils from '@date-io/moment';
import {DatePicker} from 'material-ui-pickers';
import CheckboxBlock from './CheckboxBlock';
import TextInput from "./TextInput";
import SectionTitle from "./SectionTitle";
import {withSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import ENV from "../../../config/environment";
import {StyledCardContent} from "../../../common/StyledContentCard";
import SettingSpecialMenuBlock from "./SettingSpecialMenuBlock";
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";
import {DEFAULT_GRID_SPACING} from "../../../constants";

class InstanceSettingsForm extends Component {

    render() {
        return (
            <Fragment>
                <StyledCardContent>
                    <Formik
                        initialValues={{
                            client_name: this.props.instance.client_name,
                            client_short_place_information: this.props.instance.client_short_place_information,
                            is_accommodation_available: this.props.instance.is_accommodation_available,
                            is_accommodation_only_for_second_day_participants: this.props.instance.is_accommodation_only_for_second_day_participants,
                            is_two_day_party: this.props.instance.is_two_day_party,
                            is_photos_page_enabled: this.props.instance.is_photos_page_enabled,
                            is_accommodation_page_enabled: this.props.instance.is_accommodation_page_enabled,
                            is_gifts_page_enabled: this.props.instance.is_gifts_page_enabled,
                            is_pdf_download_enabled: this.props.instance.is_pdf_download_enabled,
                            is_chat_enabled: this.props.instance.is_chat_enabled,
                            declaration_enabled_until: this.props.instance.declaration_enabled_until,
                            special_menus: this.props.instance.special_menus,
                        }}
                        onSubmit={(values, {setSubmitting, setErrors, setTouched, resetForm}) => {
                            setSubmitting(true);
                            api.request({
                                url: api.suRoutes.setInstanceSettings,
                                method: 'put',
                                data: values
                            }).then((response) => {
                                if (response.data && response.data.instance && response.data.instance.id) {
                                    store.dispatch(updateInstance(response.data.instance));

                                    this.props.enqueueSnackbar('Zapisano', {
                                        variant: 'success',
                                    });
                                    resetForm(values);
                                }
                            });
                        }}
                    >
                        {({values, errors, touched, dirty, handleChange, handleBlur, submitForm, handleSubmit, setFieldValue, isSubmitting, isValid, validateForm}) => {
                            let specialMenus = false;

                            if (values.special_menus && values.special_menus.length) {
                                specialMenus = true;
                            }
                            return (
                                <div>
                                    {ENV.IS_PREMIUM ? (
                                    <StyledCard>
                                        <StyledCardHeader>Dostosuj aplikację do swojego wesela</StyledCardHeader>
                                        <Fragment>
                                            <Grid container spacing={DEFAULT_GRID_SPACING}>
                                                <Grid item xs={12} sm={6}>
                                                    <div className="form-group">
                                                        <TextInput
                                                            name="client_name"
                                                            key="client_name"
                                                            label="Imiona Pary Młodej"
                                                            value={values.client_name}
                                                            onChange={handleChange}
                                                            placeholder="np. Ilona i Jan"
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <div className="form-group">
                                                        <TextInput
                                                            name="client_short_place_information"
                                                            key="client_short_place_information"
                                                            label="Miejsce imprezy i data"
                                                            value={values.client_short_place_information}
                                                            onChange={handleChange}
                                                            placeholder="np. Warszawa, 21.05.2020"
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <div className="form-group">
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <TextInput
                                                        component={'DatePicker'}
                                                        label="Pozwalaj na wypełnianie deklaracji do:"
                                                        value={values.declaration_enabled_until}
                                                        fullWidth
                                                        format="DD/MM/YYYY"
                                                        placeholder="10/10/2018"
                                                        onChange={(value) => {
                                                            setFieldValue('declaration_enabled_until', value)
                                                        }}/>
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Fragment>
                                    </StyledCard>
                                    ) : null}

                                    <StyledCard>
                                        <StyledCardHeader>
                                            Opcje instancji
                                            {ENV.IS_PREMIUM ? (
                                                <div className={'aside'}>
                                                    <CommonOutlinedButton style={{position: 'relative', top: '-20px'}} className={'size-small fill-primary'} disabled={!dirty} type="submit" onClick={handleSubmit}>Zapisz zmiany</CommonOutlinedButton>
                                                </div>
                                            ) : null}
                                        </StyledCardHeader>
                                        <div>
                                            <CheckboxBlock
                                                mainLabel="Impreza dwudniowa"
                                                text={ENV.IS_PREMIUM ? "Jeśli opcja jest zaznaczona to użytkownicy będą mieli możliwość zadeklarowania przybycia na poprawiny." :
                                                    "Zaznacz, jeśli organizujesz poprawiny."}
                                                name="is_two_day_party"
                                                key="is_two_day_party"
                                                checked={values.is_two_day_party}
                                                value="1"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div>
                                            <SettingSpecialMenuBlock
                                                mainLabel={specialMenus ? 'Oferuję poniższe dodatkowe warianty menu' : 'Nie oferuję specjalnego menu'}
                                                menus={values.special_menus || []}
                                                name="special_menus"
                                                key="special_menus"
                                                handleChange={(newValue) => {
                                                    setFieldValue('special_menus', newValue);
                                                }}/>
                                        </div>
                                        <div>
                                            <CheckboxBlock
                                                mainLabel="Nocleg jest dostępny dla gości"
                                                text={`${ENV.IS_PREMIUM ? 'Opcja ta włącza możliwość rezerwowania noclegów. Uczestnik, któremu dasz możliwość noclegu (możesz to zrobić na liście uczestników) będzie mógł zaznaczyć w deklaracji, że jest chętny na nocleg.' : 'Zaznacz, jeśli organizujesz nocleg.'}`}
                                                name="is_accommodation_available"
                                                key="is_accommodation_available"
                                                checked={values.is_accommodation_available}
                                                value="1"
                                                onChange={handleChange}/>
                                        </div>
                                        {ENV.IS_PREMIUM && values.is_two_day_party ? (
                                            <div style={{paddingLeft: '30px'}}>
                                                <CheckboxBlock
                                                    mainLabel="Nocleg tylko dla gości, którzy zostają na poprawinach"
                                                    text='Jeśli zaznaczone, Twoi goście będą mogli zarezerwować nocleg tylko, gdy zdecydują się zostać na poprawinach. Jako Para Młoda w ramach wyjątku możesz nadal zaznaczyć nocleg dla gościa niezależnie od tego ustawienia.'
                                                    name="is_accommodation_only_for_second_day_participants"
                                                    key="is_accommodation_only_for_second_day_participants"
                                                    checked={values.is_accommodation_available && values.is_accommodation_only_for_second_day_participants}
                                                    value="1"
                                                    onChange={handleChange}/>
                                            </div>
                                        ) : ''}
                                        {ENV.IS_PREMIUM ? (
                                            <Fragment>
                                                <div style={{paddingLeft: '30px'}}>
                                                    <CheckboxBlock
                                                        mainLabel="Pokazuj zakładkę Nocleg"
                                                        text='Zakładka będzie widoczna tylko jeśli zaznaczysz tę opcję wraz z opcją "Nocleg jest dostępny dla gości"'
                                                        name="is_accommodation_page_enabled"
                                                        key="is_accommodation_page_enabled"
                                                        checked={values.is_accommodation_available && values.is_accommodation_page_enabled}
                                                        value="1"
                                                        onChange={handleChange}/>
                                                </div>
                                                <div>
                                                    <CheckboxBlock
                                                        mainLabel="Pokazuj zakładkę Zdjęcia"
                                                        text="Zakładka będzie widoczna dla wszystkich."
                                                        name="is_photos_page_enabled"
                                                        key="is_photos_page_enabled"
                                                        checked={values.is_photos_page_enabled}
                                                        value="1"
                                                        onChange={handleChange}/>
                                                </div>
                                                <div>
                                                    <CheckboxBlock
                                                        mainLabel="Pokazuj zakładkę Prezenty"
                                                        text="Zakładka będzie widoczna dla wszystkich."
                                                        name="is_gifts_page_enabled"
                                                        key="is_gifts_page_enabled"
                                                        checked={values.is_gifts_page_enabled}
                                                        value="1"
                                                        onChange={handleChange}/>
                                                </div>
                                                <div>
                                                    <CheckboxBlock
                                                        mainLabel="Pobieranie informacji w formie PDF"
                                                        text="Goście będą mogli pobierać najważniejsze informacje o wydarzeniu w formie pliku PDF."
                                                        name="is_pdf_download_enabled"
                                                        key="is_pdf_download_enabled"
                                                        checked={values.is_pdf_download_enabled}
                                                        value="1"
                                                        onChange={handleChange}/>
                                                </div>
                                                <div>
                                                    <CheckboxBlock
                                                        mainLabel="Chat z gośćmi"
                                                        text="Goście będą mogli odezwać się do Ciebie poprzez wbudowany w fancy.wedding chat."
                                                        name="is_chat_enabled"
                                                        key="is_chat_enabled"
                                                        checked={values.is_chat_enabled}
                                                        value="1"
                                                        onChange={handleChange}/>
                                                </div>
                                            </Fragment>
                                        ) : null}
                                        <br/>
                                        <CommonOutlinedButton disabled={!dirty} className={'fill-primary'} type="submit" onClick={handleSubmit}>Zapisz zmiany</CommonOutlinedButton>
                                    </StyledCard>

                                </div>
                            )
                        }}
                    </Formik>
                </StyledCardContent>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        instance: state.auth.instance
    }
};

export default connect(mapStateToProps)(withSnackbar(InstanceSettingsForm));
