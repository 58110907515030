import React from "react";
import styled from "styled-components";
import WEDDING_CONFIG from "../../../wedding-config";

const colors = WEDDING_CONFIG.colors;

const StyledBlogNews = styled.div`
  width: 100%;
  
  img {
      max-width: 100%;
      margin-bottom: 5px;
      //border-bottom: 2px solid ${colors.primaryLight};
  }
  .content {
    //padding: 10px;
  }
  
  .title {
    color: ${colors.primaryLight};
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-decoration: none;
    display: block;
  }
  
  .intro {
    margin-bottom: 10px;
  }
  
  .summary {
    color: #999;
  }
`;

export default function BlogNews(props) {
    const item = props.item;

    return (
        <StyledBlogNews>
            <a className={'image'} href={item.url} target={'_blank'}>
                <img className={'card-image'} src={item.image} alt={item.title}/>
            </a>
            <div className={'content'}>
                <a href={item.url} target={'_blank'} className={'title'}>
                    {item.title}
                </a>
                <div className={'intro'}>
                    {item.intro}
                </div>
                <div className={'summary'}>
                    <a href={item.url} target={'_blank'}>Czytaj więcej</a> | {item.date}
                </div>
            </div>
        </StyledBlogNews>
    )
}
