import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import WEDDING_CONFIG from '../../../wedding-config';
import api from '../../../api';
import { CONTENTS } from '../../../constants';
import menuService from '../../../service/menu.service';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import ContentTypes from '../../../constants/content-types.constants';
import MediaSizes from '../../../constants/media-sizes.constants';
import { animateScroll as scroll } from 'react-scroll';
import moment from 'moment';
import localization from 'moment/locale/pl';
import Hidden from "@material-ui/core/Hidden";
const colors = WEDDING_CONFIG.colors;

const StyledPageTitle = styled.h1`
    text-align: left;
    font-size: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 15px;
    margin-top: 0;
    color: ${colors.primary};
    position: relative;
    width: calc(100% - 60px);
    
    @media (max-width: ${MediaSizes.tablet}px) {
        font-size: 1.7rem;
        padding-bottom: 0;
        margin-bottom: 2rem;
        width: 100%;
    }
    
    @media (min-width: ${MediaSizes.desktopLg}px) {
        margin-bottom: 2rem;
        font-size: 2rem;
    }
`;

const StyledPageContent = styled.div`
    text-align: left;
    color: #555;
    
    &, p {
      font-size: 1rem;
    }
    
    h1, h2, h3, h4 {
        color: ${colors.primary};
    }
    
    p, ul, ol {
      margin: 5px 0;
    }
    
    @media (max-width: ${MediaSizes.tablet}px) {
        //padding-bottom: 40px;
    }
`;

const StyledContentText = styled.div`
    //font-size: 1.4rem;
    margin: 30px 0;
    
    @media (max-width: ${MediaSizes.tablet}px) {
        font-size: 1.2rem;
    }
`;

const StyledContentTimeline = styled.div`
    position: relative;
    border-left: 1px solid #ddd;
    padding: 20px 0 20px 100px;
    margin-left: 55px;
    
    .time {
        position: absolute;
        top: 50%;
        left: 0;
        text-align: center;
        transform: translate(-50%, -50%);
        color: ${colors.primary};
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 2px;
        background: ${colors.altWhite};
    }

    .date {
        position: relative;
        font-size: 1rem;
    }
    
    .date-mobile {
        color: ${colors.primary};
        margin-top: 12px;
    }
    
    .label {
        font-size: 2rem;
        color: ${colors.primary};
        display: inline;
    }
    
    .address {
        display: inline-block;
        margin-left: 10px;
        color: #888;
    }
    
    .text {
        margin: 15px 0;
        max-width: 550px;
    }
    
    .map-frame {
    }
    
    @media (max-width: ${MediaSizes.tablet}px) {
        padding: 20px 0 20px 90px;
        margin-left: 70px;
        
        .time {
            font-size: 1.4rem;
        }
    
        .address {
            display: block;
            margin-left: 0;
            font-size: 1rem;
        }
        
        .icon {
            font-size: 1rem;
        }
    }
    
    @media (max-width: ${MediaSizes.phone}px) {
        padding: 20px 0 20px 50px;
        margin-left: 30px;
    }
    
    @media (min-width: ${MediaSizes.desktopLg}px) {
        padding: 20px 0 20px 100px;

        .time {
            font-size: 2.5rem;
        }
    }
`;


class GenericPage extends Component {
    state = {
        pageName: '',
        title: '',
        type: '',
        menuItems: [],
        content: undefined,
        mainMenuFullscreenVisible: true,
        mapAnchors: {}
    };

    constructor() {
        super();
        this.state.menuItems = menuService.getMenuItems()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.app.activePage !== prevState.pageName) {
            return {
                pageName: nextProps.app.activePage,
                title: '',
                content: '',
            };
        }

        return null;
    }

    componentDidMount() {
        scroll.scrollToTop();
        this.loadPage(this.props.app.activePage);
    }

    componentDidUpdate(prevProps, prevState) {
        let pageName = this.props.app.activePage;

        if (pageName !== prevState.pageName && Object.values(CONTENTS).includes(pageName)) {
            this.loadPage(pageName);
        }
    }

    loadPage(pageName) {
        api.request({
            url: api.routes.getContent.replace(':name', pageName),
            data: { pageName }
        }).then((response) => {
            let title = '';
            let content;
            let type = '';

            try {
                title = response.data.title;
            } catch(e) {}

            try {
                type = response.data.type;
            } catch(e) {}

            try {
                content = JSON.parse(response.data.content);
            } catch(e) {
                content = response.data.content;
            }

            this.setState({
                pageName: pageName,
                title: title,
                type: type,
                content: content
            })
        })
    }

    handleMapButtonClick(index, event) {
        this.setState({
            mapAnchors: {
                [index]: event.currentTarget
            },
        });
    }

    handleMapPopoverClose() {
        this.setState({
            mapAnchors: {},
        });
    }

    renderModularSwitch(content, index) {

        switch(content.type) {
            case "text":
                return (
                    <StyledContentText dangerouslySetInnerHTML={{__html: content.data}}></StyledContentText>
                );
                break;
            case "timeline":
                const anchorEl = this.state.mapAnchors[index];
                const isPopoverOpen = Boolean(anchorEl);
                const localeMoment = moment(content.data.date);
                localeMoment.locale('pl', localization);
                return (
                    <StyledContentTimeline>
                        <Grid container>
                            <Grid item sm={12}>
                                <div className="time">
                                    {content.data.time}
                                    {content.data.date ? (
                                        <Hidden xsDown>
                                            <div className="date">{localeMoment.format('dddd, DD.MM')}</div>
                                        </Hidden>
                                    ) : ''}
                                </div>
                                <div className="label">{content.data.name}</div>
                                <div className="address">
                                    <Icon fontSize="small" className="icon">location_on</Icon> {content.data.place}{content.data.street ? ', ' + content.data.street : ''}
                                </div>
                                <Hidden lgUp>
                                    <div className="date-mobile mobile-phone-only">{localeMoment.format('dddd, DD.MM')}</div>
                                </Hidden>
                                <div className="text" style={{whiteSpace: "pre-wrap"}}>{content.data.text}</div>
                                {/*{content.data.map && content.data.map.lat && content.data.map.lng ? (
                                    <div className="map">
                                        <CommonOutlinedButton onClick={this.handleMapButtonClick.bind(this, index)}>Pokaż na mapie</CommonOutlinedButton>
                                        <MapDialog mapData={content.data.map} open={isPopoverOpen} anchorEl={anchorEl} onClose={this.handleMapPopoverClose.bind(this)} />
                                    </div>
                                ) : ''}*/}
                            </Grid>
                            <Grid item sm={6}>
                            </Grid>
                        </Grid>
                    </StyledContentTimeline>
                );
                break;
        }
    }

    renderType(type) {
        switch(type) {
            case ContentTypes.MODULAR:
                if (!Array.isArray(this.state.content)) {
                    return '';
                }
                return (
                    <Fragment>
                        {this.state.content.map((content, index) => (<div key={index}>
                            {this.renderModularSwitch(content, index)}
                        </div>))}
                    </Fragment>
                );
                break;
            case ContentTypes.STANDARD:
            case ContentTypes.SHORT:
                return (
                    <div dangerouslySetInnerHTML={{__html: this.state.content }}></div>
                );
                break;
            default:
                return (
                    <div>Wystąpił błąd</div>
                );
                break;
        }
    }

    render() {
        return (
            <Fragment>
                <StyledPageTitle className={'font-primary'}>{this.state.title}</StyledPageTitle>
                <StyledPageContent>
                    {this.state.type ? this.renderType(this.state.type) : ''}
                </StyledPageContent>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app
    }
};

export default connect(mapStateToProps)(GenericPage);
