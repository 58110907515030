import React, {Fragment, useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {StyledTooltip} from "../../../../common/TooltipTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ENV from "../../../../config/environment";
import FancyBlock from "../../partials/FancyBlock";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";
import {useSelector} from "react-redux";
import Icon from "@material-ui/core/Icon";
import WEDDING_CONFIG from "../../../../wedding-config";
import IconButton from "@material-ui/core/IconButton";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import {FieldArray} from "formik-newest";
import classnames from 'classnames';

const StyledFormControlLabel = styled(FormControlLabel)`
    margin-left: 0;
`;

const StyledTabLabels = styled.div`
  margin-bottom: 20px;
`;

const StyledTabLabel = styled.span`
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  color: #aaa;
  
  &:hover {
    color: #333;
  }
  
  &.active {
    color: ${WEDDING_CONFIG.colors.primary};
  }
`;

const StyledFormFields = styled.div`
  &.mobile {
    ${StyledTabLabel} {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
`;

export const UserFormView = {
    KIDS: 'KIDS',
    DECLARATION: 'DECLARATION',
    PARTICIPANT: 'PARTICIPANT',
    OTHER: 'OTHER'
}

export function UserEditForm({ focusComponent, ...props}) {
    const user = props.user;
    const isAdding = !!props.isAdding;
    const index = props.index;
    const { values, setValues, handleChange, setFieldValue, dirty } = props.formProps;
    const [currentView, setCurrentView] = useState(focusComponent || UserFormView.PARTICIPANT);

    const indeterminateIcon = () => <Icon>help_outline</Icon>;
    const isMobile = useMediaQuery(MediaRule.tablet);
    const instance = useSelector(state => state.auth.instance);

    const willBePresent = !!values.participants[index].present_first_day || !!values.participants[index].present_second_day;

    function clearDeclaration() {
        setFieldValue(`participants.${index}.present_first_day`, null);
        setFieldValue(`participants.${index}.present_second_day`, null);
        setFieldValue(`participants.${index}.accommodation`, null);
        setFieldValue(`participants.${index}.main_special_menu`, null);
        setFieldValue(`participants.${index}.partner_special_menu`, null);
    }

    return (
        <StyledFormFields className={classnames({ mobile: isMobile })}>
            <StyledTabLabels>
                <StyledTabLabel className={currentView === UserFormView.PARTICIPANT ? 'active' : ''} onClick={() => setCurrentView(UserFormView.PARTICIPANT)}>{isAdding ? `#${index + 1}` : null} Uczestnik</StyledTabLabel>
                <StyledTabLabel className={currentView === UserFormView.KIDS ? 'active' : ''} onClick={() => setCurrentView(UserFormView.KIDS)}>Dzieci</StyledTabLabel>
                <StyledTabLabel className={currentView === UserFormView.DECLARATION ? 'active' : ''} onClick={() => setCurrentView(UserFormView.DECLARATION)}>Deklaracja</StyledTabLabel>
                {ENV.IS_PREMIUM && (instance.is_two_day_party || instance.is_accommodation_available)  ? <StyledTabLabel className={currentView === UserFormView.OTHER ? 'active' : ''} onClick={() => setCurrentView(UserFormView.OTHER)}>Opcje</StyledTabLabel> : null}
            </StyledTabLabels>
            {currentView === UserFormView.PARTICIPANT ? <div style={{minHeight: 350, maxWidth: '100%'}}>
                <Grid container spacing={4} style={{marginTop: 0 }}>
                    <Grid item xs={6} sm={6}>
                        <div style={{fontSize: '1.2rem'}}>Uczestnik</div>
                        <div>
                            <TextField
                                name={`participants.${index}.first_name`}
                                key="first_name"
                                value={values.participants[index].first_name}
                                onChange={handleChange}
                                autoComplete="false"
                                label="Imię">
                            </TextField>
                        </div>
                        <div>
                            <TextField
                                name={`participants.${index}.last_name`}
                                key="last_name"
                                value={values.participants[index].last_name}
                                onChange={handleChange}
                                label="Nazwisko">
                            </TextField>
                        </div>
                    </Grid>
                    {!(ENV.IS_PREMIUM && values.participants[index].disallow_define_partner) ? (
                        <Grid item xs={6} sm={6}>
                            <div style={{fontSize: '1.2rem'}}>
                                Partner
                                <StyledTooltip
                                    placement="top"
                                    title={'Jeśli uczestnik będzie z partnerem, ale jeszcze nie wiesz z kim, pozostaw tę opcję zaznaczoną, a poniższe pola puste.'}>
                                    <span><Checkbox
                                        style={{padding: 0, marginLeft: 15}}
                                        name={`participants.${index}.is_with_partner`}
                                        key={`participants.${index}.is_with_partner`}
                                        checked={values.participants[index].is_with_partner}
                                        value={true}
                                        onChange={handleChange}
                                    /></span>
                                </StyledTooltip>

                            </div>
                            <div>
                                <TextField
                                    name={`participants.${index}.partner_first_name`}
                                    key="partner_first_name"
                                    value={values.participants[index].partner_first_name}
                                    onChange={handleChange}
                                    disabled={!values.participants[index].is_with_partner}
                                    label="Imię partnera">
                                </TextField>
                            </div>
                            <div>
                                <TextField
                                    name={`participants.${index}.partner_last_name`}
                                    key="partner_last_name"
                                    value={values.participants[index].partner_last_name}
                                    onChange={handleChange}
                                    disabled={!values.participants[index].is_with_partner}
                                    label="Nazwisko">
                                </TextField>
                            </div>
                        </Grid>
                    ): ''}
                </Grid>
                {ENV.IS_PREMIUM ? (
                    <Fragment>
                        <Grid item xs={12} style={{marginTop: 20}}>
                            <div style={{marginTop: 10}}>
                                <FancyBlock
                                    small={true}
                                    inactive={!values.participants[index].disallow_define_partner}
                                    tooltip={'Jeśli chcesz zaprosić tylko samego uczestnika, zaznacz tę opcję. Uczestnik nie będzie mógł dodać partnera.'}
                                    mainProps={{
                                        onClick: () => {
                                            const newValue = !values.participants[index].disallow_define_partner;
                                            setFieldValue(`participants.${index}.disallow_define_partner`, newValue);
                                            if (newValue) {
                                                setFieldValue(`participants.${index}.is_with_partner`, false);
                                            }
                                        }
                                    }}
                                    mainLabel={values.participants[index].is_with_partner ? "Zablokuj dodanie partnera" : "Zablokuj dodanie partnera"}>
                                    <Checkbox
                                        name={`participants.${index}.disallow_define_partner`}
                                        key="disallow_define_partner"
                                        checked={values.participants[index].disallow_define_partner}
                                        value={true}
                                        onChange={handleChange}
                                    />
                                </FancyBlock>
                            </div>
                            <div style={{marginTop: 10}}>
                                <FancyBlock
                                    small={true}
                                    inactive={!values.participants[index].allow_adding_kids}
                                    tooltip={'Uwaga! Jeśli zaznaczone, użytkownik będzie mógł dodawać dzieci samodzielnie. Weź to pod uwagę planując ilość miejsc.'}
                                    mainProps={{
                                        onClick: () => {
                                            setFieldValue(`participants.${index}.allow_adding_kids`, !values.participants[index].allow_adding_kids);
                                        }
                                    }}
                                    mainLabel="Pozwól użytkownikowi samodzielnie zmieniać dane dzieci">
                                    <Checkbox
                                        name={`participants.${index}.allow_adding_kids`}
                                        key="allow_adding_kids"
                                        checked={values.participants[index].allow_adding_kids}
                                        value={true}
                                        onChange={handleChange}
                                    />
                                </FancyBlock>
                            </div>
                        </Grid>
                    </Fragment>
                ) : null}

            </div> : null}



            {currentView === UserFormView.KIDS ? (
                <Fragment>
                <div>
                    <StyledFormControlLabel
                        control={
                            <Checkbox
                                name={`participants.${index}.is_with_kids`}
                                key={`participants.${index}.is_with_kids`}
                                checked={values.participants[index].is_with_kids}
                                value={true}
                                onChange={handleChange}
                            />
                        }
                        label="Uczestnik będzie z dziećmi"
                    />
                </div>
                <FieldArray
                name={`participants.${index}.kids`}
                render={ arrayHelpers => (
                <Fragment>
                    {values.participants[index].is_with_kids ? values.participants[index].kids.map((kid, indexKid) => (
                            <Grid container key={`participants.${index}.kid${indexKid}`}>
                                <Grid item xs={4} sm={5}>
                                    <TextField
                                        name={`participants.${index}.kids[${indexKid}].kid_first_name`}
                                        key={`participants.${index}.kids[${indexKid}].kid_first_name`}
                                        value={values.participants[index].kids[indexKid].kid_first_name}
                                        label="Imię"
                                        type="text"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={6}>
                                    <TextField
                                        name={`participants.${index}.kids[${indexKid}].kid_last_name`}
                                        key={`participants.${index}.kids[${indexKid}].kid_last_name`}
                                        value={values.participants[index].kids[indexKid].kid_last_name}
                                        label="Nazwisko"
                                        type="text"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={1}>
                                    <StyledTooltip title="Usuń" placement="top">
                                        <IconButton onClick={() => arrayHelpers.remove(indexKid)}>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </StyledTooltip>
                                </Grid>
                            </Grid>
                        )
                    ) : null}
                    {values.participants[index].is_with_kids ? <div>
                        <br />
                        <CommonOutlinedButton disabled={!values.participants[index].is_with_kids} className="sm" button onClick={(e) => arrayHelpers.push({ kid_first_name: '', kid_last_name: ''})}>
                            Dodaj dziecko
                        </CommonOutlinedButton>
                    </div> : null}
                </Fragment>
            )}
                />
                </Fragment>

            ) : null}



            {currentView === UserFormView.DECLARATION ? (
                <Fragment>
                    <div className={'info-box'}>Jeśli znasz już decyzję tego gościa, możesz od razu oznaczyć jego obecność.</div>
                    <div>
                        <FancyBlock
                            inactive={!values.participants[index].present_first_day}
                            small={true}
                            mainProps={{
                                onClick: (e) => {
                                    setFieldValue(`participants.${index}.present_first_day`, !values.participants[index].present_first_day);

                                    if (!willBePresent) {
                                        setFieldValue(`participants.${index}.accommodation`, false);
                                    }

                                    if (values.participants[index].present_second_day === null) {
                                        setFieldValue(`participants.${index}.present_second_day`, false);
                                    }
                                }
                            }}
                            mainLabel="Będzie obecny(a) na weselu">
                            <Checkbox
                                indeterminate={values.participants[index].present_first_day === null}
                                indeterminateIcon={indeterminateIcon()}
                                key={`participants.${index}.present_first_day`}
                                name={`participants.${index}.present_first_day`}
                                checked={!!values.participants[index].present_first_day}
                                value={true}
                                onChange={handleChange}
                            />
                        </FancyBlock>
                    </div>
                    {instance.is_two_day_party ? (
                        <div>
                            <FancyBlock
                                inactive={!values.participants[index].present_second_day}
                                small={true}
                                mainProps={{
                                    onClick: () => {
                                        setFieldValue(`participants.${index}.present_second_day`, !values.participants[index].present_second_day);

                                        if (!willBePresent) {
                                            setFieldValue(`participants.${index}.accommodation`, false);
                                        }

                                        if (values.participants[index].present_first_day === null) {
                                            setFieldValue(`participants.${index}.present_first_day`, false);
                                        }
                                    }
                                }}
                                mainLabel="Będzie obecny(a) na poprawinach">
                                <Checkbox
                                    indeterminate={values.participants[index].present_second_day === null}
                                    indeterminateIcon={indeterminateIcon()}
                                    key={`participants.${index}.present_second_day`}
                                    name={`participants.${index}.present_second_day`}
                                    checked={!!values.participants[index].present_second_day}
                                    value={true}
                                    onChange={handleChange}
                                />
                            </FancyBlock>
                        </div>
                    ) : ''}
                    {instance.is_accommodation_available ? (
                        <div>
                            <FancyBlock
                                inactive={!values.participants[index].accommodation}
                                small={true}
                                mainLabel="Skorzysta z noclegu"
                                mainProps={{
                                    onClick: () => {
                                        if (!willBePresent) return;
                                        setFieldValue(`participants.${index}.accommodation`, !values.participants[index].accommodation);
                                    }
                                }}>
                                <Checkbox
                                    indeterminate={values.participants[index].accommodation === null}
                                    indeterminateIcon={indeterminateIcon()}
                                    key={`participants.${index}.accommodation`}
                                    name={`participants.${index}.accommodation`}
                                    checked={willBePresent && !!values.participants[index].accommodation}
                                    value={true}
                                    onChange={handleChange}
                                />
                            </FancyBlock>
                        </div>
                    ) : ''}
                    {instance.special_menus && instance.special_menus.length ? (
                        <FancyBlock
                            inactive={!willBePresent}
                            small={true}
                            mainLabel={values.participants[index].first_name ? values.participants[index].first_name + " dostanie menu" : 'Menu'}>
                            <Select
                                disabled={!willBePresent}
                                style={{marginLeft: 10}}
                                displayEmpty
                                key={`participants.${index}.main_special_menu`}
                                name={`participants.${index}.main_special_menu`}
                                value={values.participants[index].main_special_menu || ''}
                                onChange={handleChange}
                            >
                                <MenuItem value="">zwykłe</MenuItem>
                                {values.participants[index].main_special_menu && !instance.special_menus.find((menu) => menu === values.participants[index].main_special_menu) ? (
                                    <MenuItem value={values.participants[index].main_special_menu} disabled>
                                        {values.participants[index].main_special_menu}
                                    </MenuItem>
                                ): null}
                                {instance.special_menus.map((menu, menuIdx) => <MenuItem key={`menu${menuIdx}`} value={menu}>{menu}</MenuItem>)}
                            </Select>
                        </FancyBlock>
                    ) : ''}
                    {instance.special_menus && instance.special_menus.length && values.participants[index].is_with_partner ? (
                        <FancyBlock
                            inactive={!willBePresent}
                            small={true}
                            mainLabel={values.participants[index].partner_first_name ? values.participants[index].partner_first_name + " dostanie menu" : "Menu dla partnera"}>
                            <Select
                                disabled={!willBePresent}
                                style={{marginLeft: 10}}
                                displayEmpty
                                key={`participants.${index}.partner_special_menu`}
                                name={`participants.${index}.partner_special_menu`}
                                value={values.participants[index].partner_special_menu || ''}
                                onChange={handleChange}
                            >
                                <MenuItem value="">zwykłe</MenuItem>
                                {values.participants[index].partner_special_menu && !instance.special_menus.find((menu) => menu === values.participants[index].partner_special_menu) ? (
                                    <MenuItem value={values.participants[index].partner_special_menu} disabled>
                                        {values.participants[index].partner_special_menu}
                                    </MenuItem>
                                ): null}
                                {instance.special_menus.map((menu, menuIdx) => <MenuItem key={`mnu${menuIdx}`} value={menu}>{menu}</MenuItem>)}
                            </Select>
                        </FancyBlock>
                    ) : ''}
                    <p>
                        Status deklaracji:<br />{values.participants[index].present_first_day === null && values.participants[index].present_second_day === null ? "Niewypełniona." : (
                        "Wypełniona. Uczestnik " + (willBePresent ? 'obecny.' : 'nieobecny.')
                    )}
                    </p>

                    <div>
                        <CommonOutlinedButton
                            disabled={values.participants[index].present_first_day === null && values.participants[index].present_second_day === null}
                            className={'size-small'}
                            onClick={clearDeclaration}>Wyzeruj deklarację</CommonOutlinedButton>
                    </div>
                </Fragment>
            ) : null}

            {ENV.IS_PREMIUM && currentView === UserFormView.OTHER && (instance.is_two_day_party || instance.is_accommodation_available) ? (
                <Fragment>
                    <div className={'info-box'}>
                        Poniższe opcje odnoszą się do widoczności poszczególnych informacji dla gości.
                        <br />
                        <br />
                        {instance.is_two_day_party ? <Fragment>Przykładowo, jeśli nie chcesz aby gość widział informacje o poprawinach, odznacz tę opcję.
                            Wtedy gość nie będzie również widział opcji zaznaczenia poprawin podczas składania Deklaracji Online.</Fragment> : <Fragment>
                            Przykładowo, jeśli nie chcesz aby gość widział informacje o noclegu, odznacz poniższą opcję.
                            Gość nie będzie również widział opcji zaznaczenia chęci na rezerwację noclegu podczas składania Deklaracji Online.
                        </Fragment>}
                    </div>
                    {instance.is_two_day_party ? <div style={{marginTop: 10}}>
                        <FancyBlock
                            small={true}
                            inactive={!values.participants[index].allow_be_present_second_day}
                            mainProps={{
                                onClick: () => {
                                    setFieldValue(`participants.${index}.allow_be_present_second_day`, !values.participants[index].allow_be_present_second_day);
                                }
                            }}
                            mainLabel="Pozwól użytkownikowi zaznaczyć obecność na poprawinach">
                            <Checkbox
                                name={`participants.${index}.allow_be_present_second_day`}
                                key="allow_be_present_second_day"
                                checked={values.participants[index].allow_be_present_second_day}
                                value={true}
                                onChange={handleChange}
                            />
                        </FancyBlock>
                    </div> : null}
                    {instance.is_accommodation_available ? <div style={{marginTop: 10}}>
                        <FancyBlock
                            small={true}
                            inactive={!values.participants[index].allow_selecting_accommodation}
                            mainProps={{
                                onClick: () => {
                                    setFieldValue(`participants.${index}.allow_selecting_accommodation`, !values.participants[index].allow_selecting_accommodation);
                                }
                            }}
                            mainLabel="Pozwól użytkownikowi zarezerwować nocleg">
                            <Checkbox
                                name={`participants.${index}.allow_selecting_accommodation`}
                                key="allow_selecting_accommodation"
                                checked={values.participants[index].allow_selecting_accommodation}
                                value={true}
                                onChange={handleChange}
                            />
                        </FancyBlock>
                    </div> : null}
                </Fragment>
            ) : null}
        </StyledFormFields>
    );
}
