import React, {Fragment, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';
import MediaSizes, {MediaRule} from "../../../constants/media-sizes.constants";
import {MainMenuMobile} from "../components/MainMenuMobile";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import {MainMenuList} from "../components/MainMenuList";
import HeaderLogoPanel from "./HeaderLogoPanel";
import {PageWrapper} from "./PageWrapper";
import {PwaInstruction} from "../components/PwaInstruction";

const colors = WEDDING_CONFIG.colors;

const StyledHeaderContainer = styled.div`
  background-image: url('/images/bubbles-bg3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  padding-top: 0;
  padding-top: env(safe-area-inset-top);
  
  ${props => props.mobileMenuExpanded ? `
      bottom: 0;
      overflow-y: auto;
  ` : null}

  @media (min-width: ${MediaSizes.phone + 1}px) {
    position: sticky;
  }
`;

const HeadWrapper = styled(PageWrapper)`
    padding: 10px 15px 0 15px;
`;

export default function TopHeader() {
    const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
    const [pwaInstructionVisible, setPwaInstructionVisible] = useState(false);
    const isMobile = useMediaQuery(MediaRule.tablet);

    return (
        <StyledHeaderContainer id={'top-header'} mobileMenuExpanded={mobileMenuExpanded}>
            <HeadWrapper className="font-primary" mobileMenuExpanded={mobileMenuExpanded}>
                <HeaderLogoPanel isMobile={isMobile} mobileMenuExpanded={mobileMenuExpanded} setMobileMenuExpanded={setMobileMenuExpanded} />
                {isMobile ?
                    (mobileMenuExpanded ?
                        <MainMenuMobile
                            setPwaInstructionVisible={setPwaInstructionVisible}
                            menuToggle={() => setMobileMenuExpanded(false)} /> :
                        null)
                    :
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <MainMenuList />
                        </Grid>
                    </Grid>
                }
            </HeadWrapper>
            {pwaInstructionVisible ? <PwaInstruction onClose={() => setPwaInstructionVisible(false)} /> : null}
        </StyledHeaderContainer>
    )
}
