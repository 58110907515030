import React from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import WEDDING_CONFIG from '../../../wedding-config';
import { connect } from 'react-redux';
import participantService from "../../../service/participant.service";
import {CommonOutlinedButton} from "../../../common/StyledButton";
import SimpleDialog from "../../../common/SimpleDialog";
import moment from "moment";
import localization from 'moment/locale/pl';

const colors = WEDDING_CONFIG.colors;

class ChangelogDialog extends React.Component {
    state = {
        type: 'big-full',
        mode: 'all',
        selected: [],
        usersList: [],
    };
    form;

    handleChangeMode(e) {
        if (!this.state.usersList.length && e.target.value === 'selected') {
            this.getList();
        }
    }

    getList() {
        participantService.getParticipantsList().then(response => {
            this.setState({
                usersList: response.data.list
            });
        })
    }

    render() {
        const localeMoment = moment(this.props.changelogDetails.created_at);
        localeMoment.locale('pl', localization);

        return (
            <SimpleDialog
                fullScreen={this.props.isMobile}
                open={true}
                maxWidth={'sm'}
                fullWidth={false}
                title={localeMoment.format('D MMMM YYYY')}
                onClose={this.props.handleClose}
                actions={<CommonOutlinedButton onClick={this.props.handleClose} color="primary" variant="outlined" size="large" autoFocus>
                    Zamknij
                </CommonOutlinedButton>}
                aria-labelledby="responsive-dialog-title">
                {this.props.changelogDetails.long_text}
            </SimpleDialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(withMobileDialog()(ChangelogDialog));
