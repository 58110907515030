import api from '../api';

const getTasks = () => {
    return api.request({
        url: api.suRoutes.tasksList,
        method: 'get',
        data: {}
    });
};

const getTasksSummary = () => {
    return api.request({
        url: api.suRoutes.tasksListSummary,
        method: 'get',
        data: {}
    });
};

const addTask = (data) => {
    return api.request({
        url: api.suRoutes.saveTask,
        method: 'post',
        data,
    });
};

const editTask = (data, id) => {
    return api.request({
        url: api.suRoutes.saveTask + '/' + id,
        method: 'put',
        data,
    });
};

const removeTask = (id) => {
    return api.request({
        url: api.suRoutes.saveTask + '/' + id,
        method: 'delete',
    });
};

const markAsDone = (done, id) => {
    return api.request({
        url: api.suRoutes.saveTask + '/' + id + '/mark-as-done',
        method: 'put',
        data: {
            is_done: done
        },
    });
};

export default {
    getTasks,
    getTasksSummary,
    addTask,
    editTask,
    removeTask,
    markAsDone,
};
