import Icon from "@material-ui/core/Icon";
import ENV from "../../../config/environment";
import PremiumLabel from "../partials/PremiumLabel";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import NavLink from "react-router-dom/NavLink";
import {darken, transparentize} from "polished";
import {MediaHeights} from "../../../constants/media-sizes.constants";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import WEDDING_CONFIG from "../../../wedding-config";
import store from "../../../store";
import {toggleAdminPanel} from "../../../actions";
import {useSnackbar} from 'notistack';
import {Badge} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";

const colors = WEDDING_CONFIG.colors;

const StyledListItemText = styled.span`
    display: inline-block;
    vertical-align: middle;
    color: white;
`;

const StyledListItem = styled(NavLink)`
    && {
        flex-grow: 1;
        display: inline-block;
        padding: 10px 8px;
        width: 30px;
        box-sizing: border-box;
        text-decoration: none;
        border-radius: 4px 4px 0 0;
        text-align: center;
        font-size: 0.9rem;
        transition: background 0.05s ease-in;
        
        &:hover {
          background: ${transparentize(0.8, '#fff')};
        }
        
        &.active {
            //background: ${darken(0.15, colors.primary)};
            background: ${colors.white};
            
            .MuiIcon-root, ${StyledListItemText} {
              color: #666;
            }
        }
        
        &.last {
          width: auto;
        }
        
        @media (max-height: ${MediaHeights.sm}px) {
            padding: 8px 12px;
        }
        
        &.mobile-disabled {
          span {
            color: #ababab;
          }
        }
    }
`;

const StyledList = styled.div`
    display: flex;
    flexDirection: row;
    padding: 0;
    
    &.mobile {
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
    }
    
    &.mobile ${StyledListItem} {
      flex: 0 0 33%;
      border-radius: 4px;
    }
`;


const StyledListItemBlocked = styled(StyledListItem)`
  && {
    position: relative;
    
    &:hover {
      background: transparent;
    }
    
    span {
      color: ${colors.secondary};
    }
    
    .premium-label {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
    && {
      vertical-align: middle;
      width: 100%;
      display:block;
      color: white;
      margin-right: 0;
      position: relative;
      
      .MuiIcon-root {
            font-size: 2rem;
      }
      
      .MuiBadge-root {
        position: absolute;
      }
    }
`;

export function MainMenuList({ isMobile, mobileMenuToggle }) {
    const instance = useSelector(state => state.auth.instance);
    const appStore = useSelector(state => state.app);
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [chatBadgeContent, setChatBadgeContent] = useState(instance.is_chat_enabled ?
        appStore.adminNewChatMessagesCount : 0);

    function scrollClick() {
        store.dispatch(toggleAdminPanel(false));
        hideMenu();
    }

    function mobileDisabledMessage(type) {
        let msg;
        switch(type) {
            case "tables":
                msg = 'Moduł dostępny tylko w przeglądarce na komputerze.';
                break;
            case "accommodation":
                msg = 'Moduł dostępny tylko w przeglądarce na komputerze.';
                break;
        }

        if (msg) {
            enqueueSnackbar(msg);
        }
    }

    function hideMenu() {
        if (!isMobile) return;
        mobileMenuToggle();
    }

    useEffect(() => {
        if (typeof appStore.adminNewChatMessagesCount === 'number') {
            setChatBadgeContent(appStore.adminNewChatMessagesCount);
        }
    }, [dispatch, appStore.adminNewChatMessagesCount]);

    return (
        <StyledList component="nav" className={`${isMobile ? 'mobile' : ''}`}>
            <StyledListItem isActive={(a, b) => b.pathname === '/' || b.pathname.includes('/admin/instance')} to="/admin/instance" activeClassName="active" onClick={() => hideMenu()}>
                <StyledListItemIcon>
                    <Icon>whatshot</Icon>
                </StyledListItemIcon>
                <StyledListItemText>Aplikacja</StyledListItemText>
            </StyledListItem>
            <StyledListItem to="/admin/users" activeClassName="active" onClick={() => hideMenu()}>
                <StyledListItemIcon>
                    <Icon>group</Icon>
                </StyledListItemIcon>
                <StyledListItemText>Uczestnicy</StyledListItemText>
            </StyledListItem>
            <StyledListItem to="/admin/tasks" activeClassName="active" onClick={() => hideMenu()}>
                <StyledListItemIcon>
                    <Icon>playlist_add_check</Icon>
                </StyledListItemIcon>
                <StyledListItemText>Zadania</StyledListItemText>
            </StyledListItem>
            {isMobile ? (
                <StyledListItem to="#" activeClassName="inactive" className={'mobile-disabled'} onClick={() => mobileDisabledMessage('tables')}>
                    <StyledListItemIcon>
                        <Icon>view_carousel</Icon>
                    </StyledListItemIcon>
                    <StyledListItemText>Stoły</StyledListItemText>
                </StyledListItem>
            ) : (
                <StyledListItem to="/admin/tables" activeClassName="active" onClick={() => hideMenu()}>
                    <StyledListItemIcon>
                        <Icon>view_carousel</Icon>
                    </StyledListItemIcon>
                    <StyledListItemText>Stoły</StyledListItemText>
                </StyledListItem>
            )}
            {isMobile ? (
                <StyledListItem to="#" activeClassName="inactive" className={'mobile-disabled'} onClick={() => mobileDisabledMessage('accommodation')}>
                    <StyledListItemIcon>
                        <Icon>hotel</Icon>
                    </StyledListItemIcon>
                    <StyledListItemText>Noclegi</StyledListItemText>
                </StyledListItem>
            ) : (
                <StyledListItem to="/admin/accommodation-planner" activeClassName="active" onClick={() => hideMenu()}>
                    <StyledListItemIcon>
                        <Icon>hotel</Icon>
                    </StyledListItemIcon>
                    <StyledListItemText>Noclegi</StyledListItemText>
                </StyledListItem>
            )}
            <StyledListItem to="/admin/chat" activeClassName="active" onClick={() => hideMenu()}>
                <StyledListItemIcon>
                    <Icon>question_answer</Icon>
                    {/*<Badge badgeContent={ENV.IS_PREMIUM ? chatBadgeContent : 'nowość'} color="secondary" invisible={ENV.IS_PREMIUM && !chatBadgeContent}></Badge>*/}
                </StyledListItemIcon>
                <StyledListItemText>Chat</StyledListItemText>
            </StyledListItem>
            <StyledListItem to="/admin/statistics" activeClassName="active" onClick={() => hideMenu()}>
                <StyledListItemIcon>
                    <Icon>bar_chart</Icon>
                </StyledListItemIcon>
                <StyledListItemText>Statystyki</StyledListItemText>
            </StyledListItem>
            {ENV.IS_PREMIUM ? (
                <StyledListItem to="/admin/contents" activeClassName="active" onClick={() => hideMenu()}>
                    <StyledListItemIcon>
                        <Icon>create</Icon>
                    </StyledListItemIcon>
                    <StyledListItemText>Treści</StyledListItemText>
                </StyledListItem>
            ) : (
                <StyledListItemBlocked to="/admin/premium" activeClassName="d" onClick={() => hideMenu()}>
                    <StyledListItemIcon>
                        <Icon>create</Icon>
                    </StyledListItemIcon>
                    <StyledListItemText>Dla gości</StyledListItemText>
                    <PremiumLabel>premium</PremiumLabel>
                </StyledListItemBlocked>
            )}
            <StyledListItem isActive={(a, b) => b.pathname.includes('/admin/settings')} to="/admin/settings/instance-settings" activeClassName="active" onClick={() => hideMenu()}>
                <StyledListItemIcon>
                    <Icon>build</Icon>
                </StyledListItemIcon>
                <StyledListItemText>Pozostałe</StyledListItemText>
            </StyledListItem>
            <div className="fill-space"></div>
            {ENV.IS_PREMIUM ? (
                <StyledListItem className={'last'} exact to={'/'} isActive={(a, b) => false} activeClassName="active" onClick={scrollClick.bind(this)}>
                    <StyledListItemIcon>
                        <Icon>arrow_upward</Icon>
                    </StyledListItemIcon>
                    <StyledListItemText>widok gościa</StyledListItemText>
                </StyledListItem>
            ) : (
                <StyledListItem className={'last'} isActive={(a, b) => b.pathname.includes('/admin/premium')} exact to={'/admin/premium'} activeClassName="active" onClick={() => hideMenu()}>
                    <StyledListItemIcon>
                        <Icon>lock_open</Icon>
                    </StyledListItemIcon>
                    <StyledListItemText>Premium</StyledListItemText>
                </StyledListItem>
            )}
        </StyledList>
    );
}
