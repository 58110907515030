const ENV = {
  IS_PREMIUM: true,
  API_URL: 'https://api.fancy.wedding',
  INSTANCE_DEBUG_NAME: '',
  WEBSITE_URL: 'https://www.fancy.wedding',
  GOOGLE_API_KEY: 'AIzaSyAbUoKY4xoi7bzv74bA4XeRWcWBunTG4ng',
  INSTAGRAM_URL: 'https://www.instagram.com/fancy.wedding/',
  FACEBOOK_URL: 'https://www.facebook.com/Aplikacja-Weselna-fancywedding-2255817937833293'
};

export default ENV;
