import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const StyledInput = styled(TextField)`
    && input {
        font-size: 1.2rem;
    }
`;

export const StyledTextField = styled(TextField)`
    && input {
        font-size: 1rem;
    }
    .MuiInput-underline:after {
      //border-bottom-color: red;
    }
`;
