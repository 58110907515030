import React, {useState} from "react";
import styled from "styled-components";
import {CommonOutlinedButton} from "./StyledButton";
import MediaSizes from "../constants/media-sizes.constants";
import WEDDING_CONFIG from "../wedding-config";
import {darken} from "polished";

const colors = WEDDING_CONFIG.colors;

export const StyledMessagingArea = styled.div`
  padding: 10px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (min-width: ${MediaSizes.tablet}px) {
    max-width: 85%;
  }
`;

export const StyledMessagingTopBar = styled.div`
  h2 {
    border-bottom: 1px solid #ddd;
    margin-top: 0;
  }
`;

export const StyledChatBubbles = styled.div`
  flex: 1 1 1px;
  overflow-y: scroll;
`;

export const StyledMessagingBottomBar = styled.div`
  border: 0;
  border-radius: 10px;
  display: flex;
  margin-top: 30px;
`;

export const StyledChatMessageLine = styled.div`
  margin-bottom: 5px;
  white-space: pre-line;

  .message {
    display: inline-block;
    cursor: pointer;
    padding: 7px 8px;
    border-radius: 10px;
    background-color: #eee;
    word-break: break-word;
    max-width: 70%;
  }

  .content {
    color: #aaa;
    font-size: 0.8rem;
    margin-top: 2px;
  }

  .collapsed-content {
    display: none;
  }

  &.expanded .collapsed-content {
    display: block;
  }

  &.your {
    text-align: right;
    margin-right: 10px;

    .message {
      background-color: #feebf0;
    }
  }

  &.is_announcement {
    text-align: center;

    .message {
      background-color: ${WEDDING_CONFIG.colors.alternativeInfo};
      padding: 20px;
      margin: 15px 0;
    }
  }
`;

export const StyledSendButton = styled(CommonOutlinedButton)`
  && {
    margin: 0;
    border-width: 1px;
    border-radius: 0 10px 10px 0;
    padding: 18px;
    position: relative;
    top: -2px;
    
    &.is_announcement_chat {
      border-color: ${darken(0, colors.alternativeInfo)};
      background-color: ${darken(0, colors.alternativeInfo)};
      
      &:not(:disabled) {
        color: #333;
      }
    }
  }
`;

export const StyledChatMessageInput = styled.textarea`
  padding: 18px;
  font-size: 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  top: -2px;
  font-family: "PT Sans Regular";
  outline: 0;
  resize: none;
  border: 1px solid #eee;
  -webkit-appearance: none;
  border-right-width: 0;
  border-radius: 2px 0 0 2px;
  color: #888;
  transition: border-color 0.25s ease-in;
  
  &:focus {
    border-color: #ddd;
    color: #666;
  }

  ${props => props.invalid ? `
    border-color: red!important;
  ` : ''}
`;
