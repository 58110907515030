import React, {Fragment, useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import participantService from "../../../../service/participant.service";
import {StyledTooltip} from "../../../../common/TooltipTitle";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import TableContainer from "@material-ui/core/TableContainer";
import {useSelector} from "react-redux";
import {useSnackbar} from 'notistack';
import WEDDING_CONFIG from "../../../../wedding-config";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";
import UsersNonGuestsListActions from "./UsersNonGuestsListActions";
import {UsersNonGuestsListTableRow} from "./UsersNonGuestsListTableRow";
import {useFormik} from "formik-newest";
import {StyledCardHeader} from "../../../../common/StyledCard";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import UserNonGuestEditDialog from "./UserNonGuestEditDialog";
import Grid from "@material-ui/core/Grid";
import classnames from 'classnames';
import {StyledTable} from "../../../../common/StyledTable";

const colors = WEDDING_CONFIG.colors;

const StyledSummaryTableRow = styled(TableRow)`
  & .cell {
    font-weight: bold;
    color: ${colors.primary};
  }
`;

const StyledTableContainer = styled(TableContainer)`
  && {
    overflow-x: initial;
    
    &.mobile {
        .MuiTableCell-sizeSmall {
          padding: 6px 6px 6px 0;
        }
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 5px;
    .icon {
      font-size: 20px;
      
      &.icon-positive {
          color: #ab1945;
      }
    }
  }
`;

function yesOrNoIconEditRenderer(userId, value) {
    if (value === null) {
        return '-';
    }

    return (
        <StyledIconButton disabled>
            {value ? (<Icon className="icon icon-positive">check</Icon>) : (
                <Icon className="icon icon-negative">close</Icon>)}
        </StyledIconButton>
    )
}

function yesOrNo(value) {
    if (value === null) {
        return '-';
    }

    return (
        <StyledIconButton disabled>
            {value ? (<Icon className="icon icon-positive">check</Icon>) : (
                <Icon className="icon icon-negative">close</Icon>)}
        </StyledIconButton>
    );
}

function yesOrNoOrUnknown(value) {
    return (
        <StyledIconButton>
            {value ? (<Icon className="icon icon-positive">check</Icon>) : null}
            {value === false ? (<Icon className="icon icon-negative">close</Icon>) : null}
            {value === null ? (<Icon className="icon">help_outline</Icon>) : null}
        </StyledIconButton>
    );
}

function menusRenderer(row) {
    return row.main_special_menu || row.partner_special_menu ? (
        <Fragment>
            {row.main_special_menu ? <StyledTooltip title={row.first_name} placement="top">
                <div>{row.main_special_menu}</div>
            </StyledTooltip> : null}
            {row.partner_special_menu ? <StyledTooltip title={row.partner_first_name} placement="top">
                <div>{row.partner_special_menu}</div>
            </StyledTooltip> : null}
        </Fragment>
    ) : '-';
}

function participantRenderer(row) {
    return <strong>{row.name}</strong>;
}

function peopleRenderer(row) {
    return <strong>{row.people}</strong>;
}

function actionsRenderer(row, index, instance, toggleEditDialog, deleteRecord) {
    if (row.is_bride_row && !instance.is_accommodation_available) {
        return null;
    }
    return (
        <Fragment>
            <div style={{display: 'inline-block'}}>
                <UsersNonGuestsListActions user={row}
                                  index={index}
                                  instance={instance}
                                  toggleEditDialog={toggleEditDialog}
                                  onDelete={() => deleteRecord(index)}></UsersNonGuestsListActions>
            </div>
        </Fragment>
    )
}

export function UsersNonGuestsListTable({ rows, summaryRow, fetchList, setListFromBackend}) {
    const [isAdding, setIsAdding] = useState(false);
    const instance = useSelector(state => state.auth.instance);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const widths = {
        short: 50
    };
    const headerHeight =  0;
    // const headerHeight = document.getElementById('top-header') ? document.getElementById('top-header').clientHeight : 0;
    const isMobile = useMediaQuery(MediaRule.tablet);
    const [editData, setEditData] = React.useState(null);
    const [editIndex, setEditIndex] = React.useState(null);
    const form = useFormik({
        initialValues: {
            ...rows
        }
    });

    const desktopColumns = [
        {
            id: 'is_active',
            label: 'Aktywny',
            renderer: (row) => yesOrNo(row.is_active)
        },
        {id: 'participant', label: 'Uczestnik', minWidth: 150, renderer: (row) => participantRenderer(row)},
        {id: 'people', label: 'Osób', renderer: (row) => peopleRenderer(row)},
        {
            id: 'present_first_day',
            label: 'Wesele',
            renderer: (row) => yesOrNo(row.present_first_day)
        },
        {
            id: 'present_second_day',
            label: 'Poprawiny',
            visible: !!instance.is_two_day_party,
            renderer: (row) => yesOrNo(row.present_second_day)
        },
        {
            id: 'accommodation',
            label: 'Nocleg',
            visible: !!instance.is_accommodation_available,
            renderer: (row) => yesOrNo(row.accommodation)
        },
        // {id: 'special_menu', label: 'Menu', minWidth: widths.short, renderer: (row) => menusRenderer(row)},
        {
            id: 'actions',
            label: '',
            renderer: (row, rowIndex) => actionsRenderer(row, rowIndex, instance, toggleEditDialog, deleteRecord)
        },
    ];
    const mobileColumns = [
        {id: 'participant', label: 'Uczestnik', renderer: (row) => participantRenderer(row)},
        {
            id: 'people',
            label: 'Osób',
            align: 'right',
            renderer: (row) => row.people
        },
    ];
    const columns = isMobile ? mobileColumns : desktopColumns;

    function visible(column) {
        return typeof column.visible === 'boolean' ? column.visible : true;
    }

    function toggleAddDialog(show) {
        setIsAdding(show);
    }

    function renderSummaryRow() {
        const summaryRowData = {...summaryRow};

        summaryRowData.is_active = null;
        summaryRowData.participant = `Razem: ${summaryRow.total_participants} gości`;
        summaryRowData.accommodation = summaryRow.accommodation_beds;
        summaryRowData.last_visit += ' kont';

        if (isMobile) {
            summaryRowData.declaration = `Obecnych: ${summaryRow.present_first_day}`;
        }

        return (
            <StyledSummaryTableRow>
                {columns.filter(visible).map((column) => {
                    const value = summaryRowData[column.id];
                    return (
                        <TableCell
                            className={'cell'}
                            key={'summary' + column.id}
                            align={column.align}>{value}</TableCell>
                    )
                })}
            </StyledSummaryTableRow>
        )
    }

    useEffect(() => {
        fetchList();
    }, []);

    function addRecord(data) {
        const newData = JSON.parse(JSON.stringify(rows));

        newData.others.push(data);

        update(newData).then(() => {
            toggleAddDialog(false);
            enqueueSnackbar('Dodano uczestnika ' + data.name, {
                variant: 'success'
            });
        });
    }

    function updateRecord(data, isBrideRow) {
        const newData = JSON.parse(JSON.stringify(rows));

        if (isBrideRow) {
            newData.bride = { ...rows.bride, ...data };
        } else {
            newData.others[editIndex] = { ...newData.others[editIndex], ...data };
        }

        update(newData).then(() => {
            toggleEditDialog();
            enqueueSnackbar('Lista zapisana.', {
                variant: 'success'
            });
        });
    }

    function deleteRecord(index) {
        const newData = JSON.parse(JSON.stringify(rows));

        newData.others.splice(index, 1);

        update(newData).then(() => {
            enqueueSnackbar('Usunięto pozycję.', {
                variant: 'success'
            });
        });
    }

    function update(dataValues) {
        return participantService.updateSpecialParticipants(dataValues).then(response => {
            // setrows(response.data.result);
            // setSummaryRow(response.data.specialSummary);
            setListFromBackend(response.data);
        });
    }

    function toggleEditDialog(data, index) {
        setEditData(data || null);
        setEditIndex(index);
    }

    return (
        <Fragment>
            {isAdding ? <UserNonGuestEditDialog addRecord={addRecord} onClose={() => toggleAddDialog(false)} /> : null}
            {editData ? <UserNonGuestEditDialog data={editData} updateRecord={updateRecord} onClose={() => toggleEditDialog()} /> : null}
            <StyledCardHeader>
                <Grid container>
                    <Grid item xs={8}>
                        Uczestnicy spoza listy gości
                    </Grid>
                    <Grid item xs={4} className={'text-right'}>
                        <CommonOutlinedButton className={'size-small'} onClick={() => toggleAddDialog(true)}>Dodaj</CommonOutlinedButton>
                    </Grid>
                    <div className={'subtitle'}>
                        Czyli np. Para Młoda, ale również zespół, fotograf itp.
                    </div>
                </Grid>
            </StyledCardHeader>
            <br />

            <StyledTableContainer className={classnames({ mobile: isMobile })}>
                <StyledTable size="small">
                    <TableHead>
                        <TableRow>
                            {columns.filter(visible).map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth, top: headerHeight + 'px'}}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {rows && rows.others ? (
                        <TableBody>
                            <UsersNonGuestsListTableRow columns={columns} row={rows.bride} visible={visible}
                                                        yesOrNoOrUnknown={yesOrNoOrUnknown}
                                                        actionsRenderer={(row, index) => actionsRenderer(row, index, instance, toggleEditDialog)}></UsersNonGuestsListTableRow>
                            {rows.others.map((row, otherIndex) => {
                                return (
                                    <UsersNonGuestsListTableRow columns={columns} row={row} visible={visible}
                                                       key={otherIndex}
                                                       index={otherIndex}
                                                       yesOrNoOrUnknown={yesOrNoOrUnknown}
                                                       actionsRenderer={(row) => actionsRenderer(row, otherIndex, instance, toggleEditDialog, deleteRecord)}></UsersNonGuestsListTableRow>
                                );
                            })}
                            {summaryRow ? renderSummaryRow() : null}
                        </TableBody>
                    ) : null}
                </StyledTable>
            </StyledTableContainer>
        </Fragment>
    )
}
