import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { authService } from '../../../service/auth.service';
import {updateUser, updateInstance, toggleAdminPanel} from '../../../actions';
import Button from '@material-ui/core/Button';
import TRANSLATE from '../../../translation';
import MediaSizes from '../../../constants/media-sizes.constants';
import NavLink from "react-router-dom/NavLink";
import {withTranslation} from "react-i18next";

const KeyField = styled.input`
    padding: 15px 10px;
    font-size: 30px;
    font-family: "Courier New";
    margin: 0 5px;
    display: inline-block;
    width: calc(100% / 6);
    height: 51px;
    max-width: 51px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 100;
    border-radius: 3px;
    border: 1px solid #eee;
    -webkit-appearance: none;
    box-sizing: border-box;
    
    &:focus {
        outline: none;
    }
    
    @media (max-width: ${MediaSizes.tablet}px) {
        font-size: 1.2rem;
        margin: 0 3px;
        border-color: #ddd;
    }
`;

const LoginHeader = styled(Typography)`
    && {
        margin: 15px 5px;
        font-size: 1.2rem;
    }
`;

const LoadingIcon = styled(CircularProgress)`
    && {
        float: right;
    }
`;

const StyledPasswordFieldArea = styled.div`
    position: relative;
`;

const StyledPasswordFieldButton = styled(Button)`
    && {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
    }
`;

const StyledPasswordField = styled(TextField)`
    && {
        margin-left: 5px;
        width: 100%;
        max-width: 88vw;
    }
    
    && input {
        font-size: 24px;
        padding: 15px 0;
    }
`;

class LoginPremiumForm extends Component {
    state = {
        isAuthenticated: false,
        loginState: 'init',
        keyInvalid: false,
        isSubmitting: false,
        isLeavingAfterSuccess: false,
        passwordDetected: false,
        codeInfoOn: false,
        hasCodeInUrl: false
    };
    loginMessages = {
        init: this.props.t('codeLoginForm.enterCode'),
        password: this.props.t('codeLoginForm.enterPassword'),
        error: '',
        success: this.props.t('codeLoginForm.loginSuccess'),
        logging: this.props.t('codeLoginForm.loginGoingOn')
    };
    keyLength = 6;
    fieldRefs = {};
    initialKeyValues = {
        key0: '',
        key1: '',
        key2: '',
        key3: '',
        key4: '',
        key5: ''
    };
    keyValue = {
        key0: '',
        key1: '',
        key2: '',
        key3: '',
        key4: '',
        key5: '',
    };
    form;
    shouldAutoSubmit = false;

    constructor(props) {
        super(props);

        for (let i = 0; i < this.keyLength; i++) {
            this.fieldRefs['key' + i] = React.createRef();
        }

        this.handleCodeParam();
    }

    handleCodeParam() {
        const queryString = window.location.search ? window.location.search.slice(1) : null;
        let queryParams, queryParamsObj = {};

        if (queryString) {
            queryParams = queryString.split('&');
            queryParams.forEach((paramString) => {
                queryParamsObj[paramString.split('=')[0]] = paramString.split('=')[1];
            });

            if (queryParamsObj && queryParamsObj.code && queryParamsObj.code.length === this.keyLength) {
                localStorage.removeItem('token');
                this.state.hasCodeInUrl = true;
                this.shouldAutoSubmit = true;
                queryParamsObj.code.split('').forEach((keyValue, i) => {
                    this.initialKeyValues['key' + i] = keyValue;
                    this.keyValue['key' + i] = keyValue;
                });
            }
        }
    }

    handleChange(value) {
        if(value.length === this.keyLength) {
            this.authenticate(value).then(() => this.handleAuthenticateResponse);
        }
    }

    authenticate(code, password, setSubmitting) {
        const { dispatch } = this.props;

        if (code) {
            authService.authenticate({ code, password }).then(response => {
                this.setState({
                    loginState: 'success',
                    isLeavingAfterSuccess: true
                });

                setSubmitting(false);

                authService.checkLoginState()
                    .then(response => {
                        const responseData = response.data;

                        if (responseData.user.is_admin === true) {
                            dispatch(toggleAdminPanel(true));
                        }
                        dispatch(updateInstance(responseData.instance));
                        dispatch(updateUser(responseData.user));
                    })
            }).catch(error => {
                setSubmitting(false);

                if (error.response && error.response.data && error.response.data.data && error.response.data.data.requirePassword) {
                    this.setState({
                        loginState: 'password',
                        passwordDetected: true
                    });
                } else {
                    let errorResponseData;
                    try {
                        errorResponseData = TRANSLATE.now(error.response.data.message);
                    } catch(e) {}
                    if (!errorResponseData) {
                        errorResponseData = TRANSLATE.now('error.unknown');
                    }
                    this.loginMessages.error = errorResponseData;
                    this.setState({
                        loginState: 'error'
                    });
                    this.form.setFieldValue('password', '');
                }
            });
        }
    }

    inputKeyEvent(e) {
        const thisIndex = e.target.dataset.index,
            newIndex = e.target.value.length ? parseInt(thisIndex) + 1 : parseInt(thisIndex) -1,
            newName = 'key' + newIndex;

        if(newIndex <= this.keyLength && this.fieldRefs[newName] && !this.state.hasCodeInUrl) {
            this.fieldRefs[newName].current.focus();
            this.fieldRefs[newName].current.select();
        } else if(this.fieldRefs[newName]) {
            this.fieldRefs[newName].current.blur();
        }
    }

    handleAuthenticateResponse(response) {
        if (response.status === 'success') {

        } else {
            this.handleInvalidAuthentication();
        }
    }

    handleInvalidAuthentication() {
        this.state.keyInvalid = true;
    }

    buildMainMessage() {
        let result = this.loginMessages[this.state.loginState];

        if(this.state.loginState === 'error' && this.props.errorMessage) {
            result = this.props.errorMessage;
        }

        return result;
    }

    renderLoadingIcon() {
        return (
            ['logging', 'success'].indexOf(this.state.loginState) !== -1 ? <LoadingIcon size={30}/> : ''
        )
    }

    switchCodeInfoText() {
        this.setState({
            codeInfoOn: true
        })
    }

    render() {
        return (
            <Fragment>
                <LoginHeader variant="h5" gutterBottom>
                    {this.buildMainMessage()}
                    {this.renderLoadingIcon()}
                </LoginHeader>

                <Formik
                    ref={node => {
                        this.form = node;
                        if (this.form && this.shouldAutoSubmit) {
                            setTimeout(() => {
                                if (this.form) this.form.submitForm();
                                // window.location.search = '';
                            }, 1000);
                            this.shouldAutoSubmit = false;
                        }
                    }}
                    validateOnBlur={true}
                    validateOnChange={false}
                    initialValues={{
                        ...this.initialKeyValues,
                        password: ''
                    }}
                    validate={(values) => {
                        const errors = {};
                        let { password, ...codeValues } = { ...this.keyValue, password: values.password };

                        codeValues = Object.values(codeValues).join('');

                        if (codeValues.length !== this.keyLength) {
                            errors.key0 = 'Wrong';
                        }

                        if (this.state.passwordDetected && !password) {
                            errors.password = 'No password';
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        let { password, ...codeValues } = values;
                        codeValues = Object.values(codeValues).join('');
                        setSubmitting(true);
                        this.setState({ loginState: 'logging' }, () => {
                            setTimeout(() => {
                                this.authenticate(codeValues, password, setSubmitting);
                            }, 1000);
                        });
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, submitForm, handleSubmit, isSubmitting, isValid, validateForm, setFieldValue }) => {

                        return (
                            <div>
                                {
                                    this.state.passwordDetected ?
                                        (
                                            <Fragment>
                                                <StyledPasswordFieldArea>
                                                    <StyledPasswordFieldButton color="primary" variant="contained" type="submit" onClick={() => {
                                                        validateForm();
                                                        if(isValid) {
                                                            submitForm();
                                                        }
                                                    }} disabled={isSubmitting}>{this.props.t('codeLoginForm.enter')}</StyledPasswordFieldButton>
                                                    <StyledPasswordField
                                                        autoFocus
                                                        name="password"
                                                        key="password"
                                                        type="password"
                                                        onChange={handleChange}
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter') {
                                                                submitForm();
                                                            }
                                                        }}
                                                        value={values.password}
                                                        fullWidth
                                                    />
                                                </StyledPasswordFieldArea>
                                                <div>
                                                    <NavLink style={{position: 'relative', top: 30, left: 7 }} to={'/request-reset-password'}>{this.props.t('loginPanel.forgotPassword')}</NavLink>
                                                </div>
                                            </Fragment>
                                        ) : (<div style={{display: 'flex', width: '100%'}}>
                                                {Object.keys(this.fieldRefs).map((item, i) => (
                                                    <KeyField
                                                        data-index={i}
                                                        key={'key' + i}
                                                        name={'key' + i}
                                                        field={'key' + i}
                                                        value={this.keyValue['key' + i]}
                                                        ref={this.fieldRefs['key' + i]}
                                                        type="text"
                                                        maxLength="1"
                                                        autoFocus={i === 0}
                                                        placeholder=""
                                                        disabled={this.state.loginState === 'logging' || this.state.loginState === 'success'}
                                                        // disabled={isSubmitting || this.state.isLeavingAfterSuccess}
                                                        onKeyUp={(e) => {
                                                            if (this.fieldRefs['key' + i] && e.key === 'Backspace' && i > 0) {
                                                                const index = i - 1;
                                                                if (this.fieldRefs['key' + i].current.value === '') {
                                                                    this.fieldRefs['key' + index].current.focus();
                                                                    this.keyValue['key' + index] = '';
                                                                    this.fieldRefs['key' + index].current.value = '';
                                                                }
                                                            }
                                                        }}
                                                        onInput={(e) => {
                                                            this.keyValue['key' + i] = e.target.value;
                                                            setFieldValue('key' + i, e.target.value);
                                                            console.log('test', this)
                                                            this.inputKeyEvent.call(this, e);
                                                            validateForm().then((errors) => {
                                                                if (!Object.keys(errors).length) {
                                                                    submitForm();
                                                                }
                                                            });
                                                        }}
                                                        onFocus={(e) => {
                                                            if (e.target) {
                                                                e.target.select();
                                                            }
                                                        }}
                                                        onPaste={(event) => {
                                                            let paste = (event.clipboardData || window.clipboardData).getData('text');

                                                            if (event && paste.length > 1) {
                                                                event.preventDefault();
                                                            }

                                                            if (paste.length === 6) {
                                                                paste.split('').forEach((letter, i) => {
                                                                    this.keyValue['key' + i] = letter;
                                                                    setFieldValue('key' + i, letter);
                                                                });
                                                                this.inputKeyEvent.call(this, event);
                                                                validateForm().then((errors) => {
                                                                    if (!Object.keys(errors).length) {
                                                                        submitForm();
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            // validateForm();
                                                            // if(isValid) {
                                                            //     submitForm();
                                                            // }
                                                        }}
                                                    />
                                                ))}</div>
                                        )

                                }
                            </div>
                        )
                    }}
                </Formik>

            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.auth.user,
        authUser: state.auth.user,
        errorMessage: state.auth.error || null
    }
};


const ConnectedLoginPage = withTranslation()(connect(mapStateToProps)(LoginPremiumForm));
export { ConnectedLoginPage as LoginPremiumForm };
