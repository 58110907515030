import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import SectionTitle from "../partials/SectionTitle";
import api from "../../../api";
import {StyledCardContent} from "../../../common/StyledContentCard";
import {StyledCard} from "../../../common/StyledCard";
import WEDDING_CONFIG from "../../../wedding-config";
import styled, {keyframes} from 'styled-components';
import TablesSetup from "./TablesSetup";
import {CommonOutlinedButton} from "../../../common/StyledButton";
import TableSetupDialog from "./TableSetupDialog";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ActionsPanelButton from "../partials/ActionsPanelButton";
import ActionsPanel from "../partials/ActionsPanel";
import {MediaHeights} from "../../../constants/media-sizes.constants";
import {FormControl, Icon} from "@material-ui/core";
import TablePlanDialog from "./TablePlanDialog";
import tableService from "../../../service/table.service";
import store from '../../../store';
import {setSelectedSetId} from "../../../actions/table.actions";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FileSaver from "file-saver";
import IconButton from "@material-ui/core/IconButton";
import {StyledEmptyPlaceholder} from "../../../common/StyledEmptyPlaceholder";
import ActionsSelect from "../partials/ActionsSelect";
import jsPDF from 'jspdf';
import TableDownloadDialog from "./tables-setup/TableDownloadDialog";
import {DesktopActionsWrapper} from "./DesktopActions";

const colors = WEDDING_CONFIG.colors;

const StyledWrapper = styled.div`
    text-align: left;
    max-width: 1200px;
    padding: 15px 15px 0 15px;
    margin: 0 auto;

    @media (max-height: ${MediaHeights.sm}px) {
        padding-top: 0;
    }
`;

const animateCustomProgress = keyframes`
  0% {
    width: 0;
    opacity: 0;
  }
  
  40% {
    opacity: 0;
  }
  
  100% {
    width: 100%;
    opacity: 1;
  }
`;
const CustomProgress = styled.div`
  width: 0;
  height: 3px;
  background: ${colors.primaryLight};
  //opacity: 0;
  &.animate {
    animation: ${animateCustomProgress} 1.4s linear;
  }
`;

class Tables extends Component {
    state = {
        selectedSet: null,
        sets: [],
        loaded: false,
        isAddingSet: false,
        isAddingTable: false,
        isLoading: false
    };
    tableSetup;
    progressEl = React.createRef();

    componentDidMount() {
        this.fetchSets();
    }

    fetchSets(preventSelect) {
        this.setLoading(true);
        return tableService.getTableSets()
            .then((response) => {
                let selectedSet = !preventSelect && response.data.length ? response.data[0] : null;
                if (!preventSelect && this.props.tables.selectedTableSetId) {
                    const set = response.data.find((set) => set.id === this.props.tables.selectedTableSetId);
                    if (set) {
                        selectedSet = set;
                    }
                }

                if (selectedSet) {
                    store.dispatch(setSelectedSetId(selectedSet.id));
                }

                this.setState({
                    selectedSet: selectedSet,
                    sets: response.data,
                    loaded: true,
                    isLoading: false,
                });
            });
    }

    onSetChange(event) {
        if (event.target.value === 'none') {
            this.addSet();
            return;
        }
        this.setState({
            selectedSet: event.target.value,
        });
        store.dispatch(setSelectedSetId(event.target.value.id))
    }

    setLoading(value) {
        this.setState({
            isLoading: value
        });
    }

    addSet() {
        this.setState({
            isAddingSet: true
        });
    }

    addTable() {
        this.setState({
            isAddingTable: true
        });
    }

    handleSetDelete(set, e) {
        e.preventDefault();
        e.stopPropagation();

        if (window.confirm('Uwaga! Usuwasz cały plan "' + set.name + '" ze wszystkimi stołami i przypisaniami. Czy na pewno chcesz kontynuować?')) {
            this.setLoading(true);
            tableService.removeTableSet(set.id)
                .then(() => {
                    this.fetchSets(true);
                });
        }
    }

    closeSetsModal(createdSetResponse) {
        this.setState({
            isAddingSet: false,
        });
        if (createdSetResponse) {
            store.dispatch(setSelectedSetId(createdSetResponse.data.id));

            this.fetchSets();
        }
    }

    closeModal() {
        this.setState({
            isAddingTable: false,
        });
        this.tableSetup.fetchData();
    }

    getStage() {
        return this.tableSetup.stage;
    }

    render() {
        const canDownload = !!this.state.selectedSet && this.props.tables.seatsCount > 0;
        return (
            <Fragment>
                <TablePlanDialog open={this.state.isAddingSet} handleClose={this.closeSetsModal.bind(this)}
                                 isFirst={this.state.sets.length === 0}/>
                <TableSetupDialog open={this.state.isAddingTable} handleClose={this.closeModal.bind(this)}/>
                <StyledWrapper>
                    <DesktopActionsWrapper>
                        <ActionsPanel>
                            <Grid container>
                                <Grid item xs={12} sm={8}>
                                    <ActionsSelect
                                        icon={this.state.selectedSet ? 'turned_in' : 'turned_in_not'}
                                        style={{width: '230px'}}
                                        displayEmpty
                                        value={this.state.selectedSet || ''}
                                        renderValue={selected => {
                                            if (!selected) {
                                                return <em>Wybierz plan stołów</em>;
                                            }

                                            return selected.name;
                                        }}
                                        onChange={this.onSetChange.bind(this)}>
                                        <MenuItem key={1} value={'none'}>+ nowy plan</MenuItem>

                                        {this.state.sets.map((set) => (
                                            <MenuItem key={set.id} value={set}>{set.name}
                                                <IconButton style={{position: 'absolute', right: 0}}
                                                            onClick={this.handleSetDelete.bind(this, set)}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </MenuItem>
                                        ))}
                                    </ActionsSelect>
                                    <span className={'separator'}></span>
                                    <ActionsPanelButton disabled={!this.state.selectedSet || this.state.isLoading}
                                                        onClick={this.addTable.bind(this)} text={"Dodaj stół"}
                                                        icon={"add_circle"}></ActionsPanelButton>
                                    <TableDownloadDialog selectedTableSetId={this.state.selectedSet?.id} getStage={this.getStage.bind(this)} disabled={!canDownload || this.state.isLoading}></TableDownloadDialog>
                                </Grid>
                                <Grid item xs={12} sm={4} className={'text-right'}>
                                    Przypisanych: {this.props.tables.assignments.length} / {this.props.tables.usersList.length}<br/>
                                    Dostępnych
                                    miejsc: {this.props.tables.seatsCount - this.props.tables.assignments.length} / {this.props.tables.seatsCount}<br/>
                                </Grid>
                            </Grid>
                        </ActionsPanel>
                    </DesktopActionsWrapper>
                </StyledWrapper>

                <CustomProgress ref={this.progressEl} className={this.state.isLoading ? 'animate' : ''}/>
                {!!this.state.selectedSet ? (
                    <StyledCard>
                        <TablesSetup
                            ref={(ref) => this.tableSetup = ref}
                            selectedSetId={this.state.selectedSet ? this.state.selectedSet.id : null}
                            key={this.state.selectedSet ? this.state.selectedSet.id : null}
                        />
                    </StyledCard>
                ) : (
                    this.state.loaded ? (<StyledEmptyPlaceholder style={{marginTop: '80px'}}>
                        <p>{!this.state.sets.length ? <div>
                            Zacznij od stworzenia listy gości, a potem <a className={'color-primary link-style'} onClick={() => this.addSet()}>utwórz pierwszy plan stołów.</a>
                        </div> : 'Wybierz plan stołów.'}</p>
                    </StyledEmptyPlaceholder>) : null
                )}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        instance: state.auth.instance,
        tables: state.tables
    }
};

export default connect(mapStateToProps)(Tables);
