import axios from 'axios';
import store from './store';
import ENV from './config/environment';
import { setError } from './actions';
import TRANSLATE from "./translation";
import {isPWA} from "./utilities";

const axiosInstance = axios.create({
    baseURL: ENV.API_URL,
    headers: {
        'hw-env-type': ENV.IS_PREMIUM ? 'standalone' : 'standard',
        'hw-pwa': isPWA()
    }
});

axiosInstance.interceptors.response.use(function (response) {
    if (response.data.forceLogout === true) {
        localStorage.removeItem('token');
        setTimeout(() => {
            window.location = '/';
        }, 2000);
    }
    return response.data || {};
}, function (error) {
    let errorMessage = '';

    if (getIntervalUrls().some((path) => error.config.url.endsWith(path))) {
        // interval urls fails should not show dialog error
        return Promise.reject(error);
    }

    if (error && error.response) {
        if (error.response.data && error.response.data) {
            if (error.response.data.silent !== true && error.response.data.message) {
                const translation = TRANSLATE.now(error.response.data.message, true);

                if (translation) {
                    errorMessage = translation;
                } else {
                    errorMessage = error.response.data.message;
                }
            } else if(error.response.data.silent !== true) {
                errorMessage = error.response.statusText;
            }
            if (error.response.data.forceLogout === true) {
                localStorage.removeItem('token');
                setTimeout(() => {
                   window.location = '/';
                }, 2000);
            }
            if (error.response.status === 401 && window.location.pathname !== '/' && error.response.data.silent) {
                localStorage.removeItem('token');
                window.location = '/';
            }
        } else {
            errorMessage = error.response.statusText;
        }
    } else {
        errorMessage = error && error.message === 'Network Error' ?
            'Serwer nie odpowiada.' : 'Wystąpił błąd';
    }

    store.dispatch(setError(errorMessage));

    return Promise.reject(error);
});

const setDefaults = ({ token }) => {
    axiosInstance.defaults.headers.common['hw-token'] = token;
};

const routes = {
    instance: 'auth/instance',
    authenticate: 'auth',
    authenticateStandard: 'auth/standard',
    checkAuthenticationState: 'auth/state',
    requestResetPassword: 'auth/request-password-reset',
    resetPassword: 'auth/reset-password',
    appParams: 'public/params',
    resetPasswordPossibility: 'auth/is-possible-to-reset-password?token=:token',
    getParticipantData: '/participant',
    saveParticipantData: '/participant',
    saveKid: '/participant/kid',
    deleteKid: '/participant/kid/:id',
    getContent: '/content/:name',
    saveContent: '/content/:name',
    downloadPDFSummary: '/participant/download-pdf-summary',
    getChatMessages: '/chat/messages',
    sendMessageToAdmin: '/chat/send-message',
    getNewMessagesCount: '/chat/new-messages-count'
};

const tablesUrl = '/su/tables/';
const tablesSetUrl = tablesUrl + ':id_set/';
const accommodationUrl = '/su/accommodation/';

const suRoutes = {
    getParticipant: '/su/participants/:id',
    getParticipantsList: '/su/participants/list',
    saveParticipantEditList: '/su/participants',
    saveParticipantData: '/su/participants/:id',
    setParticipantActive: '/su/participants/:id/active',
    setParticipantAccommodationAvailability: '/su/participants/:id/accommodation',
    setParticipantSecondDayAvailability: '/su/participants/:id/second_day',
    printCodes: '/su/document/codes',
    deleteParticipant: '/su/participants/:id',
    saveContent: '/content/:name',
    getInstanceDomains: '/su/instance-domains',
    checkInstanceExistance: '/su/instance-exists?name=:name&domain=:domain',
    checkPromoCode: '/su/order-premium/promo-code-check/:code',
    getInstanceData: '/su/instance',
    arePromoCodesAvailable: '/su/are-promo-codes-available',
    getChangelogData: '/su/instance/changelog',
    getInstancePanelData: '/su/instance/panel-data',
    getInstanceStatistics: '/su/instance/statistics',
    getInvoiceData: '/su/instance/get-invoice-data',
    orderPremium: '/su/order-premium',
    setInstanceSettings: '/su/instance/settings',
    setInstanceActiveParam: '/su/instance/active',
    changeInstancePassword: '/su/instance/password',
    getInstanceVisits: '/su/instance/visits',
    getInstanceSpecialParticipants: '/su/instance/special-participants',
    setInstanceSpecialParticipants: '/su/instance/special-participants',
    exportParticipantsConfirmed: '/su/export/participants-confirmed',
    saveTask: '/su/tasks',
    tasksList: '/su/tasks/list',
    tasksListSummary: '/su/tasks/list-summary',
    accommodationSets: accommodationUrl + 'sets',
    accommodationDeleteSet: accommodationUrl + 'sets/:id_set',
    accommodationPlaces: accommodationUrl + 'sets/:id_set/places',
    accommodationEditPlaces: accommodationUrl + 'places/:id_place',
    accommodationAssignments: accommodationUrl + ':id_set/assignments',
    accommodationGuest: accommodationUrl + 'places',
    accommodationRoom: accommodationUrl + 'places/:id_place/room',
    accommodationEditRoom: accommodationUrl + 'room/:id_room',
    accommodationUsersList: accommodationUrl + 'users',
    accommodationAssignParticipant: accommodationUrl + ':id_set/assignments',
    accommodationRemoveParticipant: accommodationUrl + ':id_set/assignments/:id',
    accommodationExport: '/su/export/accommodation/:id_set',
    tableSetList: tablesUrl + 'get-sets',
    tableSetCreate: tablesUrl + 'create-set',
    tableSetDelete: tablesSetUrl + 'remove-set',
    tableList: tablesSetUrl + 'tables-list',
    tableCreate: tablesSetUrl + 'table/create',
    tableDelete: tablesSetUrl + 'table/:id',
    tableEditName: tablesSetUrl + 'table/:id/name',
    tableRestoreSeats: tablesSetUrl + 'table/:id/restore-seats',
    tableDisableSeat: tablesSetUrl + 'table/:id/disable-seat',
    tableUsersList: tablesUrl + 'users',
    tableUpdateRotation: tablesSetUrl + 'table/:id/update-rotation',
    tableUpdatePosition: tablesSetUrl + 'table/:id/update-position',
    tableGetAssignments: tablesSetUrl + 'assignments',
    tableAssignParticipant: tablesSetUrl + 'assignments',
    tableSwapParticipant: tablesSetUrl + 'assignments/move',
    tableRemoveParticipant: tablesSetUrl + 'assignments/remove',
    tableExportList: '/su/export/table-set/:id_set',
    getChatList: '/su/chat/list',
    getChatFormUsersList: '/su/chat/users-list',
    getSingleChatMessages: '/su/chat/:id_participant/messages',
    getSingleChatAnnouncementMessages: '/su/chat/announcements',
    getNewMessagesCount: '/su/chat/new-messages-count',
    sendChatMessageToParticipant: '/su/chat/:id_participant/send-message',
    sendChatAnnouncementMessage: '/su/chat/send-announcement',
    toggleInstanceNewsletter: '/su/instance/toggle-newsletter',
    initializeAccountDeletion: '/su/instance/initialize-delete-account'
};

function getIntervalUrls() {
    return [
        suRoutes.getNewMessagesCount,
        routes.getNewMessagesCount,
        routes.appParams
    ]
}

export default { request: axiosInstance, routes, suRoutes, setDefaults }
