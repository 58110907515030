import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import WEDDING_CONFIG from '../../../wedding-config';
import {MediaHeights} from "../../../constants/media-sizes.constants";

const colors = WEDDING_CONFIG.colors;

const StyledSpan = styled.span`
  &&.premium-label {
      color: ${colors.primaryLight};
      background: white;
      padding: 3px 6px;
      font-size: 0.65rem;
      font-weight: bold;
      border-radius: 4px;
      letter-spacing: 1px;
      
      &.alt-primary {
        background: ${colors.primaryLight};
        color: white;
      }
  }
`;

function PremiumLabel(props) {
    return (
        <StyledSpan className={`premium-label ${props.cls || ''} ${props.primary ? 'alt-primary' : ''}`}>PREMIUM</StyledSpan>
    );
}

export default PremiumLabel;
