import React, {Fragment, useEffect, useState} from 'react';
import WEDDING_CONFIG from "../../../../wedding-config";
import api from "../../../../api";
import UserEditDialog from "./UserEditDialog";

const colors = WEDDING_CONFIG.colors;

const UserEditAction = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [user, setUser] = useState(null);

    const fetchUser = (userId) => {
        return api.request({
            url: api.suRoutes.getParticipant.replace(':id', userId),
            method: 'get'
        }).then((response) => {
            return { ...response.data, kids: JSON.parse(response.data.kids) };
        });
    }

    useEffect(() => {
        if (isOpen) {
            fetchUser(props.userId).then((fetchedUser) => {
                setUser(fetchedUser);
            });
        } else {
            setUser(null);
        }
    }, [isOpen]);

    return (
        <Fragment>
            {React.cloneElement(props.children, {
                onClick: () => {
                    setIsOpen(true);
                    // if (props.menuClose) props.menuClose();
                }
            })}

            {user ? (
                <UserEditDialog user={user} onUserUpdate={props.onUserUpdate} setUser={setUser} instance={props.instance} focusComponent={props.focusComponent} onClose={() => { setIsOpen(false); if(props.handleClose) props.handleClose(); }}></UserEditDialog>
            ) : null}
        </Fragment>
    )
};

export default UserEditAction;
