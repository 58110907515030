import api from "../api";
import store from "../store";
import {setApiParams} from "../actions";

const getInstanceData = () => {
    return api.request({
        url: api.suRoutes.getInstancePanelData,
        method: 'get'
    });
}

const getApiParams = () => {
    return api.request({
        url: api.routes.appParams,
        method: 'get'
    });
}

let interval;

const runAndSetIntervalForApiParams = () => {
    clearInterval(interval);
    getApiParams().then((appParamsResponse) => {
        store.dispatch(setApiParams(appParamsResponse.data));
    });
    interval = setInterval(() => {
        getApiParams().then((appParamsResponse) => {
            store.dispatch(setApiParams(appParamsResponse.data));
        });
    }, 30000);
};

const getChangelogData = () => {
    return api.request({
        url: api.suRoutes.getChangelogData,
        method: 'get'
    });
}

const arePromoCodesAvailable = () => {
    return api.request({
        url: api.suRoutes.arePromoCodesAvailable,
        method: 'get'
    });
}

export default {
    getApiParams,
    runAndSetIntervalForApiParams,
    getInstanceData,
    arePromoCodesAvailable,
    getChangelogData
}
