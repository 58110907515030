import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectionTitle from "../partials/SectionTitle";
import {StyledCardContent} from "../../../common/StyledContentCard";
import Grid from "@material-ui/core/Grid";
import Route from "react-router-dom/Route";
import Instance from "./Instance";
import Switch from "react-router-dom/Switch";
import Help from "./Help";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";
import MediaSizes from "../../../constants/media-sizes.constants";
import NavLink from "react-router-dom/NavLink";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InstanceSettingsForm from "../partials/InstanceSettingsForm";
import {ListItem} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "react-router-dom/Link";
import List from "@material-ui/core/List";
import ChangePasswordForm from "./ChangePasswordForm";
import WEDDING_CONFIG from "../../../wedding-config";
import My from "./My";
import Advanced from "./Advanced";

const colors = WEDDING_CONFIG.colors;

const StyledList = styled(List)`
  padding-top: 0;
  
  li {
    &:not(:first-child) {
      margin-top: 18px;
    }
   
  }
  
  .active {
    color: ${colors.primaryLight};
    
    span {
      font-weight: bold;
    }
  }
`;

const StyledListItem = styled(ListItem)``;
const StyledListItemIcon = styled(ListItemIcon)``;
const StyledListItemText = styled.span``;

function ListItemLink(props) {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <NavLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <li>
            <ListItem button component={renderLink}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

class Settings extends Component {
    render() {
        return (
            <div>
                <Grid container>
                    <Grid item sm={3}>
                        <StyledList>
                            <ListItemLink to="/admin/settings/instance-settings" primary="Ustawienia aplikacji" icon={<Icon>settings_applications</Icon>} activeClassName="active" />
                            <ListItemLink to="/admin/settings/my" primary="Mój pakiet" icon={<Icon>grade</Icon>} activeClassName="active" />
                            <ListItemLink to="/admin/settings/change-password" primary="Zmiana hasła" icon={<Icon>fingerprint</Icon>} activeClassName="active" />
                            <ListItemLink to="/admin/settings/advanced" primary="Zaawansowane akcje" icon={<Icon>build</Icon>} activeClassName="active" />
                            <ListItemLink to="/admin/settings/help" primary="Pomoc / Kontakt" icon={<Icon>help</Icon>} activeClassName="active" />
                        </StyledList>
                    </Grid>
                    <Grid item sm={9}>
                        <div>
                            <Switch>
                                <Route path='/admin/settings/instance-settings' component={InstanceSettingsForm} />
                                <Route path='/admin/settings/my' component={My} />
                                <Route path='/admin/settings/change-password' component={ChangePasswordForm} />
                                <Route path='/admin/settings/advanced' component={Advanced} />
                                <Route path='/admin/settings/help' component={Help} />
                            </Switch>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(Settings);
