import React, { Component, Fragment } from 'react';
import { StyledHeader } from './commons';
import { connect } from 'react-redux';
import styled from 'styled-components';

const StyledContainer = styled.div`
  &, p {
    font-size: 1.15rem;
  }
`;

class MainFormSectionGreeting extends Component {

    render() {
        const props = this.props;

        if (!props.isActive) {
            return null;
        }

        return (
            <div>
                {this.props.instance ? (
                    <Fragment>
                        <StyledHeader>
                            { this.props.content ? this.props.content.title : '' }
                        </StyledHeader>
                        <StyledContainer className="font-md font-primary" dangerouslySetInnerHTML={{__html: this.props.content ? this.props.content.content : ''}}></StyledContainer>
                    </Fragment>
                ) : ''}
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.auth.user,
        instance: store.auth.instance
    }
};

export default connect(mapStateToProps)(MainFormSectionGreeting);