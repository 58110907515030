import React from 'react';
import styled from 'styled-components';
import MediaSizes from "../constants/media-sizes.constants";

export const StyledCard = styled.div`
  padding: 15px;
  box-shadow: 3px 3px 16px 8px #f7f7f7;
  margin-bottom: 1rem;
  background: white;
  
  &.no-mb {
    margin-bottom: 0;
  }
  
  &.no-mb-mobile {
    @media (max-width: ${MediaSizes.phone}px) {
      margin-bottom: 0;      
    }
  }
  
  &.height-100 {
    height: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
  }
  
  p {
    color: #505050;
  }
  
  .title {
    font-size: 1.4rem;
  }
  
      &.actions {
        @media (max-width: ${MediaSizes.tablet}px) {
          padding: 0;
        }
      }
    @media (min-width: ${MediaSizes.phone}px) {
      padding: 30px;
      
    }
`;

export const StyledCardHeader = styled.div`
  font-size: 1.4rem;
  margin-bottom: 1rem;
  position: relative;
  
  .aside {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  a.aside {
    font-size: 1rem;
    top: 0;
  }
  
  .subtitle {
    font-size: 1rem;
    margin-top: 0.25rem;
  }
`;
