import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import IconButton from "../modules/admin/components/participants-list/UsersList";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledLoaderWrapper = styled.div`
  text-align: center;
  padding: 60px 0;
`;

const StyledLoaderText = styled.div`
  font-size: 2rem;
  margin-top: 25px;

  &.smaller {
    font-size: 1rem;
  }
`;

export function Loader({smaller}) {
    return (
        <StyledLoaderWrapper>
            <CircularProgress color="primary" size={smaller ? 80 : 120}/>
            <StyledLoaderText className={smaller ? 'smaller' : ''}>Wczytywanie...</StyledLoaderText>
        </StyledLoaderWrapper>
    );
}
