import React, {useEffect, useState, Fragment} from 'react';
import {StyledBlock, StyledBlockLabel} from "./partials/StyledBlock";
import {StyledFancyButtonV2} from "../../../common/StyledButton";
import Icon from "@material-ui/core/Icon";
import GuestChatDialog from "./GuestChatDialog";
import ChatService from "../../../service/chat.service";
import store from "../../../store";
import {setAdminChatNewMessagesCount, setGuestChatNewMessagesCount} from "../../../actions";
import WEDDING_CONFIG from "../../../wedding-config";
import {Badge} from "@material-ui/core";
import styled from "styled-components";
import {useSelector} from "react-redux";
import ENV from "../../../config/environment";

const StyledButton = styled(StyledFancyButtonV2)`
  position: relative;
  
  .MuiBadge-root {
    right: 23px;
    position: absolute;
    top: 19px;
  }
`;

export function ChatBlock({}) {
    const [chatOpened, setChatOpened] = useState(false);
    const [newMessagesCount, setNewMessagesCount] = useState(false);
    const storeUser = useSelector(state => state.auth.user);
    const storeInstance = useSelector(state => state.auth.instance);

    function getMessagesCount() {
        return ChatService.getGuestChatNewMessagesCount().then((response) => {
            store.dispatch(setGuestChatNewMessagesCount(response.data.count));
            setNewMessagesCount(response.data.count);
        });
    }

    useEffect(() => {
        if (!(ENV.IS_PREMIUM && !storeUser.is_admin) || !storeInstance.is_chat_enabled) {
            return;
        }

        getMessagesCount();

        const intervalId = setInterval(() => {
            getMessagesCount().catch((e) => {
                clearInterval(intervalId);
            });
        }, WEDDING_CONFIG.chatNewMessageCheckInterval);

        return () => {
            clearInterval(intervalId);
        }
    }, []);
    
    return (
        <StyledBlock className={`${newMessagesCount ? 'shake' : ''}`}>
            {newMessagesCount ? <Fragment>
                <StyledBlockLabel>Nowa wiadomość</StyledBlockLabel>
                <div>
                    Masz nieprzeczytaną wiadomość od Młodej Pary.
                </div>
            </Fragment> : <Fragment>
                <StyledBlockLabel>Masz pytanie?</StyledBlockLabel>
                <div>
                    Napisz do nas na chacie!
                </div>
            </Fragment>}
            <br />
            <StyledButton
                className={'white-hover'}
                onClick={() => {
                    setChatOpened(true);
                    setNewMessagesCount(0);
                }}>
                otwórz chat
                <Badge color="primary" badgeContent={newMessagesCount} invisible={!newMessagesCount}></Badge>
            </StyledButton>

            <div className={'floating-right-icon'}>
                <Icon className={'icon'}>sms</Icon>
            </div>

            {chatOpened ? <GuestChatDialog handleClose={() => setChatOpened(false)} /> : null}
        </StyledBlock>
    );
}
