import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {StyledCardContent} from "../../../common/StyledContentCard";
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";
import {CommonOutlinedButton} from "../../../common/StyledButton";
import api from "../../../api";
import store from "../../../store";
import {updateInstance} from "../../../actions";
import ENV from "../../../config/environment";
import moment from "moment";

class Advanced extends Component {
    state = {
        isDeleteAccountBlocked: false
    };
    componentDidMount() {
        this.setState({
            isDeleteAccountBlocked: !this.props.instance.is_account_deletion_enabled
        });
    }

    clearCache() {
        if (window.caches) {
            window.caches.keys().then(function(names) {
                for (let name of names) {
                    window.caches.delete(name);
                }
            });
        }

        alert('Operacja powiodła się. Nastąpi odświeżenie strony.');
        window.location.reload();
    }

    setNewsletter(newValue) {
        api.request({
            url: api.suRoutes.toggleInstanceNewsletter,
            method: 'put',
            data: {
                newsletter_on: newValue
            }
        }).then(() => {
            store.dispatch(updateInstance({
                newsletter: newValue
            }));
        });
    }

    deleteAccount() {
        if (!window.confirm('Potwierdzam, że chcę usunąć konto fancy.wedding wraz ze wszystkimi wprowadzonymi dotąd przeze mnie danymi.')) {
            return;
        }

        api.request({
            url: api.suRoutes.initializeAccountDeletion,
            method: 'post',
            data: {
            }
        }).then(() => {
            window.alert('E-mail z potwierdzeniem został wysłany. Sprawdź pocztę.');
        });
    }

    render() {
        return (
            <Fragment>
                <StyledCard>
                    <StyledCardHeader>Ustawienia zaawansowane</StyledCardHeader>
                </StyledCard>
                <StyledCard>
                    <StyledCardContent>
                        <h2 className='mt-0'>Bądź na bieżąco</h2>
                        <p>
                            {this.props.instance.newsletter ? <Fragment>
                                Newsletter jest włączony.
                            </Fragment> : <Fragment>
                                Newsletter jest wyłączony. Nie będziemy do Ciebie wysyłać wiadomości innych niż zmiany w regulaminie czy ważne informacje na temat Twojego konta.
                            </Fragment>}
                        </p>
                        <CommonOutlinedButton fill={'primary'} onClick={() => this.setNewsletter(!this.props.instance.newsletter)}>
                            {this.props.instance.newsletter ? 'Wyłącz' : 'Włącz'} newsletter
                        </CommonOutlinedButton>
                    </StyledCardContent>
                </StyledCard>

                <StyledCard>
                    <StyledCardContent>
                        <h2 className='mt-0'>Wyczyść cache</h2>
                        <p>
                            Jeśli doświadczasz problemów z aplikacją, coś nie działa, spróbuj wyczyścić cache klikając przycisk poniżej.
                        </p>
                        <CommonOutlinedButton fill={'primary'} onClick={this.clearCache}>Wyczyść cache i odśwież</CommonOutlinedButton>
                    </StyledCardContent>
                </StyledCard>

                <StyledCard>
                    <StyledCardContent>
                        <h2 className='mt-0'>Twoja data ślubu</h2>
                        <p>
                            Data ślubu, którą wprowadziłeś(aś) podczas rejestracji: <strong>{moment(this.props.instance.initial_wedding_date).format('ll')}</strong>.
                        </p>
                        <p>
                            Ze względów zabezpieczających przed ewentualnymi oszustwami nie udostępniamy opcji swobodnej zmiany tej daty w aplikacji.
                        </p>
                        <p>
                            Jednocześnie rozumiemy, że zdarza się sytuacja, gdy data wesela się zmieni. Oczywiście w tym wypadku pomożemy, prosimy jedynie o kontakt poprzez e-mail (kontakt@fancy.wedding) z informacją jaka jest nowa data zaślubin.
                        </p>
                    </StyledCardContent>
                </StyledCard>

                <StyledCard>
                    <StyledCardContent>
                        <h2 className='mt-0'>Usuń konto fancy.wedding</h2>
                        <p>
                            Po kliknięciu otrzymasz e-mail z wymaganym potwierdzeniem. Po potwierdzeniu nie będziesz mógł/mogła więcej zalogować się na konto.
                        </p>
                        <CommonOutlinedButton fill={'primary'} onClick={this.deleteAccount} disabled={this.state.isDeleteAccountBlocked}>Usuń konto</CommonOutlinedButton>
                        {this.state.isDeleteAccountBlocked ? <p className={'color-red'}>
                            Opcja usunięcia konta została tymczasowo zablokowana, ponieważ masz konto typu Premium (lub jesteś w trakcie jego zakupu), a data wesela, którą wprowadziłeś(aś), jest w przyszłości.
                            <br/>
                            Jeżeli jednak chcesz usunąć swoje konto, prosimy o wiadomość e-mail na adres kontakt@fancy.wedding.
                        </p> : null}
                    </StyledCardContent>
                </StyledCard>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user,
        instance: state.auth.instance,
    }
};

export default connect(mapStateToProps)(Advanced);
