import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';
import Grid from '@material-ui/core/Grid';
import Link from 'react-router-dom/Link';
import Redirect from 'react-router-dom/Redirect';
import ReactQuill from 'react-quill';
import contentService from '../../../service/content.service';
import ActionsPanel from '../partials/ActionsPanel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { CommonOutlinedButton } from '../../../common/StyledButton';
import { Formik, FastField, FieldArray } from 'formik';
import ContentTypes from '../../../constants/content-types.constants';
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import ActionsPanelButton from '../partials/ActionsPanelButton';
import { withSnackbar } from 'notistack';
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";
import {CONTENTS_INFO_TRANSLATIONS, CONTENTS_TRANSLATIONS, DEFAULT_GRID_SPACING} from "../../../constants";
import TextInput from "../partials/TextInput";
import {DesktopActionsWrapper} from "./DesktopActions";
import {StyledSelect, StyledSelectOption} from "../../../common/StyledSelect";
import ContentTarget from "../../../constants/content-targets.constants";
import MediaSizes from "../../../constants/media-sizes.constants";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const colors = WEDDING_CONFIG.colors;

const QuillModules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link'],
        ['clean'],
        [{ 'align': [] }],
    ],
};

const ContentSeparatorContent = styled.div`
    // padding: 40px;
`;

const ContentSeparatorRemove = styled(CommonOutlinedButton)`
    float: right;
    z-index: 1;
`;

const StyledInputField = styled(TextField)`
    && {
        font-size: 1rem;
    }
`;

const StyledTextAreaField = styled(TextField)`
    && {
        margin-top: 40px;
    }
`;

const StyledTargetInfo = styled.div`
  display: inline-block;
  padding: 5px 10px;
  font-size: 0.9rem;
  background-color: #fff8e7;
  
  @media (min-width: ${MediaSizes.desktop}px) {
    margin-left: 20px;      
  }
`;

const StyledFlyingContainer = styled.div`
  display: inline-block;
  position: sticky;
  top: 150px;
  
  @media (max-width: ${MediaSizes.tablet}px) {
    .action {
      display: none;
    } 
  }
`;

const StyledPageGrid = styled(Grid)`
  flex-direction: column-reverse;

  @media (min-width: ${MediaSizes.phone + 1}px) {
    flex-direction: row;
  }
`;

const ModularContentTypes = [
    {
        value: 'text',
        name: 'Pole tekstowe'
    },
    {
        value: 'timeline',
        name: 'Wydarzenie czasowe'
    },
];

class ContentsEdit extends Component {
    state = {
        title: '',
        text: '',
        type: undefined,
        shouldRedirect: false,
        showSavedMessage: false,
        newContentType: 'text',
        content: []
    };
    form;

    componentDidMount() {
        contentService.getContent(this.props.match.params.name).then(response => {
            let content = response.data.content;

            this.form.setValues({
                title: response.data.title,
                content: content
            });

            this.setState({
                type: response.data.type
            })
        });
    }

    handleChangeNewContentType(e) {
        this.setState({
            newContentType: e.target.value
        });
    }

    getNewBlock() {
        let data;
        switch(this.state.newContentType) {
            case "text":
                data = '';
                break;
            case "timeline":
                data = {
                    map: {}
                };
                break;
        }
        return {
            type: this.state.newContentType,
            target: null,
            data: data
        };
    }

    renderModularTypesSwitch(content, index, values, { handleChange, setFieldValue }) {
        const options = [
            {
                name: 'Informacja dla wszystkich',
                value: ContentTarget.ALL
            },
            {
                name: 'Informacja o poprawinach',
                value: ContentTarget.SECOND_DAY_PARTICIPANTS
            },
            {
                name: 'Informacja o noclegu',
                value: ContentTarget.ACCOMMODATION_PARTICIPANT
            }
        ];
        const msg = {
            [ContentTarget.ALL]: 'Treść będzie widoczna dla wszystkich gości.',
            [ContentTarget.SECOND_DAY_PARTICIPANTS]: 'Treść będzie widoczna tylko dla gości, którzy mogą być na poprawinach.',
            [ContentTarget.ACCOMMODATION_PARTICIPANT]: 'Treść będzie widoczna tylko dla gości, którzy mogą rezerwować nocleg.'
        };
        const selectVisibility =
            <Fragment>
                <StyledSelect
                    name={`content.${index}.target`}
                    key={`content.${index}.target`}
                    label={'Widoczność'}
                    onChange={(e) => {
                        setFieldValue(`content.${index}.target`, e.target.value === ContentTarget.ALL ? null : e.target.value);
                    }}
                    value={values.target === null || values.target === undefined ? ContentTarget.ALL : values.target}>
                    {options.map((option) => <StyledSelectOption value={option.value}>
                        {option.name}
                    </StyledSelectOption>)}
                </StyledSelect>
                <StyledTargetInfo>
                    {msg[values.target || ContentTarget.ALL]}
                </StyledTargetInfo>
            </Fragment>;
        switch(content.type) {
            case "text":
                return (
                    <Fragment>
                        {selectVisibility}
                        <br />
                        <br />
                        <ReactQuill
                            theme="snow"
                            key={`content.${index}.data`}
                            modules={QuillModules}
                            name={`content.${index}.data`}
                            value={values.data}
                            onChange={(value) => {
                                setFieldValue(`content.${index}.data`, value);
                            }}
                        />
                    </Fragment>
                );
                break;
            case "timeline":
                return (
                    <FieldArray
                        name="data"
                        render={arrayHelpers => (
                            <Fragment>
                                {selectVisibility}
                                <br />
                                <br />
                                <Grid spacing={DEFAULT_GRID_SPACING} container>
                                    <Grid item xs={6} sm={3}>
                                        <StyledInputField
                                            name={`content.${index}.data.name`}
                                            key={`content.${index}.data.name`}
                                            value={values.data.name}
                                            onChange={handleChange}
                                            label="Wydarzenie"
                                            placeholder="np. Ślub"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <StyledInputField
                                            name={`content.${index}.data.time`}
                                            key={`content.${index}.data.time`}
                                            value={values.data.time}
                                            onChange={handleChange}
                                            inputProps={{
                                                maxLength: 5,
                                            }}
                                            label="Czas w formacie 00:00"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DatePicker
                                                label="Data"
                                                value={values.data.date}
                                                keyboard
                                                clearable
                                                autoOk
                                                format="DD/MM/YYYY"
                                                placeholder="np. 10/10/2021"
                                                onChange={(value) => {
                                                    setFieldValue(`content.${index}.data.date`, value)
                                                }} />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <StyledInputField
                                            name={`content.${index}.data.place`}
                                            key={`content.${index}.data.place`}
                                            value={values.data.place}
                                            onChange={handleChange}
                                            label="Miejsce"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <StyledTextAreaField
                                            name={`content.${index}.data.text`}
                                            key={`content.${index}.data.text`}
                                            value={values.data.text}
                                            multiline
                                            rows={5}
                                            fullWidth
                                            onChange={handleChange}
                                            label="Opis wydarzenia"
                                        />
                                    </Grid>
                                    {/*<Grid item xs={12} sm={6}>
                                        <h4>Mapa</h4>
                                        <StyledInputField
                                            name={`content.${index}.data.map.lng`}
                                            key={`content.${index}.data.map.lng`}
                                            value={values.data.map.lng}
                                            fullWidth
                                            onChange={handleChange}
                                            label="Szerokość geograficzna (longitude)"
                                        />
                                        <StyledInputField
                                            name={`content.${index}.data.map.lat`}
                                            key={`content.${index}.data.map.lat`}
                                            value={values.data.map.lat}
                                            fullWidth
                                            onChange={handleChange}
                                            label="Wysokość geograficzna (latitude)"
                                        />
                                    </Grid>*/}

                                </Grid>
                            </Fragment>
                        )} />

                );
                break;
        }
    }

    onDragEnd(result, contents) {
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            contents,
            result.source.index,
            result.destination.index
        );

        this.form.setFieldValue('content', items)
    }

    renderType(type, formikParams) {
        const getListStyle = isDraggingOver => ({
            background: isDraggingOver ? colors.lightPlaceholder : "transparent",
            // padding: grid,
            // width: 250
        });
        const getItemStyle = (isDragging, draggableStyle) => ({
            // some basic styles to make the items look a bit nicer
            userSelect: "none",

            // change background colour if dragging
            background: isDragging ? colors.lightPlaceholder : "transparent",

            // styles we need to apply on draggables
            ...draggableStyle
        });
        const { values, setFieldValue, errors, touched, handleChange, handleBlur, submitForm, handleSubmit, isSubmitting, isValid } = formikParams;
        switch(type) {
            case ContentTypes.MODULAR:
                let contents = Array.isArray(values.content) ? values.content : [];
                return (
                    <FieldArray
                        name="content"
                        render={arrayHelpers => (
                            <Fragment>
                                <DragDropContext onDragEnd={(result) => this.onDragEnd.call(this, result, contents)}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                 style={getListStyle(snapshot.isDraggingOver)}>
                                                {contents.map((content, index) => (
                                                    <Draggable key={`content${index}`} draggableId={`content${index}`} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div ref={provided.innerRef}
                                                                 {...provided.draggableProps}
                                                                 {...provided.dragHandleProps} style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}>
                                                                <StyledCard>
                                                                    <ContentSeparatorRemove onClick={() => {
                                                                        if (window.confirm('Czy na pewno chcesz usunąć tę treść? Po zapisaniu nie będzie można jej przywrócić.')) {
                                                                            arrayHelpers.remove(index);
                                                                        }
                                                                    }}>usuń</ContentSeparatorRemove>
                                                                    <StyledCardHeader>Blok typu <b>{ModularContentTypes[ModularContentTypes.findIndex((item) => item.value === content.type)].name}</b></StyledCardHeader>
                                                                    <ContentSeparatorContent>{this.renderModularTypesSwitch(content, index, values.content[index], formikParams)}</ContentSeparatorContent>
                                                                </StyledCard>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>

                                <StyledCard className={'text-center'}>
                                    <StyledCardHeader>Dodaj treść</StyledCardHeader>
                                    <TextField
                                        select
                                        label="Typ treści"
                                        value={this.state.newContentType}
                                        onChange={this.handleChangeNewContentType.bind(this)}
                                        margin="normal"
                                    >
                                        {ModularContentTypes.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div>
                                        <CommonOutlinedButton style={{ width: '200px', margin: '20px auto'}} onClick={() => arrayHelpers.push(this.getNewBlock())}>
                                            Stwórz blok
                                        </CommonOutlinedButton>
                                    </div>
                                </StyledCard>
                            </Fragment>
                        )}
                    />
                );
                break;
            case ContentTypes.SHORT:
                return (
                    <StyledCard>
                        <StyledCardHeader>
                            Treść
                        </StyledCardHeader>
                        <ReactQuill
                            theme="snow"
                            key={'content'}
                            modules={QuillModules}
                            name={`content`}
                            value={values.content}
                            onChange={(value) => {
                                setFieldValue(`content`, value);
                            }}
                        />
                    </StyledCard>
                );
                break;
            case ContentTypes.STANDARD:
                return (
                    <StyledCard>
                        <StyledCardHeader>
                            Treść
                        </StyledCardHeader>
                        <ReactQuill
                        theme="snow"
                        key={'content'}
                        modules={QuillModules}
                        name={`content`}
                        value={values.content}
                        onChange={(value) => {
                            setFieldValue(`content`, value);
                        }}
                        />
                    </StyledCard>
                );
                break;
            default:
                return (
                    <div>Wystąpił błąd</div>
                );
                break;
        }
    }

    render() {
        if (this.state.shouldRedirect) {
            return <Redirect to="/admin/contents"/>;
        }
        return (
            <div>
                <DesktopActionsWrapper>
                    <Grid container spacing={DEFAULT_GRID_SPACING}>
                        <Grid item xs={12}>
                            <ActionsPanel>
                                <ActionsPanelButton component={Link} to="/admin/contents" icon={"arrow_back"} text={"powrót"}></ActionsPanelButton>
                                <ActionsPanelButton type="submit" onClick={() => this.form.submitForm()} text={"Zapisz zmiany"} icon={"save"}></ActionsPanelButton>
                            </ActionsPanel>
                        </Grid>
                    </Grid>
                </DesktopActionsWrapper>

                    <Formik ref={node => (this.form = node)}
                            initialValues={{
                                title: '',
                                content: this.state.type === ContentTypes.MODULAR ? [] : ''
                            }}
                            onSubmit={(values, { setSubmitting, setErrors }) => {
                                contentService.saveContent(this.props.match.params.name, values).then(response => {
                                    this.setState({
                                        shouldRedirect: false,
                                    });
                                    this.props.enqueueSnackbar('Zapisano', {
                                        variant: 'success',
                                    });
                                });
                            }}
                    >
                        {(formikParams) => {

                            const { values, errors, touched, handleChange, handleBlur, submitForm, handleSubmit, isSubmitting, isValid, setFieldValue } = formikParams;
                            return (<div>
                                <StyledPageGrid container spacing={DEFAULT_GRID_SPACING}>
                                    <Grid item xs={12} sm={8}>
                                        <StyledCard>
                                            <div className="form-group">
                                                <TextInput
                                                    name="title"
                                                    key="title"
                                                    label="Tytuł strony"
                                                    value={values.title}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </StyledCard>
                                        {this.state.type ? this.renderType(this.state.type, formikParams) : ''}
                                    </Grid>
                                    <Grid item xs={12} sm={4} style={{position: 'relative'}}>
                                        <StyledFlyingContainer>
                                            <StyledCardHeader style={{fontSize: '2rem', marginBottom: 20}}>{CONTENTS_TRANSLATIONS[this.props.match.params.name]}</StyledCardHeader>

                                            <div className={'info-box info-box--big'} style={{ marginBottom: '20px'}}>
                                                {CONTENTS_INFO_TRANSLATIONS[this.props.match.params.name]}
                                            </div>

                                            {this.state.type === ContentTypes.MODULAR ? <div className={'info-box'} style={{ marginBottom: '20px'}}>
                                                Możesz zmieniać kolejność elementów podnosząc i upuszczając kafelki w odpowiednich miejscach.
                                            </div> : null}

                                            <ActionsPanel className={'actions'} style={{ margin: '50px auto'}}>
                                                <ActionsPanelButton component={Link} to="/admin/contents" icon={"arrow_back"} text={"powrót"}></ActionsPanelButton>
                                                <ActionsPanelButton type="submit" onClick={handleSubmit} text={"Zapisz zmiany"} icon={"save"}></ActionsPanelButton>
                                            </ActionsPanel>
                                        </StyledFlyingContainer>
                                    </Grid>
                                </StyledPageGrid>

                                <br />
                            </div>);
                        }}

                    </Formik>


            </div>
        )
    }
}

export default withSnackbar(ContentsEdit);
