import Backdrop from "@material-ui/core/Backdrop";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import React, {Fragment, useState} from "react";
import styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../constants/media-sizes.constants";
import Icon from "@material-ui/core/Icon";
import ENV from "../../../config/environment";
import WEDDING_CONFIG from "../../../wedding-config";

const colors = WEDDING_CONFIG.colors;

const StyledSpeedDial = styled(SpeedDial)`
  && {
    position: fixed;
    right: 20px;
    bottom: 20px;
    
    .MuiSpeedDialAction-staticTooltipLabel {
      font-size: 1.1rem;
      white-space: nowrap;
    }
    
    .MuiIcon-root {
      font-size: 1.9rem;
    }
  }
`;

const StyledBackdrop = styled(Backdrop)`
  && {
    z-index: 1000;
  }
`;

const StyledSpeedDialAction = styled(SpeedDialAction)`
  && {
    &.MuiSpeedDialAction-fab {
      padding: 30px;
    }
    
    &.locked-premium {
      background: ${colors.secondary};
    }
    
    .MuiIcon-root {
      position: absolute;
    }
    
  }
`;

export default function MobileActions({ actions }) {
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery(MediaRule.tablet);

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    return <Fragment>
        <StyledBackdrop open={open} />

        <StyledSpeedDial
            ariaLabel=""
            hidden={!isMobile}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
        >
            {actions.filter((action) => action.visible !== false).map((action, idx) => (
                <StyledSpeedDialAction
                    key={`mobile_action_${idx}`}
                    className={`${action.lockedForPremium && !ENV.IS_PREMIUM ? 'locked-premium' : ''}`}
                    icon={action.icon ? <Icon>{action.icon}</Icon> : null}
                    tooltipTitle={action.text}
                    tooltipOpen
                    onClick={action.onClick}
                />
            ))}

        </StyledSpeedDial>

    </Fragment>
}

