import React, {Component} from 'react';
import {connect} from 'react-redux';
import store from '../../../store';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import api from '../../../api';
import moment from 'moment';
import {transparentize} from 'polished';
import {setChangelogData, setInstanceActiveParam} from '../../../actions';
import {Loader} from "../../../common/Loader";
import {CommonOutlinedButton} from '../../../common/StyledButton';
import SectionTitle from "../partials/SectionTitle";
import styled from 'styled-components';
import {StyledTooltip} from "../../../common/TooltipTitle";
import Changelog from "./Changelog";
import {withSnackbar} from "notistack";
import {StyledCardContent} from "../../../common/StyledContentCard";
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";
import WEDDING_CONFIG from "../../../wedding-config";
import ENV from "../../../config/environment";
import Link from "react-router-dom/Link";
import NavLink from "react-router-dom/NavLink";
import {DEFAULT_GRID_SPACING, DEFAULT_IMAGE_BORDER_RADIUS} from "../../../constants";
import websiteService from "../../../service/website.service";
import appService from "../../../service/app.service";
import BlogNews from "../partials/BlogNews";
import TasksCounter from "../dashboard/TasksCounter";
import Hidden from "@material-ui/core/Hidden";
import { version } from '../../../../package.json';

const colors = WEDDING_CONFIG.colors;

const StyledDemoMessage = styled.div`
  background: ${colors.primaryLight};
  color: white;
  padding: 12px 15px 12px 85px;
  position: relative;
  border-radius: 4px;
  
  .icon {
    position: absolute;
    top: 4px;
    font-size: 60px;
    left: 10px;
  }
`;

const StyledPremiumOrderLink = styled(Link)`
  position: relative;
  display: block;
  
  span {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
    color: white;
    font-size: 1.5rem;
    border-radius: 0 0 ${DEFAULT_IMAGE_BORDER_RADIUS}px ${DEFAULT_IMAGE_BORDER_RADIUS}px;
    transition: background 0.1s ease-in;
    background: ${transparentize(0.75, '#fff')};
  }
  
  .image {
    width: 100%;
    height: 296px;
    background: url('/images/deklaracje.jpg');
    background-size: cover;
    background-position: center center;
    border-radius: ${DEFAULT_IMAGE_BORDER_RADIUS}px;
  }
  
  &:hover span {
    background: ${colors.secondary};
    font-weight: bold;
  }
`;

const StyledLoggedInList = styled(List)`
  .icon {
    font-size: 2rem;
  }
  
  .more {
    margin-top: 24px;
  }
`;

const StyledSocialIcon = styled.div`
  display: inline-block;
  background: ${colors.primaryLight};
  border-radius: 10px;
  padding: 3px;
  margin-right: 10px;
  
  img {
    display: block;
    width: 40px;
  }
`;

const StyledSocialLink = styled.a`
 
`;

const StyledTimeCard = styled(StyledCard)`
  && {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    
    p {
      display: flex;
      color: #505050;
      position: relative;
      padding-left: 5px;
      &:before {
        content: '';
        display: inline-block;
        width: 0;
        position: absolute;
        top: 50%;
        left: -20px;
        height: 2px;
        transform: translateY(-50%);
        border: solid 6px #eee;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }
    }
  }
`;

const StyledDiv = styled.div`

  color: ${colors.textRead};
    p {
      color: ${colors.textRead};
    }
`;

const StyledTime = styled.span`
    font-size: 1rem;
    
    span {
      font-weight: bold;
      color: ${colors.primaryLight};
    }
`;

class Instance extends Component {
    state = {
        nextButtonDisabled: false,
        instance: undefined,
        logged: [],
        blogNews: []
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getInstanceData();
        this.fetchNews();
    }

    fetchNews() {
        websiteService.getNews().then((data) => {
            this.setState({
                blogNews: data.data.slice(0, 2)
            })
        });
    }

    getInstanceData() {
        appService.getInstanceData().then(response => {
            if (response.data && response.data && response.data.instance) {
                this.setState({
                    instance: response.data.instance,
                    logged: response.data.logged
                });

                store.dispatch(setChangelogData(response.data.changelog));
                store.dispatch(setInstanceActiveParam(response.data.instance.is_active));
            }
        });
    }

    handleActiveChange() {
        api.request({
            url: api.suRoutes.setInstanceActiveParam,
            method: 'put',
            data: {
                active: !this.props.app.isInstanceActive
            }
        }).then((response) => {
            if (response.data && response.data && response.data.changed) {
                this.setState({
                    instance: Object.assign(this.state.instance, {
                        is_active: response.data.is_active
                    })
                });

                store.dispatch(setInstanceActiveParam(response.data.is_active));
            }
        })
    }

    getDaysToWedding() {
        return moment(this.props.instance.initial_wedding_date).startOf('day').diff(moment().startOf('day'), 'days');
    }

    /*renderDaysToWeddingMsg(days) {
        if (days === 1) {
            return <p>
                Twój ślub odbędzie się już jutro!<br/>Powodzenia na Nowej Drodze!
            </p>
        }
        if (days === 0) {
            return <p>
                Twój ślub odbędzie się już dziś!<br/>Co tu jeszcze robisz?! <Icon style={{verticalAlign:'middle', fontSize:'1.2rem'}}>sentiment_very_satisfied</Icon><br/>Powodzenia na Nowej Drodze!
            </p>
        }
        if (days > 190) {
            return <p>
                To może być dobry moment na zaproszenie gości. Co powiesz na nasze Deklaracje Online?
            </p>
        }
        // if (days > 130) {
        //     return <p>
        //         Masz jeszcze trochę czasu.<br/>Upewnij się, że zaplanowałeś
        //     </p>
        // }
        // if (days > 90) {
        //     return <p>
        //         Czas leci nieubłagalnie!
        //     </p>
        // }
        if (days > 60) {
            return <p>
                To dobry moment aby prześledzić listę zaproszonych gości i upewnić się, że wszyscy odpowiedzieli na zaproszenie.
            </p>
        }
        if (days > 30) {
            return <p>
                Zaplanowaliście już rozłożenie stołów na sali?
            </p>
        }
        if (days > 14) {
            return <p>
                Czas leci nieubłagalnie!<br/>To może być dobry moment, aby pozamykać ostatnie otwarte zadania i użyć naszego planera stołów oraz noclegów.
            </p>
        }
        if (days > 7) {
            return <p>
                Czas by dopiąć wszystko na ostatni guzik.<br/>Postaraj się dokończyć otwarte zadania. Powodzenia :)
            </p>
        }
        if (days > 0) {
            return <p>
                Już za kilka dni, już niedługo! Powodzenia! :)
            </p>
        }
        if (days === -1) {
            return <p>
                Twój ślub odbył się wczoraj.<br/>Mamy nadzieję, że wszystko poszło po Twojej myśli. <Icon style={{verticalAlign:'middle', fontSize:'1.2rem'}}>sentiment_very_satisfied</Icon>
            </p>
        }
        if (days < 0) {
            return <p>
                Twój ślub odbył się {Math.abs(days)} dni temu.<br/>Mamy nadzieję, że wszystko poszło po Twojej myśli. <Icon style={{verticalAlign:'middle', fontSize:'1.2rem'}}>sentiment_very_satisfied</Icon>
            </p>
        }
    }*/

    renderSimpleDaysToWeddingMsg(days) {
        if (days < 0) {
            return <div>
                Dziękujemy za skorzystanie z aplikacji. <br/>Mamy nadzieję, że ułatwiła Wam ona przygotowania i wszystko się udało. <Icon style={{verticalAlign:'middle', fontSize:'1.2rem'}}>sentiment_very_satisfied</Icon>
            </div>
        }
    }

    render() {
        const daysToWedding = this.getDaysToWedding();

        if (!this.state.instance) {
            return (
                <Loader/>
            )
        }
        return (
            <div>
                <Grid container spacing={DEFAULT_GRID_SPACING}>
                    <Grid item xs={12} sm={4}>
                        <StyledCard className="no-mb-mobile js-step-admin-instance">
                            <StyledCardHeader>
                                <SectionTitle fontSize={'lg'} style={{marginTop: 0}}>
                                    <span style={{verticalAlign:'middle'}}>Cześć <span className={'color-primary'} style={{textTransform: 'capitalize'}}>{this.props.user.first_name}</span>!</span> <Icon style={{verticalAlign:'middle', fontSize:'2rem'}}>sentiment_very_satisfied</Icon>
                                </SectionTitle>
                            </StyledCardHeader>
                            <div>
                                <StyledDiv>
                                    <div>
                                        {daysToWedding > 0 ?
                                            <StyledTime>
                                                Do Twojego ślubu pozostało <span>{daysToWedding + (daysToWedding === 1 ? ' dzień' : ' dni')}</span>.
                                            </StyledTime> : null}
                                    </div>
                                    {this.renderSimpleDaysToWeddingMsg(daysToWedding)}
                                </StyledDiv>
                            </div>
                            <CommonOutlinedButton className={'size-small'} component={NavLink} to={`/admin/settings/instance-settings`} style={{marginTop: '20px'}}>Ustawienia</CommonOutlinedButton>
                        </StyledCard>

                        <Hidden xsDown>
                            <StyledCard className="js-step-admin-news">
                                <StyledCardHeader>
                                    <SectionTitle>Co nowego w fancy.wedding?</SectionTitle>
                                </StyledCardHeader>
                                <StyledCardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Changelog />
                                        </Grid>
                                    </Grid>
                                </StyledCardContent>
                            </StyledCard>
                        </Hidden>

                        <Hidden xsDown>
                            <StyledCard className="js-step-admin-news">
                                <StyledCardHeader>Bądź na bieżąco</StyledCardHeader>
                                <StyledCardContent>
                                    <a href={ENV.INSTAGRAM_URL} target={'_blank'} title={'Instagram fancy.wedding'}>
                                        <StyledSocialIcon>
                                            <img src={'/instagram.png'} />
                                        </StyledSocialIcon>
                                    </a>
                                    <a href={ENV.FACEBOOK_URL} target={'_blank'} title={'Facebook fancy.wedding'}>
                                        <StyledSocialIcon>
                                            <img src={'/facebook.png'} />
                                        </StyledSocialIcon>
                                    </a>
                                    <div style={{marginTop: 10}}>
                                        <StyledSocialLink href={'https://www.fancy.wedding'} target={'_blank'} title={'fancy.wedding'}>
                                            www.fancy.wedding
                                        </StyledSocialLink>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div>Znalazłeś błąd w aplikacji? Prosimy o informację na <a href={'mailto:kontakt@fancy.wedding'}>kontakt@fancy.wedding</a>, abyśmy mogli ulepszyć Wasze doświadczenia.</div>
                                    </div>
                                    <div style={{marginTop: 10, color: '#888', fontSize: 10}}>
                                        v{version}
                                    </div>
                                </StyledCardContent>
                            </StyledCard>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={DEFAULT_GRID_SPACING}>
                            {ENV.IS_PREMIUM ? (
                                <Grid item xs={12} sm={6}>
                                    <StyledCard className="js-step-admin-last-logins no-mb">
                                        <StyledCardHeader>
                                            Ostatnie logowania
                                        </StyledCardHeader>
                                        <StyledCardContent>
                                            {this.state.logged && this.state.logged.length ? (
                                                <StyledLoggedInList dense={true}>
                                                    {this.state.logged.map((visit, index) => visit.participant ? (
                                                        <ListItem key={index} className={'item'}>
                                                            <ListItemIcon>
                                                                <StyledTooltip
                                                                    title={`Zalogowano z ${visit.device === 'desktop' ? 'komputera' : 'urządzenia mobilnego'}`}
                                                                    placement="top">
                                                                    <Icon className={'icon'}>{visit.device === 'desktop' ? 'computer' : 'phone_iphone'}</Icon>
                                                                </StyledTooltip>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={`${visit.participant.first_name} ${visit.participant.last_name}`}
                                                                secondary={moment(visit.time).format('DD-MM-YYYY')}
                                                                title={moment(visit.time).format('DD-MM-YYYY HH:mm:ss')}
                                                            />
                                                        </ListItem>
                                                    ) : '')}
                                                    <div className={'more'}>
                                                        <Link to={'/admin/statistics'}>pokaż statystyki</Link>
                                                    </div>
                                                </StyledLoggedInList>
                                            ) : 'Nikt z gości jeszcze się nie logował.'}
                                        </StyledCardContent>
                                    </StyledCard>
                                </Grid>
                            ) : null}

                            {this.props.instance.is_demo_instance ? (
                                <Grid item xs={12} sm={6}>
                                    <StyledCard>
                                        <StyledCardContent>
                                            <StyledDemoMessage>
                                                <Icon className="icon">sentiment_satisfied_alt</Icon>
                                                Używasz instancji prezentacyjnej DEMO. Oznacza to możliwość, że nie tylko Ty
                                                korzystasz z niej jako administrator.
                                                Bardzo prosimy pamiętać o zachowaniu kultury podczas edycji zawartości. Dzięki!
                                            </StyledDemoMessage>
                                        </StyledCardContent>
                                    </StyledCard>
                                </Grid>
                            ) : ''}
                            <Grid item xs={12} sm={6}>
                                <StyledCard className={'no-mb'}>
                                    <StyledCardHeader>
                                        Twoje zadania
                                    </StyledCardHeader>
                                    <StyledCardContent style={{height: 300}}>
                                        <TasksCounter></TasksCounter>
                                    </StyledCardContent>
                                </StyledCard>
                            </Grid>
                            {!ENV.IS_PREMIUM ? (
                                <Grid item xs={12} sm={6}>
                                    <StyledCard className={'no-mb'}>
                                        <StyledCardHeader>
                                            Deklaracje Online dla gości
                                        </StyledCardHeader>
                                        <StyledCardContent>
                                            <StyledPremiumOrderLink to={'/admin/premium'}>
                                                <div className={'image'} />
                                                <span>włącz</span>
                                            </StyledPremiumOrderLink>
                                        </StyledCardContent>
                                    </StyledCard>
                                </Grid>
                            ) : null}
                            <Grid item xs={12} sm={12}>
                                <StyledCard>
                                    <StyledCardHeader>
                                        Sprawdź nasz blog
                                        <a className={'aside'} target="_blank" href={`${ENV.WEBSITE_URL}/blog?ref=app`}>przejdź do bloga</a>
                                    </StyledCardHeader>
                                    <StyledCardContent>
                                        <Grid container spacing={2}>
                                            {this.state.blogNews.map((item) => (
                                                <Grid item xs={12} sm={6}>
                                                    <BlogNews item={item}></BlogNews>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </StyledCardContent>
                                </StyledCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user,
        instance: state.auth.instance,
    }
};

export default connect(mapStateToProps)(withSnackbar(Instance));
