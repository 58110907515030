import styled from "styled-components";
import Table from "@material-ui/core/Table";

export const StyledTable = styled(Table)`
  && {
    .MuiTableCell-sizeSmall {
      padding: 6px 6px 6px 14px;
    }
    
    &.mobile {
        .MuiTableCell-sizeSmall {
          padding: 6px 6px 6px 0;
        }
    }
    
    .MuiTableCell-root {
      border-bottom: 1px solid rgb(240 240 240);
    }
  }
`;
