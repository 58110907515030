import api from '../api';

const getSets = () => {
    return api.request({
        url: api.suRoutes.accommodationSets,
        method: 'get',
    });
};

const getPlaces = (idSet) => {
    return api.request({
        url: api.suRoutes.accommodationPlaces.replace(':id_set', idSet),
        method: 'get',
    });
};

const getParticipantsList = () => {
    return api.request({
        url: api.suRoutes.accommodationUsersList
    });
};

const addRoom = (idPlace, data) => {
    return api.request({
        url: api.suRoutes.accommodationRoom.replace(':id_place', idPlace),
        method: 'post',
        data: {
            name: data.name,
            beds_number: data.beds_number
        },
    });
};

const editRoom = (idRoom, data) => {
    return api.request({
        url: api.suRoutes.accommodationEditRoom.replace(':id_room', idRoom),
        method: 'put',
        data: {
            name: data.name
        }
    });
};

const removeRoom = (idRoom) => {
    return api.request({
        url: api.suRoutes.accommodationEditRoom.replace(':id_room', idRoom),
        method: 'delete',
    });
};

const addPlace = (idSet, data) => {
    return api.request({
        url: api.suRoutes.accommodationPlaces.replace(':id_set', idSet),
        method: 'post',
        data: {
            name: data.name,
        },
    });
};

const editPlace = (idPlace, data) => {
    return api.request({
        url: api.suRoutes.accommodationEditPlaces.replace(':id_place', idPlace),
        method: 'put',
        data: {
            name: data.name
        }
    });
};

const removePlace = (idPlace) => {
    return api.request({
        url: api.suRoutes.accommodationEditPlaces.replace(':id_place', idPlace),
        method: 'delete',
    });
};

const addSet = (data) => {
    return api.request({
        url: api.suRoutes.accommodationSets,
        method: 'post',
        data: {
            name: data.name,
        },
    });
};

const removeSet = (setId) => {
    return api.request({
        url: api.suRoutes.accommodationDeleteSet.replace(':id_set', setId),
        method: 'delete',
    });
};

const getAssignments = (idSet) => {
    return api.request({
        url: api.suRoutes.accommodationAssignments.replace(':id_set', idSet),
        method: 'get',
    });
};

const assignParticipant = (idSet, assignment) => {
    return api.request({
        url: api.suRoutes.accommodationAssignParticipant.replace(':id_set', idSet),
        method: 'post',
        data: assignment
    });
};

const removeParticipant = (idSet, idAssignment) => {
    return api.request({
        url: api.suRoutes.accommodationRemoveParticipant.replace(':id_set', idSet).replace(':id', idAssignment),
        method: 'delete',
    });
};

const exportList = (idSet, places) => {
    return api.request({
        url: api.suRoutes.accommodationExport.replace(':id_set', idSet),
        method: 'post',
        data: { places },
        responseType: 'blob'
    });
};

export default {
    getSets,
    getPlaces,
    getAssignments,
    getParticipantsList,
    addSet,
    addRoom,
    editRoom,
    removeRoom,
    addPlace,
    editPlace,
    removeSet,
    removePlace,
    assignParticipant,
    removeParticipant,
    exportList,
};