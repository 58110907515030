import React, {Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styled from "styled-components";
import ENV from "../../../../config/environment";
import WEDDING_CONFIG from "../../../../wedding-config";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";
import Collapse from "@material-ui/core/Collapse";
import {lastVisitRenderer} from "./UsersListTable";
import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";

const colors = WEDDING_CONFIG.colors;

const StyledTableRow = styled(TableRow)`
  opacity: ${props => props['is_active'] ? '1' : '0.3'};
`;

export function UsersListTableRow({ columns, row, visible, yesOrNoOrUnknown, actionsRenderer }) {
    const [rowExpanded, setRowExpanded] = React.useState(false);
    const isMobile = useMediaQuery(MediaRule.tablet);
    const instance = useSelector(state => state.auth.instance);

    return (
        <Fragment>
            <StyledTableRow hover key={row.code} is_active={row.is_active} onClick={() => isMobile ? setRowExpanded(!rowExpanded) : false}>
                {columns.filter(visible).map((column) => {
                    const value = row[column.id];
                    return (
                        <TableCell key={column.id} align={column.align}>
                            {column.renderer ? column.renderer(row) : value}
                        </TableCell>
                    );
                })}
            </StyledTableRow>
            {isMobile ? <TableRow>
                <TableCell style={{ border: 0, paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
                        <div style={{ paddingBottom: 12, paddingTop: 12 }} >
                            <Grid container>
                                <Grid item xs={6}>
                                    {!row.is_active ? (
                                        <div style={{color: 'red'}}>Użytkownik dezaktywowany. {ENV.IS_PREMIUM ? 'Użytkownik nie może się logować i nie jest liczony do statystyk.' : ''}</div>
                                    ) : null}
                                    <div>Wesele: {yesOrNoOrUnknown(row.present_first_day)}</div>
                                    {instance.is_two_day_party ? <div>Poprawiny: {yesOrNoOrUnknown(row.present_second_day)}</div> : null}
                                    {instance.is_accommodation_available ? <div>Nocleg: {yesOrNoOrUnknown(row.accommodation)}</div> : null}
                                </Grid>
                                <Grid item xs={6} className={'text-right'}>
                                    {actionsRenderer(row)}
                                </Grid>
                            </Grid>
                            {row.present_first_day ? <Grid container>
                                <Grid item xs={12}>
                                    {row.main_special_menu ? <div>Menu ({row.first_name}): {row.main_special_menu}</div> : null}
                                    {row.partner_special_menu && row.partner_first_name && row.is_with_partner ? <div>Menu ({row.partner_first_name}): {row.partner_special_menu}</div> : null}
                                    {ENV.IS_PREMIUM ? <div>Zalogowano: {lastVisitRenderer(row)}</div> : null}
                                    {row.participant_note ? <div>Wiadomość: {row.participant_note}</div> : null}
                                </Grid>
                            </Grid> : null}
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow> : null}
        </Fragment>
    );
}
