import React, {Fragment, useState} from 'react';
import { useForm } from 'react-hook-form';
import WEDDING_CONFIG from '../../../../wedding-config';
import TextInput from "../../partials/TextInput";
import IncrementField from "../../partials/IncrementField";
import SimpleDialog from "../../../../common/SimpleDialog";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import {addRoom, editPlace, removePlace} from "../../../../actions/accommodation.actions";
import store from "../../../../store";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

const colors = WEDDING_CONFIG.colors;

const StyledBox = styled.div`
  width: 400px;
  max-width: 100%;
  padding: 25px 40px 25px 0;
  
  &.right {
    border-left: 1px solid #ddd;
    padding: 25px 0 25px 40px;
  }
`;

const AccommodationChangePlaceDialog = (props) => {
    const { register, handleSubmit, errors, getValues, setValue, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: props.place.name
        }
    }); // initialise the hook
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = data => {
        setIsLoading(true);
        store.dispatch(editPlace(props.place.id, data))
            .then(() => {
                setIsLoading(false);
                props.handleClose();
            });
    };
    let formRef;

    const onDelete = () => {
        if (!window.confirm(`Czy na pewno chcesz usunąć ${props.place.name}?`)) {
            return;
        }
        setIsLoading(true);
        store.dispatch(removePlace(props.place.id))
            .then(() => {
                setIsLoading(false);
                props.handleClose();
            });
    };

    return (
        <SimpleDialog
            open={true}
            title={'Zmień lub usuń miejsce'}
            actions={<Fragment>
                <CommonOutlinedButton onClick={() => props.handleClose()} color="secondary">
                    Anuluj
                </CommonOutlinedButton>
            </Fragment>}
          {...props}>
            <Grid container>
                <Grid item xs={6}>
                    <StyledBox className={'text-center'}>
                        <strong style={{fontSize: '1.2rem'}}>Zmień nazwę.</strong>
                        <br />
                        <br />

                        <form onSubmit={handleSubmit(onSubmit)} ref={(ref) => formRef = ref}
                              style={{width: 200, margin: "0 auto"}}>
                            <TextInput
                                inputRef={register({required: true})}
                                name="name"
                                key="name"
                                label={'Nowa nazwa'}
                                maxLength={16}
                                placeholder={'Podaj nową nazwę'}
                            />
                            <div className={'text-c'} style={{marginTop: 20}}>
                                <CommonOutlinedButton className={'fill-primary'} disabled={!formState.isValid || !formState.dirty || isLoading} onClick={() => formRef.dispatchEvent(new Event('submit', { cancelable: true }))} color="primary">
                                    Zapisz
                                </CommonOutlinedButton>
                            </div>
                        </form>
                    </StyledBox>
                </Grid>
                <Grid item xs={6} className={'text-center'}>
                    <StyledBox className={'right'}>
                        <strong style={{fontSize: '1.2rem'}}>Usuń miejsce.</strong>
                        <br />
                        <p>Usunięte zostaną także pokoje z wszystkimi przypisaniami.</p>
                        <CommonOutlinedButton onClick={() => onDelete()} color="primary" disabled={isLoading}>
                            Usuń
                        </CommonOutlinedButton>
                    </StyledBox>
                </Grid>
            </Grid>
        </SimpleDialog>
    )
};

export default AccommodationChangePlaceDialog;
