import { CONTENTS } from '../constants';
import store from '../store';

const getMenuItems = () => {
    const menuItems = [
        CONTENTS.PAGE_GENERAL
    ];
    let storeInstance = store.getState().auth.instance;
    let storeUser = store.getState().auth.user;

    menuItems.push(CONTENTS.PAGE_MAP);

    if (storeInstance.is_accommodation_available && storeInstance.is_accommodation_page_enabled && storeUser.allow_selecting_accommodation) {
        menuItems.push(CONTENTS.PAGE_ACCOMMODATION);
    }

    if (storeInstance.is_photos_page_enabled) {
        menuItems.push(CONTENTS.PAGE_PHOTOS);
    }

    if (storeInstance.is_gifts_page_enabled) {
        menuItems.push(CONTENTS.PAGE_GIFTS);
    }

    if (storeInstance.is_pdf_download_enabled) {
        menuItems.push('DOWNLOAD_PDF_SUMMARY');
    }

    return menuItems;
};

export default {
    getMenuItems
};
