import { combineReducers } from "redux";
import { app } from "./app.reducer";
import { auth } from "./auth.reducer";
import { tables } from "./table.reducer";
import { accommodation } from "./accommodation.reducer";

const rootReducer = combineReducers({
    auth,
    app,
    tables,
    accommodation
});

export default rootReducer;