import React, {useState} from "react";
import styled from 'styled-components';

const StyledBar = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 8px;
  background: black;
  z-index: 10000;
  color: white;
  text-align: center;
  font-size: 16px;
  cursor: pointer;

  &.action {
    background-color: #4f9702;
    cursor: pointer;

    &:hover {
      background-color: #458002;
    }
  }

  &.warning {
    background-color: #d59b2d;
  }
  
  &.hidden {
    display: none;
  }
`;

export default function UpdateBar({ text, variant, onClick }) {
    const [hidden, setHidden] = useState(false);

    return <StyledBar
        onClick={onClick || (() => { setHidden(true) })}
        className={`${variant === 'warning' ? 'warning' : ''} ${variant === 'action' ? 'action' : ''} ${hidden ? 'hidden' : ''}`}>
        {text}
    </StyledBar>;
}
