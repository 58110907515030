import api from '../api';
import ENV from "../config/environment";
import {setIsAfterWedding, setPremiumPrice} from "../actions";
import store from '../store';

const getLocalJWT = () => {
    return localStorage.getItem('token');
};

const setLocalJWT = (token) => {
    localStorage.setItem('token', token);
    api.setDefaults({token});
};

const checkLoginState = () => {
    return api.request({
        url: api.routes.checkAuthenticationState
    }).then((resp) => {
        store.dispatch(setPremiumPrice(resp.data.premiumPrice));
        store.dispatch(setIsAfterWedding(resp.data.isAfterWedding));
        return resp;
    });
};

const requestResetPassword = (email) => {
    return api.request({
        method: 'post',
        url: api.routes.requestResetPassword,
        data: { email }
    });
};

const resetPassword = (data) => {
    return api.request({
        method: 'post',
        url: api.routes.resetPassword,
        data: { ...data }
    });
};

const resetPasswordPossibility = (token) => {
    return api.request({
        method: 'get',
        url: api.routes.resetPasswordPossibility.replace(':token', token),
    });
};

const clearToken = () => {
    api.setDefaults({token: null});
    localStorage.removeItem('token');
};

const logOff = () => {
    clearToken();
    window.location.href = '/';
};

const authenticate = (data) => {
    return api.request({
        url: ENV.IS_PREMIUM ? api.routes.authenticate : api.routes.authenticateStandard,
        method: 'post',
        data: data
    }).then((response) => {
        const token = response.data.auth_token;

        if (token) {
            setLocalJWT(token);
        }

        return response;
    });
};

export const authService = {
    authenticate,
    resetPasswordPossibility,
    requestResetPassword,
    resetPassword,
    checkLoginState,
    getLocalJWT,
    logOff,
    clearToken
};
