import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {StyledTooltip} from "../../../../common/TooltipTitle";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import UserEditAction from "./UserEditAction";
import UsersListParticipantCodeDialog from "./UsersListParticipantCodeDialog";
import ENV from "../../../../config/environment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";
import participantService from "../../../../service/participant.service";
import styled from "styled-components";
import UserNonGuestEditDialog from "./UserNonGuestEditDialog";
import {useSelector} from "react-redux";

const StyledIconButton = styled(IconButton)`
  &.mobile {
    position: relative;
    left: 20px;
    top: -8px;
  }
`;

export default function UsersNonGuestsListActions({ user, index, toggleEditDialog, onDelete}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMobile = useMediaQuery(MediaRule.tablet);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <StyledIconButton className={isMobile ? 'mobile' : ''} onClick={handleClick}>
                <Icon>more_vert</Icon>
            </StyledIconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { toggleEditDialog(user, index); handleClose(); }}>Zmień</MenuItem>
                {!user.is_bride_row ? <MenuItem onClick={() => { onDelete(index); handleClose(); }}>Usuń</MenuItem> : null}
            </Menu>
        </div>
    );
}
