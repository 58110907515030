import React, {Fragment, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import WEDDING_CONFIG from "../../../../wedding-config";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import SimpleDialog from "../../../../common/SimpleDialog";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import copy from "copy-to-clipboard";
import {useSnackbar} from 'notistack';
import {StyledCard, StyledCardHeader} from "../../../../common/StyledCard";
import {DEFAULT_GRID_SPACING} from "../../../../constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";

const colors = WEDDING_CONFIG.colors;

const StyledBox = styled(StyledCard)`
  .code {
    font-size: 2.2rem;
    margin-top: 20px;
  }
  
  .link {
    a {
        display: inline-block;
        padding: 7px 32px;
        background-color: #eee;
        font-weight: bold;
        vertical-align: middle;
        border-radius: 5px;
        text-decoration: none;
    }
  }
`;

export default function UsersListParticipantCodeDialog(props) {
    const [isOpen, setIsOpen] = useState(false);
    const user = props.user;
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    function copyCodeToClipboard(code) {
        copy(code);
        enqueueSnackbar('Kod skopiowano do schowka', {
            variant: 'success',
        });
    }

    function copyLinkToClipboard(code) {
        copy(linkWithCode(code));
        enqueueSnackbar('Link skopiowano do schowka', {
            variant: 'success',
        });
    }

    function linkWithCode(code) {
        return `${window.location.origin}/?code=${code}`;
    }

    return (
        <Fragment>
            {React.cloneElement(props.children, {
                onClick: () => setIsOpen(true)
            })}
            {isOpen ? <SimpleDialog
                open={true}
                title={`Kod logowania (${user.first_name} ${user.last_name})`}
                handleClose={() => setIsOpen(false)}
                actions={<div className={'text-center'}>
                    <CommonOutlinedButton onClick={() => setIsOpen(false)} color="secondary">
                        Zamknij okno
                    </CommonOutlinedButton>
                </div>}
                {...props}>
                <Grid container spacing={DEFAULT_GRID_SPACING}>
                    <Grid item xs={12} sm={4}>
                        <StyledBox className={'text-center height-100'}>
                            <StyledCardHeader>Kod:</StyledCardHeader>
                            <div className={'code'}>
                                {user.code}
                                <br/>
                                <IconButton
                                    onClick={() => copyCodeToClipboard(user.code)}>
                                    <Icon>content_paste</Icon>
                                </IconButton>
                            </div>
                        </StyledBox>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <StyledBox className={'text-center height-100'}>
                            <StyledCardHeader>Zaloguj jako {user.first_name}.</StyledCardHeader>
                            <p>Skopiuj poniższy link, aby zalogować się jako użytkownik<br/>lub aby przekazać temu gościowi.</p>
                            <div className={'link'}>
                                <a href={linkWithCode(user.code)} target={'_blank'}>{linkWithCode(user.code)}</a>
                                <IconButton onClick={() => copyLinkToClipboard(user.code)}>
                                    <Icon>content_paste</Icon>
                                </IconButton>
                            </div>
                            <p>Uwaga! Jeśli wkleisz go w tej samej przeglądarce, zostaniesz wylogowany z panelu.</p>
                        </StyledBox>
                    </Grid>
                </Grid>
            </SimpleDialog> : null}
        </Fragment>

    )
};
