import styled from 'styled-components';
import Checkbox from "@material-ui/core/Checkbox";
import WEDDING_CONFIG from '../../../../wedding-config';
import MediaSizes from '../../../../constants/media-sizes.constants';

const colors = WEDDING_CONFIG.colors;

export const StyledHeader = styled.h1`
    font-size: 2.4rem;
    text-align: center;
    
    &:after {
        content: '----';
        display: block;
        color: ${colors.primary}
        font-family: "July Seventh";
    }
    
    @media (max-width: ${MediaSizes.tablet}px) {
        margin-top: 0;
        font-size: 1.6rem;
    }
`;


export const StyledBigCheckbox = styled(Checkbox)`
    && {
        font-size: 3rem;
        width: 80px;
        height: 80px;
        
        svg {
            width: 50px;
            height: 50px;
        }
       
        @media (max-width: ${MediaSizes.phone}px) {
            width: 50px;
            height: 50px;
        }
    }
`;


export const stylesForFormControlLabel = {
    fontSize: '2rem'
};
