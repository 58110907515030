import CircularProgress from "@material-ui/core/CircularProgress";
import React, {Fragment, useEffect, useState} from "react";
import {Doughnut} from "react-chartjs-2";
import TasksService from "../../../service/task.service";
import WEDDING_CONFIG from "../../../wedding-config";
import styled from "styled-components";
import Link from "react-router-dom/Link";

const StyledTitle = styled.div`
  font-size: 2.5rem;
  color: ${WEDDING_CONFIG.colors.primaryLight};
  position: absolute;
  text-align: center;
  pointer-events: none;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  
  .sub {
    font-size: 1.5rem;
  }

  &.no-tasks {
    pointer-events: all;
    color: #666;
    font-size: 2rem;
  
      .sub {
        font-size: 1.2rem;
      }
  }
`;

export default function TasksCounter() {
    const [mappedData, setMappedData] = useState(undefined);
    const [numbers, setNumbers] = useState({ percent: 0, left: 0 });

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 85,
        legend: {
            display: false
        },
        tooltips: {
            mode: 'nearest',
            intersect: false
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };

    useEffect(() => {
        TasksService.getTasksSummary().then((data) => {
            setMappedData(remapData(data.data));
            setNumbers(getNumbers(data.data));
        });
    }, []);

    return (
        <Fragment>
            {numbers.total > 0 ? (
                <Fragment>
                    <Doughnut data={mappedData} options={options} />
                    <StyledTitle>
                        <div>{numbers.percent}%</div>
                        <div className={'sub'}>{numbers.left === 0 ? 'wykonanych!' : `pozostało: ${numbers.left}`}</div>
                    </StyledTitle>
                </Fragment>
            ) : <Fragment>
                <StyledTitle className={'no-tasks'}>
                    <div>brak zadań</div>
                    <div className={'sub'}>
                        <Link to={'/admin/tasks'}>zacznij planować</Link>
                    </div>
                </StyledTitle>
            </Fragment>}
        </Fragment>
    )
}

function getNumbers(data) {
    const total = data.length;
    const totalDone = data.filter((item) => item.is_done).length;

    return {
        total,
        percent: (totalDone / total * 100).toFixed(),
        left: total - totalDone
    };
}

function remapData(data) {
    const total = data.length;
    const totalDone = data.filter((item) => item.is_done).length;
    const totalUndone = total - totalDone;

    return {
        datasets: [
            {
                data: [totalDone, totalUndone],
                backgroundColor: [
                    WEDDING_CONFIG.colors.primaryLight
                ],
                label: 'Dataset 1'
            }
        ],
        labels: [
            'Wykonane',
            'Pozostałe'
        ]
    };
}
