import React, {Fragment, useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import participantService from "../../../../service/participant.service";
import {StyledTooltip} from "../../../../common/TooltipTitle";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import TableContainer from "@material-ui/core/TableContainer";
import UserEditAction from "./UserEditAction";
import {useSelector} from "react-redux";
import ENV from "../../../../config/environment";
import moment from "moment";
import UsersListActions from "./UsersListActions";
import {useSnackbar} from 'notistack';
import store from "../../../../store";
import {setSuccess} from "../../../../actions";
import WEDDING_CONFIG from "../../../../wedding-config";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";
import {UsersListTableRow} from "./UsersListTableRow";
import {StyledEmptyPlaceholder} from "../../../../common/StyledEmptyPlaceholder";
import {UserFormView} from "./UserEditForm";
import classnames from 'classnames';
import {StyledTable} from "../../../../common/StyledTable";
import Button from '@material-ui/core/Button';

const colors = WEDDING_CONFIG.colors;

const StyledSummaryTableRow = styled(TableRow)`
  & .cell {
    font-weight: bold;
    color: ${colors.primary};
    
    .summary-icon {
          font-size: 0.9rem;
      vertical-align: middle;
    }
  }
`;

const StyledTableContainer = styled(TableContainer)`
  && {
    overflow-x: initial;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 5px;
    .icon {
      font-size: 20px;
      
      &.icon-positive {
          color: #ab1945;
      }
    }
  }
`;

const StyledMark = styled.span`
  &.is-active {
    opacity: 0.3;
    color: black;
  }
`;

const StyledVegeIcon = styled(Icon)`
  && {
    color: #5fbd5f;
    font-size: 1.25rem;
    vertical-align: text-bottom;
  }
`;
const StyledAnnouncementIcon = styled(Icon)`
  && {
    font-size: 1.25rem;
    vertical-align: text-bottom;
  }
`;

const StyledPositive = styled(StyledMark)`
    color: #ab1945;
    .icon { font-size: 20px; }
    
    &.hover:hover {
      cursor: pointer;
      color: #999;
    }
`;

const StyledNegative = styled(StyledMark)`
    opacity: 0.3;
    .icon { font-size: 18px; }
    &.hover:hover {
      cursor: pointer;
      color: #ab1945;
    }
`;

const StyledAllowedCircle = styled(Button)`
    && {
        display: inline-block;
        border-radius: 50%;
        padding: 3px 9px;
        font-size: 0.7rem;
        min-width: auto;
        background: #ddd;
        color: #aaa;
        margin-right: 5px;
        font-weight: bold;
        
        ${props => props.active ? `
            background: #b56982;
            color: white;
        
            &:hover {
              background: #b56982;
              color: #ccc;
            }
        ` : null}
    }
`;

function tickRenderer(value, clsName = '', action, tooltip) {
    if (value === null) {
        return '-';
    }

    return (
        <StyledTooltip
            placement="bottom"
            title={tooltip}>
            <StyledIconButton className={clsName} onClick={action}>
                {value ? (<Icon className="icon icon-positive">check</Icon>) : (
                    <Icon className="icon icon-negative">close</Icon>)}
            </StyledIconButton>
        </StyledTooltip>
    );
}

function yesOrNoIconEditRenderer(userId, value, instance, onUserUpdate, focusComponent) {
    if (value === null) {
        return '-';
    }

    return (
        <UserEditAction userId={userId} instance={instance} onUserUpdate={onUserUpdate} focusComponent={focusComponent}>
            <StyledIconButton className={'hover'}>
                {value ? (<Icon className="icon icon-positive">check</Icon>) : (
                    <Icon className="icon icon-negative">close</Icon>)}
            </StyledIconButton>
        </UserEditAction>
    )
}

function yesOrNo(value, clsName = '', action, tooltip) {
    if (value === null) {
        return '-';
    }

    return (
        <StyledTooltip
            placement="bottom"
            title={tooltip}>
            <StyledIconButton className={clsName} onClick={action}>
                {value ? (<Icon className="icon icon-positive">check</Icon>) : (
                    <Icon className="icon icon-negative">close</Icon>)}
            </StyledIconButton>
        </StyledTooltip>
    );
}

function yesOrNoOrUnknown(value) {
    return (
        <StyledIconButton>
            {value ? (<Icon className="icon icon-positive">check</Icon>) : null}
            {value === false ? (<Icon className="icon icon-negative">close</Icon>) : null}
            {value === null ? (<Icon className="icon">help_outline</Icon>) : null}
        </StyledIconButton>
    );
}

function declarationRenderer(row, storeInstance, onUserUpdate) {
    const note = ENV.IS_PREMIUM && row.participant_note ? (
        <StyledTooltip style={{marginLeft: 5, verticalAlign: 'middle'}} title={row.participant_note}
                       placement="top">
            <Icon>announcement</Icon>
        </StyledTooltip>
    ) : null;
    return (
        <UserEditAction userId={row.id} instance={storeInstance} onUserUpdate={onUserUpdate} focusComponent={UserFormView.DECLARATION}>
            {row.present_first_day !== null ? (
                <div style={{whiteSpace: 'nowrap'}}>
                    <a style={{color: '#444', verticalAlign: 'middle'}}
                       className={'cursor-pointer hover-decoration'}>Wypełniona</a>
                    {note}
                </div>
            ) : (
                <div>
                    <a className={'cursor-pointer hover-decoration'}>Wypełnij</a>
                    {note}
                </div>
            )}
        </UserEditAction>
    )
}

function participantRenderer(row, isMobile) {
    function leafRender() {
        return row.main_special_menu ? <StyledVegeIcon>eco</StyledVegeIcon> : null;
    }
    function noteRender() {
        return isMobile && row.participant_note ? <StyledAnnouncementIcon>announcement</StyledAnnouncementIcon> : null;
    }
    return (
        <Fragment>
            <strong>{row.first_name} <span style={{whiteSpace: 'nowrap'}}>{row.last_name} {leafRender()} {noteRender()}</span></strong>
            {row.first_name !== row.orig_first_name || row.last_name !== row.orig_last_name ? (
                <StyledTooltip title={<div>
                    <div>Uwaga!</div>
                    <div>Imię lub nazwisko dla tej osoby zostało przez nią zmienione/zaktualizowane.
                    </div>
                    <div>Nazwa podana poprzednio przez
                        Ciebie: <strong>{row.orig_first_name} {row.orig_last_name}</strong>
                    </div>
                </div>}>
                    <Icon>announcement</Icon>
                </StyledTooltip>
            ) : null}
            <div>
                {!row.disallow_define_partner && row.is_with_partner ? (
                    !row.partner_first_name ? (
                            <StyledNegative>będzie z partnerem, ale nie określono z
                                kim</StyledNegative>) :
                        (
                            <Fragment>{row.partner_first_name} {row.partner_last_name ? row.partner_last_name :
                                <StyledNegative>(nieznane
                                    nazwisko)</StyledNegative>} {isMobile && row.partner_special_menu ? <StyledVegeIcon>eco</StyledVegeIcon> : null}</Fragment>)
                ) : row.is_with_partner === false ? (
                    <StyledNegative>bez partnera</StyledNegative>) : (
                    <StyledNegative>nie określono partnera</StyledNegative>)
                }
            </div>
            <div>
                {row.kids.map((kid, index) => (
                    <div key={index}>{kid.kid_first_name} {kid.kid_last_name} <Icon
                        style={{fontSize: '1rem', verticalAlign: 'middle'}}>child_care</Icon></div>
                ))}
            </div>
        </Fragment>
    )
}

function menusRenderer(row) {
    return row.main_special_menu || row.partner_special_menu ? (
        <Fragment>
            {row.main_special_menu ? <StyledTooltip title={row.first_name} placement="top">
                <div>{row.main_special_menu}</div>
            </StyledTooltip> : null}
            {row.partner_special_menu ? <StyledTooltip title={row.partner_first_name} placement="top">
                <div>{row.partner_special_menu}</div>
            </StyledTooltip> : null}
        </Fragment>
    ) : '-';
}

function allowUserToBe(row, instance, secondDayClick, accommodationClick) {
    return <Fragment>
        {instance.is_two_day_party ?
            <StyledTooltip title={'Informacje o poprawinach są dla gościa ' + (row.allow_be_present_second_day ? 'widoczne' : 'niewidoczne')}>
                <StyledAllowedCircle onClick={() => secondDayClick(row.id, row.allow_be_present_second_day)} active={row.allow_be_present_second_day}>P</StyledAllowedCircle>
            </StyledTooltip>
            : null}
        {instance.is_accommodation_available ?
            <StyledTooltip title={'Informacje o noclegu są dla gościa '  + (row.allow_selecting_accommodation ? 'widoczne' : 'niewidoczne')}>
                <StyledAllowedCircle onClick={() => accommodationClick(row.id, row.allow_selecting_accommodation)} active={row.allow_selecting_accommodation}>N</StyledAllowedCircle>
            </StyledTooltip>
            : null}
    </Fragment>;
}

export function lastVisitRenderer(row) {
    return row.last_visit ? <span
        title={moment(row.last_visit).format('HH:mm:ss')}>{moment(row.last_visit).format('DD-MM-YYYY').replace(' ', '\n')}</span> : '-';
}

function actionsRenderer(row, instance, deleteParticipant, onUserUpdate) {
    return (
        <Fragment>
            <div style={{display: 'inline-block'}}>
                <UsersListActions user={row}
                                  instance={instance}
                                  onUserUpdate={onUserUpdate}
                                  delete={(callback) => deleteParticipant(row.id, callback)}></UsersListActions>
            </div>
        </Fragment>
    )
}

export function UsersListTable({ searchKeyword, rows, summaryRow, fetchList, setListFromBackend }) {
    const instance = useSelector(state => state.auth.instance);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const widths = {
        short: 50
    };
    const headerHeight = 0;
    const isMobile = useMediaQuery(MediaRule.tablet);

    const desktopColumns = [
        {
            id: 'is_active',
            label: 'Aktywny',
            renderer: (row) => yesOrNo(row.is_active, null, () => updateUserActiveState(row.id, row.is_active), 'Kliknij, aby zmienić. ' + (ENV.IS_PREMIUM ? 'Nieaktywny użytkownik nie będzie mógł się logować.' : 'Nieaktywny użytkownik nie będzie liczony w statystykach.'))
        },
        {
            id: 'declaration',
            summaryIcon: 'people',
            label: 'Deklaracja',
            renderer: (row) => declarationRenderer(row, instance, fetchList)
        },
        {id: 'participant', label: 'Gość', minWidth: 100, renderer: (row) => participantRenderer(row)},
        {
            id: 'present_first_day',
            label: 'Wesele',
            renderer: (row) => yesOrNoIconEditRenderer(row.id, row.present_first_day, instance, fetchList, UserFormView.DECLARATION)
        },
        {
            id: 'present_second_day',
            label: 'Poprawiny',
            visible: !!instance.is_two_day_party,
            renderer: (row) => yesOrNoIconEditRenderer(row.id, row.present_second_day, instance, fetchList, UserFormView.DECLARATION)
        },
        {
            id: 'accommodation',
            label: 'Nocleg',
            visible: !!instance.is_accommodation_available,
            renderer: (row) => yesOrNoIconEditRenderer(row.id, row.accommodation, instance, fetchList, UserFormView.DECLARATION)
        },
        {
            id: 'kids',
            label: 'Dzieci',
            minWidth: widths.short,
            renderer: (row) => row.kids.length ? row.kids.length : null
        },
        {id: 'special_menu', label: 'Menu', minWidth: widths.short, renderer: (row) => menusRenderer(row)},
        {
            id: 'last_visit',
            summaryIcon: 'people',
            label: 'Ostatnie logowanie',
            visible: ENV.IS_PREMIUM,
            minWidth: 100,
            renderer: (row) => lastVisitRenderer(row)
        },
        {
            id: 'allow_to',
            label: 'Opcje',
            minWidth: 61,
            visible: ENV.IS_PREMIUM && (instance.is_two_day_party || instance.is_accommodation_available),
            renderer: (row) => allowUserToBe(row, instance, updateUserAllowBePresentSecondDay, updateUserAllowAccommodation)
        },
        {
            id: 'actions',
            label: '',
            renderer: (row) => actionsRenderer(row, instance, deleteParticipant, fetchList)
        },
    ];
    const mobileColumns = [
        {id: 'participant', label: 'Gość', renderer: (row) => participantRenderer(row, true)},
        {
            id: 'declaration',
            label: 'Deklaracja',
            align: 'right',
            minWidth: widths.short,
            renderer: (row) => yesOrNoOrUnknown(row.present_first_day)
        },
    ];
    const columns = isMobile ? mobileColumns : desktopColumns;

    function updateUserAllowAccommodation(participantId, currentValue) {
        participantService.updateParticipantAccommodationAvailabilityParam(participantId, {
            allow_selecting_accommodation: !currentValue
        }).then(() => {
            fetchList();
        })
    }

    function updateUserAllowBePresentSecondDay(participantId, currentValue) {
        participantService.updateParticipantSecondDayAvailabilityParam(participantId, {
            allow_be_present_second_day: !currentValue
        }).then(() => {
            fetchList();
        })
    }

    function updateUserActiveState(id, currentState) {
        participantService.updateParticipantActiveParam(id, {
            is_active: !currentState
        }).then(() => {
            fetchList();
        })
    }

    function visible(column) {
        return typeof column.visible === 'boolean' ? column.visible : true;
    }

    function filterByKeyword(user) {
        return (user.first_name || '').toLowerCase().includes(searchKeyword) ||
            (user.last_name || '').toLowerCase().includes(searchKeyword) ||
            (user.partner_first_name || '').toLowerCase().includes(searchKeyword) ||
            (user.partner_last_name || '').toLowerCase().includes(searchKeyword);
    }

    function deleteParticipant(id, callback) {
        if (!window.confirm('Jesteś pewien, że chcesz usunąć tego użytkownika?')) return;

        participantService.deleteParticipant(id).then((response) => {
            setListFromBackend(response.data);
            store.dispatch(setSuccess('Usunięto użytkownika.'));
            if (callback) {
                callback();
            }
        })
    }

    function renderSummaryRow() {
        const summaryRowData = {...summaryRow};
        const options = [];

        summaryRowData.is_active = null;
        summaryRowData.declaration += ` / ${rows.length}`;
        summaryRowData.participant = `Razem: ${summaryRow.total_participants} gości`;
        summaryRowData.accommodation = summaryRow.accommodation_beds;
        summaryRowData.last_visit += ` / ${rows.length}`;
        if (instance.is_two_day_party) {
            options.push(summaryRow.allow_be_present_second_day);
        }
        if (instance.is_accommodation_available) {
            options.push(summaryRow.allow_selecting_accommodation);
        }
        summaryRowData.allow_to = options.join(' | ');

        if (isMobile) {
            summaryRowData.declaration = `Obecnych: ${summaryRow.present_first_day}`;
        }

        return (
            <StyledSummaryTableRow>
                {columns.filter(visible).map((column) => {
                    const value = summaryRowData[column.id];
                    return (
                        <TableCell
                            className={'cell'}
                            key={'summary' + column.id}
                            align={column.align}>{value} {column.summaryIcon ? <Icon className={'summary-icon'}>{column.summaryIcon}</Icon> : null}</TableCell>
                    )
                })}
            </StyledSummaryTableRow>
        )
    }

    useEffect(() => {
        fetchList();
    }, []);

    const rowsFiltered = rows.filter(filterByKeyword);

    return (
        <Fragment>
            <StyledTableContainer>
                <StyledTable stickyHeader size="small" className={classnames({mobile: isMobile})}>
                    <TableHead>
                        <TableRow>
                            {columns.filter(visible).map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth, top: headerHeight + 'px'}}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {rowsFiltered.length ? (
                        <TableBody>
                            {summaryRow && rowsFiltered.length > 10 ? renderSummaryRow() : null}
                            {rowsFiltered.map((row, rowIdx) => {
                                return (
                                    <UsersListTableRow key={rowIdx}
                                                       columns={columns}
                                                       row={row}
                                                       visible={visible}
                                                       yesOrNoOrUnknown={yesOrNoOrUnknown}
                                                       actionsRenderer={(row) => actionsRenderer(row, instance, deleteParticipant, fetchList)}></UsersListTableRow>
                                );
                            })}
                            {summaryRow ? renderSummaryRow() : null}
                        </TableBody>
                    ) : null}
                </StyledTable>
                {!rowsFiltered.length ?
                    <StyledEmptyPlaceholder>
                        <p>{searchKeyword ? 'Brak wyników.' : 'Lista jest pusta.'}</p>
                    </StyledEmptyPlaceholder> : null}
            </StyledTableContainer>
        </Fragment>
    )
}
