import React, {Fragment} from 'react';
import {Rect, Text, Transformer, Group} from 'react-konva';
import Seat from "./Seat";
import {SeatEdit} from "./SeatEdit";

export class TableRectangle extends React.Component {
    state = {
        id: null,
        name: null,
        width: 8,
        height: 2,
        isRemoveEnabled: false,
        seatsData: []
    };
    seatsData = [];
    snapSizePx = 20;
    layerPaddingPx = 40;
    isEditMode = false;
    group;
    rect;
    shadowRect;

    constructor(props) {
        super(props);
        this.isEditMode = props.editMode;
        this.snapSizePx = props.snapSize;
        this.layerPaddingPx = 2 * props.snapSize;

        if (props.id) {
            this.state.id = props.id;
        }
        if (props.name) {
            this.state.name = props.name;
        }
        if (props.width) {
            this.state.width = this.calculateTableWidth(props.width, props.height, props.rotation);
        }
        if (props.height) {
            this.state.height = this.calculateTableHeight(props.width, props.height, props.rotation);
        }

        this.state.seatsData = this.props.seatsData || this.generateSeats();
        this.state.isResizeEnabled = this.isEditMode;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.isEditMode) {
            if (this.state.width !== nextProps.width) {
                this.setState({
                    width: nextProps.width,
                    seatsData: this.generateSeats()
                }, () => {
                    this.changeUp();
                })
            }
            if (this.state.height !== nextProps.height) {
                this.setState({
                    height: nextProps.height,
                    seatsData: this.generateSeats()
                }, () => {
                    this.changeUp();
                })
            }
        } else {
            if (this.props.rotation !== nextProps.rotation || this.props.name !== nextProps.name || this.props.seatsData !== nextProps.seatsData) {
                this.setState({
                    name: nextProps.name,
                    width: this.calculateTableWidth(nextProps.width, nextProps.height, nextProps.rotation),
                    height: this.calculateTableHeight(nextProps.width, nextProps.height, nextProps.rotation),
                    seatsData: nextProps.seatsData || this.generateSeats()
                });
            }
        }
    }

    componentDidMount() {
        if (this.props.onDataChange) {
            this.changeUp();
        }
        this.shadowRect.hide();
    }

    calculateTableWidth(width, height, rotation) {
        let result = width;
        if (rotation === 90 || rotation === 270) {
            result = height;
        }
        return result;
    }

    calculateTableHeight(width, height, rotation) {
        let result = height;
        if (rotation === 90 || rotation === 270) {
            result = width;
        }
        return result;
    }

    calculateTableSize(size) {
        return this.snapSizePx * size;
    }

    generateSeats() {
        const width = this.state.width;
        const height = this.state.height;
        const seatSize = 2;
        const horizontalSeatsNumber = Math.round(width / seatSize);
        const verticalSeatsNumber = Math.round(height / seatSize);
        const seats = [];
        [...Array(horizontalSeatsNumber)]
            .forEach((val, index) => seats.push({ id: 'top_' + index, position: 'top', index: index, disabled: false }));
        [...Array(horizontalSeatsNumber)]
            .forEach((val, index) => seats.push({ id: 'bottom_' + index, position: 'bottom', index: index, disabled: false }));

        [...Array(verticalSeatsNumber)]
            .forEach((val, index) => seats.push({ id: 'left_' + index, position: 'left', index: index, disabled: false }));
        [...Array(verticalSeatsNumber)]
            .forEach((val, index) => seats.push({ id: 'right_' + index, position: 'right', index: index, disabled: false }));

        return seats;
    }

    changeUp() {
        this.props.onDataChange({
            width: this.state.width,
            height: this.state.height,
            seats: this.state.seatsData,
        });
    }

    onSeatDisableStateChange(seat, disabled) {
        this.state.seatsData.find((foundSeat) => foundSeat.position === seat.position && foundSeat.index === seat.index)
            .disabled = disabled;
        this.changeUp();
    }

    removeTable() {
        if (window.confirm('Czy na pewno chcesz usunąć ten stół wraz z wszystkimi przypisaniami?')) {
            this.props.onTableRemoveClick(this.props.id);
        }
    }

    convertRealPositionToRotated(position) {
        const positions = ['top', 'right', 'bottom', 'left', 'top', 'right', 'bottom', 'left', 'top', 'right', 'bottom', 'left'];

        if (!this.props.rotation) {
            return position;
        }

        switch (this.props.rotation) {
            case 90:
                position = positions[positions.indexOf(position) + 1];
                break;
            case 180:
                position = positions[positions.indexOf(position) + 2];
                break;
            case 270:
                position = positions[positions.indexOf(position) + 3];
                break;
        }

        return position;
    }

    renderSeats() {
        const seatSize = 2;
        let seatsData = [...this.state.seatsData];
        let seats = seatsData.map((seat) => {
            const seatToRender = {
                ...seat,
            };
            let position = this.convertRealPositionToRotated(seat.position);
            let sizeXY;
            const oneSeatSize = this.snapSizePx * seatSize;

            if (this.props.isLegacy) {
                const reverseOnRotation = {
                    0: ['left', 'bottom'],
                    90: ['top', 'bottom'],
                    180: ['top', 'bottom', 'left', 'right'],
                    270: ['left', 'right'],
                }
                if (this.props.rotation && reverseOnRotation[this.props.rotation].includes(position)) {
                    seat.renderIndex = seatsData.filter((seatM) => position === this.convertRealPositionToRotated(seatM.position)).length - 1 - seat.index;
                    sizeXY = seat.renderIndex * oneSeatSize;
                } else {
                    seat.renderIndex = seat.index;
                    sizeXY = seat.index * oneSeatSize;
                }
            } else {
                if ((position === 'bottom' || position === 'left')) {
                    seat.renderIndex = seatsData.filter((seatM) => position === this.convertRealPositionToRotated(seatM.position)).length - 1 - seat.index;
                    sizeXY = seat.renderIndex * oneSeatSize;
                } else {
                    seat.renderIndex = seat.index;
                    sizeXY = seat.index * oneSeatSize;
                }
            }


            switch (position) {
                case 'top':
                    seatToRender.x = oneSeatSize + sizeXY;
                    seatToRender.y = 0;
                    break;
                case 'bottom':
                    seatToRender.x = oneSeatSize + seat.renderIndex * oneSeatSize;
                    seatToRender.y = this.calculateTableSize(this.state.height) + this.snapSizePx * 2;
                    break;
                case 'left':
                    seatToRender.x = 0;
                    seatToRender.y = oneSeatSize + seat.renderIndex * oneSeatSize;
                    break;
                case 'right':
                    seatToRender.x = this.calculateTableSize(this.state.width) + this.snapSizePx * 2;
                    seatToRender.y = oneSeatSize + sizeXY;
                    break;
            }

            return seatToRender;
        });

        if (!this.isEditMode) {
            seats = seats.filter((seat) => !seat.disabled);
        }

        return seats;
    }

    render() {
        const layerPadding = this.layerPaddingPx;
        return (
            <Fragment>
                <Rect // shadow grid rect
                    ref={(ref) => this.shadowRect = ref}
                    x={0}
                    y={0}
                    width={this.calculateTableSize(this.state.width)}
                    height={this.calculateTableSize(this.state.height)}
                    fill={'#e0b1c3'}
                />

                <Group
                    ref={(ref) => this.group = ref}
                    x={this.props.x || (this.props.stage.width() / 2) - (this.state.width * this.snapSizePx + 4 * this.snapSizePx) / 2}
                    y={this.props.y || (this.props.stage.height() / 2) - (this.state.height * this.snapSizePx + 4 * this.snapSizePx) / 2}
                    draggable={!this.isEditMode}
                    onMouseEnter={() => {
                        if (this.isEditMode) {
                            return;
                        }
                        this.group._orgZIndex = this.group.zIndex();
                        this.group.zIndex(1000);
                        this.setState({
                            isRemoveEnabled: true,
                        }, () => this.props.stage.batchDraw());
                    }}
                    onMouseLeave={() => {
                        if (this.isEditMode) {
                            return;
                        }
                        this.group.zIndex(this.group._orgZIndex || 1);
                        this.setState({
                            isRemoveEnabled: false,
                        }, () => this.props.stage.batchDraw());
                    }}
                    onTransformEnd={e => {
                        if (this.props.tableRotationChange) {
                            this.props.tableRotationChange(this.props.id, this.group.attrs.rotation);
                        }
                    }}
                    onDragMove={() => {
                        this.shadowRect.show();
                        this.shadowRect.position({
                            x: layerPadding + Math.round(this.group.x() / this.snapSizePx) * this.snapSizePx,
                            y: layerPadding + Math.round(this.group.y() / this.snapSizePx) * this.snapSizePx
                        });
                        this.props.stage.batchDraw();
                    }}
                    onDragEnd={(e) => {
                        this.group.position({
                            x: Math.round(this.group.x() / this.snapSizePx) * this.snapSizePx,
                            y: Math.round(this.group.y() / this.snapSizePx) * this.snapSizePx
                        });
                        this.shadowRect.hide();
                        this.props.stage.batchDraw();

                        if (this.props.tablePositionChange) {
                            this.props.tablePositionChange(this.props.id, this.group.attrs.x, this.group.attrs.y);
                        }
                    }}
                >

                    <Rect // main rect
                        ref={(ref) => this.rect = ref}
                        x={this.snapSizePx * 2}
                        y={this.snapSizePx * 2}
                        fill={'#fff'}
                        cornerRadius={4}
                        width={this.calculateTableSize(this.state.width)}
                        height={this.calculateTableSize(this.state.height)}
                        strokeWidth={2}
                        stroke={'#999'}
                        centeredScaling={true}
                        onTransform={e => {
                            this.rect.setAttrs({
                                width: this.rect.width() * this.rect.scaleX(),
                                height: this.rect.height() * this.rect.scaleY(),
                                scaleX: 1,
                                scaleY: 1,
                            });
                        }}
                        onTransformEnd={e => {
                            this.setState({
                                width: this.rect.width() / this.snapSizePx,
                                height: this.rect.height() / this.snapSizePx,
                                seatsData: this.generateSeats()
                            }, () => {
                                this.changeUp();
                            });
                        }}
                    />

                    {this.renderSeats().map((seat) => this.props.editMode ? (<SeatEdit
                        snapSize={this.snapSizePx}
                        x={seat.x}
                        y={seat.y}
                        // rotation={-this.props.rotation}
                        onDisableStateChange={this.onSeatDisableStateChange.bind(this, seat)}/>) : (
                        !seat.disabled ? (<Seat
                            key={seat.id}
                            //rotation={-this.props.rotation}
                            seatId={seat.id}
                            onSeatClick={this.props.onSeatClick}
                            tableId={this.props.id}
                            tables={this.props.tables}
                            snapSize={this.snapSizePx}
                            assignment={this.props.seatAssignments.find((assignment) => assignment.id_seat === seat.id && assignment.id_table === this.props.id)}
                            x={seat.x}
                            y={seat.y} />) : null
                    ))}

                    <Group
                        // rotation={-this.props.rotation}
                        x={this.snapSizePx * 2}
                        y={this.snapSizePx * 2}>
                        <Text ref={(ref) => this.nameRef = ref}
                                verticalAlign={'middle'}
                              width={this.calculateTableSize(this.state.width)}
                              height={this.calculateTableSize(this.state.height)}
                              align={'center'}
                              text={this.props.name}/>
                        <Text fontFamily={'Material Icons'}
                              text={'edit'}
                              fontSize={18}
                              x={this.calculateTableSize(this.state.width) - 20}
                              y={3}
                              fill={this.state.isRemoveEnabled ? '#444' : '#ccc'}
                              verticalAlign={'middle'}
                              onTap={(e) => this.props.onActionsClick({
                                  id: this.state.id,
                                  x: e.evt.clientX,
                                  y: e.evt.clientY,
                                  rotateEnabled: true,
                                  restoreSeatsEnabled: this.state.seatsData.some((seat) => seat.disabled)
                              })}
                              onClick={(e) => {
                                  this.props.onActionsClick({
                                      id: this.state.id,
                                      x: e.evt.clientX,
                                      y: e.evt.clientY,
                                      rotateEnabled: true,
                                      restoreSeatsEnabled: this.state.seatsData.some((seat) => seat.disabled)
                                  });
                              }}
                              align={'center'}
                        />
                    </Group>

                </Group>

            </Fragment>
        );
    }
}
