import React, { Component, Fragment } from 'react';
import styled, {css, keyframes} from 'styled-components';
import MenuList from '@material-ui/core/MenuList';
import Tooltip from '@material-ui/core/Tooltip';
import WEDDING_CONFIG from '../../../../wedding-config';
import {darken} from 'polished';
import { connect } from 'react-redux';
import store from '../../../../store';
import mainMenuConfig from './MainMenuConfig';
import Icon from '@material-ui/core/Icon';
import { setActivePage } from '../../../../actions';
import MediaSizes, {MediaHeights} from '../../../../constants/media-sizes.constants';
import menuService from '../../../../service/menu.service';
import Hidden from "@material-ui/core/Hidden";
import {withTranslation} from "react-i18next";
const colors = WEDDING_CONFIG.colors;

const StyledMenuList = styled(MenuList)`
    && {
        position: static;
        list-style-type: none;
        display: inline-block;
        left: 0;
        bottom: 0;
        width: 100%;
        font-size: 2rem;
        text-align: left;
       
       @media (max-width: ${MediaSizes.tablet}px) {
         position: static;
         width: 100%;

          li {
            display: inline-block;
          }
        }
        
        @media (max-width: ${MediaSizes.phone}px) {
          transform: none;
          width: 100%;
          height: auto;
          background: ${colors.primary};
          position: relative;
          bottom: unset;
          left: 0;
          
          &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            height: 30px;
            transform: translateY(100%);
            border-style: solid;
            border-width: 0 100vw 40px 0;
            border-color: transparent ${colors.primary} transparent transparent;
          }
        }
        
        @media (min-height: ${MediaHeights.sm}px) {
          bottom: 100px;
          font-size: 2.4rem;
        }
    }
`;

const StyledLink = styled.div`
    color: #eee;
    border-bottom: solid 0px #da5d7d;
    padding: 13px 25px 18px 25px;
    font-size: 1.1rem;
    cursor: pointer;
    //letter-spacing: 1px;
    transition: color 0.1s ease-in;
    margin: 0;
    border-radius: 5px;
    
    &:hover {
        color: #ddd;
    }
    
    ${props => {
    if (props.active) {
        return css`
            background: ${colors.altWhite};
            color: ${colors.primary};
            
            &:hover {
                color: ${darken(0.1, colors.primary)};
            }
            `;
    }
}}
    
   @media (max-width: ${MediaSizes.tablet}px) {
      border-radius: 5px;
      font-size: 1.2rem;
      padding: 10px;
      margin-bottom: 2px;
      color: white;
    }
`;

const StyledIcon = styled(Icon)`
  position: relative;
  top: 3px;
  left: -7px;
`;

function CustomMenuItem({ that, text, to, tooltip, icon, page, onClick, zindex, active}) {
    let item = (
        <StyledLink onClick={onClick.bind(that)} page={page} to={to} active={active}>
            <StyledIcon className="icon" fontSize="medium">{icon}</StyledIcon> {text}
        </StyledLink>
    );

    return (
        tooltip ?
            <Tooltip
                title={tooltip}
                placement="bottom">
                <span>{item}</span>
            </Tooltip>
            :
            <li>
                {item}
            </li>
    );
}

class MainMenuInPage extends Component {

    state = {
        menuItems: [],
    };

    componentDidMount() {
        const menuItems = menuService.getMenuItems();

        this.setState({
            menuItems: menuItems
                .filter(page => page !== 'DOWNLOAD_PDF_SUMMARY')
                .map((page) => mainMenuConfig.config[page])
                .filter(page => page)
        })
    }

    menuItemClick(page) {
        store.dispatch(setActivePage(page));
    }

    render() {
        return (
            <Fragment>
                <Hidden smDown>
                    <StyledMenuList>
                        {this.state.menuItems.map((item, i) => (
                            <CustomMenuItem
                                key={`${i}menuitem`}
                                zindex={item.zIndex}
                                that={this}
                                onClick={() => this.menuItemClick(item.page)}
                                page={item.page}
                                alt={this.props.state ? 1 : 0}
                                text={this.props.t(item.text)}
                                to={item.to}
                                icon={item.icon}
                                active={this.props.app.activePage === item.page ? 1 : 0}
                                disabled={item.disabled ? '' : null}
                            />
                        ))}
                    </StyledMenuList>
                </Hidden>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        instance: state.auth.instance
    }
};

export default withTranslation()(connect(mapStateToProps)(MainMenuInPage));
