import React, {Component} from 'react';
import styled from 'styled-components';
import {lighten, darken} from 'polished';
import Icon from '@material-ui/core/Icon';
import {CommonOutlinedButton} from '../../../common/StyledButton';

import WEDDING_CONFIG from '../../../wedding-config';
import PremiumLabel from "./PremiumLabel";
import ENV from "../../../config/environment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../constants/media-sizes.constants";

const colors = WEDDING_CONFIG.colors;

const StyledButton = styled(CommonOutlinedButton)`
    && {
        border: 0;
    //border-width: 1px;
      display: inline-flex;
      margin-right: 10px;
      padding: 0;
    background: ${colors.primaryLight};
    // background: ${lighten(0.03, colors.primary)};
     border-bottom: 2px solid ${darken(0.1, colors.primary)};
    color: white;
    letter-spacing: 0;
    border-radius: 2px;
    transition: background 0.2s ease-in, color 0.2s ease-in;
    
    .icon-box {
        padding: 8px 14px;
    }
    
    .text {
        padding: 14px 18px 14px 0;
    }
    
    //box-shadow: 1px 1px 0px 1px #ddd;
    background: #f7f7f7;
    border-bottom: 2px solid #b7b7b7;
    border-right: 1px solid #ddd;
    color: #992652;
    
    &:hover {
      border-bottom: 2px solid #992652;
      border-right: 1px solid #ddd;
      background: #eee;
      color: #992652;
    }
        
        &[href] span {
          //padding-top: 10px;
        }
        
        &[disabled] {
          border-color: #b7b7b7;
          background: #dcdcdc;
        }
        
        .premium-label {
          display: none;
          position: absolute;
          top: -8px;
          right: -8px;
        }
        
        &.locked-for-premium {
          color: ${colors.secondary};
          border-color: ${colors.secondary};
          
          .premium-label {
            display: inline;
            border: 1px solid transparent;
          }
          
          &:hover {
            color: white;
            background: ${colors.secondary};
            
            .premium-label {
              background: ${colors.secondary};
              border-color: white;
            }
          }
        }
        
        &.mobile {
          margin-right: 0;
          flex: 1;
          .MuiButton-label {
            flex-direction: column;
          }
            .icon-box {
                font-size: 1.5rem;
            }
            
            .text {
                padding: 0 14px 10px;
            }
        }
    }
`;

const StyledIcon = styled(Icon)`
    && { 
      vertical-align: middle;
      font-size: 1.4em; 
    }
`;

export default function ActionsPanelButton({icon, text, className, visible, ...other}) {
    const isMobile = useMediaQuery(MediaRule.tablet);

    if (visible === false) {
        return null;
    }
    return (
        <StyledButton className={`action ${isMobile ? 'mobile' : ''} ${className || ''}`} {...other}>
            <div className={'icon-box'}><StyledIcon>{icon}</StyledIcon></div>
            <div className={'text'}>{text}</div>
            {!ENV.IS_PREMIUM ? (<PremiumLabel primary></PremiumLabel>) : null}
        </StyledButton>
    )
}
