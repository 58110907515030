import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './App';
import registerServiceWorker, {isLocalhost, unregister} from './registerServiceWorker';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import store from "./store";
import { Provider } from "react-redux";
import api from './api';
import ENV from './config/environment';
import { SnackbarProvider } from 'notistack';
import WEDDING_CONFIG from "./wedding-config";
import ScrollToTop from "./ScrollTop";
import './i18n';

const theme = createMuiTheme({
    typography: {
        "fontFamily": "\"PT Sans Regular\", \"Helvetica\", \"Arial\", sans-serif",
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: WEDDING_CONFIG.colors.primaryLight,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: WEDDING_CONFIG.colors.primaryLight,
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#fff',
        },
        // error: will use the default color
    },
});

function getInstance() {
    let name;

    if (isLocalhost && ENV.INSTANCE_DEBUG_NAME) {
        name = ENV.INSTANCE_DEBUG_NAME;
    } else {
        name = window.location.host.split('.')[0];
    }

    return api.request({
        url: api.routes.instance,
        method: 'get',
        headers: {
            'hw-instance-name': name
        }
    })
}

function renderApp(errorMessage) {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <MuiThemeProvider theme={theme}>
                    <ScrollToTop />

                    <SnackbarProvider
                        maxSnack={1}
                        classes={{
                            variantSuccess: 'snackbar-ok'
                        }}>
                        <App errorMessage={errorMessage} />
                    </SnackbarProvider>
                </MuiThemeProvider>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    );
    registerServiceWorker();
}

if (ENV.IS_PREMIUM) {
    getInstance()
        .then((response) => {
            if (response.data.instance) {
                api.request.defaults.headers.common['hw-instance'] = response.data.instance;
                api.request.defaults.validateStatus = function (status) {
                    return status < 400; // Reject only if the status code is greater than or equal to 500
                };
                document.title = response.data.name + ' | ' + document.title;
                renderApp(response.data.reason);
            } else {
                if (process.env.NODE_ENV === 'production' && window.location.host !== '') {
                    window.location.href = 'https://www.fancy.wedding';
                }
            }
        }).catch((e) => {
            console.error(e);
            if (process.env.NODE_ENV === 'production') {
                window.location.href = 'https://www.fancy.wedding';
            }
    });
} else {
    renderApp();
}
