import tableService from '../service/table.service';
import store from "../store";

export const TABLE_ACTION = {
    SET_SELECTED_SET_ID: 'SET_SELECTED_SET_ID',
    SET_TABLES_DATA: 'SET_TABLES_DATA',
    SET_TABLES_INFO: 'SET_TABLES_INFO',
    UPDATE_TABLE: 'UPDATE_TABLE',
    SET_USER_LIST: 'SET_USER_LIST',
    SET_AVAILABLE_USERS_LIST: 'SET_AVAILABLE_USERS_LIST',
    SET_ASSIGNMENTS: 'SET_ASSIGNMENTS',
    ADD_ASSIGNMENT: 'ADD_ASSIGNMENT',
    SWAP_ASSIGNMENT: 'SWAP_ASSIGNMENT',
    REMOVE_ASSIGNMENT: 'REMOVE_ASSIGNMENT',
    EDIT_NAME: 'EDIT_NAME',
};

export const setSelectedSetId = (id) => ({
    type: TABLE_ACTION.SET_SELECTED_SET_ID,
    id
});

export const setUsersList = (usersList) => ({
    type: TABLE_ACTION.SET_USER_LIST,
    usersList
});

export const setTablesData = (tables) => (dispatch) => {
    dispatch({
        type: TABLE_ACTION.SET_TABLES_DATA,
        tables
    });
    dispatch(setTablesInfo());
};

export const setTablesInfo = () => ({
    type: TABLE_ACTION.SET_TABLES_INFO,
});

export const setAvailableUsersList = () => ({
    type: TABLE_ACTION.SET_AVAILABLE_USERS_LIST,
});

const setAssignmentsToStore = (assignments) => ({
    type: TABLE_ACTION.SET_ASSIGNMENTS,
    assignments
});

export const setAssignments = (assignments) => (dispatch) => {
    dispatch(setAssignmentsToStore(assignments));
    dispatch(setAvailableUsersList());
};

export const addAssignment = (idSet, assignment) => (dispatch) => {
    dispatch(() => tableService.assignParticipant(idSet, assignment))
        .then((response) => {
            dispatch(setAssignmentsToStore(response.data));
            // dispatch(addAssignmentToStore(response.data));
            dispatch(setAvailableUsersList());
        });
};

export const swapAssignment = (idSet, data) => (dispatch) => {
    return dispatch(() => tableService.swapParticipant(idSet, data))
        .then((response) => {
            dispatch(setAssignmentsToStore(response.data));
            dispatch(setAvailableUsersList());
        });
};

const removeAssignmentFromStore = (assignment) => ({
    type: TABLE_ACTION.REMOVE_ASSIGNMENT,
    assignment
});

export const removeAssignment = (idSet, assignment) => (dispatch) => {
    dispatch(() => tableService.removeParticipant(idSet, assignment))
        .then(() => {
            dispatch(removeAssignmentFromStore(assignment));
            dispatch(setAvailableUsersList());
        });
};

const editTableInStore = (tableId, tableNewName) => ({
    type: TABLE_ACTION.EDIT_NAME,
    tableId,
    tableNewName,
});

export const editTable = (idSet, tableId, tableNewName) => (dispatch) => {
    return dispatch(() => tableService.editTableName(idSet, tableId, tableNewName)).then((response) => {
        store.dispatch(editTableInStore(tableId, tableNewName));
    });
};

export const updateTableInStore = (tableId, newTableObject) => (dispatch) => {
    dispatch({
        type: TABLE_ACTION.UPDATE_TABLE,
        tableId,
        newTableObject,
    });
    dispatch(setTablesInfo());
}
