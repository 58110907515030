import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AttendeesForm } from '../forms/AttendeesForm';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import WEDDING_CONFIG from '../../../wedding-config';
import store from '../../../store';
import { setMainModalActiveStep, toggleMainModal } from '../../../actions';
import DeclarationThankYouDialog from './DeclarationThankYouDialog';
import MediaSizes from '../../../constants/media-sizes.constants';
import Hidden from '@material-ui/core/Hidden';

const colors = WEDDING_CONFIG.colors;

const StyledDialog = styled(Dialog)`
    && {
    }
`;

const StyledDialogActions = styled(DialogActions)`
    && {
        margin: 0;
    }
`;

const StyledMobileStepper = styled(MobileStepper)`
    && {
        width: 100%;
    }
`;

const StyledNextButton = styled(Button)`
    div {
        display: inline-block;
        padding: 20px;
        font-size: 40px;
        text-transform: lowercase;
        color: ${colors.primary}
    }
    
    &[disabled] div {
        color: #888;
    }
    
    @media (max-width: ${MediaSizes.tablet}px) {
        div {
            font-size: 1.7rem;
            padding: 5px;
        }
    }
`;

const StyledPrevButton = styled(Button)`
    font-size: 40px;
    div {
        display: inline-block;
        padding: 20px;
        
        font-size: 20px;
        text-transform: lowercase;
    }
    
    @media (max-width: ${MediaSizes.tablet}px) {
        div {
            font-size: 1rem;
            padding: 2px;
        }
    }
   
`;

const StyledDialogContent = styled(DialogContent)`
    && {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        box-sizing: border-box;
        
      @media (max-width: ${MediaSizes.phone}px) {
        padding-top: calc(20px + env(safe-area-inset-top))!important;
      }
    }
`;

class PartnerNameDialog extends Component {
    state = {
        nextButtonDisabled: false
    };
    attendeesForm = React.createRef();
    initialStepsArray = [
        'greeting',
        'participation',
        'partners',
        'additional',
        'summary'
    ];

    componentDidMount() {
        this.setState({
            steps: this.initialStepsArray
        })
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isMainModalOpened: props.app.isMainModalOpened,
            mainModalActiveStep: props.app.mainModalActiveStep,
            mainModalMaxSteps: props.app.mainModalMaxSteps
        }
    }

    submitForm() {
        this.attendeesForm.form.submitForm();
    }


    handleNext = () => {
        let newStep,
            formData;

        if (this.props.app.mainModalActiveStep === this.props.app.mainModalMaxSteps - 1) {
            // submit
            this.submitForm();
            return;
        }

        formData = this.attendeesForm.form.state.values;

        if (this.props.app.mainModalActiveStep === 1 && !(formData.present_first_day || formData.present_second_day)) {
            newStep = this.props.app.mainModalActiveStep + 3;
        } else {
            newStep = this.props.app.mainModalActiveStep + 1;
        }

        store.dispatch(setMainModalActiveStep(newStep));

        if (newStep === this.props.app.mainModalMaxSteps - 1) {
            // next step disabled
            this.setState({
                nextButtonDisabled: true
            });
            setTimeout(() => {
                this.setState({
                    nextButtonDisabled: false
                });
            }, 3000)
        }
    };

    handleBack = () => {
        if (this.props.app.mainModalActiveStep === 0) {
            store.dispatch(toggleMainModal(false));
            return;
        }
        let formData = this.attendeesForm.form.state.values,
            newStep;

        if (this.props.app.mainModalActiveStep === 4 && !formData.present_first_day) {
            newStep = this.props.app.mainModalActiveStep - 3;
        } else {
            newStep = this.props.app.mainModalActiveStep - 1;
        }

        store.dispatch(setMainModalActiveStep(newStep));
    };

    render() {
        const activeStep = this.props.app.mainModalActiveStep;
        return (
            <div>
                <StyledDialog
                    open={this.props.app.isMainModalOpened || false}
                    onClose={this.handleClose}
                    classes={{paper: 'no-slide', root: 'no-slide'}}
                    aria-labelledby="form-dialog-title"
                    scroll="paper"
                    fullWidth={false}
                    fullScreen={true}
                >
                    <StyledDialogContent>
                        <AttendeesForm activeStep={activeStep} ref={instance => { this.attendeesForm = instance; }} />
                    </StyledDialogContent>
                    <StyledDialogActions>
                        <StyledMobileStepper
                            variant="dots"
                            steps={this.props.app.mainModalMaxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <StyledNextButton size="small" onClick={this.handleNext} disabled={this.state.nextButtonDisabled}>

                                    <div className="font-secondary mobile-phone-only">
                                        {activeStep === 4 ? (
                                            <Fragment>
                                                <Hidden xsDown>
                                                    Potwierdzam
                                                </Hidden>
                                                <Hidden smUp>
                                                    OK
                                                </Hidden>
                                            </Fragment>
                                        ) : 'dalej'}
                                    </div>
                                    <KeyboardArrowRight />
                                </StyledNextButton>
                            }
                            backButton={
                                <StyledPrevButton size="small" onClick={this.handleBack}>
                                    <KeyboardArrowLeft />
                                    <div>{activeStep === 0 ? "wróć": "cofnij"}</div>
                                </StyledPrevButton>
                            }
                        />
                    </StyledDialogActions>
                </StyledDialog>
                <DeclarationThankYouDialog />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user
    }
};

const ConnectedPartnerNameDialog = connect(mapStateToProps)(PartnerNameDialog);
export { ConnectedPartnerNameDialog as PartnerNameDialog };
