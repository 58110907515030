import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled, {css} from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slider from '@material-ui/core/Slider';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik } from 'formik';
import {Layer, Line, Stage} from "react-konva";
import {TableRectLegacy} from "./tables-setup/TableRectLegacy";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {TableCircle} from "./tables-setup/TableCircle";
import TextInput from "../partials/TextInput";
import tableService from "../../../service/table.service";
import {CommonOutlinedButton} from "../../../common/StyledButton";
import SimpleDialog from "../../../common/SimpleDialog";

const colors = WEDDING_CONFIG.colors;

const StyledDialogContent = styled(DialogContent)`
  && {
    overflow: visible;
  }
`;
const StyledSlider = styled(Slider)`
  && {
    position: absolute;
    z-index: 1000;

    ${props => {
        const value = props.snapSize * 4;
        
        return css`
            &.left {
              height: calc(100% - ${value * 2}px);
              bottom: ${value}px;
              left: 5px;
            }
            &.bottom {
              width: calc(100% - ${value * 2}px);
              left: ${value}px;
              bottom: 5px;
            }
        `;
    }}
  }
`;

const StyledTotalSeats = styled.span`
  font-size: 1rem;
  color: ${colors.primaryLight};
  position: absolute;
  top: 5px;
  right: 20px;
  
  .big {
    font-size: 2.9rem;
    margin-left: 6px;
  }
`;

class TableSetupDialog extends Component {
    state = {
        open: true,
        stageWidth: 500,
        sliderCircleValue: 3,
        sliderBottomValue: 3,
        sliderLeftValue: 1,
        totalSeats: 0,
        isLoading: false
    };
    form;
    container = React.createRef();

    componentDidMount() {
    }

    setLoading(value) {
        this.setState({
            isLoading: value
        });
    }

    submitForm() {
        this.form.submitForm();
    }

    calculateTotalSeats(type, data) {
        let seats = data.seats.filter((seat) => !seat.disabled).length;

        this.setState({
            totalSeats: seats
        })
    }

    render() {
        return (
            <SimpleDialog
                title={'Dodaj stół'}
                open={this.props.open}
                onClose={this.props.handleClose}
                fullWidth={true}
                maxWidth={'lg'}
                aria-labelledby="form-dialog-title">
                <Formik
                    ref={node => (this.form = node)}
                    initialValues={{
                        table_name: '',
                        type: null,
                        data: null,
                    }}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        this.setLoading(true);
                        tableService.addTable(this.props.tables.selectedTableSetId, values).then(() => {
                            this.setLoading(false);
                            this.props.handleClose();
                        }).catch((e) => {
                            this.setLoading(false);
                        });
                    }}>
                    {({values, handleChange, dirty, setFieldValue}) => {
                        const snapSize = 20;
                        const width = this.state.stageWidth;
                        const height = width;

                        return (
                            <Fragment>
                                <StyledDialogContent>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={8} lg={6} style={{position: 'relative'}}>
                                            <div style={{width: this.state.stageWidth + 'px', position: 'relative'}}>
                                                {typeof width === 'number' ? (
                                                    <Stage
                                                        ref={(ref) => this.stage = ref}
                                                        width={width}
                                                        height={height}>
                                                        <Layer>
                                                            {[...Array(Math.round(width / snapSize))].map((value, i) => (
                                                                <Line
                                                                    key={i}
                                                                    points={[snapSize * i, 0, snapSize * i, height]}
                                                                    strokeWidth={0.5}
                                                                    stroke={'#ddd'} />
                                                            ))}
                                                            {[...Array(Math.round(height / snapSize))].map((value, i) => (
                                                                <Line
                                                                    key={i}
                                                                    points={[0,snapSize * i,width,snapSize * i]}
                                                                    strokeWidth={0.5}
                                                                    stroke={'#ddd'} />
                                                            ))}
                                                        </Layer>
                                                        <Layer>
                                                            {values.type === 'rect' ? (<TableRectLegacy
                                                                snapSize={snapSize}
                                                                stage={this.stage}
                                                                width={this.state.sliderBottomValue * 2}
                                                                height={this.state.sliderLeftValue * 2}
                                                                onDataChange={(data) => {
                                                                    setFieldValue('data', data);
                                                                    this.calculateTotalSeats('rect', data);
                                                                }}
                                                                editMode={true} />) : null}
                                                            {values.type === 'circle' ? (<TableCircle
                                                                snapSize={snapSize}
                                                                stage={this.stage}
                                                                seatsNumber={this.state.sliderCircleValue}
                                                                onDataChange={(data) => {
                                                                    setFieldValue('data', data);
                                                                    this.calculateTotalSeats('circle', data);
                                                                }}
                                                                editMode={true} />) : null}
                                                        </Layer>
                                                    </Stage>
                                                ) : null}
                                                {values.type === 'circle' ? (
                                                    <StyledSlider
                                                        snapSize={snapSize}
                                                        className={'bottom'}
                                                        defaultValue={this.state.sliderCircleValue >= 3 ? this.state.sliderCircleValue : 3}
                                                        valueLabelDisplay="auto"
                                                        step={1}
                                                        min={3}
                                                        max={15}
                                                        onChange={(ev, val) => {
                                                            if (val === this.state.sliderCircleValue) {
                                                                return;
                                                            }
                                                            this.setState({
                                                                sliderCircleValue: val
                                                            });
                                                        }}
                                                    />
                                                ) : null}
                                                {values.type === 'rect' ? (
                                                    <Fragment>
                                                        <StyledSlider
                                                            snapSize={snapSize}
                                                            className={'bottom'}
                                                            defaultValue={this.state.sliderBottomValue}
                                                            valueLabelDisplay="auto"
                                                            step={1}
                                                            min={1}
                                                            max={10}
                                                            onChange={(ev, val) => {
                                                                if (val === this.state.sliderBottomValue) {
                                                                    return;
                                                                }
                                                                this.setState({
                                                                    sliderBottomValue: val
                                                                });
                                                            }}
                                                        />
                                                        <StyledSlider
                                                            snapSize={snapSize}
                                                            className={'left'}
                                                            orientation={'vertical'}
                                                            defaultValue={this.state.sliderLeftValue}
                                                            valueLabelDisplay="auto"
                                                            step={1}
                                                            min={1}
                                                            max={10}
                                                            onChange={(ev, val) => {
                                                                this.setState({
                                                                    sliderLeftValue: val
                                                                });
                                                            }}
                                                        />
                                                    </Fragment>
                                                ) : null}
                                                {values.type ? (
                                                    <StyledTotalSeats>
                                                        <span>łącznie miejsc</span>
                                                        <span class="big">{this.state.totalSeats}</span>
                                                    </StyledTotalSeats>
                                                ) : null}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={6}>
                                                <h3>1. Wybierz typ stołu</h3>
                                                <div>
                                                    <RadioGroup aria-label="type" name="type" value={values.type} onChange={handleChange} row>
                                                        <FormControlLabel value="rect" control={<Radio />} label="Prostokątny" />
                                                        <FormControlLabel value="circle" control={<Radio />} label="Okrągły" />
                                                    </RadioGroup>
                                                </div>
                                            {values.type ? (
                                                <Fragment>
                                                    <h3>2. Dostosuj rozmiar</h3>
                                                    <p>Użyj suwaków po lewej stronie, aby dostosować liczbę miejsc siedzących.<br/>
                                                    Kliknij w dane miejsce, aby je ukryć.</p>

                                                    <h3>3. Podaj krótką nazwę</h3>
                                                    <TextInput
                                                        name="table_name"
                                                        key="table_name"
                                                        value={values.table_name}
                                                        placeholder={'...lub numer'}
                                                        onChange={handleChange}
                                                    />
                                                </Fragment>
                                            ) : null}
                                        </Grid>
                                    </Grid>


                                </StyledDialogContent>
                                <DialogActions>
                                    <CommonOutlinedButton onClick={this.props.handleClose} color="secondary">
                                        Anuluj
                                    </CommonOutlinedButton>
                                    <CommonOutlinedButton className={'fill-primary'} disabled={!values.table_name || !values.type || this.state.isLoading} onClick={this.submitForm.bind(this)} color="primary">
                                        Zapisz
                                    </CommonOutlinedButton>
                                </DialogActions>
                            </Fragment>
                        )
                    }}
                </Formik>

            </SimpleDialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        instance: state.auth.instance,
        tables: state.tables
    }
};

export default connect(mapStateToProps)(TableSetupDialog);
