import React, { Component } from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { InlineDatePicker } from 'material-ui-pickers';

const colors = WEDDING_CONFIG.colors;

const StyledControl = (el) => styled(el)`
    && {
        font-size: 1.2rem;
        margin-top: 18px;
        border-bottom: 2px solid #eee;
        transition: border-color 0.15s ease-in;
        //width: 300px;

        & > div:before, & > div:after {
          content: none;
        }
        &:hover {
          border-color: #bbb;
        }
    }
`;

export const StyledInputBase = StyledControl(InputBase);

const StyledDatePicker = StyledControl(InlineDatePicker);


const StyledInputLabel = styled(InputLabel)`
    && {
      font-size: 1.2rem;
    }
`;

export default class TextInput extends Component {
    render() {
        const { component, width, label, ...inputParams } = this.props;
        return (
            <FormControl style={{width: '100%'}}>
                <StyledInputLabel shrink>
                    {label}
                </StyledInputLabel>
                {component === 'DatePicker' ? (
                    <StyledDatePicker
                        onlyCalendar
                        {...inputParams}
                        width={width}
                        mask={value =>
                            // handle clearing outside if value can be changed outside of the component
                            value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                        }
                    />
                ) : (
                    <StyledInputBase
                        variant="filled"
                        {...inputParams}
                        width={width}
                    />
                )}
            </FormControl>
        )
    }
}
