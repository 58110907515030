import styled from "styled-components";
import {MediaHeights} from "../../../constants/media-sizes.constants";
import {isPWA} from "../../../utilities";

const isPwa = isPWA();

export const PageWrapper = styled.div`
    text-align: left;
    max-width: 1200px;
    padding: 10px 15px 0 15px;
    margin: 0 auto;
        //box-shadow: 0px 4px 8px #a0a0a0;
        
    
    &.content {
        @media (max-height: ${MediaHeights.sm}px) {
          padding-top: 16px;
        }
    }

    @media (max-height: ${MediaHeights.sm}px) {
    ${isPwa ? `
        padding: 35px 15px 0 15px;
    ` : null}
    }
`;

export const PageFullWidthWrapper = styled(PageWrapper)`
    max-width: 1620px;
`;
