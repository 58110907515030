import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import MediaSizes from '../../../constants/media-sizes.constants';
import ENV from "../../../config/environment";
import {LoginLiteForm} from "./LoginLiteForm";
import {LoginPremiumForm} from "./LoginPremiumForm";
import {StyledCard} from "../../../common/StyledCard";

const animFade = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
  
`;

const LoginFormContainer = styled.div`
    margin: 0 auto;
    opacity: 0;
    width: 350px;
    max-width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
    animation: ${animFade} 1.5s ease-in forwards;
    animation-delay: 0.5s;
    position: absolute;
    left: 50%;
    top: calc(50% - 100px);
    transform: translate(-50%, -50%);
    
    &.premium {
      width: 450px;
    } 
    
    @media (max-width: ${MediaSizes.phone}px) {
        position: static;
        transform: none;
        margin-top: 30px;
    }
`;

const LoadingIcon = styled(CircularProgress)`
    && {
        float: right;
    }
`;

const StyledLogoContainer = styled.div`
    margin-bottom: 60px;
    text-align: center;
    
    @media (max-width: ${MediaSizes.phone}px) {
        height: 27vh;
        position: relative;
    }
`;

const StyledLogo = styled.img`
    width: 180px;
    max-width: 50vw;
    
    @media (max-width: ${MediaSizes.phone}px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

class Login extends Component {
    state = {
        isAuthenticated: false,
        loginState: 'init',
        keyInvalid: false,
        isSubmitting: false,
        passwordDetected: false,
        hasCodeInUrl: false
    };
    loginMessages = {
        init: 'Wpisz swój kod',
        password: 'Wpisz hasło',
        error: '',
        success: 'Super!',
        logging: 'Logowanie...'
    };
    shouldAutoSubmit = false;

    constructor(props) {
        super(props);

        this.handleCodeParam();
    }

    handleCodeParam() {
        const queryString = window.location.search ? window.location.search.slice(1) : null;
        let queryParams, queryParamsObj = {};

        if (queryString) {
            queryParams = queryString.split('&');
            queryParams.forEach((paramString) => {
                queryParamsObj[paramString.split('=')[0]] = paramString.split('=')[1];
            });

            if (queryParamsObj && queryParamsObj.code && queryParamsObj.code.length === this.keyLength) {
                localStorage.removeItem('token');
                this.state.hasCodeInUrl = true;
                this.shouldAutoSubmit = true;
                queryParamsObj.code.split('').forEach((keyValue, i) => {
                    this.initialKeyValues['key' + i] = keyValue;
                    this.keyValue['key' + i] = keyValue;
                });
            }
        }
    }

    buildMainMessage() {
        let result = this.loginMessages[this.state.loginState];

        if(this.state.loginState === 'error' && this.props.errorMessage) {
            result = this.props.errorMessage;
        }

        return result;
    }

    renderLoadingIcon() {
        return (
            ['logging', 'success'].indexOf(this.state.loginState) !== -1 ? <LoadingIcon size={30}/> : ''
        )
    }

    render() {
        return (
            <LoginFormContainer className={`${ENV.IS_PREMIUM ? 'premium' : ''}`}>
                <StyledLogoContainer>
                    <a href="https://fancy.wedding" target="_blank">
                        <StyledLogo src="/logo-fancywedding-sm.png" />
                    </a>
                </StyledLogoContainer>

                {ENV.IS_PREMIUM ? (<LoginPremiumForm />) : <LoginLiteForm />}
            </LoginFormContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.auth.user,
        authUser: state.auth.user,
        errorMessage: state.auth.error || null
    }
};


const ConnectedLoginPage = connect(mapStateToProps)(Login);
export { ConnectedLoginPage as Login };
