import React, {Fragment} from "react";
import ActionsPanel from "../partials/ActionsPanel";
import ActionsPanelButton from "../partials/ActionsPanelButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MediaSizes, {MediaRule} from "../../../constants/media-sizes.constants";
import styled from "styled-components";

export const DesktopActionsWrapper = styled.div`
    @media (min-width: ${MediaSizes.tabletPro}px) {
          margin-bottom: 20px;
    }
`;

export default function DesktopActions({ actions }) {
    const isMobile = useMediaQuery(MediaRule.tablet);

    if (isMobile) {
        return null;
    }
    return <Fragment>
        <ActionsPanel>
            {actions.map((action, idx) => (
                <ActionsPanelButton
                    key={`desktop_action_${idx}`}
                    {...action} />
            ))}

        </ActionsPanel>
    </Fragment>
}

