import React, {Fragment} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export function TableShapeActions({ open, handleClose, handleDelete, handleRestoreSeats, handleNameChange, handleRotate, tableData }) {
    return <Fragment>
        <Menu
            id="menu-shape"
            anchorReference="anchorPosition"
            anchorPosition={{ top: tableData.y, left: tableData.x }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center'
            }}
            open={open}
            onClose={handleClose}
            tabIndex={-1}
        >
            {tableData.rotateEnabled && handleRotate ? <MenuItem onClick={() => handleRotate(tableData.id, 'left')}>Obróć w lewo</MenuItem> : null}
            {tableData.rotateEnabled && handleRotate ? <MenuItem onClick={() => handleRotate(tableData.id, 'right')}>Obróć w prawo</MenuItem> : null}
            {tableData.restoreSeatsEnabled && handleRestoreSeats ? <MenuItem onClick={() => handleRestoreSeats(tableData.id)}>Przywróć ukryte miejsca</MenuItem> : null}
            <MenuItem onClick={() => handleNameChange(tableData.id)}>Zmień nazwę</MenuItem>
            {handleDelete ? <MenuItem onClick={() => handleDelete(tableData.id)}>Usuń stół</MenuItem> : null}
        </Menu>
    </Fragment>
}
