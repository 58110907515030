import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SectionTitle from "../partials/SectionTitle";
import {StyledCardContent} from "../../../common/StyledContentCard";
import NavLink from "react-router-dom/NavLink";
import ENV from "../../../config/environment";
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";

class My extends Component {
    render() {
        return (
            <StyledCard>
                <StyledCardHeader>Mój pakiet</StyledCardHeader>

                <StyledCardContent>
                    {ENV.IS_PREMIUM ? (
                        <Fragment>
                            <p>
                                Obecnie korzystasz z pakietu Premium.
                            </p>
                            <p>
                                Oznacza to, że masz dostęp do wszystkich funkcjonalności aplikacji.
                            </p>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <p>
                                Obecnie używasz pakietu <strong className={'color-primary'}>STANDARD</strong>.
                            </p>
                            <p>
                                Aby odblokować pakiet  <strong className={'color-primary'}>PREMIUM</strong>, <NavLink to={'/admin/premium'}>kliknij tutaj</NavLink>.
                            </p>
                        </Fragment>
                    )}
                </StyledCardContent>
            </StyledCard>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(My);
