import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const StyledCircularProgress = styled(CircularProgress)`
    && {
        margin-bottom: 30px;
    }
`;

const StyledWrapper = styled.div`
    position: relative;
    top: 50vh;
    transform: translateY(-65%);
`;

class MainLoading extends Component {
    render() {
        return (
            <StyledWrapper>
                <StyledCircularProgress size={160} thickness={3} />
            </StyledWrapper>
        )
    }
}

export default MainLoading;