import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';
import {authService} from '../../../service/auth.service';
import {connect} from "react-redux";
import {CommonOutlinedButton} from '../../../common/StyledButton';
import Grid from '@material-ui/core/Grid';
import MediaSizes from '../../../constants/media-sizes.constants';
import ENV from "../../../config/environment";
import store from "../../../store";
import {toggleAdminPanel} from "../../../actions";
import Snackbar from "@material-ui/core/Snackbar";
import {withTranslation} from "react-i18next";
import LocalStorageService from "../../../service/localstorage.service";
import LocalStorageKeys from "../../../constants/local-storage-key.constants";

const colors = WEDDING_CONFIG.colors;

const StyledContainer = styled.div`
  padding: 0;
  color: #888;
  position: absolute;
  right: 20px;
  top: 0;
  display: block;
  text-align: right;
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: ${colors.primary};

  ${props => props.mobile ? `
    position: static;
    color: ${colors.white};
    margin-top: 5px;
    
    .item-left {
        text-align: left;
    }
    .item-right {
        text-align: right;
    }
    ` : ''} 
  
  @media (max-width: ${MediaSizes.phone}px) {
      padding-top: env(safe-area-inset-top);
    }

`;

const StyledLogoutButton = styled(CommonOutlinedButton)`
  && {
    padding: 5px 10px;
    margin-top: 15px;

    &.mobile {
      padding: 3px 5px;
      font-size: 0.85rem;
      margin-top: 5px;
    }

    &.Mui-disabled {
      border-color: #ddd;
    }

    @media (max-width: ${MediaSizes.tablet}px) {
      margin: 0;
    }
  }

`;

const StyledHello = styled.span`
  display: inline-block;
  font-size: 1.2rem;
  margin-top: 20px;
  margin-right: 5px;

  strong {
    font-size: 2rem;
  }

  &.mobile strong {
    font-size: 1.1rem;
  }

  @media (max-width: ${MediaSizes.phone}px) {
    margin: 0;
    font-size: 1rem;

    strong {
      font-size: 1.5rem;
    }
  }
`;

class Hello extends Component {
    state = {
        adminMessage: this.props.user.is_admin && !LocalStorageService.getFromLS(LocalStorageKeys.GuestPreviewForAdminMsgHid)
    }

    backToOrganizer() {
        store.dispatch(toggleAdminPanel(true));
        LocalStorageService.setInLS(LocalStorageKeys.GuestPreviewForAdminMsgHid, false);
    }

    render() {
        return (
            <StyledContainer className="font-primary" mobile={this.props.mobile}>
                <Grid container direction={this.props.mobile ? 'row-reverse' : ''}>
                    <Grid item xs={8} sm={12} className="item-right">
                        <StyledHello className={this.props.mobile ? 'mobile' : ''}>
                            {this.props.t('guest.hello.loggedInAs')}<br/>
                            <strong>{this.props.user.first_name}</strong>
                            {this.props.user.is_with_partner && this.props.user.partner_first_name ? (
                                <strong> / {this.props.user.partner_first_name}</strong>
                            ) : ''}
                        </StyledHello>
                    </Grid>
                    <Grid item xs={4} sm={12} className="item-left">
                        {this.props.user.is_admin && !this.props.app.activePage ? (
                            <StyledLogoutButton style={{marginTop: '13px'}}
                                                className={`${this.props.mobile ? 'white mobile' : ''} rounded`}
                                                onClick={this.backToOrganizer}>{this.props.t('common.goBackToPanel')}</StyledLogoutButton>
                        ) : (
                            <StyledLogoutButton style={{marginTop: '13px'}} disabled={!ENV.IS_PREMIUM}
                                                className={`${this.props.mobile ? 'white mobile' : ''} rounded`}
                                                href="/"
                                                onClick={authService.logOff}>{this.props.t('common.logOut')}</StyledLogoutButton>
                        )}
                    </Grid>
                </Grid>
                <Snackbar
                    open={this.state.adminMessage}
                    message={this.props.t('guest.hello.loggedInAsAdmin')}
                    action={
                        <CommonOutlinedButton color="inherit" size="small"
                                              onClick={() => {
                                                  this.setState({adminMessage: false});
                                                  LocalStorageService.setInLS(LocalStorageKeys.GuestPreviewForAdminMsgHid, true);
                                              }}>
                            {this.props.t('common.ok')}
                        </CommonOutlinedButton>
                    }
                />
            </StyledContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        app: state.app
    }
};

export default withTranslation()(connect(mapStateToProps)(Hello));
