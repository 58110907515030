import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import WEDDING_CONFIG from '../../../wedding-config';
import {MediaHeights, MediaRule} from "../../../constants/media-sizes.constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const colors = WEDDING_CONFIG.colors;

const StyledActions = styled.div`
    z-index: 10;
    color: ${colors.primaryLight};
    
    .separator {
      &:before {
        content: '';
        margin-left: 10px;
        margin-right: 20px;
        display: inline-block;
        height: 70%;
        width: 1px;
        background: #ddd;
        vertical-align: middle;
      }
    }
    
    @media (max-height: ${MediaHeights.sm}px) {
        margin-top: 15px;
    }
`;

const StyledActionsChildren = styled.div`
    &.mobile {
      flex-wrap: nowrap;
      display: flex;
    }
`;


export const ActionButton = styled(Button)`
    && {
        padding: 20px;
    }
`;

export default function ActionsPanel(props) {
    const isMobile = useMediaQuery(MediaRule.tablet);

    return (
        <StyledActions>
            <StyledActionsChildren className={`${isMobile ? 'mobile' : ''}`}>
                {props.children}
            </StyledActionsChildren>
        </StyledActions>
    );
}

