import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import styled from 'styled-components';
import { lighten } from 'polished';
import WEDDING_CONFIG from '../../../wedding-config';
import { connect } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import participantService from "../../../service/participant.service";
import {Formik} from "formik";
import FileSaver from "file-saver";
import SimpleDialog from "../../../common/SimpleDialog";
import {CommonOutlinedButton} from "../../../common/StyledButton";
import {DEFAULT_GRID_SPACING} from "../../../constants";

const colors = WEDDING_CONFIG.colors;

const StyledList = styled(List)`
  && {
    max-height: 400px;
    overflow-y: scroll;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 0;
  }
`;

class CodesDownloadDialog extends React.Component {
    state = {
        type: 'big-full',
        mode: 'all',
        selected: [],
        usersList: [],
        isLoading: false,
    };
    form;

    handleChangeMode(e) {
        if (!this.state.usersList.length && e.target.value === 'selected') {
            this.getList();
        }
    }

    getList() {
        participantService.getParticipantsList().then(response => {
            this.setState({
                usersList: response.data.list
            });
        })
    }

    setIsLoading(value) {
        this.setState({
            isLoading: value
        })
    }

    render() {
        return (
            <SimpleDialog
                title={'Pobierz etykiety'}
                fullScreen={false}
                open={true}
                fullWidth={false}
                onClose={this.props.handleClose}
                aria-labelledby="responsive-dialog-title"
                actions={(
                    <Fragment>
                        <CommonOutlinedButton onClick={this.props.handleClose} style={{marginRight: '10px'}}>
                            Zamknij
                        </CommonOutlinedButton>
                        <CommonOutlinedButton className={'fill-primary'} onClick={() => this.form.submitForm()} autoFocus disabled={this.state.isLoading}>
                            Pobierz
                        </CommonOutlinedButton>
                    </Fragment>
                )}>
                <Formik ref={node => (this.form = node)}
                        initialValues={{
                            type: 'big-full',
                            mode: 'all',
                            selected: [],
                        }}
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            this.setIsLoading(true);
                            participantService.printCodes(values.type, values.mode, values.mode === 'selected' ? values.selected : []).then((response) => {
                                const file = new Blob([response], {type: 'application/pdf'});
                                FileSaver.saveAs(file, "codes.fancy-wedding.pdf");
                                this.setIsLoading(false);
                            });
                        }}>
                    {({values, handleChange, setFieldValue}) => {
                        return (
                            <Grid container spacing={DEFAULT_GRID_SPACING}>
                                <Grid item xs={12} sm={values.mode === 'selected' ? 6 : 12}>
                                    <div>
                                        <FormControl>
                                            <FormLabel>Jakie etykiety wygenerować?</FormLabel>
                                            <RadioGroup
                                                name="type"
                                                value={values.type}
                                                onChange={handleChange}>
                                                <FormControlLabel value="big-full" control={<Radio />} label="Z kodami" />
                                                <FormControlLabel value="big-full-empty-code" control={<Radio />} label="Bez kodów" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <br />
                                    <div>
                                        <FormControl>
                                            <FormLabel>Dla kogo?</FormLabel>
                                            <RadioGroup
                                                name="mode"
                                                value={values.mode}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    this.handleChangeMode(e);
                                                }}>
                                                <FormControlLabel value="all" control={<Radio />} label="Dla wszystkich" />
                                                <FormControlLabel value="selected" control={<Radio />} label="Dla wybranych uczestników" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </Grid>
                                {values.mode === 'selected' ? (
                                    <Grid item xs={12} sm={6} className="item-left">

                                        <FormControl>
                                            <FormLabel>Wybierz uczestników:</FormLabel>
                                            <StyledList
                                                dense={true}>
                                                {this.state.usersList.map(user => (
                                                    <ListItem key={user.id} dense button onClick={() => {
                                                        const currentIndex = values.selected.indexOf(user.id);
                                                        const newChecked = [...values.selected];

                                                        if (currentIndex === -1) {
                                                            newChecked.push(user.id);
                                                        } else {
                                                            newChecked.splice(currentIndex, 1);
                                                        }

                                                        setFieldValue('selected', newChecked);
                                                    }}>
                                                        <StyledCheckbox
                                                            checked={values.selected.indexOf(user.id) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                        />
                                                        <ListItemText primary={`${user.first_name} ${user.last_name}`} />
                                                    </ListItem>
                                                ))}
                                            </StyledList>
                                        </FormControl>
                                    </Grid>
                                ) : ''}
                            </Grid>
                        )
                    }}
                </Formik>
            </SimpleDialog>
        );
    }
}

CodesDownloadDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(withMobileDialog()(CodesDownloadDialog));
