import React, { Component, Fragment } from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { StyledInput } from '../../../../common/StyledInput';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import {StyledHeader, StyledBigCheckbox} from './commons';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FieldArray } from 'formik';
import WEDDING_CONFIG from '../../../../wedding-config';
import MediaSizes from '../../../../constants/media-sizes.constants';
import { connect } from 'react-redux';
import {DEFAULT_GRID_SPACING} from "../../../../constants";

const colors = WEDDING_CONFIG.colors;

const StyledKidListItem = styled.div`
    && {
        display: flex;
        flexWrap: wrap;
        margin-top: 3px;
        
        .text-field {
            margin: 0 20px 0 0;
        }
        
        .number {
            display: inline-block;
            padding: 25px;
        }
         
        @media (max-width: ${MediaSizes.phone}px) {
            //display: block;
        }
    }
`;

const StyledAddButton = styled(ListItem)`
    && {
        padding-left: 11px;
    }
`;

const StyledBottomInfo = styled.div`
    color: ${colors.primary};
    font-size: 1rem;
    text-align: left;
    margin-top: 20px;
`;

const StyledBox = styled.div`
    padding: 25px 45px;
    margin: 10px;
    border-radius: 2px;
    // box-shadow: 1px 1px 17px 0 #ddd;
    text-align: center;
    position: relative;
    
    &.inactive {
        opacity: 0.3;
    }
    
    .absolute-checkbox {
        position: absolute;
        top: -10px;
        right: 11px;
    }
    
    @media (max-width: ${MediaSizes.phone}px) {
        padding: 10px;
        margin: 0;
        width: 100%;
        box-sizing: border-box;
        
        .absolute-checkbox {
            top: -18px;
            right: -10px;
        }
        
        &.kids {
          margin-top: 30px;
        }
    }
`;

const StyledTypoHeader = styled(Typography)`
    && {
        color: ${colors.primary};
        font-size: 1.8rem;
        margin-bottom: 30px;
        border-bottom: 6px dotted #eee;
        padding-bottom: 10px;
        text-align: left;
        letter-spacing: 2px;
        
        .sub {
            font-size: 1rem;
            letter-spacing: 1px;
            margin-top: 7px;
        }
        
        @media (max-width: ${MediaSizes.phone}px) {
            width: 100%;
            font-size: 1.4rem;
            margin-bottom: 10px;
            box-sizing: border-box;
        }
    }
`;


const StyledAmp = styled.div`
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    color: #c24364;
    font-family: July Seventh;
    font-style: italic;
    margin-top: 120px;
    
    &.inactive {
        color: #888;
        opacity: 0.3;
    }
    
    @media (max-width: ${MediaSizes.phone}px) {
        display: none;
    }
`;

const StyledNoPartner = styled.div`
    font-size: 1.2rem;
    padding: 45px 0;
`;

const StyledNoKids = styled.div`
    font-size: 1.2rem;
    padding: 10px 0;
`;

class MainFormSectionPartners extends Component {
    state = {
        kidsFormVisible: false
    };

    constructor(props) {
        super(props);
        let kidsFormVisible = false;

        if (props.user.allow_adding_kids || props.formProps.values.kids.length) {
            kidsFormVisible = true;
        }

        this.state.kidsFormVisible = kidsFormVisible;
    }

    render() {
        const { values, errors, touched, handleChange, handleBlur, submitForm, handleSubmit, isSubmitting, isValid }
            = this.props.formProps;

        if (!this.props.isActive) {
            return null;
        }

        return (
            <div>
                <StyledHeader>
                    Kto będzie z Tobą?
                </StyledHeader>

                <Grid container spacing={DEFAULT_GRID_SPACING}>
                    <Grid item xs={12} sm={5}>
                        <StyledBox>
                            <StyledTypoHeader variant="h4" gutterBottom>
                                Osoba #1
                            </StyledTypoHeader>
                            <StyledInput
                                margin="dense"
                                name="first_name"
                                key="first_name"
                                label="Imię"
                                type="text"
                                onChange={handleChange}
                                value={values.first_name}
                                fullWidth
                            />
                            <StyledInput
                                margin="dense"
                                name="last_name"
                                key="last_name"
                                label="Nazwisko"
                                type="text"
                                onChange={handleChange}
                                value={values.last_name}
                                fullWidth
                            />
                        </StyledBox>
                    </Grid>
                    <Grid item sm={2}>
                        <StyledAmp className={`${!values.is_with_partner ? 'inactive' : ''}`}>
                            &
                        </StyledAmp>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <StyledBox className={`${!values.is_with_partner ? 'inactive' : ''}`}>
                            <StyledBigCheckbox
                                className="absolute-checkbox"
                                name="is_with_partner"
                                key="is_with_partner"
                                checked={!!values.is_with_partner}
                                value="1"
                                onChange={handleChange}
                            />
                            <StyledTypoHeader variant="h4" gutterBottom>
                                Osoba #2
                            </StyledTypoHeader>
                            {
                                values.is_with_partner ? (
                                    <Fragment>
                                        <StyledInput
                                            margin="dense"
                                            name="partner_first_name"
                                            key="partner_first_name"
                                            label="Imię"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.partner_first_name}
                                            fullWidth
                                        />
                                        <StyledInput
                                            margin="dense"
                                            name="partner_last_name"
                                            key="partner_last_name"
                                            label="Nazwisko"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.partner_last_name}
                                            fullWidth
                                        />
                                    </Fragment>
                                ) : (
                                    <StyledNoPartner>
                                        będę bez partnera
                                    </StyledNoPartner>
                                )
                            }
                            {values.is_with_partner && (!values.partner_first_name || !values.partner_last_name) ? (
                                <StyledBottomInfo>Jeśli jeszcze nie wiesz z kim się wybierzesz, pozostaw powyższe pola puste</StyledBottomInfo>
                            ) : ''}
                        </StyledBox>
                    </Grid>
                    {this.state.kidsFormVisible ? (
                        <Grid item xs={12} sm={12}>
                            <StyledBox className={`kids ${!values.is_with_kids ? 'inactive' : ''}`}>
                                <StyledBigCheckbox
                                    className="absolute-checkbox"
                                    name="is_with_kids"
                                    key="is_with_kids"
                                    checked={!!values.is_with_kids}
                                    value={values.is_with_kids ? "1" : "0"}
                                    onChange={handleChange}
                                />
                                <StyledTypoHeader variant="h4" gutterBottom>
                                    Dzieci
                                    <div className="sub">prosimy dodać dzieci tylko  jeśli nie dostały one osobnego zaproszenia</div>
                                </StyledTypoHeader>
                                {
                                    values.is_with_kids ? (
                                        <FieldArray
                                            name="kids"
                                            render={ arrayHelpers => (
                                                <div>
                                                    {values.kids.map((kid, index) => (
                                                            <StyledKidListItem
                                                                key={index}
                                                                role={undefined}
                                                                dense
                                                                button
                                                            >
                                                                <span className="number">{ index + 1 }.</span>
                                                                <StyledInput
                                                                    className="text-field"
                                                                    name={`kids[${index}].kid_first_name`}
                                                                    key={`kid_first_name${index}`}
                                                                    label="Imię"
                                                                    type="text"
                                                                    onChange={handleChange}
                                                                    value={kid.kid_first_name}
                                                                    fullWidth
                                                                    required
                                                                />
                                                                <StyledInput
                                                                    className="text-field"
                                                                    name={`kids[${index}].kid_last_name`}
                                                                    key={`kid_last_name${index}`}
                                                                    label="Nazwisko"
                                                                    type="text"
                                                                    onChange={handleChange}
                                                                    value={kid.kid_last_name}
                                                                    fullWidth
                                                                    required
                                                                />
                                                                {this.props.user.allow_adding_kids ? (
                                                                    <Tooltip title="Usuń">
                                                                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                                                                            &nbsp;&nbsp;<Icon>remove_circle_outline</Icon>&nbsp;&nbsp;
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ) : ''}
                                                            </StyledKidListItem>
                                                        )
                                                    )}
                                                    {this.props.user.allow_adding_kids ? (
                                                        <StyledAddButton button onClick={() => arrayHelpers.push({
                                                            kid_first_name: '',
                                                            kid_last_name: values.last_name === values.partner_last_name ?
                                                                values.last_name :
                                                                ''
                                                        })}>
                                                            <ListItemIcon >
                                                                <AddIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Kliknij tutaj aby dodać dziecko"/>
                                                        </StyledAddButton>
                                                    ) : ''}
                                                </div>
                                            )}
                                        />
                                    ) : (
                                        <StyledNoKids>
                                            będę bez dzieci
                                        </StyledNoKids>
                                    )
                                }
                            </StyledBox>
                        </Grid>
                    ) : ''}
                </Grid>
            </div>
        );
    }

}


const mapStateToProps = (store) => {
    return {
        user: store.auth.user
    }
};

export default connect(mapStateToProps)(MainFormSectionPartners);
