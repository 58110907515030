import React, {Fragment, useState} from 'react';
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import WEDDING_CONFIG from "../../../../wedding-config";
import SimpleDialog, {
    SimpleDialogModalLikeOverlay,
    SimpleDialogModalLikeTitle, SimpleDialogModalLikeActions
} from "../../../../common/SimpleDialog";
import {useFormik} from "formik-newest";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";
import taskService from "../../../../service/task.service";
import Autosuggest from "react-autosuggest";
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import TextInput from "../../partials/TextInput";
import {useSnackbar} from "notistack";
import classnames from 'classnames';

const colors = WEDDING_CONFIG.colors;

const StyledBox = styled.div`
  width: 500px;
  max-width: 100%;
`;

const StyledAutocompleteWrapper = styled.div`
  position: relative;
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background: white;
    width: 100%;
    box-shadow: 1px 1px 3px #ddd;
    z-index: 20;
  }
`;

const StyledOption = styled.div`
  cursor: pointer;
  padding: 8px;
  border-bottom: 1px solid #eee;
    &:hover {
      color: white;
      background-color: ${colors.primaryLight};
    }
    
    &.proposal {
      color: ${colors.primaryLight};
      &:hover {
          color: white;
      }
    }
`;

const StyledInputField = styled(TextInput)`
    && {
        font-size: 1rem;
        width: 100%;
    }
`;

const StyledListHelp = styled.div`
  padding-top: 10px;
  font-size: 0.9rem;
  color: #999;
`;

export default function TaskForm({ taskData, tasks, onSave, onClose, ...props}) {
    const isMobile = useMediaQuery(MediaRule.tablet);
    const isEdit = !!taskData;
    const [shouldClose, setShouldClose] = useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const form = useFormik({
        initialValues: taskData ? {
            ...taskData
        } : {
            id: null,
            list: '',
            name: '',
            date: null,
            note: ''
        },
        onSubmit: values => {
            if (isEdit) {
                taskService.editTask(values, values.id).then(response => {
                    onSave();
                    onClose();
                    enqueueSnackbar('Zapisano zmiany', {
                        variant: 'success',
                    });
                });
            } else {
                taskService.addTask(values).then(response => {
                    onSave();
                    if (shouldClose) {
                        onClose();
                    }
                    form.resetForm();

                    enqueueSnackbar('Dodano nowe zadanie', {
                        variant: 'success',
                    });
                });
            }
        },
    });
    const tasksCategoriesProposals = [
        'Zadania dla Pana Młodego',
        'Zadania dla Pani Młodej',
        'Kwiaciarnia',
        'Kościół',
        'Prezenty dla rodziców',
        'Zaproszenia',
        'Jedzenie',
        'Napoje',
        'Inne'
    ];
    let options = Object.keys(tasks)
        .filter((name) => name !== 'others')
        .map(name => ({ value: name, label: name }));
    tasksCategoriesProposals.forEach(proposal => {
       if (!options.find(opt => opt.value === proposal)) {
           options.push({ value: proposal, label: '+ ' + proposal + '', isProposal: true });
       }
    });

    function renderSuggestionsContainer({ containerProps, children, query }) {
        // if (options.length) return null;
        return (
            <div {... containerProps}>
                {children}
                <StyledListHelp>
                    np. "Zadania dla Pana Młodego"
                </StyledListHelp>
            </div>
        );
    }

    return (
        <Fragment>
            <SimpleDialog
                onClose={onClose}
                title={isEdit ? 'Edytuj zadanie' : 'Nowe zadanie'}
                actions={<Fragment>
                    {!isMobile ? <CommonOutlinedButton onClick={() => onClose()} color="secondary">
                        Anuluj
                    </CommonOutlinedButton> : null}
                    <CommonOutlinedButton disabled={!form.dirty || !form.values.name} className="fill-primary"
                                          onClick={() => { setShouldClose(true); form.submitForm()}} color="secondary">
                        Zapisz
                    </CommonOutlinedButton>
                    {!isEdit ? <CommonOutlinedButton disabled={!form.dirty || !form.values.name} className="fill-primary"
                                                     onClick={() => { setShouldClose(false); form.submitForm();}} color="secondary">
                        Zapisz i dodaj kolejne
                    </CommonOutlinedButton> : null}
                </Fragment>}
                open={true}
                {...props}>
                <StyledBox>
                    <div className="form-group">
                        <StyledInputField autoComplete={false} label="Zadanie *" type="text" placeholder={'Krótki opis'} name="name" value={form.values.name} onChange={form.handleChange}  />
                    </div>

                    <div className="form-group">
                        <StyledAutocompleteWrapper>
                            <Autosuggest
                                suggestions={options}
                                renderSuggestion={(suggestion) => (<StyledOption className={classnames({ proposal: suggestion.isProposal})}>{suggestion.label}</StyledOption>)}
                                getSuggestionValue={(suggestion) => suggestion.value}
                                onSuggestionsFetchRequested={(value) => {}}
                                shouldRenderSuggestions={() => true}
                                focusInputOnSuggestionClick={false}
                                onSuggestionsClearRequested={(value) => {}}
                                renderSuggestionsContainer={renderSuggestionsContainer}
                                inputProps={{
                                    id: 'list',
                                    placeholder: options.length ? 'Wybierz z propozycji lub wpisz nazwę kategorii' : 'Wpisz nazwę nowej kategorii',
                                    name: 'list',
                                    value: form.values.list,
                                    onChange: (event, { newValue }) => {
                                        form.setFieldValue('list', newValue)
                                    }
                                }}
                                renderInputComponent={(inputProps) => {
                                    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

                                    return (
                                        <StyledInputField
                                            fullWidth
                                            label="Kategoria zadania"
                                            InputProps={{
                                                inputRef: node => {
                                                    ref(node);
                                                    inputRef(node);
                                                },
                                            }}
                                            {...other}
                                        />
                                    )
                                }}
                            />
                        </StyledAutocompleteWrapper>
                    </div>

                    <div className="form-group">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <StyledInputField
                                component={'DatePicker'}
                                value={form.values.date}
                                format="DD/MM/YYYY"
                                label="Planowana data"
                                onChange={(value) => {
                                    form.setFieldValue('date', value)
                                }}/>
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className="form-group">
                        <StyledInputField
                            multiline rows={10} label="Notatka" type="text" id="note" name="note" value={form.values.note} onChange={form.handleChange}  />
                    </div>

                </StyledBox>
            </SimpleDialog>
        </Fragment>
    )
};
