import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { authService } from '../../../service/auth.service';
import {updateUser, updateInstance, setError} from '../../../actions';
import Button from '@material-ui/core/Button';
import TRANSLATE from '../../../translation';
import MediaSizes from '../../../constants/media-sizes.constants';
import {CommonOutlinedButton} from "../../../common/StyledButton";
import api from "../../../api";
import store from "../../../store";
import TextInput from "../../admin/partials/TextInput";


const animFade = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
  
`;

const LoginFormContainer = styled.div`
    && {
        margin: 0 auto;
        opacity: 0;
        display: inline-block;
        height: 100%;
        padding: 10px;
        text-align: left;
        animation: ${animFade} .5s ease-in forwards;
        
        &.is-premium {
          text-align: center;
          
          h2 {
            font-size: 2rem;
          }
          
          .url {
            font-size: 1.5rem;
          }
        }
    }  
`;

const StyledEmailField = styled(TextField)`
    && {
        margin-left: 5px;
        width: 480px;
        max-width: 88vw;
    }
    
    && input {
        font-size: 24px;
        padding: 15px 0;
    }
`;

const LoginHeader = styled(Typography)`
    && {
        margin: 15px 5px;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
        font-weight: 300;
        font-size: 1.5rem;
        
        @media (max-width: ${MediaSizes.tablet}px) {
            font-size: 1rem;
        }
    }
`;

const StyledTextInput = styled(TextInput)`
  && {
    margin-bottom: 20px;
    width: 100%;
  }
`;

class PasswordChange extends Component {
    state = {
        isPossible: null,
        passwordChanged: false
    };
    form;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.checkIfResetPossible(this.retrieveTokenFromUrl());
    }

    resetPassword(data, setSubmitting) {
        authService.resetPassword(data).then(response => {
            setSubmitting(false);

            this.setState({
                isSuccess: true
            });
        }).catch((error) => {
            setSubmitting(false);
        });
    }

    retrieveTokenFromUrl() {
        const queryString = window.location.search ? window.location.search.slice(1) : null;
        let queryParams, queryParamsObj = {};

        if (queryString) {
            queryParams = queryString.split('&');
            queryParams.forEach((paramString) => {
                queryParamsObj[paramString.split('=')[0]] = paramString.split('=')[1];
            });

            return queryParamsObj['token'];
        }
    }

    checkIfResetPossible(token) {
        authService.resetPasswordPossibility(token).then(response => {
            this.setState({
                isPossible: response.data.possible
            });
        }).catch((error) => {
            this.setState({
                isPossible: false
            });
        });
    }

    render() {
        if (this.state.isPossible === false) {
            return (
                <div>Nieprawidłowy link.</div>
            );
        } else if (this.state.passwordChanged) {
            return (
                <div>
                    Twoje hasło zostało zmienione.
                </div>
            )
        } else if (this.state.isPossible === null) {
            return null;
        }
        return (
            <LoginFormContainer>
                <LoginHeader variant="h5" gutterBottom>
                    Ustaw nowe hasło
                </LoginHeader>

                <Formik
                    initialValues={{
                        password: '',
                        password_repeat: '',
                        token: this.retrieveTokenFromUrl()
                    }}
                    onSubmit={(values, {setSubmitting, setErrors, resetForm}) => {
                        setSubmitting(true);
                        api.request({
                            url: api.routes.resetPassword,
                            method: 'put',
                            data: values
                        }).then((response) => {
                            let hasChanged = false;

                            try {
                                hasChanged = response.status === 'success';
                            } catch (e) {
                                hasChanged = false;
                            }

                            if (hasChanged) {
                                this.setState({
                                    passwordChanged: true
                                });
                                resetForm({});
                            }
                        });
                    }}
                >
                    {({values, errors, touched, dirty, handleChange, handleBlur, submitForm, handleSubmit, isSubmitting, isValid, validateForm}) => {
                        return (
                            <div>
                                <StyledTextInput
                                    name="password"
                                    key="password"
                                    placeholder="Nowe hasło"
                                    onChange={handleChange}
                                    type="password"
                                    fullWidth
                                />
                                <StyledTextInput
                                    name="password_repeat"
                                    key="password_repeat"
                                    placeholder="Powtórz nowe hasło"
                                    onChange={handleChange}
                                    type="password"
                                    fullWidth
                                />
                                <br/>
                                <br/>
                                <CommonOutlinedButton type="submit"  disabled={!dirty || !isValid}
                                                      onClick={handleSubmit}>Ustaw</CommonOutlinedButton>
                            </div>
                        )
                    }}
                </Formik>
            </LoginFormContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.auth.user,
        authUser: state.auth.user,
        errorMessage: state.auth.error || null
    }
};


export default connect(mapStateToProps)(PasswordChange);
