import React from 'react';
import styled from 'styled-components';
import {DEFAULT_IMAGE_BORDER_RADIUS} from "../constants";

export const StyledCardContent = styled.div`
  position: relative;
  
  &.centered {
    text-align: center;
  }
  
  .card-image {
     max-width: 100%;
     border-radius: ${DEFAULT_IMAGE_BORDER_RADIUS}px;
  }
`;
