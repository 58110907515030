import appService from "../service/app.service";

export const ACTION_NAMES = {
    SET_API_PARAMS: 'SET_API_PARAMS',
    TOGGLE_MAIN_MODAL: 'TOGGLE_MAIN_MODAL',
    SET_MAIN_MODAL_ACTIVE_STEP: 'SET_MAIN_MODAL_ACTIVE_STEP',
    SET_MAIN_MODAL_MAX_STEPS: 'SET_MAIN_MODAL_MAX_STEPS',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_INSTANCE: 'UPDATE_INSTANCE',
    UPDATE_USER_AND_INSTANCE: 'UPDATE_USER_AND_INSTANCE',
    SET_AUTHENTICATED: 'SET_AUTHENTICATED',
    SET_ERROR: 'SET_ERROR',
    SET_SUCCESS: 'SET_SUCCESS',
    TOGGLE_ADMIN_USER_EDIT_MODAL: 'TOGGLE_ADMIN_USER_EDIT_MODAL',
    SET_INSTANCE_ACTIVE_PARAM: 'SET_INSTANCE_ACTIVE_PARAM',
    SET_ACTIVE_PAGE: 'SET_ACTIVE_PAGE',
    SET_TRIAL_DAYS: 'SET_TRIAL_DAYS',
    TOGGLE_DECLARATION_SUCCESS_MODAL: 'TOGGLE_DECLARATION_SUCCESS_MODAL',
    TOGGLE_DECLARATION_THANK_YOU_MODAL: 'TOGGLE_DECLARATION_THANK_YOU_MODAL',
    TOGGLE_ADMIN_PANEL: 'TOGGLE_ADMIN_PANEL',
    SET_PDF_DOWNLOADED: 'SET_PDF_DOWNLOADED',
    SET_PREMIUM_PRICE: 'SET_PREMIUM_PRICE',
    SET_AFTER_WEDDING: 'SET_AFTER_WEDDING',
    SET_INITIAL_SCREEN_AS_ADMIN: 'SET_INITIAL_SCREEN_AS_ADMIN',
    SET_CHANGELOG_DATA: 'SET_CHANGELOG_DATA',
    SET_CHAT_NEW_MESSAGES_COUNT: 'SET_CHAT_NEW_MESSAGES_COUNT',
    SET_GUEST_CHAT_NEW_MESSAGES_COUNT: 'SET_GUEST_CHAT_NEW_MESSAGES_COUNT',
};

export const setApiParams = (apiParams) => ({
    type: ACTION_NAMES.SET_API_PARAMS,
    apiParams
});

export const setChangelogData = (changelogData) => ({
    type: ACTION_NAMES.SET_CHANGELOG_DATA,
    changelogData
});

export const setAdminChatNewMessagesCount = (count) => ({
    type: ACTION_NAMES.SET_CHAT_NEW_MESSAGES_COUNT,
    count
});


export const setGuestChatNewMessagesCount = (count) => ({
    type: ACTION_NAMES.SET_GUEST_CHAT_NEW_MESSAGES_COUNT,
    count
});

export const setIsAfterWedding = (isAfterWedding) => ({
    type: ACTION_NAMES.SET_AFTER_WEDDING,
    isAfterWedding
});

export const setPDFDownloaded = (wasPDFDownloaded) => ({
    type: ACTION_NAMES.SET_PDF_DOWNLOADED,
    wasPDFDownloaded
});

export const setPremiumPrice = (premiumPrice) => ({
    type: ACTION_NAMES.SET_PREMIUM_PRICE,
    premiumPrice
});

export const setAuthenticated = (user) => ({
    type: ACTION_NAMES.SET_AUTHENTICATED,
    user
});

export const toggleAdminPanel = (adminPanelOn) => {
    if (adminPanelOn) {
        appService.runAndSetIntervalForApiParams();
    }

    return {
        type: ACTION_NAMES.TOGGLE_ADMIN_PANEL,
        adminPanelOn
    };
};

export const updateUser = (user) => ({
    type: ACTION_NAMES.UPDATE_USER,
    user
});

export const updateUserAndInstance = (user, instance) => ({
    type: ACTION_NAMES.UPDATE_USER_AND_INSTANCE,
    user,
    instance
});

export const updateInstance = (instance) => ({
    type: ACTION_NAMES.UPDATE_INSTANCE,
    instance
});

export const toggleMainModal = (isMainModalOpened) => ({
    type: ACTION_NAMES.TOGGLE_MAIN_MODAL,
    isMainModalOpened
});

export const toggleDeclarationThankYouModal = (isDeclarationThankYouDialogOpen) => ({
    type: ACTION_NAMES.TOGGLE_DECLARATION_THANK_YOU_MODAL,
    isDeclarationThankYouDialogOpen
});

export const toggleDeclarationSuccessModal = (isDeclarationSuccessOpened) => ({
    type: ACTION_NAMES.TOGGLE_DECLARATION_SUCCESS_MODAL,
    isDeclarationSuccessOpened
});

export const setMainModalActiveStep = (mainModalActiveStep) => ({
    type: ACTION_NAMES.SET_MAIN_MODAL_ACTIVE_STEP,
    mainModalActiveStep
});

export const setError = (errorMessage) => ({
    type: ACTION_NAMES.SET_ERROR,
    errorMessage
});

export const setSuccess = (successMessage) => ({
    type: ACTION_NAMES.SET_SUCCESS,
    successMessage
});

export const toggleAdminUserEditModal = (userId) => ({
    type: ACTION_NAMES.TOGGLE_ADMIN_USER_EDIT_MODAL,
    userId
});

export const setInstanceActiveParam = (isInstanceActive) => ({
    type: ACTION_NAMES.SET_INSTANCE_ACTIVE_PARAM,
    isInstanceActive
});

export const setActivePage = (activePage) => ({
    type: ACTION_NAMES.SET_ACTIVE_PAGE,
    activePage
});

export const setTrialDays = (trialDays) => ({
    type: ACTION_NAMES.SET_TRIAL_DAYS,
    trialDays
});

