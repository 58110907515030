import React, {Component, Fragment} from 'react';
import styled, {css, keyframes} from 'styled-components';
import MainMenuDummies from './MainMenuDummies';
import WEDDING_CONFIG from '../../../../wedding-config';
import {lighten, darken, transparentize} from 'polished';
import {connect} from 'react-redux';
import store from '../../../../store';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import {setActivePage} from '../../../../actions';
import MediaSizes, {MediaHeights} from '../../../../constants/media-sizes.constants';
import menuService from '../../../../service/menu.service';
import mainMenuConfig from './MainMenuConfig';
import Hidden from '@material-ui/core/Hidden';
import {withTranslation} from "react-i18next";

const colors = WEDDING_CONFIG.colors;


const initAnimation = () => {
    return keyframes`
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    `;
};

const infiniteAnimation = () => {
    const y = 0;
    return keyframes`
      0% {
        transform: rotateZ(360deg) translateX(-50%) translateY(${y}px);
      }

      20% {
        transform: rotateZ(360deg) translateX(-50%) translateY(${y - 4}px);
      }

      25% {
        transform: rotateZ(360deg) translateX(-50%) translateY(${y - 6}px);
      }

      65% {
        transform: rotateZ(360deg) translateX(-50%) translateY(${y + 4}px);
      }

      75% {
        transform: rotateZ(360deg) translateX(-50%) translateY(${y + 6}px);
      }

      100% {
        transform: rotateZ(360deg) translateX(-50%) translateY(${y}px);
      }
    `;
};


const StyledMenuList = styled.ul`
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50px;
  left: 50%;
  display: inline-block;
  z-index: 0;

  @media (max-width: ${MediaSizes.tablet}px) {
    position: relative;
    transform: translateX(-50%);
    width: 438px;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
  }

  @media (max-width: ${MediaSizes.tabletPro}px) {
    top: 100px;
  }
`;

function calcX(r, index, length) {
    return -r * 1.9 * Math.cos(Math.PI / (length) * index);
}

function calcY(r, index, length) {
    return r * 2 * Math.sin(Math.PI / (length) * index);
}

const StyledBlock = styled(Button)`
  && {
    //flex: 50%;
    flex-grow: 0;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 100px;
    left: 0;
    text-align: center;
      //background: ${colors.primary};
    font-weight: 100;
    color: white;
    padding: 30px 0;
    text-transform: none;
    transform: translateX(-50%);
    transition: all 0.2s ease-in;

    .couple {
      width: 120px;
    }
  }

`;

const StyledMenuBlock = styled(StyledBlock)`
  && {
    ${props => {
      let delay = 1500;
      let widthLg = 300;
      let widthMd = 250;
      let widthSm = 150;
      let widthXs = 150;

      return css`
            color: ${colors.primary};
            background: ${transparentize(0.0, '#fff')}!important;
            // box-shadow: 0px 1px 3px #ccc;
            box-shadow: 1px 2px 1px #a732592e, 0px 0px 2px #ccc;
            z-index: ${props.index};
            // border: 1px solid ${lighten(0.15 + 0.01 * props.index, colors.secondary)};
            opacity: 0;
            animation: ${initAnimation()} ${delay}ms ease-in-out forwards;
            animation-delay: ${props.index * 150}ms;
            
            ${props.index > 0 ? ` 
                left: ${calcX(170, props.index, props.all.length)}px;
                top: ${110 + calcY(170, props.index, props.all.length)}px;
                width: ${widthSm}px;
                height: ${widthSm}px;
                font-size: 1.2rem;
                line-height: 1.8rem;
                
                .icon {
                    font-size: 3rem;
                }
                
                @media (max-height: ${MediaHeights.sm}px) {
                    left: ${calcX(130, props.index, props.all.length)}px;
                    top: ${110 + calcY(130, props.index, props.all.length)}px;
                    width: ${widthXs}px;
                    height: ${widthXs}px;
                    font-size: 1.2rem;
                    
                    .icon {
                        font-size: 2rem;
                    }
                }
            ` : `
                width: ${widthLg}px;
                height: ${widthLg}px;
                font-size: 2rem;
                left: 0;
                
                .icon {
                    font-size: 6rem;
                }
                
                @media (max-height: ${MediaHeights.sm}px) {
                    width: ${widthMd}px;
                    height: ${widthMd}px;
                    font-size: 1.8rem;
                    
                    .icon {
                        font-size: 3rem;
                    }
                }
            `}
        
                
            @media (max-width: ${MediaSizes.tablet}px) {
                position: static;
                margin: 2px;
                transform: none;
                vertical-align: bottom;
               
            }
            
            &, &:focus {
                            // background: white;

                background: ${transparentize(0, darken(0.04 * props.index, colors.primary))};
            }
    
            &:hover, &:focus {
                background: white!important;
                box-shadow: 0 0 0 3px ${colors.primary};
            }
           
                // color: ${colors.primary};
          `;
    }});
  }
`;

class MainMenuAlt extends Component {

    state = {
        menuItems: [],
    };

    componentDidMount() {
        const menuItems = menuService.getMenuItems();

        this.setState({
            menuItems: menuItems
                .filter(page => page !== 'DOWNLOAD_PDF_SUMMARY')
                .map((page) => mainMenuConfig.config[page])
                .filter(page => page)
        })
    }

    menuItemClick(page) {
        store.dispatch(setActivePage(page));
    }

    render() {
        return (
            <Fragment>
                <StyledMenuList>
                    {this.state.menuItems.map((item, i, all) => (
                        <StyledMenuBlock index={i} all={all}
                                         onClick={item.onClick || (() => this.menuItemClick(item.page))}>
                            <div>
                                {i === 0 ? (
                                    <img className="couple" src="/couple-color.png"/>
                                ) : (
                                    <Icon className="icon" fontSize="large">{item.icon}</Icon>
                                )}
                            </div>
                            <div>
                                {this.props.t(item.text)}
                            </div>
                        </StyledMenuBlock>
                    ))}

                    <Hidden mdDown>
                        <MainMenuDummies/>
                    </Hidden>
                </StyledMenuList>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        instance: state.auth.instance
    }
};

export default withTranslation()(connect(mapStateToProps)(MainMenuAlt));
