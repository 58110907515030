import React from 'react';
import {Circle, Group, Label, Tag, Text} from 'react-konva';
import store from '../../../../store';
import {setTablesData, swapAssignment} from "../../../../actions/table.actions";

export default function Seat({ assignment, snapSize, tables, tableId, seatId, onSeatClick, x, y, rotation }) {
    let seat;
    let seatCircle;
    let seatAssignment;
    let seatCircleAssignment;
    let snapSizePx = snapSize;
    const labelPos = calculateLabelPosition();

    function calculateLabelPosition() {
        let x = 1;
        let y = 0;

        y += snapSizePx * 1.5;
        return { x, y };
    }

    function getSeatColor() {
        if (!assignment) {
            return '#fff';
        }

        return assignment.present_first_day || assignment.present_second_day ? '#992652' : '#bbb';
    }

    function haveIntersection(r1, r2) {
        return !(
            r2.x > r1.x + r1.width ||
            r2.x + r2.width < r1.x ||
            r2.y > r1.y + r1.height ||
            r2.y + r2.height < r1.y
        );
    }

    function swapSeatAssignment(seatSource, seatDestination) {
        const data = {
            moved_id: seatSource.assignment.id,
            new_id_table: seatDestination.tableId,
            new_id_seat: seatDestination.seatId,
            swapping_with: seatDestination.assignment ? seatDestination.assignment.id : null
        };

        return store.dispatch(swapAssignment(localStorage.getItem('selectedTableSetId'), data));
    }

    function reorderTables() {
        const rTables = tables;
        const seatsTableIndex = rTables.findIndex((table) => table.id === tableId);
        const seatsTable = rTables[seatsTableIndex];

        rTables.splice(seatsTableIndex, 1);
        rTables.push(seatsTable);
        store.dispatch(setTablesData(rTables));
    }

    function getSeatAssignment(seat) {
        const seatRect = seat.getClientRect();
        const seatAssignmentRect = seat.find('.seat-assignment-circle')[0].getClientRect();
        return {
            ...seatAssignmentRect,
            x: seatRect.x + seatAssignmentRect.width/2,
            y: seatRect.y + seatAssignmentRect.width/2,
        }
    }

    return (
        <Group
            ref={(ref) => {
                seat = ref;
                if (seat) {
                    seat.seatId = seatId;
                    seat.tableId = tableId;
                    seat.assignment = assignment;
                }
            }}
            name={'seat'}
            onClick={(e) => onSeatClick(e, seatId, tableId, assignment)}
            onTap={(e) => onSeatClick(e, seatId, tableId, assignment)}
            onMouseEnter={() => {
                if (!assignment) {
                    seatCircle.fill('#ddd');
                    seatCircle.getLayer().batchDraw();
                }
            }}
            onMouseLeave={() => {
                if (!assignment) {
                    seatCircle.fill('#fff');
                    seatCircle.getLayer().batchDraw();
                }
            }}
            x={x}
            y={y}>
            {/*<Text text={this.props.assignment ? this.props.assignment.id : 'empty'}/>*/}
            <Circle
                ref={(ref) => seatCircle = ref}
                name={'seat-slot'}
                offsetX={-snapSizePx * 0.65 - snapSizePx}
                offsetY={-snapSizePx * 0.65 - snapSizePx}
                scaleX={0.6}
                scaleY={0.6}
                width={snapSizePx * 2}
                height={snapSizePx * 2}
                fill={'#fff'}
                stroke={'#ccc'}
                strokeWidth={1}
            />
            {assignment ? (
                <Group
                    ref={(ref) => seatAssignment = ref}
                    name={'seat-assignment'}
                    draggable={true}
                    listening={true}
                    x={0}
                    y={0}
                    onDragStart={(e) => {
                        reorderTables();
                        if (seat) seat.find('.seat-slot').fill('#ddd');
                    }}
                    onDragMove={(e) => {
                        const target = e.target;
                        const targetRect = e.target.getClientRect();
                        const otherSeats = seat.getStage().find('.seat');
                        let closestSeat;

                        otherSeats.forEach((seat) => {
                            seat.find('.seat-slot').fill('transparent');
                            seat.find('.seat-slot').opacity(1);
                            seat.find('.seat-assignment').opacity(1);
                            if (target.parent === seat) {
                                return;
                            }

                            if (haveIntersection(seat.getClientRect(), getSeatAssignment(target))) {
                                if (!closestSeat) {
                                    closestSeat = seat;
                                } else {
                                    const closestSeatRect = closestSeat.getClientRect();
                                    const closest = closestSeatRect.x + closestSeatRect.width/2 - targetRect.x + closestSeatRect.y + closestSeatRect.height/2 - targetRect.y;
                                    const thisSeatRect = seat.getClientRect();
                                    const thisRect = thisSeatRect.x + thisSeatRect.width/2 - targetRect.x + thisSeatRect.y + thisSeatRect.height/2 - targetRect.y;
                                    if (thisRect < closest) {
                                        closestSeat = seat;
                                    }
                                }
                            }
                        });

                        if (closestSeat) {
                            closestSeat.find('.seat-slot').fill('pink');
                            closestSeat.find('.seat-slot').opacity(0.1);
                            closestSeat.find('.seat-assignment').opacity(0.1);
                        }
                    }}
                    onDragEnd={(e) => {
                        const target = e.target;
                        const targetRect = e.target.getClientRect();
                        const otherSeats = seat.getStage().find('.seat');
                        let newSeat;

                        otherSeats.forEach((seat) => {
                            seat.find('.seat-slot').fill('transparent');
                            seat.find('.seat-slot').opacity(1);
                            seat.find('.seat-assignment').opacity(1);

                            if (newSeat || target.parent === seat) {
                                return;
                            }

                            if (haveIntersection(seat.getClientRect(), getSeatAssignment(target))) {
                                newSeat = seat;
                            }
                        });

                        if (newSeat) {
                            swapSeatAssignment(target.parent, newSeat)
                                .then(() => {
                                    target.x(0);
                                    target.y(0);
                                })
                        } else {
                            target.x(0);
                            target.y(0);
                        }
                    }}>
                    <Circle
                        ref={(ref) => seatCircleAssignment = ref}
                        name={'seat-assignment-circle'}
                        offsetX={-snapSizePx * 0.65 - snapSizePx}
                        offsetY={-snapSizePx * 0.65 - snapSizePx}
                        scaleX={0.6}
                        scaleY={0.6}
                        width={snapSizePx * 2}
                        height={snapSizePx * 2}
                        fill={getSeatColor()}
                        stroke={'#ccc'}
                        strokeWidth={1}
                    />
                    <Text fontFamily={'Material Icons'}
                          text={'person'}
                          fontSize={27}
                          fill={'#eee'}
                          width={snapSizePx * 2}
                          height={snapSizePx * 2}
                          align={'center'}
                          verticalAlign={'middle'}
                    />
                    <Label x={labelPos.x} y={labelPos.y} opacity={0.9}>
                        <Tag fill={'#fff'} strokeWidth={0} />
                        <Text
                            align={'center'}
                            width={snapSizePx * 2 - 2}
                            opacity={0.8}
                            padding={3}
                            fontSize={10}
                            ellipsis={true}
                            wrap={'none'}
                            text={assignment.first_name + '\n' + (assignment.last_name || '')}
                            fill="#000"/>
                    </Label>
                </Group>
            ) : null}

        </Group>
    );
}

