import React, {Component} from 'react';
import styled from 'styled-components';
import {lighten, darken} from 'polished';
import WEDDING_CONFIG from '../../../wedding-config';
import Select from "@material-ui/core/Select";
import Icon from "@material-ui/core/Icon";

const colors = WEDDING_CONFIG.colors;

const StyledSelectContainer = styled.div`
    && {
        border: 0;
        outline: 0;
    //border-width: 1px;
      display: inline-flex;
      margin-right: 10px;
      padding: 0;
    background: ${colors.primaryLight};
    // background: ${lighten(0.03, colors.primary)};
     border-bottom: 2px solid ${darken(0.1, colors.primary)};
    letter-spacing: 0;
    border-radius: 2px;
    transition: background 0.2s ease-in, color 0.2s ease-in;
    margin-top: 1px;
    //box-shadow: 1px 1px 0px 1px #ddd;
    background: #f7f7f7;
    border-bottom: 2px solid #b7b7b7;
    border-right: 1px solid #ddd;
    color: #992652;
    
    &:hover {
      border-bottom: 2px solid #992652;
      border-right: 1px solid #ddd;
      background: #eee;
      color: #992652;
    }
    .icon-box {
        padding: 8px 14px;
    }
    
    .icon {
      position: relative;
      font-size: 1.4rem;
      top: 8px;
    }
    
    .text {
        padding: 14px 18px 14px 0;
    }
        
`;

const StyledSelect = styled(Select)`
    && {
        width: 100%;
        background: transparent;
        padding: 12px 0 13px;
        color: #992652;
        
        .MuiSelect-select:focus {
            background: transparent;
        }
        &:before, &:after {
          content: none;
        }
    }
`;

class ActionsSelect extends Component {
    render() {
        const {icon, text, children, style, ...other} = this.props;

        return (
            <StyledSelectContainer style={style}>
                <div className={'icon-box'}><Icon className={'icon'}>{icon}</Icon></div>
                <StyledSelect
                    {...other}>
                    {children}
                </StyledSelect>
            </StyledSelectContainer>
        )
    }
}

export default ActionsSelect;
