import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import styled from 'styled-components';
import { lighten } from 'polished';
import WEDDING_CONFIG from '../../../wedding-config';
import { connect } from 'react-redux';
import store from "../../../store";
import {setPDFDownloaded, toggleDeclarationThankYouModal} from "../../../actions";
import moment from 'moment';
import localization from 'moment/locale/pl';
import mainMenuConfig from './menus/MainMenuConfig';
import {CommonOutlinedButton} from "../../../common/StyledButton";

const colors = WEDDING_CONFIG.colors;


const StyledDialogActions = styled(DialogActions)`
    && {
        text-align: center;
        padding: 40px;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    && {
        padding: 40px;
        text-align: center;
        
        h2 {
            font-size: 2rem;
            color: ${colors.primary};
        }
    }
`;

const StyledDialogContent = styled(DialogContent)`
    && {
        padding: 0 40px;
        font-size: 1.2rem;
        color: #444;
    }
`;

const StyledButton = styled(CommonOutlinedButton)`
    && {}
`;

class DeclarationThankYouDialog extends React.Component {
    handleClose = () => {
        store.dispatch(toggleDeclarationThankYouModal(false));
    };

    render() {
        const { fullScreen } = this.props;
        const localeMoment = moment(this.props.instance.declaration_enabled_until);
        localeMoment.locale('pl', localization);

        return (
            <Dialog
                fullScreen={fullScreen}
                disableBackdropClick={true}
                open={this.props.app.isDeclarationThankYouDialogOpen}
                onClose={this.handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <StyledDialogTitle id="responsive-dialog-title">{"Śliczne dzięki za deklarację!"}</StyledDialogTitle>
                <StyledDialogContent>
                    Otrzymaliśmy Twoją decyzję. Teraz wiemy już wszystko!
                    {localeMoment.isValid() ? (
                        <Fragment>
                            <br/>
                            Pamiętaj, że możesz zmienić swoją deklarację do dnia {localeMoment.format('D MMMM YYYY')}r.
                        </Fragment>
                    ) : ''}
                    {this.props.user.present_first_day && this.props.instance.is_pdf_download_enabled ? (
                        <div><br />Zachęcamy również do pobrania poniższego pliku PDF. Znajdziecie tam najważniejsze informacje organizacyjne.
                        </div>
                    ) : ''}
                    {/*<h4>Zachęcamy do zostawienia nam swojego adresu e-mail</h4>*/}
                    {/*<span>Dzięki temu my będziemy mieli możliwość przypomnieć Ci o wydarzeniu, a Ty będziesz mógł/mogła*/}
                            {/*przypomnieć swój kod, jeśli go zapomnisz.*/}
                        {/*</span>*/}
                </StyledDialogContent>
                <StyledDialogActions style={{justifyContent: 'center'}}>
                    {this.props.user.present_first_day ? (
                        <StyledButton
                            className={'fill-primary'}
                            disabled={this.props.app.wasPDFDownloaded}
                            style={{width: '180px'}} onClick={() => {
                            mainMenuConfig.config.DOWNLOAD_PDF_SUMMARY.onClick();
                            store.dispatch(setPDFDownloaded(true));

                        }} color="primary" variant="outlined" size="large" autoFocus>
                            Pobierz PDF
                        </StyledButton>
                    ) : null}
                    <StyledButton onClick={this.handleClose} color="primary" variant="outlined" size="large" autoFocus>
                        Zamknij
                    </StyledButton>
                </StyledDialogActions>
            </Dialog>
        );
    }
}

DeclarationThankYouDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user,
        instance: state.auth.instance
    }
};

export default connect(mapStateToProps)(withMobileDialog()(DeclarationThankYouDialog));
