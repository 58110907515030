import React, { Component } from 'react';
import {Login} from "../components/Login";
import styled from "styled-components";
import MediaSizes from "../../../constants/media-sizes.constants";

const StyledLoginPageContainer = styled.div`
  min-height: 100vh;
    background: url('/bride.jpg') right top no-repeat white;
    
    @media (max-width: ${MediaSizes.phone}px) {
        background-position: top left;
      background-size: 250% auto;
    }
`;

class LoginPage extends Component {
    render() {
        return (
            <StyledLoginPageContainer>
                <Login />
            </StyledLoginPageContainer>
        )
    }
}

export default LoginPage;
