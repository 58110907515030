import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";
import styled from "styled-components";

const StyledIconButton = styled(IconButton)`
  &.mobile {
    position: relative;
    left: 8px;
    top: -8px;
  }
`;

export default function TasksListActions({ onEdit, onDelete }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMobile = useMediaQuery(MediaRule.tablet);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    return (
        <div>
            <StyledIconButton className={isMobile ? 'mobile' : ''} onClick={handleClick}>
                <Icon>more_vert</Icon>
            </StyledIconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { onEdit(); handleClose(); }}>Edytuj</MenuItem>
                <MenuItem onClick={() => { onDelete(); handleClose(); }}>Usuń</MenuItem>
            </Menu>
        </div>
    );
}
