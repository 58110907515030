import React from 'react';
import {Circle, Text, Group} from 'react-konva';

export class SeatEdit extends React.Component {
    state = {
        deselected: false,
    };
    snapSizePx;
    group;

    constructor(props) {
        super(props);
        this.snapSizePx = props.snapSize;
    }

    handleClick = () => {
        this.setState({
            deselected: !this.state.deselected
        }, () => {
            this.props.onDisableStateChange(this.state.deselected);
        });
    };

    render() {
        return (
            <Group
                ref={(ref) => this.group = ref}
                x={this.props.x}
                y={this.props.y}
                rotation={this.props.rotation}
                listening={true}
                onMouseOver={(e) => {
                    this.group.getStage().container().style.cursor = 'pointer';
                }}
                onMouseLeave={(e) => {
                    this.group.getStage().container().style.cursor = 'default';
                }}>
                <Circle
                    offsetX={-this.snapSizePx * 1.2}
                    offsetY={-this.snapSizePx * 1.2}
                    scaleX={0.8}
                    scaleY={0.8}
                    width={this.snapSizePx * 2}
                    height={this.snapSizePx * 2}
                    fill={'#fff'}
                    stroke={'#aaa'}
                    strokeWidth={1}
                    opacity={this.state.deselected ? 0.2 : 1}
                    onClick={this.handleClick}
                />
                {!this.state.deselected ? (<Text fontFamily={'Material Icons'}
                      text={'person'}
                      fontSize={26}
                      x={-0.5}
                      y={-1}
                      fill={'#ccc'}
                      width={this.snapSizePx * 2}
                      height={this.snapSizePx * 2}
                      align={'center'}
                      verticalAlign={'middle'}
                      style={{cursor: 'pointer'}}
                      onClick={this.handleClick}
                />) : null}
            </Group>
        );
    }
}
