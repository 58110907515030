
const getFromLS = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

const setInLS = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export default {
    getFromLS,
    setInLS
}
