import {Component, Fragment, useEffect, useState} from "react";
import React from "react";
import styled from 'styled-components';
import moment from 'moment';
import localization from 'moment/locale/pl';
import WEDDING_CONFIG from "../../../wedding-config";
import {useSelector} from "react-redux";
import appService from "../../../service/app.service";
import store from "../../../store";
import {setChangelogData} from "../../../actions";
import {CommonOutlinedButton} from "../../../common/StyledButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../constants/media-sizes.constants";
import ChangelogDialog from "./ChangelogDialog";

const colors = WEDDING_CONFIG.colors;

const StyledChangelogItem = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
  border-right: solid 1px #eee;
  border-bottom: solid 1px #eee;
  padding: 10px;
  display: flex;
`;

const StyledChangelogItemTitle = styled.div`
  font-size: 0.9rem;
  color: ${colors.primaryLight};
  text-align: left;
  width: 130px;
  margin-bottom: 7px;
`;

const StyledTd = styled.div`
  padding: 0;
  margin-top: 4px;
  margin-bottom: 10px;
  color: #505050;
`;

const StyledReadMore = styled.small`
  a {
    color: ${colors.primaryLight};
  }
`;

export default function Changelog() {
    const changelogData = useSelector(state => state.app.changelogData) || [];
    const [showMore, setShowMore] = useState(false);
    const [details, setDetails] = useState(false);
    const isMobile = useMediaQuery(MediaRule.tablet);
    let changelogDataDisplayed = showMore ? changelogData : changelogData.slice(0, 2);

    useEffect(() => {
        if (!changelogData.length) {
            appService.getChangelogData().then((response) => {
                store.dispatch(setChangelogData(response.data));
            });
        }
    }, []);

    return (
        <Fragment>
            {details ? <ChangelogDialog
                isMobile={isMobile}
                changelogDetails={details}
                handleClose={() => setDetails(null)}/> : null}
            {changelogDataDisplayed && changelogDataDisplayed.length ? (
                <div>
                    {changelogDataDisplayed.map((changelogItem, index) => {
                        const localeMoment = moment(changelogItem.created_at);
                        localeMoment.locale('pl', localization);

                        return (
                            <StyledTd>
                                <StyledChangelogItemTitle>{localeMoment.format('D MMMM YYYY')}</StyledChangelogItemTitle>

                                {changelogItem.text}
                                {changelogItem.long_text ? <StyledReadMore> <a href={''} onClick={(e) => {e.preventDefault(); setDetails(changelogItem)}}>czytaj więcej</a></StyledReadMore> : null}
                            </StyledTd>
                        ) })}
                </div>
            ) : 'Brak aktualności na tę chwilę.'}
            {!showMore ? <div className={isMobile ? 'text-right' : ''}>
                <CommonOutlinedButton className={'size-small'} onClick={() => setShowMore(true)}>pokaż więcej</CommonOutlinedButton>
            </div> : null}
        </Fragment>
    );
}
