export const CONTENTS = {
    PAGE_GENERAL: 'about_wedding',
    PAGE_MAP: 'map',
    PAGE_ACCOMMODATION: 'accommodation',
    PAGE_GIFTS: 'gifts',
    // BOTTOM_LEFT_TEXT: 'bottom_left_text',
    DECLARATION_INTRO: 'declaration_intro',
    PAGE_PHOTOS: 'photos',
    AFTER_WEDDING: 'after_wedding',
};

export const DEFAULT_GRID_SPACING = 2;
export const DEFAULT_IMAGE_BORDER_RADIUS = 10;


export const CONTENTS_TRANSLATIONS = {
    about_wedding: 'Strona: O weselu',
    map: 'Strona: Mapa i dojazd',
    photos: 'Strona: Zdjęcia (zapowiedź)',
    accommodation: 'Strona: Nocleg',
    gifts: 'Strona: Prezenty',
    // bottom_left_text: 'Element: Dolny tekst',
    declaration_intro: 'Deklaracja: tekst powitalny',
    after_wedding: 'Podziękowanie / zdjęcia (po weselu)'
};

export const CONTENTS_INFO_TRANSLATIONS = {
    about_wedding: 'Na tej stronie wypisz wszelkie informacje organizacyjne i harmonogram wydarzenia.',
    map: 'Tutaj dodaj informacje i wskazówki na temat dojazdu.',
    photos: 'Będziecie mieli galerię zdjęć online dla gości? Zostaw taką informację na tej stronie, aby wszyscy pamiętali o zalogowaniu się później.',
    accommodation: 'Jeśli oferujecie nocleg, umieść tutaj informację na ten temat.',
    gifts: 'Na stronie Prezenty możecie zaproponować gościom swoje sugestie odnośnie prezentów.',
    bottom_left_text: 'Element: Dolny tekst.',
    declaration_intro: 'Tutaj umieśćcie tekst, który ma się wyświetlić na pierwszej stronie deklaracji.',
    after_wedding: 'Ta treść (i tylko ta!) zostanie wyświetlona gościom, którzy zalogują się do aplikacji już po weselu. Możesz umieścić tutaj ładne podziękowanie za przybycie a także np. link do galerii zdjęć lub grupy FB.'
};
