import { ACTION_NAMES } from '../actions';

const initialState = {
    apiParams: {},
    premiumPrice: null,
    isMainModalOpened: false,
    isDeclarationThankYouDialogOpen: false,
    mainModalActiveStep: 0,
    mainModalMaxSteps: 5,
    errorMessage: null,
    isInstanceActive: true,
    activePage: null,
    isAfterWedding: false,
    adminPanelOn: false,
    successMessage: null,
    changelogData: null,
    adminNewChatMessagesCount: 0,
    guestNewChatMessagesCount: 0,
};

export const app = (state = initialState, action) => {
    switch(action.type) {
        case ACTION_NAMES.SET_API_PARAMS:
            state = {
                ...state,
                apiParams: action.apiParams
            };
            break;
        case ACTION_NAMES.SET_GUEST_CHAT_NEW_MESSAGES_COUNT:
            state = {
                ...state,
                guestNewChatMessagesCount: action.count
            };
            break;
        case ACTION_NAMES.SET_CHAT_NEW_MESSAGES_COUNT:
            state = {
                ...state,
                adminNewChatMessagesCount: action.count
            };
            break;
        case ACTION_NAMES.SET_CHANGELOG_DATA:
            state = {
                ...state,
                changelogData: action.changelogData
            };
            break;
        case ACTION_NAMES.SET_AFTER_WEDDING:
            state = {
                ...state,
                isAfterWedding: action.isAfterWedding
            };
            break;
        case ACTION_NAMES.SET_PREMIUM_PRICE:
            state = {
                ...state,
                premiumPrice: action.premiumPrice
            };
            break;
        case ACTION_NAMES.SET_PDF_DOWNLOADED:
            state = {
                ...state,
                wasPDFDownloaded: action.wasPDFDownloaded
            };
            break;
        case ACTION_NAMES.TOGGLE_ADMIN_PANEL:
            state = {
                ...state,
                adminPanelOn: action.adminPanelOn
            };
            break;
        case ACTION_NAMES.TOGGLE_MAIN_MODAL:
            state = {
                ...state,
                isMainModalOpened: action.isMainModalOpened
            };
            break;
        case ACTION_NAMES.SET_MAIN_MODAL_ACTIVE_STEP:
            state = {
                ...state,
                mainModalActiveStep: action.mainModalActiveStep
            };
            break;
        case ACTION_NAMES.SET_MAIN_MODAL_MAX_STEPS:
            state = {
                ...state,
                mainModalMaxSteps: action.mainModalMaxSteps
            };
            break;
        case ACTION_NAMES.SET_ERROR:
            state = {
                ...state,
                errorMessage: action.errorMessage
            };
            break;
        case ACTION_NAMES.SET_SUCCESS:
            state = {
                ...state,
                successMessage: action.successMessage
            };
            break;
        case ACTION_NAMES.TOGGLE_ADMIN_USER_EDIT_MODAL:
            state = {
                ...state,
                adminUserEditId: action.userId
            };
            break;
        case ACTION_NAMES.SET_INSTANCE_ACTIVE_PARAM:
            state = {
                ...state,
                isInstanceActive: action.isInstanceActive
            };
            break;
        case ACTION_NAMES.SET_ACTIVE_PAGE:
            state = {
                ...state,
                activePage: action.activePage
            };
            break;
        case ACTION_NAMES.SET_TRIAL_DAYS:
            state = {
                ...state,
                trialDays: action.trialDays
            };
            break;
        case ACTION_NAMES.TOGGLE_DECLARATION_THANK_YOU_MODAL:
            state = {
                ...state,
                isDeclarationThankYouDialogOpen: action.isDeclarationThankYouDialogOpen
            };
            break;
    }

    return state;
};
