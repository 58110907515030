import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, {keyframes} from "styled-components";
import Icon from "@material-ui/core/Icon";
import {StyledEmptyPlaceholder} from "../../../common/StyledEmptyPlaceholder";
import ActionsPanel from "../partials/ActionsPanel";
import Grid from "@material-ui/core/Grid";
import ActionsPanelButton from "../partials/ActionsPanelButton";
import {StyledCard} from "../../../common/StyledCard";
import {lighten} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import accommodationService from '../../../service/accommodation.service';
import WEDDING_CONFIG from "../../../wedding-config";
import {StyledFancyButton} from "../../../common/StyledButton";
import {UserSelectBox, UserSelectBoxType} from "./tables-setup/UserSelectBox";
import store from "../../../store";
import {
    addAssignment,
    setUsersList,
    removeAssignment,
    setSelectedSetId,
    setAvailableUsersList, getSets, removeSet
} from "../../../actions/accommodation.actions";
import AccommodationRoomDialog from "./accommodation/AccommodationRoomDialog";
import AccommodationPlaceDialog from "./accommodation/AccommodationPlaceDialog";
import AccommodationSetDialog from "./accommodation/AccommodationSetDialog";
import AccommodationChangePlaceDialog from "./accommodation/AccommodationChangePlaceDialog";
import AccommodationChangeRoomDialog from "./accommodation/AccommodationChangeRoomDialog";
import {StyledTooltip} from "../../../common/TooltipTitle";
import AccommodationDownloadDialog from "./accommodation/AccommodationDownloadDialog";
import ActionsSelect from "../partials/ActionsSelect";
import {DesktopActionsWrapper} from "./DesktopActions";

const colors = WEDDING_CONFIG.colors;

const StyledPlace = styled.div`
  border: 1px solid ${lighten(colors.primaryLight, 0.5)};
  border-radius: 2px;
  padding: 30px;
  position: relative;
`;

const StyledPlaceName = styled.div`
  position: absolute;
  top: 0;
  left: 30px;
  background: white;
  padding: 3px 10px;
  font-size: 1.6rem;
  transform: translateY(-50%);
  cursor: pointer;
  
  .icon {
    opacity: 0.3;
  }
  
  &:hover {
    .icon {
      opacity: 1;
    }
  }
`;

const StyledPlaceRooms = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const animNew = keyframes`
  0% {
    opacity: 0;
    background: ${colors.primaryLight};
    transform: translateY(-80px);
  }

  40% {
    opacity: 0;
    transform: translateY(-80px);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
    background: #f7f7f7;
  }  
`;

const StyledRoom = styled.div`
      border: 2px solid white;
  position: relative;
  width: calc(100% / 5);
  box-sizing: border-box;
        background: #f7f7f7;
    color: #666;
    padding: 0 10px 90px 10px;
    min-height: 300px;

  .room-name {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    white-space: nowrap;
    cursor: pointer;
    
    &:hover {
      background: #ddd;
    }
  }
  
  .room-number {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
  }
  
  .room-guests {
    margin-top: 20px;
    text-align: center;
  }
  
  &.just-added {
    animation: 0.5s ${animNew} forwards;
  }
`;

const StyledAddRoom = styled(StyledFancyButton)`
  && {
      width: calc(100% / 5);
      min-height: 300px;
      text-align: center;
      border: 0;
      //margin-top: 30px;
      color: #aaa;
      background: #fcfcfc;

      .icon {
        font-size: 3rem;
      }
      
      .label {
        position: absolute;
        top: 15px;
        letter-spacing: 0;
        left: 0;
        right: 0;
      }
      
      &:hover {
        color: #888;
      background: #eee;
      }
  }
`;

const StyledRoomAddGuestButton = styled.div`
  && {
    box-sizing: border-box;
      text-align: center;
      padding: 3px 0;
      margin-top: 2px;
      cursor: pointer;
      
      &:hover {
        background: #eee;
      }
      
      &.empty {
          font-style: italic;
          color: #aaa;
      }
  }
`;

class AccommodationPlanner extends Component {
    state = {
        selectedSet: null,
        canDownload: false,
        userSelecting: null,
        addingSet: false,
        addingRoomToPlaceId: null,
        addingPlace: false,
        editingState: null,
        editingRoom: null,
        managingPlaces: false
    };

    componentDidMount() {
        this.getSets();
        this.getUsers();
        if (this.props.accommodation.selectedAccommodationSetId) {
            store.dispatch(setSelectedSetId(this.props.accommodation.selectedAccommodationSetId));
        }
    }

    changeSet(event) {
        if (event.target.value === 'none') {
            this.addSet();
            return;
        }

        store.dispatch(setSelectedSetId(event.target.value));
    }

    getSets() {
        store.dispatch(getSets());
    }

    getUsers() {
        return accommodationService.getParticipantsList()
            .then((response) => {
                store.dispatch(setUsersList(response.data));
                store.dispatch(setAvailableUsersList());
            });
    }

    handleOpenClose(key, value) {
        this.setState({
            [key]: value
        });
    }

    addPlace() {
        this.setState({
            addingPlace: true
        });
    }

    handleClosePlaceDialog() {
        this.setState({
            addingPlace: false
        });
    }

    addSet() {
        this.setState({
            addingSet: true
        });
    }

    handleCloseAddSet() {
        this.setState({
            addingSet: false
        });
    }

    addRoom(placeId) {
        this.setState({
            addingRoomToPlaceId: placeId
        });
    }

    handleCloseRoomDialog() {
        this.setState({
            addingRoomToPlaceId: null
        });
    }

    removeSet(set, e) {
        e.preventDefault();
        e.stopPropagation();
        if (!window.confirm(`Czy na pewno chcesz usunąć plan ${set.name} wraz z wszystkimi jego miejscami, pokojami i przypisaniami?`)) {
            return;
        }
        store.dispatch(removeSet(set.id));
    }

    onUserSelect(user) {
        store.dispatch(addAssignment(this.props.accommodation.selectedAccommodationSetId, {
            id_participant: user.id,
            participant_type: user.participant_type,
            participant_index: user.participant_index,
            name: user.first_name,
            accommodation: user.accommodation,
            id_accommodation_set: this.state.userSelecting.id_accommodation_set,
            id_accommodation_room: this.state.userSelecting.id_accommodation_room,
            override: this.state.userSelecting && this.state.userSelecting.assignment ? this.state.userSelecting.assignment.id : undefined,
        }));
        this.setState({
            userSelecting: null,
        });
    }

    onRemove(assignment) {
        store.dispatch(removeAssignment(this.props.accommodation.selectedAccommodationSetId, assignment));

        this.setState({
            userSelecting: null,
        });
    }

    onRoomSlotClick(e, roomId, assignment) {
        const panelWidth = 330;
        const panelHeight = 530;
        let x = e.pageX + panelWidth > window.innerWidth ? window.innerWidth - panelWidth : e.pageX;
        let y = e.pageY + panelHeight > window.innerHeight ? window.innerHeight - panelHeight : e.pageY;
        this.setState({
            userSelecting: {
                id_accommodation_set: this.props.accommodation.selectedAccommodationSetId,
                id_accommodation_room: roomId,
                assignment: assignment,
                x,
                y,
            }
        })
    }

    hideUserPanel(e) {
        if (e.target.id !== 'backdrop') {
            return false;
        }
        this.setState({
            userSelecting: null
        })
    }

    getRoomAssignments(roomId) {
        return this.props.accommodation.assignments.filter((assignment) => assignment.id_accommodation_room === roomId)
    }

    getPlaceholder() {
        if (this.props.accommodation.setsLoaded && !this.props.accommodation.sets.length) {
            return <div>
                Na początek stwórz swój pierwszy plan noclegów.
                <br />
                <small>Możesz to zrobić <a  className={'color-primary link-style'} onClick={() => this.addSet()}>klikając tutaj</a>.</small>
            </div>;
        }
        if (this.props.accommodation.placesLoaded && !this.props.accommodation.places.length) {
            return <div>
                Stwórz co najmniej jedno miejsce noclegowe.
                <br />
                <small>Możesz to zrobić klikając w przycisk Dodaj miejsce u góry.</small>
            </div>;
        }

        return null;
    }

    renderGuests(bedsNumber, roomId) {
        const guests = this.getRoomAssignments(roomId);
        return Array.apply(null, Array(bedsNumber))
            .map((val, index) => [guests[index], index])
            .map(([guest, index]) => guest ? (
            <StyledRoomAddGuestButton key={index} onClick={(e) => this.onRoomSlotClick(e, roomId, guest)}>
                {guest.name}
            </StyledRoomAddGuestButton>
        ) : (
            <StyledRoomAddGuestButton key={index} onClick={(e) => this.onRoomSlotClick(e, roomId)} className={'empty'}>
                puste
            </StyledRoomAddGuestButton>
            ))
    }

    render() {
        const placeholder = this.getPlaceholder();
        return (
            <div>
                {this.state.addingSet ? <AccommodationSetDialog placeId={this.state.addingRoomToPlaceId} handleClose={this.handleCloseAddSet.bind(this)}></AccommodationSetDialog> : null}
                {!!this.state.addingRoomToPlaceId ? <AccommodationRoomDialog placeId={this.state.addingRoomToPlaceId} handleClose={this.handleCloseRoomDialog.bind(this)}></AccommodationRoomDialog> : null}
                {this.state.addingPlace ? <AccommodationPlaceDialog setId={this.props.accommodation.selectedAccommodationSetId} handleClose={this.handleClosePlaceDialog.bind(this)}></AccommodationPlaceDialog> : null}
                {this.state.editingState ? <AccommodationChangePlaceDialog place={this.state.editingState} handleClose={() => this.handleOpenClose('editingState', null)}></AccommodationChangePlaceDialog> : null}
                {this.state.editingRoom ? <AccommodationChangeRoomDialog room={this.state.editingRoom} handleClose={() => this.handleOpenClose('editingRoom', null)}></AccommodationChangeRoomDialog> : null}
                <div>
                    <DesktopActionsWrapper>
                        <ActionsPanel>
                            <Grid container>
                                <Grid item xs={12} sm={8}>
                                    <ActionsSelect
                                        icon={this.props.accommodation.selectedAccommodationSetId ? 'turned_in' : 'turned_in_not'}
                                        style={{width: '230px'}}
                                        displayEmpty
                                        value={this.props.accommodation.selectedAccommodationSetId || ''}
                                        renderValue={selected => {
                                            if (!selected || selected === 'null') {
                                                return <em>Wybierz plan noclegów</em>;
                                            }
                                            const obj = this.props.accommodation.sets.find((set) => set.id === selected);

                                            if (obj) {
                                                return obj.name;
                                            }
                                        }}
                                        onChange={this.changeSet.bind(this)}>
                                        <MenuItem key={1} value={'none'}>+ nowy plan</MenuItem>

                                        {this.props.accommodation.sets.map((set) => (
                                            <MenuItem key={set.id} value={set.id}>{set.name}
                                                <IconButton style={{position: 'absolute', right: 0}}
                                                            onClick={this.removeSet.bind(this, set)}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </MenuItem>
                                        ))}
                                    </ActionsSelect>
                                    <span className={'separator'}></span>
                                    <ActionsPanelButton onClick={this.addPlace.bind(this)} text={"Dodaj miejsce"}
                                                        disabled={!this.props.accommodation.selectedAccommodationSetId}
                                                        icon={"apartment"}></ActionsPanelButton>
                                    <AccommodationDownloadDialog
                                        setId={this.props.accommodation.selectedAccommodationSetId}
                                        places={this.props.accommodation.places}></AccommodationDownloadDialog>
                                </Grid>
                                <Grid item xs={12} sm={4} className={'text-right'}>

                                </Grid>
                            </Grid>
                        </ActionsPanel>
                    </DesktopActionsWrapper>

                    {this.state.userSelecting ? (
                        <UserSelectBox
                            usersList={this.props.accommodation.availableUsersList}
                            assigned={this.state.userSelecting.assignment}
                            hideUserPanel={this.hideUserPanel.bind(this)}
                            handleSelect={this.onUserSelect.bind(this)}
                            handleRemove={this.onRemove.bind(this)}
                            type={UserSelectBoxType.Accommodation}
                            x={this.state.userSelecting.x}
                            y={this.state.userSelecting.y}/>
                    ) : null}

                    {this.props.accommodation.places.map((place) =>
                        <StyledCard key={place.id}>
                            <StyledPlace>
                                <StyledPlaceName onClick={() => this.handleOpenClose('editingState', place)}>{place.name} <Icon className={'icon'}>edit</Icon></StyledPlaceName>
                                <StyledPlaceRooms>
                                    {place.rooms.map((room) => (
                                        <StyledRoom key={room.id} className={room.isNew ? 'just-added' : ''}>
                                            <StyledTooltip placement={'top'} title={'Zmień lub usuń'}>
                                                <div className={'room-name'} onClick={() => this.handleOpenClose('editingRoom', room)}>{room.name}</div>
                                            </StyledTooltip>
                                            <div className={'room-number'}>{this.getRoomAssignments(room.id).length} / {room.beds_number}</div>
                                            <div className={'room-guests'}>
                                                {this.renderGuests(room.beds_number, room.id)}
                                            </div>
                                        </StyledRoom>
                                    ))}
                                    <StyledAddRoom onClick={() => this.addRoom(place.id)}>
                                        <div className={'label'}>nowy pokój</div>
                                        <Icon className={'icon'}>add_circle</Icon>
                                    </StyledAddRoom>
                                </StyledPlaceRooms>
                            </StyledPlace>
                        </StyledCard>
                    )}
                    {placeholder ? (
                        <StyledEmptyPlaceholder style={{marginTop: '80px'}}>
                            <p>{placeholder}</p>
                        </StyledEmptyPlaceholder>
                    ) : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user,
        accommodation: state.accommodation
    }
};

export default connect(mapStateToProps)(AccommodationPlanner);
