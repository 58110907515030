import React, { Component } from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {StyledBigCheckbox, StyledHeader, stylesForFormControlLabel} from './commons';
import {connect} from "react-redux";
import styled from "styled-components";
import StyledCheckboxLabel from '../../../../common/StyledCheckboxLabel';

const StyledFormControlLabel = styled(FormControlLabel)``;

class MainFormSectionParticipation extends Component {

    render() {
        const props = this.props,
            formProps = props.formProps,
            values = formProps.values;

        if (!props.isActive) {
            return null;
        }

        const isAccommodationDisabled = this.props.instance.is_accommodation_only_for_second_day_participants ?
            !values.present_first_day || !values.present_second_day :
            !values.present_first_day;

        return (
            <div>
                <StyledHeader>
                    Czy będziesz obecny na weselu?
                </StyledHeader>

                <div>
                    <StyledFormControlLabel
                        className="font-md"
                        style={stylesForFormControlLabel}
                        control={
                            <StyledBigCheckbox
                                name="present_first_day"
                                key="present_first_day"
                                checked={!!values.present_first_day}
                                value="1"
                                onChange={(e, value) => {
                                    formProps.handleChange(e);
                                    if (!value) {
                                        formProps.setFieldValue('accommodation', isAccommodationDisabled);
                                    }
                                }}
                            />
                        }
                        label={<StyledCheckboxLabel>
                            Będę obecny(a) na weselu
                            <div>
                                <small style={{ color: '#aaa' }}>Pozostaw odznaczone, jeśli Cię nie będzie.</small>
                            </div>
                        </StyledCheckboxLabel>}
                    />
                </div>

                {this.props.instance.is_two_day_party && this.props.user.allow_be_present_second_day ? (
                    <div>
                        <StyledFormControlLabel
                            control={
                                <StyledBigCheckbox
                                    name="present_second_day"
                                    key="present_second_day"
                                    checked={!!values.present_second_day}
                                    value="1"
                                    onChange={(e, value) => {
                                        formProps.handleChange(e);
                                        if (!value) {
                                            formProps.setFieldValue('accommodation', false);
                                        }
                                    }
                                    }
                                />
                            }
                            label={<StyledCheckboxLabel>
                                Będę obecny(a) na poprawinach
                                <div>
                                    <small style={{ color: '#aaa' }}>Pozostaw odznaczone, jeśli Cię nie będzie.</small>
                                </div>
                            </StyledCheckboxLabel>}
                        />
                    </div>
                ) : ''}

                {this.props.instance.is_accommodation_available && this.props.user.allow_selecting_accommodation ? (
                    <div>
                        <StyledFormControlLabel
                            control={
                                <StyledBigCheckbox
                                    name="accommodation"
                                    key="accommodation"
                                    checked={!!(values.present_first_day || values.present_second_day) && !!values.accommodation}
                                    disabled={isAccommodationDisabled}
                                    value="1"
                                    onChange={formProps.handleChange}
                                />
                            }
                            label={<StyledCheckboxLabel>Chcę skorzystać z noclegu</StyledCheckboxLabel>}
                        />
                    </div>
                ) : ''}
            </div>
        )
    }

}



const mapStateToProps = (store) => {
    return {
        instance: store.auth.instance,
        user: store.auth.user
    }
};

export default connect(mapStateToProps)(MainFormSectionParticipation);
