import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { authService } from '../../../service/auth.service';
import MediaSizes from '../../../constants/media-sizes.constants';
import {CommonOutlinedButton} from "../../../common/StyledButton";
import {withTranslation} from "react-i18next";


const animFade = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
  
`;

const LoginFormContainer = styled.div`
    && {
        margin: 0 auto;
        opacity: 0;
        display: inline-block;
        height: 100%;
        padding: 10px;
        text-align: left;
        animation: ${animFade} .5s ease-in forwards;
        
        &.is-premium {
          text-align: center;
          
          h2 {
            font-size: 2rem;
          }
          
          .url {
            font-size: 1.5rem;
          }
        }
    }  
`;

const StyledEmailField = styled(TextField)`
    && {
        margin-left: 5px;
        width: 480px;
        max-width: 88vw;
    }
    
    && input {
        font-size: 24px;
        padding: 15px 0;
    }
`;

const LoginHeader = styled(Typography)`
    && {
        margin: 15px 5px;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
        font-weight: 300;
        font-size: 1.5rem;
        
        @media (max-width: ${MediaSizes.tablet}px) {
            font-size: 1rem;
        }
    }
`;


class PasswordResetRequest extends Component {
    state = {
        errorMessage: '',
        isAuthenticated: false,
        isSubmitting: false,
        isPremium: false,
        premiumInstanceUrl: false,
        isSuccess: false,
    };
    form;

    constructor(props) {
        super(props);
    }

    resetPassword(data, setSubmitting) {
        authService.requestResetPassword(data.email).then(response => {
            setSubmitting(false);

            this.setState({
                isSuccess: true
            });
        }).catch((error) => {
            setSubmitting(false);
        });
    }

    render() {
        return (
            <LoginFormContainer className={this.state.isPremium ? "is-premium" : ""} >
                <LoginHeader variant="h5" gutterBottom>
                    {this.props.t(this.state.isSuccess ? 'passwordRemind.checkEmail' : 'passwordRemind.resetPassTitle')}
                </LoginHeader>

                {this.state.isSuccess ? (
                    <div style={{margin: 5}}>
                        {this.props.t('passwordRemind.ifExists')}
                    </div>
                ) : (
                    <Formik
                        ref={node => this.form = node}
                        validateOnBlur={true}
                        validateOnChange={false}
                        initialValues={{
                            email: '',
                        }}
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            setSubmitting(true);
                            this.resetPassword(values, setSubmitting);
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, submitForm, handleSubmit, isSubmitting, isValid, validateForm, setFieldValue }) => {
                            return (
                                <div>
                                    <StyledEmailField
                                        autoFocus
                                        name="email"
                                        key="email"
                                        placeholder={this.props.t('passwordRemind.email')}
                                        type="email"
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                submitForm();
                                            }
                                        }}
                                        value={values.email}
                                        fullWidth
                                    />
                                    <br />
                                    <br />
                                    <CommonOutlinedButton
                                        onClick={submitForm}
                                        disabled={!values.email}>{this.props.t('common.send')}</CommonOutlinedButton>
                                </div>
                            )
                        }}
                    </Formik>
                )}
            </LoginFormContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.auth.user,
        authUser: state.auth.user,
        errorMessage: state.auth.error || null
    }
};


export default withTranslation()(connect(mapStateToProps)(PasswordResetRequest));
