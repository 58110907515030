import React, {Fragment, useState} from 'react';
import { useForm } from 'react-hook-form';
import WEDDING_CONFIG from '../../../../wedding-config';
import SimpleDialog from "../../../../common/SimpleDialog";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import ActionsPanelButton from "../../partials/ActionsPanelButton";
import StyledCheckboxLabel from "../../../../common/StyledCheckboxLabel";
import {StyledBigCheckbox} from "../../../guest-area/forms/main-form/commons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FileSaver from "file-saver";
import accommodationService from "../../../../service/accommodation.service";

const AccommodationDownloadDialog = (props) => {
    const { register, handleSubmit, errors, getValues, setValue, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            places: props.places.map(place => place.id),
        }
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const onSubmit = data => {
        let places = data.places.filter(placeId => placeId);
        setIsLoading(true);
        accommodationService.exportList(props.setId, places)
            .then((response) => {
                FileSaver.saveAs(response, 'nocleg-fancy.wedding.pdf');
                setIsLoading(false);
                setIsOpen(false)
            }).catch(() => {
            setIsLoading(false);
        });

    };
    let formRef;

    return (
        <Fragment>
            <ActionsPanelButton disabled={!props.setId || !props.places.length} onClick={() => setIsOpen(true)}
                                text={"Pobierz plan"}
                                icon={"get_app"}></ActionsPanelButton>
            <SimpleDialog
                open={isOpen}
                title={'Eksport do pliku PDF'}
                subTitle={<div>Możesz odznaczyć miejsca, których nie chcesz drukować.</div>}
                actions={<Fragment>
                    <CommonOutlinedButton onClick={() => setIsOpen(false)} color="secondary">
                        Anuluj
                    </CommonOutlinedButton>
                    <CommonOutlinedButton className={'fill-primary'} disabled={!formState.isValid || isLoading} onClick={() => formRef.dispatchEvent(new Event('submit', { cancelable: true }))} color="primary">
                        Pobierz
                    </CommonOutlinedButton>
                </Fragment>}
                {...props}>
                <form onSubmit={handleSubmit(onSubmit)} ref={(ref) => formRef = ref}>
                    {props.places.map((place, index) => (
                        <FormControlLabel
                            key={index}
                            className="font-md"
                            control={
                                <StyledBigCheckbox
                                    inputRef={register}
                                    name={`places[${index}]`}
                                    defaultChecked={true}
                                    key={place.id}
                                    value={place.id}
                                />
                            }
                            label={<StyledCheckboxLabel>{place.name}</StyledCheckboxLabel>}
                        />
                    ))}
                </form>
            </SimpleDialog>
        </Fragment>
    )
};

export default AccommodationDownloadDialog;
