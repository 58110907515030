import React, {useState, Fragment, useEffect} from 'react';
import SimpleDialog from "../../../../common/SimpleDialog";
import styled from "styled-components";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import Select from "@material-ui/core/Select";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import ChatService from '../../../../service/chat.service';
import {StyledSelectOption} from "../../../../common/StyledSelect";
import store from "../../../../store";
import {setSuccess} from "../../../../actions";

const StyledSelectField = styled(Select)`
  && {
    width: 300px;
    max-width: 100%;
  }
`;

export const StyledChatMessageInput = styled.textarea`
  padding: 18px;
  font-size: 16px;
  display: block;
  width: 500px;
  max-width: 100%;
  box-sizing: border-box;
  font-family: "PT Sans Regular";
  outline: 0;
  resize: none;
  border: 1px solid #ddd;
  color: #888;
  transition: border-color 0.25s ease-in;
  
  &:focus {
    border-color: #ddd;
    color: #666;
  }

  ${props => props.invalid ? `
    border-color: red!important;
  ` : ''}
`;

export function AdminChatNewMessage({ handleClose }) {
    const [messageType, setMessageType] = useState(null);
    const [usersList, setUsersList] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [message, setMessage] = useState('');

    function sendMessage() {
        if (messageType === 'all') {
            if(!window.confirm('Uwaga! Wysyłasz ogłoszenie do wszystkich użytkowników. Kontynuować?')) {
                return;
            }
        }

        const request = messageType === 'all' ?
            ChatService.sendSuAnnouncementMessage(message) :
            ChatService.sendSuMessageToParticipant(selectedUser.id, message);

        request.then(() => {
            store.dispatch(setSuccess(messageType === 'all' ?
                'Ogłoszenie zostało wysłane.' : 'Wiadomość została wysłana.'));
            handleClose(true);
        });
    }

    function getUsers() {
        ChatService.getSuChatUsersList().then((userslist) => {
            setUsersList(userslist.data);
        });
    }

    function isSubmitDisabled() {
        if (messageType === 'single' && (!selectedUser || !message)) {
            return true;
        }

        if (messageType === 'all' && !message) {
            return true;
        }

        return false;
    }

    useEffect(() => {
        if (messageType === 'single' || !usersList) {
            getUsers();
        }
    }, [messageType]);

    return (
        <SimpleDialog
            title={'Do kogo chcesz napisać?'}
            open={true}
            onClose={handleClose}
            actions={
                <Fragment>
                    <CommonOutlinedButton onClick={() => handleClose()} color="primary">
                        Anuluj
                    </CommonOutlinedButton>
                    {messageType ? <CommonOutlinedButton className={'fill-primary'} disabled={isSubmitDisabled()} onClick={() => sendMessage()} color="primary">
                        Wyślij
                    </CommonOutlinedButton> : null}
                </Fragment>
            }
            maxWidth={'md'}>
            <div>
                <RadioGroup
                    value={messageType}
                    onChange={(event, value) => {
                    if (value !== null) {
                        setMessageType(value)
                    }
                }}>
                    <FormControlLabel value="single" control={<Radio />} label="Do wybranego gościa" />
                    <FormControlLabel value="all" control={<Radio />} label="Do wszystkich gości" />
                </RadioGroup>
            </div>
            {messageType ? (
                <Fragment>
                    <div>
                        {messageType === 'single' ? <p>
                            Wybierz gościa:
                        </p> : null}
                        {messageType === 'all' ? <div className={'info-box info-box--big'} style={{ marginTop: '20px', marginBottom: '20px'}}>
                            Wysyłasz ogłoszenie do wszystkich użytkowników.
                        </div> : null}
                    </div>
                    {messageType === 'single' ? (
                        <div style={{ marginBottom: '20px'}}>
                            <StyledSelectField onChange={(event) => setSelectedUser(event.target.value)} placeholder={'Wybierz z listy...'}>
                                {usersList ? usersList.map((user) => <StyledSelectOption value={user}>
                                    {user.first_name} {user.last_name}
                                </StyledSelectOption>) : null}
                            </StyledSelectField>
                        </div>
                    ) : null}
                    <div>
                        <StyledChatMessageInput placeholder={'Napisz wiadomość...'} onChange={(e) => setMessage(e.target.value)} />
                    </div>
                </Fragment>
            ) : null}
        </SimpleDialog>
    )
}
