import React, {Fragment, useState} from "react";
import {Polar} from "react-chartjs-2";
import styled from "styled-components";
import Link from "react-router-dom/Link";
import WEDDING_CONFIG from "../../../../wedding-config";

const StyledTitle = styled.div`
  font-size: 2.5rem;
  color: ${WEDDING_CONFIG.colors.primaryLight};
  text-align: center;
  
  .sub {
    font-size: 1.5rem;
  }

  &.no-tasks {
    pointer-events: all;
    color: #666;
    font-size: 2rem;
  
      .sub {
        font-size: 1.2rem;
      }
  }
`;

export default function TasksChart({ data }) {
    const mappedData = remapData(data);
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true,
            fullWidth: true,
            position: 'bottom'
        },
        tooltips: {
            mode: 'index',
            intersect: true,
            callbacks: {
                title: (items, data) => {
                    return data.labels[items[0].index];
                },
                label: function(tooltipItem, data) {
                    const isLeftToDo = tooltipItem.datasetIndex === 1;
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';

                    if (label) {
                        label += ': ';
                    }
                    if (isLeftToDo) {
                        label += Math.round(tooltipItem.yLabel * 100) / 100 - data.datasets[0].data[tooltipItem.index];
                    } else {
                        label += Math.round(tooltipItem.yLabel * 100) / 100;
                    }
                    return label;
                }
            }
        },
        scale: {
            // display:false,
            pointLabels:{
                display: false
            },
            lineArc: false,
            ticks: {
                maxTicksLimit: 1,
                callback: () => '',
                max: Math.max(...Object.values(data).map(items => items.length))
            },
            gridLines:{
                display: false,
                offsetGridLines: true,
                drawBorder: false,
                lineWidth: 1
            }
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };

    return (
        <div style={{minHeight: 400}}>
            <Polar data={mappedData} options={options} />
        </div>
    )
}

function remapData(data) {
    const newData = {};

    Object.keys(data).forEach((item) => {
        if (!data[item].length) {
            return;
        }

        const name = item === 'others' ? 'Pozostałe' : item;

        newData[name] = data[item];
    });
    const pData = Object.values(newData);
    const dataAll = pData.map(items => items.length);
    const dataDone = pData.map(items => items.filter(item => item.is_done).length);

    return {
        datasets: [
            {
                data: dataDone,
                backgroundColor: '#bd7089 #c47e95 #ca8da1 #d19bac #d7a9b8 #deb8c4 #e5c6d0 #ebd4dc'.split(' '),
                borderColor: 'transparent',
                label: 'Wykonane'
            },
            {
                data: dataAll,
                backgroundColor: dataAll.map(() => '#eee'),
                label: 'Do zrobienia'
            },
        ],
        labels: Object.keys(newData)
    };
}
