import styled from "styled-components";

export const StyledEmptyPlaceholder = styled.div`
  text-align: center;
  p {
    font-size: 1.8rem;
    color: #ccc;
  }
  
  .icon {
    font-size: 3rem;
    vertical-align: sub;
  }
  
  small {
    font-size: 1.15rem;
  }
`;
