import React, {Fragment, useState} from 'react';
import WEDDING_CONFIG from '../../../../wedding-config';
import SimpleDialog from "../../../../common/SimpleDialog";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import ActionsPanelButton from "../../partials/ActionsPanelButton";
import tableService from "../../../../service/table.service";
import FileSaver from "file-saver";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import jsPDF from "jspdf";

const colors = WEDDING_CONFIG.colors;

const StyledBox = styled.div`
  width: 400px;
  max-width: 100%;
  padding: 25px 40px 25px 0;
  
  &.right {
    border-left: 1px solid #ddd;
    padding: 25px 0 25px 40px;
  }
`;

const TableDownloadDialog = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const downloadGraphic = () => {
        const stage = props.getStage();
        const pdf = new jsPDF({
            unit: 'px',
            orientation: 'portrait',
            format: 'a4'
        });
        pdf.setTextColor('#000000');
        const width = 405;
        const ratio = stage.width() / stage.height();
        pdf.addImage(
            stage.toDataURL({pixelRatio: 2}),
            20,
            20,
            width,
            width / ratio,
        );
        getDataUri('/logo-transparent-canvas.png', function(base, w, h) {
            pdf.addImage(
                base,
                'png',
                20,
                20,
                w / 8,
                h / 8,
            );
            pdf.save('plan-stolow.pdf');
        });

        function getDataUri(url, cb)
        {
            var image = new Image();

            image.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = this.naturalWidth;
                canvas.height = this.naturalHeight;

                //next three lines for white background in case png has a transparent background
                var ctx = canvas.getContext('2d');
                ctx.fillStyle = '#fff';  /// set white fill style
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                canvas.getContext('2d').drawImage(this, 0, 0);

                cb(canvas.toDataURL('image/jpeg'), this.naturalWidth, this.naturalHeight);
            };
            image.src = url;
        }
    };
    const downloadList = () => {
        setIsLoading(true);

        tableService.exportList(props.selectedTableSetId)
            .then((response) => {
                FileSaver.saveAs(response, 'rozmieszczenie-gosci.pdf');
                setIsLoading(false);
            });
    };

    return (
        <Fragment>
            <ActionsPanelButton disabled={props.disabled} onClick={() => setIsOpen(true)}
                                text={"Pobierz plan"}
                                icon={"get_app"}></ActionsPanelButton>
            <SimpleDialog
                open={isOpen}
                handleClose={() => setIsOpen(false)}
                title={'Eksport do pliku PDF'}
                subTitle={<div>Możesz wydrukować wersję w formie graficznej lub w formie listy.</div>}
                actions={<Fragment>
                    <CommonOutlinedButton onClick={() => setIsOpen(false)} color="secondary">
                        Zamknij
                    </CommonOutlinedButton>
                </Fragment>}
                {...props}>
                <Grid container>
                    <Grid item xs={6}>
                        <StyledBox className={'text-center'}>
                            <strong style={{fontSize: '1.2rem'}}>Pobierz grafikę</strong>
                            <br />
                            <p>Wersja graficzna Twojego planu.</p>
                            <CommonOutlinedButton className={'fill-primary'} onClick={() => downloadGraphic()} color="primary" disabled={isLoading}>
                                Pobierz
                            </CommonOutlinedButton>
                        </StyledBox>
                    </Grid>
                    <Grid item xs={6} className={'text-center'}>
                        <StyledBox className={'right'}>
                            <strong style={{fontSize: '1.2rem'}}>Pobierz listę</strong>
                            <br />
                            <p>Wersja w formie listy stołów.</p>
                            <CommonOutlinedButton className={'fill-primary'} onClick={() => downloadList()} color="primary" disabled={isLoading}>
                                Pobierz
                            </CommonOutlinedButton>
                        </StyledBox>
                    </Grid>
                </Grid>
            </SimpleDialog>
        </Fragment>
    )
};

export default TableDownloadDialog;
