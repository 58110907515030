import React, {Component} from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';
import TextField from "@material-ui/core/TextField";

const colors = WEDDING_CONFIG.colors;

const StyledField = styled.div`
   text-align: center;
`;

const StyledTextField = styled(TextField)`
    && {
      width: 40px;
      display: inline-block;
      vertical-align: middle;
      
      input {
        text-align: center;
        font-size: 1.4rem;
      }
    }
`;

const StyledButton = styled.span`
    && {
      color: ${colors.primaryLight};
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      font-size: 2.5rem;
      vertical-align: middle;
      user-select: none;
      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }
`;

class IncrementField extends Component {
    inputRef = React.createRef();

    changeValue(decrement) {
        this.props.onChange({target: {name: this.props.name, value: (parseInt(this.inputRef.value) || 0) + (decrement ? -1 : 1)}})
    }

    increment() {
        if (!this.isIncrementPossible()) {
            return;
        }
        this.changeValue();
    }

    isIncrementPossible() {
        const value = this.props.value;

        if (this.props.max && parseInt(value) >= this.props.max) {
            return false;
        }
        return true;
    }

    isDecrementPossible() {
        const value = this.props.value;
        const min = this.props.min || 0;

        if (value <= min) {
            return false;
        }
        return true;
    }

    decrement() {
        if (parseInt(this.inputRef.value) > 0) {
            this.changeValue(true);
        }
    }

    render() {
        const { inputRef, isHookForm, ...other } = this.props;

        return (
            <StyledField>
                <StyledButton className={`increment-minus ${!this.isDecrementPossible() ? 'disabled' : ''}`} onClick={this.decrement.bind(this)}>-</StyledButton>
                <StyledTextField
                    className="increment-number"
                    inputRef={isHookForm && inputRef ? (input => { inputRef(input); this.inputRef = input }) : (input => this.inputRef = input)}
                    type={'number'}
                    InputProps={{
                        readOnly: true,
                    }}
                    {...other}
                />
                <StyledButton className={`increment-plus ${!this.isIncrementPossible() ? 'disabled' : ''}`} onClick={this.increment.bind(this)}>+</StyledButton>
            </StyledField>
        )
    }
}

export default IncrementField;
