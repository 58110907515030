import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { authService } from '../../../service/auth.service';
import {updateUser, updateInstance, toggleAdminPanel} from '../../../actions';
import TRANSLATE from '../../../translation';
import {CommonOutlinedButton} from "../../../common/StyledButton";
import NavLink from "react-router-dom/NavLink";
import ENV from "../../../config/environment";
import {StyledTextField} from "../../../common/StyledInput";
import {useFormik} from "formik-newest";
import store from "../../../store";
import {StyledCard} from "../../../common/StyledCard";
import {isPWA} from "../../../utilities";
import {useTranslation} from "react-i18next";

const LoginHeader = styled(Typography)`
    && {
        padding: 10px 0;
        font-weight: 300;
        font-size: 1.2rem;
    }
`;

const StyledEmailField = styled(StyledTextField)`
    && {
        width: 100%;
        max-width: 88vw;
    }
`;

const StyledPasswordField = styled(StyledTextField)`
    && {
        max-width: 88vw;
    }
`;

export function LoginLiteForm() {
    const [errorMessage, setErrorMessage] = useState('');
    const [accountIsPremium, setAccountIsPremium] = useState(false);
    const [premiumUrl, setPremiumUrl] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const { t, i18n } = useTranslation();
    const isPwa = isPWA();
    const loginForm = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: values => {
            authService.authenticate(values).then(response => {
                setIsProcessing(true);
                authService.checkLoginState()
                    .then(response => {
                        const responseData = response.data;
                        store.dispatch(toggleAdminPanel(true));
                        store.dispatch(updateInstance(responseData.instance));
                        store.dispatch(updateUser(responseData.user));
                        setIsProcessing(false);
                    })
            }).catch((error) => {
                let errorMsg = '';

                try {
                    if (error.response.data.data && error.response.data.data.is_premium && error.response.data.data.instance_url) {
                        setAccountIsPremium(true);
                        setPremiumUrl(error.response.data.data.instance_url);
                    }
                    errorMsg = TRANSLATE.now(error.response.data.message);
                } catch(e) {
                    errorMsg = 'Wystąpił nieznany błąd'
                }

                setErrorMessage(errorMsg);
                setIsProcessing(false);
                loginForm.setFieldValue('password', '');
            });
        },
    });

    return (
        <Fragment>
            {accountIsPremium ? (
                <div className={'text-center'}>
                    {isPwa ? <Fragment>
                        Prosimy od teraz loguj się na poniższym adresie WWW.
                        <br />
                        <br />
                        Jako, że adres się zmienił, niezbędna będzie również <b>ponowna instalacja aplikacji</b>.
                        <br />Możesz śmiało usunąć dotychczasowy skrót.
                        <br />Po wejściu na poniższy adres dodaj nową aplikację do ekranu początkowego.
                    </Fragment> : <Fragment>
                        Prosimy od teraz loguj się na Twoim własnym adresie WWW:
                    </Fragment>}
                    <br/><br/>
                    <a className={'url'} href={premiumUrl}>{premiumUrl}</a>
                </div>
            ) : (
                <div>
                    <LoginHeader variant="h5" gutterBottom>
                        {errorMessage || t('standardLoginForm.logInTitle')}
                        {isProcessing ? (
                            <CircularProgress style={{float: 'right'}} size={35} color="secondary" />
                        ) : null}
                    </LoginHeader>
                    <StyledCard>
                        <StyledEmailField
                            autoFocus
                            name="email"
                            key="email"
                            placeholder={'E-mail'}
                            type="email"
                            onChange={loginForm.handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    loginForm.submitForm();
                                } else {
                                    setErrorMessage('');
                                }
                            }}
                            value={loginForm.values.email}
                            fullWidth
                        />
                        <br />
                        <br />
                        <StyledPasswordField
                            name="password"
                            key="password"
                            placeholder={'Hasło'}
                            type="password"
                            onChange={loginForm.handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    loginForm.submitForm();
                                } else {
                                    setErrorMessage('');
                                }
                            }}
                            value={loginForm.values.password}
                            fullWidth
                        />
                        <br />
                        <br />
                        <CommonOutlinedButton
                            onClick={loginForm.submitForm}
                            disabled={isProcessing || !loginForm.values.email || !loginForm.values.password}>Wejdź</CommonOutlinedButton>
                        <br />
                        <br />
                        <br />
                        <NavLink to={'/request-reset-password'}>Nie pamiętam hasła</NavLink>
                        <span style={{margin: '0 10px 0 10px'}}>/</span>
                        <a href={ENV.WEBSITE_URL + '/aplikacja'}>Załóż konto</a>
                    </StyledCard>
                </div>
            )}
        </Fragment>
    );
}
