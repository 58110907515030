import React, {Component, Fragment} from 'react';
import styled, {keyframes} from 'styled-components';
import Icon from '@material-ui/core/Icon';
import WEDDING_CONFIG from '../../../wedding-config';
import {PartnerNameDialog} from './PartnerNameDialog';
import {lighten, transparentize} from 'polished';
import {connect} from 'react-redux';
import store from '../../../store'
import {toggleMainModal, setMainModalActiveStep} from '../../../actions';
import {CommonOutlinedButton, StyledFancyButtonV2} from '../../../common/StyledButton';
import MediaSizes from '../../../constants/media-sizes.constants';
import moment from 'moment';
import localization from 'moment/locale/pl';
import {withTranslation} from "react-i18next";
import {StyledBlock, StyledBlockLabel} from "./partials/StyledBlock";
import {shakeAnimation} from "../../../common/Animations";

const colors = WEDDING_CONFIG.colors;

const StyledTContainer = styled(StyledBlock)`
  z-index: 1;
  margin-top: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &.blocked {
    cursor: default;

    button {
      color: #aaa;
      //border-color: #eee;
      pointer-events: none;
    }

    .tick {
      &:before, &:after {
        border-color: #aaa;
      }
    }

    .title, .date {
      //color: #aaa;
    }
  }
`;

const StyledIcon = styled(Icon)`
  && {
    font-size: 1rem;
    color: #888;
    display: none;
    position: relative;
    left: 5px;
    top: 1px;
  }
`;

const StyledButton = styled(CommonOutlinedButton)`
  && {
    margin-top: 15px;
    width: 100%;
  }
`;

const StyledLine = styled.div`
  display: flex;
  margin-bottom: 3px;
  
  div {
    padding-left: 5px;
  }
`;

const StyledAvailableUntilBlock = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 0.8rem;
`;

const StyledDeclarationEdited = styled.div`
  text-align: center;
  font-size: 0.85rem;
  padding: 1px 12px;
  background: ${colors.primary};
  color: white;
  border-radius: 3px;
  margin-top: 15px;
`;

const StyledMsg = styled(StyledFancyButtonV2)`
  && {
    margin-top: 15px;
    animation: ${shakeAnimation} 6s ease-out infinite;
    animation-delay: 4s;
    padding: 20px 8px;

    .big {
      font-size: 1.5rem;
    }

    &.disabled {
      animation: none;
    }
  }
`;

class Details extends Component {

    state = {
        partnerNameDialogOpen: false,
        user: null
    };

    static getDerivedStateFromProps(props, state) {
        if (props.user !== state.user) {
            return {
                user: props.user,
            };
        }
        return null;
    }

    handleOpenPartnerNameDialog() {
        if (this.isDeclarationAvailable()) {
            store.dispatch(setMainModalActiveStep(0));
            store.dispatch(toggleMainModal(true));
        }
    }

    buildDetailsText() {
        let result = [],
            userData = this.state.user,
            presentOnFirstDay = this.props.user.present_first_day,
            presentOnSecondDay = this.props.user.present_second_day && this.props.instance.is_two_day_party && this.props.user.allow_be_present_second_day,
            isWithKids = this.props.user.is_with_kids,
            kids = this.props.user.kids,
            isWithPartner = this.props.user.is_with_partner;


        try {
            kids = JSON.parse(kids);
        } catch (e) {
            kids = [];
        }

        if (presentOnFirstDay && presentOnSecondDay) {
            result.push(<div>Będę na weselu i poprawinach.</div>);
        } else if (presentOnFirstDay) {
            result.push(<div>Będę na weselu.</div>);
        } else if (presentOnSecondDay) {
            result.push(<div>Będę tylko na poprawinach.</div>);
        } else {
            result.push(<div>Nie będę obecny(a).</div>);
            return result;
        }

        if (isWithPartner) {
            let text;
            if (isWithPartner) {
                if (userData.partner_first_name && userData.partner_last_name) {
                    text = `Towarzyszyć mi będzie ${userData.partner_first_name} ${userData.partner_last_name}`;
                } else {
                    text = `Będę z osobą towarzyszącą.`;
                }
            }
            result.push(<div>{text}</div>);
        }

        if (isWithKids && kids.length) {
            result.push(
                <div>{isWithPartner ? 'Będziemy' : 'Będę'} {kids.length === 1 ? 'z dzieckiem' : 'z dziećmi'}.</div>);
        }

        if (!isWithPartner) {
            result.push(<div>Wybieram się bez partnera.</div>);
        }

        if (presentOnFirstDay && this.props.instance.is_accommodation_available && this.props.user.accommodation && this.props.user.allow_selecting_accommodation) {
            result.push(<div>Chcę zarezerwować nocleg.</div>);
        }

        if (this.props.main_special_menu) {
            result.push(<div>Menu "{this.props.user.main_special_menu}" ({this.props.user.first_name}).</div>);
        } else {
            result.push(<div>Menu standardowe ({this.props.user.first_name}).</div>);
        }

        if (isWithPartner && this.props.user.partner_special_menu) {
            result.push(<div>Menu
                "{this.props.user.partner_special_menu}" {this.props.user.partner_first_name ? `(${this.props.user.partner_first_name})` : ''}.</div>);
        } else if (isWithPartner) {
            result.push(<div>Menu standardowe ({this.props.user.partner_first_name || 'osoba tow.'}).</div>);
        }

        return result;
    }

    renderAvailableUntilBlock(cls) {
        const date = this.props.instance.declaration_enabled_until;
        const localeMoment = moment(date);
        const isDeclarationAvailable = this.isDeclarationAvailable();
        localeMoment.locale('pl', localization);

        if (!localeMoment.isValid()) {
            return null;
        }

        return (
            <StyledAvailableUntilBlock className={cls}>
                {isDeclarationAvailable ? this.props.t('guest.declaration.canBeSent') : this.props.t('guest.declaration.couldBeSent')}
                <span className="date">{localeMoment.format('D MMMM YYYY')}</span>.
            </StyledAvailableUntilBlock>
        );
    }

    renderNew() {
        return (
            <StyledTContainer alt={this.props.alt}
                              className={`font-primary ${!this.isDeclarationAvailable() ? 'blocked' : ''}`}
                              onClick={this.handleOpenPartnerNameDialog.bind(this)}>
                <StyledBlockLabel className="title">{this.props.t('guest.declaration.title')}</StyledBlockLabel>
                {this.isDeclarationAvailable() ? (
                    <Fragment>
                        <div>Daj nam znać czy będziesz!</div>
                        <StyledMsg className={'white white-hover'}>
                            <div className="big">wypełnij teraz</div>
                            <div>
                                {this.renderAvailableUntilBlock('alt')}
                            </div>
                        </StyledMsg>
                        <div className={'floating-right-icon'}>
                            <Icon className={'icon'}>event_available</Icon>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <StyledMsg disabled className={'disabled'}>
                            <div className="big">deklaracja online</div>
                            <div>czas na wypełnienie upłynął</div>
                        </StyledMsg>
                        <StyledAvailableUntilBlock>w razie potrzeby prosimy o bezpośredni<br/>kontakt z Parą
                            Młodą</StyledAvailableUntilBlock>
                        <div className={'floating-right-icon'}>
                            <Icon className={'icon'}>event_busy</Icon>
                        </div>
                    </Fragment>
                )}
            </StyledTContainer>
        );
    }

    renderExisting() {
        const editedByAdminAt = moment(this.props.user.edited_by_admin_at);

        return (
            <StyledTContainer className={`font-primary ${!this.isDeclarationAvailable() ? 'blocked' : ''}`}
                              onClick={this.handleOpenPartnerNameDialog.bind(this)}>
                <StyledBlockLabel className="title">{this.props.t('guest.declaration.title')}</StyledBlockLabel>
                {
                    this.buildDetailsText().map((result, i) =>
                        <StyledLine className="tick" key={i}><Icon>check_circle</Icon> {result}</StyledLine>)
                }
                <StyledIcon>edit</StyledIcon>
                {editedByAdminAt.isValid() ? (
                    <StyledDeclarationEdited>
                        Wypełniono przez Parę Młodą.
                    </StyledDeclarationEdited>
                ) : ''}
                <br/>
                <StyledFancyButtonV2
                    className={'white-hover'}>{this.props.t(this.isDeclarationAvailable() ? 'guest.declaration.actionChange' : 'guest.declaration.actionDisabled')}</StyledFancyButtonV2>

                {this.renderAvailableUntilBlock()}

                <div className={'floating-right-icon'}>
                    <Icon className={'icon'}>{this.isDeclarationAvailable() ? 'event_available' : 'event_busy'}</Icon>
                </div>
            </StyledTContainer>
        );
    }

    isDeclarationAvailable() {
        return moment().isSameOrBefore(this.props.instance.declaration_enabled_until, 'day');
    }

    render() {
        return (
            <Fragment>
                {this.props.user.present_first_day !== null ? this.renderExisting() : this.renderNew()}
                <PartnerNameDialog/>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user,
        instance: state.auth.instance,
    }
};

const ConnectedDetails = withTranslation()(connect(mapStateToProps)(Details));
export {ConnectedDetails as Details};
