import api from '../api';

const getParticipantsList = () => {
    return api.request({
        url: api.suRoutes.getParticipantsList
    });
};

const getParticipantsListForTablesSetup = () => {
    return api.request({
        url: api.suRoutes.tableUsersList
    });
};

const updateParticipantActiveParam = (id, data) => {
    return api.request({
        url: api.suRoutes.setParticipantActive.replace(':id', id),
        method: 'put',
        data
    });
};

const updateParticipantAccommodationAvailabilityParam = (id, data) => {
    return api.request({
        url: api.suRoutes.setParticipantAccommodationAvailability.replace(':id', id),
        method: 'put',
        data
    });
};

const updateParticipantSecondDayAvailabilityParam = (id, data) => {
    return api.request({
        url: api.suRoutes.setParticipantSecondDayAvailability.replace(':id', id),
        method: 'put',
        data
    });
};

const getSpecialParticipants = () => {
    return api.request({
        url: api.suRoutes.getInstanceSpecialParticipants,
        method: 'get'
    });
};

const updateSpecialParticipants = (data) => {
    return api.request({
        url: api.suRoutes.setInstanceSpecialParticipants,
        method: 'put',
        data
    });
};

const deleteParticipant = (id) => {
    return api.request({
        url: api.suRoutes.deleteParticipant.replace(':id', id),
        method: 'delete'
    });
};

const printCodes = (type, mode, selected) => {
    return api.request({
        url: api.suRoutes.printCodes,
        method: 'post',
        responseType: 'blob',
        data: {
            type,
            mode,
            selected
        }
    });
};

export default {
    getParticipantsList,
    updateParticipantActiveParam,
    updateParticipantAccommodationAvailabilityParam,
    updateParticipantSecondDayAvailabilityParam,
    getSpecialParticipants,
    updateSpecialParticipants,
    deleteParticipant,
    printCodes,
    getParticipantsListForTablesSetup
};
