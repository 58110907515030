import {keyframes} from "styled-components";

export const shakeAnimation = keyframes`
  0% {
    transform: rotate(0);
  }

  2% {
    transform: rotate(-3deg);
  }

  4% {
    transform: rotate(3deg);
  }

  6% {
    transform: rotate(-3deg);
  }

  8% {
    transform: rotate(3deg);
  }

  10% {
    transform: rotate(0);
  }
`;

