import React, {Component} from 'react';
import {StyledFancyButton, StyledFancyButtonV2} from "../../../common/StyledButton";
import mainMenuConfig from "./menus/MainMenuConfig";
import store from "../../../store";
import {setPDFDownloaded} from "../../../actions";
import {connect} from "react-redux";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {withTranslation} from "react-i18next";
import {StyledBlock, StyledBlockLabel} from "./partials/StyledBlock";
import Icon from "@material-ui/core/Icon";

class DownloadPdf extends Component {
    render() {
        return (
            <StyledBlock>
                <StyledBlockLabel>
                    <div>{this.props.t('guest.summary.title')}</div>
                </StyledBlockLabel>
                <div>
                    {this.props.t('guest.summary.text')}
                </div>
                <br />
                <StyledFancyButtonV2
                    className={'white-hover'}
                    disabled={this.props.app.wasPDFDownloaded}
                    onClick={() => {
                        mainMenuConfig.config['DOWNLOAD_PDF_SUMMARY'].onClick();
                        store.dispatch(setPDFDownloaded(true));
                    }}>
                    {/*<Icon className={'icon'}>download</Icon>*/}
                    {this.props.t('guest.summary.action')}
                </StyledFancyButtonV2>
                <div className={'floating-right-icon'}>
                    <Icon className={'icon'}>cloud_download</Icon>
                </div>
            </StyledBlock>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        instance: state.auth.instance
    }
};

export default withTranslation()(connect(mapStateToProps)(DownloadPdf));
