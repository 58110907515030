import React from 'react';
import styled from 'styled-components';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledSelectOption = styled(MenuItem)`
    
`;

export const StyledSelect = styled(Select)`
    
`;
