import styled from "styled-components";
import {transparentize} from "polished";
import MediaSizes, {MediaHeights} from "../../../../constants/media-sizes.constants";
import WEDDING_CONFIG from "../../../../wedding-config";
import Icon from "@material-ui/core/Icon";
import {shakeAnimation} from "../../../../common/Animations";

const colors = WEDDING_CONFIG.colors;

export const StyledBlock = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  max-width: 270px;
  background: ${transparentize(0.5, colors.primary)};
  border-radius: 10px;
  text-align: left;
  box-shadow: 1px 2px 2px 1px #9d3256;
  position: relative;
  
  &.shake {
    animation: ${shakeAnimation} 6s ease-out infinite;
    animation-delay: 3s;
  }
  
  .floating-right-icon {
    position: absolute;
    right: 17px;
    top: 17px;
    opacity: 0.3;
    transition: opacity 0.2s ease-in;
    
    .MuiIcon-root {
      font-size: 2.2rem;
    }
  }

  &:hover {
    .floating-right-icon {
      opacity: 0.8;
    }
  }

  @media (max-width: ${MediaSizes.phone}px) {
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 2px 2px 1px #9d325670;
  }

  @media (max-height: ${MediaHeights.sm}px) {
    margin-top: 10px;
  }
`;

export const StyledBlockLabel = styled.div`
  font-size: 1.4rem;
  //font-weight: bold;
  display: block;
  padding: 0 0 10px;
`;
