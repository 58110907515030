import React from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';

const colors = WEDDING_CONFIG.colors;

const StyledBlock = styled(ListItem)`
    && {
        padding: 10px 0;
        width: 100%;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin-bottom: 1rem;
        position: relative;
        
        &.inactive {
          opacity: 0.5;
        }
        
        &.variant-small {
          padding: 8px 0;
          margin-bottom: 5px;

          .label {
            margin-bottom: 0;
            font-size: 1rem;
          }
          .block-left {
            padding: 5px;
          }
        }
    }
`;

const StyledBlockLeft = styled.div`
    padding: 15px 80px 15px 15px;
`;

const StyledLabel = styled.div`
    font-size: 1.3rem;
    font-weight: 100;
    margin-bottom: 0.5rem;
`;

const StyledText = styled.div`
    font-size: 0.9rem;
    font-weight: 100;
`;

const StyledCheckbox = styled(Checkbox)`
    && {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export default function CheckboxBlock(props) {
    const { mainLabel, text, onChange, small, ...checkboxParams } = props;
    return (
        <StyledBlock
            className={`${!checkboxParams.checked ? 'inactive' : ''} ${small ? 'variant-small' : ''}`}
            button
            disabled={props.disabled}
            onClick={props.disabled ? null : () => {
            onChange({
                target: { name: checkboxParams.name, value: !checkboxParams.checked }
            })
        }}>
            <StyledBlockLeft className={'block-left'}>
                <StyledLabel className={'label'}>{mainLabel}</StyledLabel>
                <StyledText>{text}</StyledText>
                <StyledCheckbox
                    {...checkboxParams}
                    onChange={onChange}/>
            </StyledBlockLeft>
        </StyledBlock>
    )
}
