import SimpleDialog from "../../../common/SimpleDialog";
import React from "react";

export function PwaInstruction({ onClose }) {
    return (
        <SimpleDialog
            title={'Zainstaluj fancy.wedding'}
            open={true}
            onClose={onClose}
            maxWidth={'md'}>
            <h3>Instrukcja dla iPhone (Safari)</h3>
            <ol>
                <li>Kliknij przycisk udostępniania na dolnym panelu Safari (środkowy).</li>
                <img src={'/images/ios-app.jpg'} className={'max-100'} />
                <li>Następnie znajdź i kliknij w pozycję "Do ekranu poczatk.".</li>
                <img src={'/images/ios-app2.jpg'} className={'max-100'} />
                <li>Potwierdź przyciskiem "Dodaj".</li>
            </ol>
            <br />
            <h3>Instrukcja dla Android i pozostałych przeglądarek na iPhone</h3>
            <p>
                Większość przeglądarek automatycznie wykryje opcję instalacji i zapyta Cię o to przy pierwszej wizycie.
            </p>
            <p>
                Jeśli Twoja przeglądarka nie wykryła aplikacji, poszukaj w opcjach strony pozycji takiej jak np. <b>Dodaj do ekranu głównego</b> lub podobnej (może się różnić w zależnośći od przeglądarki).
            </p>
            <p>
                <b>Polecamy użycie przeglądarki Chrome na Android do dodania aplikacji do pulpitu.</b>
            </p>
        </SimpleDialog>
    )
}
