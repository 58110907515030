import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import MenuList from '@material-ui/core/MenuList';
import WEDDING_CONFIG from '../../../../wedding-config';
import { connect } from 'react-redux';
import store from '../../../../store';
import mainMenuConfig from './MainMenuConfig';
import { setActivePage } from '../../../../actions';
import menuService from '../../../../service/menu.service';
import Icon from "@material-ui/core/Icon";
import MediaSizes from "../../../../constants/media-sizes.constants";
import {withTranslation} from "react-i18next";
const colors = WEDDING_CONFIG.colors;

const StyledWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.altWhite};
    z-index: 1;
    
    ${props => `
        display: ${props.visible ? 'block' : 'none'};
    `}
`;

const StyledMenuList = styled(MenuList)`
    && {
        @media (max-width: ${MediaSizes.tablet}px) {
            position: absolute;
            bottom: 5%;
            right: 0;
            text-align: right;
        }
        
        @media (max-width: ${MediaSizes.tablet}px) {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            
            .icon {
              display: none;
            }
        }
    }
`;

const StyledLink = styled.div`
    color: ${colors.primary};
    font-size: 1.4rem;
    padding: 20px;
    vertical-align: middle;
    
    ${props => `
        font-weight: ${props.active ? 'bold' : 'normal'};
        text-decoration: ${props.active ? 'underline' : 'none'}
        
        .icon {
            color: ${props.active ? colors.primary : '#ddd'};
        }
    `}
`;

const StyledIcon = styled(Icon)`
  && {
    font-size: 2.2rem;
    margin-left: 20px;
    vertical-align: middle;
  }
`;

class MainMenuFullscreen extends Component {

    state = {
        menuItems: [],
    };

    componentDidMount() {
        const menuItems = menuService.getMenuItems();

        this.setState({
            menuItems: menuItems
                .filter(page => page !== 'DOWNLOAD_PDF_SUMMARY')
                .map((page) => mainMenuConfig.config[page])
                .filter(page => page)
        })
    }

    menuItemClick(page) {
        store.dispatch(setActivePage(page));
        this.props.handleClose();
    }

    render() {
        return (
            <Fragment>
                <StyledWrapper visible={this.props.visible}>
                    <StyledMenuList>
                        {this.state.menuItems.map((item, i) => (
                            <StyledLink onClick={() => this.menuItemClick(item.page)} page={item.page} to={item.to} active={item.page === this.props.app.activePage}>
                                {this.props.t(item.text)}
                                <StyledIcon className="icon">{item.icon}</StyledIcon>
                            </StyledLink>
                        ))}
                    </StyledMenuList>
                </StyledWrapper>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        instance: state.auth.instance
    }
};

export default withTranslation()(connect(mapStateToProps)(MainMenuFullscreen));
