import React, {Component, Fragment, useRef, useState} from 'react';
import styled from 'styled-components';
import api from '../../../../api';
import Grid from "@material-ui/core/Grid";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import Hidden from '@material-ui/core/Hidden';
import {MediaRule} from "../../../../constants/media-sizes.constants";
import {UserEditForm} from "./UserEditForm";
import {Formik, useFormik} from "formik-newest";
import store from "../../../../store";
import {setSuccess} from "../../../../actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SimpleDialog from "../../../../common/SimpleDialog";
import {DEFAULT_GRID_SPACING} from "../../../../constants";
import {useSnackbar} from "notistack";

const StyledGridItem = styled(Grid)`
  && {
    &:not(:last-of-type) {
      
    }
  }
`;

export default function UsersAddDialog(props) {
    const isMobile = useMediaQuery(MediaRule.tablet);
    const [shouldClose, setShouldClose] = useState(false);
    const form = useRef();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function submitForm(doClose) {
        setShouldClose(doClose);
        // console.log(form);
        form.current.submitForm();
    }
    const hiddenConfig = {
        0: [],
        1: ['xs', 'sm'],
        2: ['xs', 'sm', 'md'],
    };

    return (
        <Fragment>
            <SimpleDialog
                open={true}
                fullWidth={true}
                onClose={props.onClose}
                title={'Dodaj nowych gości'}
                actions={<Fragment>
                    <CommonOutlinedButton onClick={() => props.onClose()} className={'action-hidden-mobile'}>Anuluj</CommonOutlinedButton>
                    <CommonOutlinedButton onClick={() => submitForm(true)} className={'fill-primary'}>Zapisz</CommonOutlinedButton>
                    <CommonOutlinedButton onClick={() => submitForm(false)} className={'fill-primary'}>Zapisz i dodaj kolejnych</CommonOutlinedButton>
                </Fragment>}
                aria-labelledby="form-dialog-title">
                <Formik
                    innerRef={form}
                    initialValues={{
                        participants: Array.apply(null, Array(3))
                            .map(() => ({
                                first_name: '',
                                last_name: '',
                                partner_first_name: '',
                                partner_last_name: '',
                                allow_adding_kids: false,
                                kids: [],
                                is_with_kids: false,
                                is_with_partner: true,
                                disallow_define_partner: false,
                                allow_be_present_second_day: true,
                                allow_selecting_accommodation: true,
                                accommodation: null,
                                present_first_day: null,
                                present_second_day: null,
                                main_special_menu: null,
                                partner_special_menu: null,
                            }))
                    }}
                    onSubmit={values => {
                        let data = values.participants.filter(participant => !!participant.first_name);

                        if (!data.length) {
                            alert('Za mało danych! Imię i nazwisko uczestnika jest wymagane.');
                            return false;
                        }
                        return api.request({
                            url: api.suRoutes.saveParticipantEditList,
                            method: 'post',
                            data,
                        }).then((response) => {
                            props.setList(response.data);

                            form.current.resetForm();
                            if (shouldClose) {
                                store.dispatch(setSuccess(response.data.countAdded > 1 ? `Dodano ${response.data.countAdded} gości.` : 'Uczestnik został dodany.'));
                                props.onClose();
                            } else {
                                enqueueSnackbar(response.data.countAdded > 1 ? `Dodano ${response.data.countAdded} gości.` : 'Uczestnik został dodany.', {
                                    variant: 'success',
                                });
                            }
                            setShouldClose(false);

                            return true;
                        });
                    }}>
                    {(formProps) => {
                        return (
                            <Grid container spacing={isMobile ? DEFAULT_GRID_SPACING : DEFAULT_GRID_SPACING * 5}>
                                {formProps.values.participants.map((newUser, i) =>
                                    (
                                        <Hidden only={hiddenConfig[i]}>
                                            <StyledGridItem item xs={12} sm={6} md={6} lg={4}>
                                                <UserEditForm isAdding={true} user={newUser} index={i} formProps={formProps}></UserEditForm>
                                            </StyledGridItem>
                                        </Hidden>
                                    )
                                )}
                            </Grid>
                        )
                    }}
                </Formik>
            </SimpleDialog>
        </Fragment>
    );
}
