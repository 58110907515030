import React, {Fragment, useEffect, useRef, useState} from 'react';
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import styled from "styled-components";
import WEDDING_CONFIG from "../../../../wedding-config";
import SimpleDialog from "../../../../common/SimpleDialog";
import {UserEditForm} from "./UserEditForm";
import api from "../../../../api";
import {Formik, useFormik} from "formik-newest";
import store from "../../../../store";
import {setSuccess} from "../../../../actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../../constants/media-sizes.constants";

const colors = WEDDING_CONFIG.colors;

const StyledBox = styled.div`
  width: 450px;
  max-width: 100%;
  min-height: 300px;
`;

const UserEditDialog = (props) => {
    const user = props.user;
    const focusComponent = props.focusComponent; // mobile
    const [hasFilledDeclaration, setHasFilledDeclaration] = useState(typeof props.user.present_first_day === 'boolean');
    const isMobile = useMediaQuery(MediaRule.tablet);

    const participantForm = useRef();

    const resetParticipantForm = (userData) => {
        let kids;
        try {
            kids = JSON.parse(userData.kids);
        } catch(e) {
            kids = [];
        }
        participantForm.current.resetForm({
            values: {
                participants: [
                    {
                        first_name: userData.first_name,
                        last_name: userData.last_name,
                        partner_first_name: userData.partner_first_name,
                        partner_last_name: userData.partner_last_name,
                        allow_adding_kids: userData.allow_adding_kids,
                        disallow_define_partner: userData.disallow_define_partner,
                        is_with_kids: userData.is_with_kids,
                        kids,
                        is_with_partner: !!userData.is_with_partner,
                        allow_be_present_second_day: userData.allow_be_present_second_day,
                        allow_selecting_accommodation: userData.allow_selecting_accommodation,
                        accommodation: userData.accommodation,
                        present_first_day: userData.present_first_day,
                        present_second_day: userData.present_second_day,
                        main_special_menu: userData.main_special_menu,
                        partner_special_menu: userData.partner_special_menu,
                    }
                ]
            }
        });
    };

    const submitUser = () => {
        participantForm.current.submitForm();
    }
    const stopPropagationForTab = (event) => {
        event.stopPropagation();
    };
    return (
        <Fragment>
            <SimpleDialog
                open={true}
                onKeyDown={stopPropagationForTab}
                actions={<Fragment>
                    <CommonOutlinedButton onClick={() => props.onClose()}>
                        Zamknij
                    </CommonOutlinedButton>
                    <CommonOutlinedButton className="fill-primary" onClick={() => submitUser()}
                                          // disabled={!participantForm.current?.dirty}
                                          color="secondary">
                        Zapisz dane
                    </CommonOutlinedButton>
                </Fragment>}
                title={`${user.first_name} ${user.last_name}`}
                {...props}>
                <StyledBox className={'text-left'}>
                    <Formik
                        innerRef={participantForm}
                        initialValues={{
                            participants: [
                                {
                                    first_name: user.first_name,
                                    last_name: user.last_name,
                                    partner_first_name: user.partner_first_name,
                                    partner_last_name: user.partner_last_name,
                                    allow_adding_kids: user.allow_adding_kids,
                                    allow_be_present_second_day: user.allow_be_present_second_day,
                                    allow_selecting_accommodation: user.allow_selecting_accommodation,
                                    disallow_define_partner: user.disallow_define_partner,
                                    is_with_kids: user.is_with_kids,
                                    is_with_partner: user.is_with_partner,
                                    kids: user.kids,
                                    accommodation: user.accommodation,
                                    present_first_day: user.present_first_day,
                                    present_second_day: user.present_second_day,
                                    main_special_menu: user.main_special_menu,
                                    partner_special_menu: user.partner_special_menu,
                                }
                            ]
                        }}
                        onSubmit={values => {
                            api.request({
                                url: api.suRoutes.saveParticipantData.replace(':id', user.id),
                                method: 'put',
                                data: values.participants[0]
                            }).then((response) => {
                                props.setUser(response.data);
                                props.onUserUpdate();
                                store.dispatch(setSuccess('Dane użytkownika zostały zaktualizowane.'));
                                resetParticipantForm(response.data);
                                props.onClose();
                            });
                        }}>
                        {(formProps) => {
                            return <UserEditForm user={user} index={0} formProps={formProps} focusComponent={focusComponent}></UserEditForm>;
                        }}
                    </Formik>
                </StyledBox>
            </SimpleDialog>
        </Fragment>
    )
};

export default UserEditDialog;
