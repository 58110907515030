import { Route } from 'react-router-dom';
import Redirect from 'react-router-dom/Redirect';
import {connect} from "react-redux";
import React, {Component} from 'react';

class PrivateRoute extends Component {
    render() {
        const { component: Component, ...rest } = this.props;
        const routeParams = {
            path: rest.path
        };
        return (
            <Route {...routeParams} render={(props) => (
                this.props.user.is_admin === true
                    ? <Component {...props} />
                    : <Redirect to='/' />
            )} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(PrivateRoute);