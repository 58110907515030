import React, {Fragment, useState} from 'react';
import { useForm } from 'react-hook-form';
import WEDDING_CONFIG from '../../../../wedding-config';
import TextInput from "../../partials/TextInput";
import SimpleDialog from "../../../../common/SimpleDialog";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import {addPlace, addSet} from "../../../../actions/accommodation.actions";
import store from "../../../../store";

const colors = WEDDING_CONFIG.colors;

const AccommodationSetDialog = (props) => {
    const { register, handleSubmit, errors, getValues, setValue, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
        }
    }); // initialise the hook
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = data => {
        setIsLoading(true);
        store.dispatch(addPlace(props.setId, data))
            .then(() => {
                setIsLoading(false);
                props.handleClose();
            }).catch((e) => {
            setIsLoading(false);
            throw e;
        })
    };
    let formRef;

    return (
        <SimpleDialog
            open={true}
            title={'Dodaj miejsce noclegu'}
            subTitle={<div>Podaj nazwę miejsca, np. Dom Panny Młodej lub Hotel XYZ.<br />Po utworzeniu będziesz mógł określić pokoje.<br/>Możesz dodać wiele miejsc.</div>}
            actions={<Fragment>
                <CommonOutlinedButton onClick={() => props.handleClose()} color="secondary">
                    Anuluj
                </CommonOutlinedButton>
                <CommonOutlinedButton className={'fill-primary'} disabled={!formState.isValid || isLoading} onClick={() => formRef.dispatchEvent(new Event('submit', { cancelable: true }))} color="primary">
                    Zapisz
                </CommonOutlinedButton>
            </Fragment>}
          {...props}>
            <form onSubmit={handleSubmit(onSubmit)} ref={(ref) => formRef = ref}>
                <TextInput
                    inputRef={register({required: true})}
                    name="name"
                    key="name"
                    autoFocus
                    label={'Nazwa'}
                    placeholder={'np. Dom Panny Młodej lub Hotel XYZ'}
                />
            </form>
        </SimpleDialog>
    )
};

export default AccommodationSetDialog;
