import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import SectionTitle from "../../partials/SectionTitle";
import api from "../../../../api";
import CheckboxBlock from "../../partials/CheckboxBlock";
import Grid from "@material-ui/core/Grid";
import contentService from "../../../../service/content.service";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import {Formik} from "formik";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Autosuggest from 'react-autosuggest';
import WEDDING_CONFIG from "../../../../wedding-config";
import taskService from "../../../../service/task.service";
import {withSnackbar} from "notistack";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ListSubheader from "@material-ui/core/ListSubheader";
import Icon from "@material-ui/core/Icon";
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import TextInput from "../../partials/TextInput";
import {StyledCardContent} from "../../../../common/StyledContentCard";
import moment from 'moment';
import {StyledEmptyPlaceholder} from "../../../../common/StyledEmptyPlaceholder";
import {StyledCard, StyledCardHeader} from "../../../../common/StyledCard";
import {DEFAULT_GRID_SPACING} from "../../../../constants";
import ENV from "../../../../config/environment";
import MobileActions from "../MobileActions";
import TaskForm from "./TaskForm";
import DesktopActions, {DesktopActionsWrapper} from "../DesktopActions";
import TasksChart from "./TasksChart";
import TasksListActions from "./TasksListActions";
import {Loader} from "../../../../common/Loader";
import Hidden from "@material-ui/core/Hidden";

const colors = WEDDING_CONFIG.colors;

const StyledInputField = styled(TextInput)`
    && {
        font-size: 1rem;
        width: 100%;
    }
`;

const StyledCheckboxBlock = styled(CheckboxBlock)`
  
    &&.inactive {
      opacity: 1;
    }
`;

const StyledListHeader = styled.div`
  font-size: 1.3rem;
  margin: 25px auto;
`;

const StyledAutocompleteWrapper = styled.div`
  position: relative;
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background: white;
    width: 100%;
    box-shadow: 1px 1px 3px #ddd;
    z-index: 20;
  }

`;

const StyledOption = styled.div`
  cursor: pointer;
  padding: 8px;
  border-bottom: 1px solid #eee;
    &:hover {
    color: white;
      background-color: ${colors.primaryLight};
    }
`;

const StyledListHelp = styled.div`
  padding-top: 10px;
  font-size: 0.9rem;
  color: #999;
`;

const StyledListSubheader = styled(ListSubheader)`
  && {
    font-size: 1.4rem;
    margin: 15px 0;
  }
`;

const StyledListItem = styled(ListItem)`
  && {
    //border: 1px solid #eee;
    border-top: 0;
    border-left: 0;
    
    &.done {
      text-decoration: line-through;
      opacity: 0.4;
      
      .actions {
          opacity: 0.4;
        }
    }
    
  }

  .primary {
    font-size: 1.1rem;
  }
  .secondary {
    font-size: 0.9rem;
  }
`;

const StyledList = styled(List)`
  && {
    
   
  }
`;

const StyledCloseEdit = styled.span`
  position: absolute;
  right: 0;
  top: 15px;
  display: block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 20px;
    right: 5px;
    display: inline-block;
    width: 30px;
    height: 2px;
    background-color: ${colors.primaryLight};
    transform: rotate(45deg);
  }
  
  &:after {
    transform: rotate(-45deg);
  }
`;

const StyledTasksCount = styled.span`
  color: ${colors.primaryLight};
  
  .done {
    font-size: 4rem;
  }
  
  .total {
    font-size: 2rem;
  }
  
  .label {
    font-size: 1rem;
    margin-left: 10px;
    display: inline-block;
  }
`;


const StyledNoTasksMessage = styled.div`
  margin-top: 40px;
  font-size: 1rem;
  color: #666;
`;

class Tasks extends Component {
    state = {
        tasks: { others: [] },
        isEdit: false,
        taskFormVisible: false,
        editTask: null,
        isLoading: true
    };
    form;

    componentDidMount() {
        this.fetchTasksList();
    }

    fetchTasksList() {
        taskService.getTasks().then((response) => {
            this.setState({
                tasks: response.data,
                isLoading: false
            })
        });
    }

    handleChange(task) {
        taskService.markAsDone(!task.is_done, task.id).then(() => this.fetchTasksList());
    }

    edit(task) {
        this.setState({
            taskFormVisible: true,
            editTask: task
        });
    }

    getTasksFlattened() {
        return [].concat.apply([], Object.values(this.state.tasks));
    }

    getTasksFlattenedCount() {
        return this.getTasksFlattened().length;
    }

    getDoneTasksFlattenedCount() {
        return this.getTasksFlattened().filter((task) => task.is_done).length;
    }

    remove(task) {
        if (window.confirm(`Czy na pewno chcesz usunąć zadanie "${task.name}"?`)) {
            taskService.removeTask(task.id).then(() => this.fetchTasksList());
        }
    }

    renderSuggestionsContainer({ containerProps, children, query }) {
        return (
            <div {... containerProps}>
                {children}
                <StyledListHelp>
                    Lista służy do pogrupowania zadań. Możesz dodać listę np. Zadania dla Pana Młodego, jak i również Zadania dla Pani Młodej.
                </StyledListHelp>
            </div>
        );
    }

    renderItem(taskList) {
        return (
            <div>
                {this.state.tasks[taskList].map((task) => (
                    <StyledCheckboxBlock mainLabel={task.name}
                                         text={task.note}
                                         name="active"
                                         key="active"
                                         checked={false}
                                         onChange={this.handleChange.bind(this)}
                                         value="1"/>
                ))}
            </div>
        );
    }
    renderItem2(taskList) {
        return this.state.tasks[taskList].length ? (<StyledCard>
                <StyledList>
                    <StyledCardHeader>
                        {this.state.tasks[taskList].length ? (taskList !== 'others' ? taskList : 'Zadania') : ''}
                    </StyledCardHeader>
                    {this.state.tasks[taskList].map((task, idx) => (
                        <StyledListItem key={idx} role={undefined} dense
                                        className={`${task.is_done ? 'done' : ''}`}
                                        disableRipple
                                        disableHover
                                        onClick={this.handleChange.bind(this, task)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={task.is_done}
                                    tabIndex={-1}
                                    inputProps={{
                                        name: "active",
                                        value: "1"
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                style={{textOverflow: 'ellipsis', overflow: 'hidden'}}
                                primary={task.name}
                                primaryTypographyProps={{className: "primary"}}
                                secondary={`${task.date ? `${moment(task.date).format('ll')}${task.note ? ' · ' : ''}` : ''}${task.note}`}
                                secondaryTypographyProps={{className: "secondary"}}/>
                            <ListItemSecondaryAction className="actions">
                                <TasksListActions onEdit={this.edit.bind(this, task)} onDelete={this.remove.bind(this, task)} />
                            </ListItemSecondaryAction>
                        </StyledListItem>
                    ))}
                </StyledList>
            </StyledCard>) : null;
    }

    toggleTaskForm(value) {
        this.setState({
            taskFormVisible: value,
            editTask: null
        });
    }

    render() {
        const actions = [
            {
                onClick: () => {
                    this.toggleTaskForm(true);
                },
                text: 'Dodaj zadanie',
                icon: 'add_task'
            }
        ];
        const totalTasks = this.getTasksFlattenedCount();
        return (
            <div>
                {this.state.taskFormVisible ? <TaskForm taskData={this.state.editTask} tasks={this.state.tasks} onSave={this.fetchTasksList.bind(this)} onClose={this.toggleTaskForm.bind(this, false)} /> : null}

                <DesktopActionsWrapper>
                    <DesktopActions actions={actions} />
                </DesktopActionsWrapper>
                <MobileActions actions={actions} />

                <Grid container spacing={DEFAULT_GRID_SPACING}>
                    <Hidden smUp>
                        <Summary totalTasks={totalTasks} count={this.getDoneTasksFlattenedCount()} />
                    </Hidden>
                    <Grid item xs={12} sm={8}>
                        {totalTasks === 0 ? (
                            <StyledEmptyPlaceholder>
                                {this.state.isLoading ? <Loader /> : <p>Brak zadań w tej chwili.<br /><small>Skorzystaj z formularza, aby dodać nowe.</small></p>}
                            </StyledEmptyPlaceholder>
                        ) : (
                            <Fragment>
                                {this.renderItem2('others')}

                                {Object.keys(this.state.tasks).filter((name) => name !== 'others').map((taskList, idx) => {
                                    return this.renderItem2(taskList);
                                })}
                            </Fragment>
                        )}
                    </Grid>
                    <Hidden xsDown>
                        <Summary totalTasks={totalTasks} count={this.getDoneTasksFlattenedCount()} />
                    </Hidden>
                </Grid>
            </div>
        )
    }
}

function Summary({totalTasks, count}) {
    return <Grid item xs={12} sm={4}>
        <StyledCard>
            <StyledCardHeader subtitle={'Stwórz listę zadań i rzeczy do załatwienia przed weselem'}>Twoje zadania</StyledCardHeader>

            <StyledCardContent>
                <StyledTasksCount>
                    <span className="done">{count}</span>
                    <span className="total"> / {totalTasks}</span>
                    <span className="label">wykonanych</span>
                </StyledTasksCount>
            </StyledCardContent>
        </StyledCard>
        {/*{totalTasks > 0 ?<StyledCard>*/}
        {/*    <StyledCardHeader>Podsumowanie</StyledCardHeader>*/}

        {/*    <TasksChart data={this.state.tasks} />*/}
        {/*</StyledCard> : null}*/}
    </Grid>
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user
    }
};

export default withSnackbar(connect(mapStateToProps)(Tasks));
