import SimpleDialog from "../../../common/SimpleDialog";
import React, {useEffect, useState, Fragment, useRef} from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../constants/media-sizes.constants";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import styled from "styled-components";
import ChatService from '../../../service/chat.service';
import {
    StyledChatBubbles, StyledChatMessageInput,
    StyledChatMessageLine,
    StyledMessagingArea, StyledMessagingBottomBar,
    StyledMessagingTopBar, StyledSendButton
} from "../../../common/ChatMessages";
import {Loader} from "../../../common/Loader";
import {Hidden} from "@material-ui/core";
import WEDDING_CONFIG from "../../../wedding-config";
import ENV from "../../../config/environment";
import {useSelector} from "react-redux";
import moment from "moment";

const StyledSimpleDialog = styled(SimpleDialog)`
  && .MuiDialogContent-root {
    padding: 0 !important;
    height: 550px;
    max-height: 100vh;
    overflow: hidden;
  }
`;

const StyledNoMessagesYet = styled.div`
  flex: 1 1 1px;
  overflow-y: scroll;
  text-align: center;
  display: flex;
  align-items: center;
`;


function GuestChatDialog({handleClose}) {
    const isMobile = useMediaQuery(MediaRule.tablet);
    const storeUser = useSelector(state => state.auth.user);
    const [inputMessageValue, setInputMessageValue] = useState('');
    const [isValueValid, setIsValueValid] = useState(true);
    const [disableInput, setDisableInput] = useState(ENV.IS_PREMIUM);
    const [currentChatData, setCurrentChatData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef();

    function getChatMessages() {
        setDisableInput(true);
        setIsLoading(true);

        ChatService.getChatMessages().then((response) => {
            setCurrentChatData(response.data);
            setDisableInput(false);
            setIsLoading(false);
            scrollChatToBottom();
        });
    }

    function onSendButtonClick() {
        setDisableInput(true);
        ChatService.sendChatMessage(inputMessageValue).then((response) => {
            setCurrentChatData(response.data);
            setDisableInput(false);
            setInputMessageValue('');
            scrollChatToBottom();
        }).catch(() => {
            setDisableInput(false);
        });
    }

    function scrollChatToBottom() {
        if (!messagesEndRef.current) {
            return;
        }
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }

    useEffect(() => {
        if (storeUser.is_admin) {
            // setting fake data for the admin user who is checking/testing the feature
            const historyItems = [
                {
                    text: 'Cześć!',
                    sent_by_admin: false,
                    sent_at: new Date()
                },
                {
                    text: 'Tak wygląda chat dla Twoich gości.',
                    sent_by_admin: false,
                    sent_at: new Date()
                },
                {
                    text: 'Wiadomości, które właśnie czytasz to tylko przykładowe treści. A więc:',
                    sent_by_admin: false,
                    sent_at: new Date()
                },
                {
                    text: 'Tak wygląda wiadomość od gościa dla Pary Młodej...',
                    sent_by_admin: false,
                    sent_at: new Date()
                },
                {
                    text: '... a tak odpowiedź od Pary!',
                    sent_by_admin: true,
                    sent_at: new Date()
                },
                {
                    text: 'Są jeszcze ogłoszenia, takie jak to. Ogłoszenia może wysyłać tylko Para Młoda. Trafiają do wszystkich gości.',
                    sent_by_admin: false,
                    is_announcement: true,
                    sent_at: new Date()
                }
            ];

            if (ENV.IS_PREMIUM) {
                historyItems.push({
                    text: 'Aby korzystać z funkcji chata, wróć do panelu administracyjnego i wybierz zakładkę Chat.',
                    sent_by_admin: false,
                    sent_at: new Date()
                })
            } else {
                setDisableInput(true);
            }
            setCurrentChatData({
                history: historyItems
            });
            return;
        }
        if (ENV.IS_PREMIUM && !storeUser.is_admin) {
            getChatMessages();
        }
    }, []);

    useEffect(() => {
        setIsValueValid(inputMessageValue.length <= WEDDING_CONFIG.maxChatMessageLetters);
    }, [inputMessageValue]);

    return <StyledSimpleDialog
        fullScreen={isMobile}
        open={true}
        width={'md'}
        fullWidth={true}
        style={{ maxWidth: '700px' }}
        onClose={handleClose}
        title={'Chat z Młodą Parą'}
        aria-labelledby="responsive-dialog-title">
        <StyledMessagingArea>
            {currentChatData && currentChatData.history && currentChatData.history.length ? (
                <StyledChatBubbles innerRef={messagesEndRef}>
                    {currentChatData.history.map((chatMessage) => (
                        <StyledChatMessageLine className={`${!chatMessage.sent_by_admin ? 'your' : 'guest'} ${chatMessage.is_announcement ? 'is_announcement' : ''}`}>
                            <div className={'message'}>
                                {chatMessage.text}

                                {!chatMessage.is_announcement && chatMessage.sent_by_admin ? <div className={'content collapsed-content'}>
                                    wysłano {moment(chatMessage.sent_at).format('lll')}
                                </div> : null}

                                {chatMessage.is_announcement ? <div className={'content'}>
                                    ogłoszenie Młodej Pary<br/>{moment(chatMessage.sent_at).format('lll')}
                                </div> : null}
                            </div>
                        </StyledChatMessageLine>
                    ))}
                </StyledChatBubbles>
            ) : (
                <StyledNoMessagesYet>
                    <div style={{ flex: 1 }}>
                        {isLoading ? (
                            <Loader smaller={true}></Loader>
                        ) : 'Napisz pierwszą wiadomość.'}
                    </div>
                </StyledNoMessagesYet>
            )}

            <StyledMessagingBottomBar>
                <div style={{flex: '1'}}>
                    <StyledChatMessageInput
                        placeholder={i18next.t('chat.writeMessage')}
                        rows={1}
                        onChange={(e) => setInputMessageValue(e.target.value)}
                        value={inputMessageValue}
                        disabled={disableInput}
                        invalid={!isValueValid}></StyledChatMessageInput>
                </div>
                <div>
                    <StyledSendButton className={'fill-primary'} onClick={onSendButtonClick} color="primary"
                                      disabled={!inputMessageValue || disableInput}>
                        {i18next.t('chat.sendMessage')}
                    </StyledSendButton>
                </div>
            </StyledMessagingBottomBar>
        </StyledMessagingArea>
    </StyledSimpleDialog>;
}

export default withTranslation()(GuestChatDialog);
