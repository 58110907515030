import React, { PureComponent } from 'react';
import styled, {css, keyframes} from 'styled-components';
import WEDDING_CONFIG from '../../../../wedding-config';

const initAnimation = (opacity) => {
    return keyframes`
        0% {
            opacity: 0;
        }
        
        100% {
            opacity: ${opacity};
        }
    `;
};

const StyledDummieTwo = styled.div`
    position: absolute;
    display: inline-block;
    border: 12px solid transparent;
    
    width: 1px;
    height: 1px;
    z-index: -1;
    
    ${props => {
    let wh = getRandomInt(25, 40);
    return css`
    border-left-color: ${getRandomInt(1, 2) === 1 ? WEDDING_CONFIG.colors.dummyBubbles : WEDDING_CONFIG.colors.secondary};
    // background: ${getRandomInt(1, 2) === 1 ? WEDDING_CONFIG.colors.dummyBubbles : WEDDING_CONFIG.colors.secondary};
    //       width: 12px;
    //       height: 12px;
    //       border-radius: 50%;
            // animation: ${initAnimation(getRandomArbitrary(0, 0.8))} 2s  ease-in-out ${getRandomInt(100, 2100)}ms forwards;
            left: ${getRandomInt(-500, 500)}px;
            top: ${getRandomInt(100, 600)}px;
            transform: rotate(${getRandomInt(0, 180)}deg);
            // width: ${wh}px;
            // height: ${wh}px;
            // opacity: 0;
            opacity: ${getRandomArbitrary(0, 0.8)};
            `;
}
    }
    
`;

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

function renderFloatingDummies() {
    let result = [];

    for(let i = 0; i < 30; i++) {
        result.push(
            <StyledDummieTwo key={`${i}dummy`} rand={Math.random()} />
        )
    }

    return (
        result
    )
}

class MainMenuDummies extends PureComponent {

    render() {
        if (this.props.hidden) return null;
        return (
            renderFloatingDummies()
        );
    }
}

export default MainMenuDummies;
