import React from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../wedding-config';
import Button from '@material-ui/core/Button';
import {lighten} from 'polished';
import {darken, transparentize} from 'polished';

const colors = WEDDING_CONFIG.colors;

export const CommonOutlinedButton = styled(Button)`
    && {
        border: 2px solid ${lighten(0.1, colors.primaryLight)};
        color: ${lighten(0.1, colors.primaryLight)};
        text-align: center;
        font-size: 1rem;
        padding: 10px 15px;
        margin-top: 1px;
        //letter-spacing: 2px;
        border-radius: 1px;
        cursor: pointer;
        display: inline-block;
        box-sizing: border-box;
        text-transform: none;
        line-height: normal;
        
        &.rounded {
          border-radius: 5px;
        }
        
        &.secondary {
          border-color: #888;
          color: #888;
        }
        
        &.white {
          border-color: white;
          color: white;
        }
        
        &.fill-primary, &:hover, &.active {
            color: white;
            border-color: ${colors.primaryLight};
            background: ${colors.primaryLight};
        }
        
        &.white-hover {
            &:hover, &.active {
                color: ${colors.primary};
                background: white;
                border-color: white;
            }
        }
        
        &.size-large {
          font-size: 1.2rem;
          padding: 6px 12px;
        }
        
        &.size-small {
          font-size: 0.9rem;
          padding: 6px 12px;
        }
        
        &.size-mini {
          font-size: 1rem;
          padding: 6px 8px;
        }
        
        &.inline {
          display: inline-block;
          width: auto;
        }
        
        &.centered {
          margin: 0 auto;
        }
        
        &.link {
          border: 0;
          background: transparent!important;
        }
        
        &[disabled] {
          border-color: #ddd;
          color: #aaa;
          background: transparent;
        }
    }
`;

export const StyledFancyButton = styled(CommonOutlinedButton)`
  && {
    color: ${colors.primary};
    text-align: center;
    width: 100%;
    box-shadow: none;
    padding-top: 8px;
    padding-bottom: 12px;
    padding-right: 50px;
    
    .icon {
      position: relative;
      top: 5px;
      margin-right: 10px;
    }
    
    &.no-border {
      border: 0;
    }
    
    &.white-bg {
      background: white;
      
      &:hover {
        background: ${colors.primaryLight};
      }
      
      &[disabled] {
        background: ${transparentize(0.2, darken(0, colors.primaryLight))};
      }
    }
  }
`;

export const StyledFancyButtonV2 = styled(CommonOutlinedButton)`
  && {
    text-align: center;
    font-size: 1rem;
    border: 0 solid ${colors.primary};
    border-radius: 6px;
    letter-spacing: 0;
    width: 100%;
    color: white;
    background: transparent;
    border: 1px solid #e4d2d7;
    //background: ${colors.primary};

    .big {
      font-size: 1.5rem;
    }

    &.disabled {
      background: ${transparentize(0.7, colors.primary)};
      color: white;
    }
  }
`;
