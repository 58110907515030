import React from "react";
import styled, {keyframes} from "styled-components";
import {MainMenuList} from "./MainMenuList";
import {StyledCard} from "../../../common/StyledCard";
import {StyledCardContent} from "../../../common/StyledContentCard";
import Grid from "@material-ui/core/Grid";
import Changelog from "./Changelog";
import {CommonOutlinedButton} from "../../../common/StyledButton";
import {isPWA} from "../../../utilities";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MediaRule} from "../../../constants/media-sizes.constants";
import WEDDING_CONFIG from "../../../wedding-config";
import {version} from '../../../../package.json';

const isPwa = isPWA();

const StyledChangelogCard = styled(StyledCard)`
  && {
    font-size: 0.9rem;
    box-shadow: none;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    color: #505050;
  }
`;

const StyledSmallCard = styled(StyledChangelogCard)`
  && {
    position: relative;
    
    .button {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const animation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const StyledContainer = styled.div`
    opacity: 0;
    animation: ${animation} 0.2s ease-in forwards;
`;

const StrongWithMargin = styled.strong`
  display: block;
  margin-bottom: 4px;
`;

export function MainMenuMobile(props) {
    const isMobile = useMediaQuery(MediaRule.tablet);

    return (
        <StyledContainer mobileMenuExpanded={props.mobileMenuExpanded}>
            <MainMenuList mobileMenuToggle={props.menuToggle} isMobile={true} />
            {!isPwa ? <StyledSmallCard>
                <Grid container>
                    <Grid item xs={8}>
                        <StrongWithMargin>Zainstaluj aplikację (<span style={{ color: WEDDING_CONFIG.colors.primary }}>zalecane</span>)</StrongWithMargin>
                        <div>Przygotowaliśmy wersję mobilną, abyście mogli jeszcze wygodniej korzystać z fancy.wedding!</div>
                    </Grid>
                    <Grid item xs={4}>
                        <CommonOutlinedButton className={'button size-small'} onClick={() => props.setPwaInstructionVisible(true)}>pokaż</CommonOutlinedButton>
                    </Grid>
                </Grid>
            </StyledSmallCard> : null}
            <StyledChangelogCard>
                <StyledCardContent>
                    <strong>Co nowego w fancy.wedding?</strong>
                    <br/>
                    <Grid container>
                        <Grid item xs={12}>
                            <Changelog />
                        </Grid>
                    </Grid>
                </StyledCardContent>
            </StyledChangelogCard>
            {isMobile ? <StyledSmallCard>
                <Grid container>
                    <Grid item xs={8}>
                        <StrongWithMargin>Zgłoś błąd</StrongWithMargin>
                        <div>Znalazłeś błąd w aplikacji? Prosimy o informację, abyśmy mogli ulepszyć Wasze doświadczenia.</div>
                        <div style={{ color: '#888', fontSize: 12 }}>v{version}</div>
                    </Grid>
                    <Grid item xs={4}>
                        <CommonOutlinedButton as={'a'} href={`mailto:kontakt@fancy.wedding`} target={isPwa ? '_blank' : ''} className={'button size-small'}>zgłoś</CommonOutlinedButton>
                    </Grid>
                </Grid>
            </StyledSmallCard> : null}
        </StyledContainer>
    )
}
