import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import participantService from '../../../../service/participant.service';
import IconButton from '@material-ui/core/IconButton';
import {withSnackbar} from "notistack";
import CodesDownloadDialog from '../CodesDownloadDialog';
import UsersAddDialog from "./UsersAddDialog";
import api from "../../../../api";
import FileSaver from "file-saver";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Close, Search} from "@material-ui/icons";
import ENV from "../../../../config/environment";
import {StyledCard, StyledCardHeader} from "../../../../common/StyledCard";
import {DEFAULT_GRID_SPACING} from "../../../../constants";
import {UsersListTable} from "./UsersListTable";
import {UsersNonGuestsListTable} from "./UsersNonGuestsListTable";
import MobileActions from "../MobileActions";
import DesktopActions, {DesktopActionsWrapper} from "../DesktopActions";
import {Loader} from "../../../../common/Loader";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import MediaSizes from "../../../../constants/media-sizes.constants";
import {debounce} from "lodash";

const StyledSearchField = styled(TextField)`
  && {
    width: 260px;
    input {
      font-size: 1.2rem;
    }
    
    @media (min-width: ${MediaSizes.tabletPro}px) {
        position: relative;
        top: -50px;
    }
  }
`;

class UsersList extends Component {
    state = {
        searchKeyword: '',
        showCodesDialog: false,
        isDownloadingCodesEnabled: false,
        userEditFormVisible: false,
        userEditId: null,
        isExportingAvailable: false,
        isAddingGuests: false,
        isSearchEnabled: false,
        usersData: null,
        usersSummary: null,
        specialUsersData: null,
        specialUsersSummary: null,
        processingExport: false
    };
    searchDebounce;
    searchField;

    constructor() {
        super();

        this.searchDebounce = debounce((searchValue) => {
            console.log('debbb')
            this.setState({
                searchKeyword: searchValue,
            });
        }, 500);
    }

    componentDidMount() {
        this.fetchList();
        this.fetchSpecialList();
    }

    fetchList() {
        participantService.getParticipantsList().then(response => {
            this.setListFromBackend(response.data);
        });
    }

    setListFromBackend(data) {
        data.list.forEach((user) => {
            let kids = [];
            if (user.is_with_kids && user.kids) {
                try {
                    kids = JSON.parse(user.kids);
                } catch (e) {
                    kids = [];
                }
            }
            user.kids = kids;
        });
        this.setState({
            usersData: data.list,
            usersSummary: data.summary,
            isExportingAvailable: !!data.list.filter((u) => u.present_first_day).length,
            isDownloadingCodesEnabled: !!data.list.length,
            isSearchEnabled: !!data.list.length
        });
    }

    fetchSpecialList() {
        participantService.getSpecialParticipants().then(response => {
            this.setSpecialListFromBackend(response.data);
        });
    }

    setSpecialListFromBackend(data) {
        data.result.bride.is_bride_row = true;
        data.result.bride.people = 2;
        data.result.bride.is_active = true;
        data.result.bride.name = 'Młoda Para';
        data.result.bride.present_first_day = true;
        data.result.bride.present_second_day = true;
        this.setState({
            specialUsersData: data.result,
            specialUsersSummary: data.specialSummary
        })
    }

    showCodesDownloadDialog(show) {
        this.setState({showCodesDialog: show});
    }

    exportUsers() {
        this.setState({
            processingExport: true
        });
        api.request({
            url: api.suRoutes.exportParticipantsConfirmed,
            method: "GET",
            responseType: 'blob'
        }).then((response) => {
            FileSaver.saveAs(response, 'lista-potwierdzonych-gosci.pdf');
            setTimeout(() => {
                this.setState({
                    processingExport: false
                });
            }, 3000);
        });
    }

    filterUsers(user) {
        return (user.first_name || '').toLowerCase().includes(this.state.searchKeyword) ||
            (user.last_name || '').toLowerCase().includes(this.state.searchKeyword) ||
            (user.partner_first_name || '').toLowerCase().includes(this.state.searchKeyword) ||
            (user.partner_last_name || '').toLowerCase().includes(this.state.searchKeyword);
    }

    render() {
        const actions = [
            {
                onClick: () => {
                    this.setState({
                        isAddingGuests: true
                    });
                },
                text: 'Dodaj gości',
                icon: 'people'
            },
            {
                onClick: () => this.exportUsers(),
                text: "Lista potwierdzonych gości",
                disabled: !this.state.isExportingAvailable || this.state.processingExport,
                icon: "get_app"
            },
            {
                onClick: () => {
                    if (ENV.IS_PREMIUM) {
                        this.showCodesDownloadDialog(true);
                    } else {
                        this.props.history.push("/admin/premium");
                    }
                },
                lockedForPremium: true,
                text: "Załączniki QR",
                visible: ENV.IS_PREMIUM,
                disabled: ENV.IS_PREMIUM && !this.state.isDownloadingCodesEnabled,
                icon: "print"
            },
            {
                onClick: () => {
                    this.props.history.push("/admin/premium");
                },
                lockedForPremium: true,
                text: "Deklaracje Online",
                visible: !ENV.IS_PREMIUM,
                icon: "book_online"
            },
        ];
        return (
            <div style={{marginBottom: 50}}>
                {this.state.isAddingGuests ? (
                    <UsersAddDialog setList={this.setListFromBackend.bind(this)}
                                    onClose={() => {
                                        this.setState({
                                            isAddingGuests: false
                                        });
                                    }}
                                    onUpdate={this.fetchList.bind(this)}></UsersAddDialog>
                ) : null}

                <DesktopActionsWrapper>
                    <DesktopActions actions={actions} />
                </DesktopActionsWrapper>

                <MobileActions actions={actions} />

                <StyledCard>
                    {this.state.specialUsersData ? (
                        <UsersNonGuestsListTable
                            rows={this.state.specialUsersData}
                            summaryRow={this.state.specialUsersSummary}
                            fetchList={this.fetchSpecialList.bind(this)}
                            setListFromBackend={this.setSpecialListFromBackend.bind(this)}></UsersNonGuestsListTable>
                    ) : <Loader />}
                </StyledCard>

                <StyledCard>
                    <Grid container spacing={DEFAULT_GRID_SPACING}>
                        <Grid item xs={6}>
                            <StyledCardHeader>
                                Lista gości
                            </StyledCardHeader>
                        </Grid>
                        <Grid item xs={6} className={'text-right'}>
                            <CommonOutlinedButton className={'size-small'} onClick={() =>
                                this.setState({
                                    isAddingGuests: true
                                })}>Dodaj</CommonOutlinedButton>

                        </Grid>
                        <Grid item xs={12} className={'text-center'}>
                            <StyledSearchField
                                placeholder={'Wyszukaj...'}
                                disabled={!this.state.isSearchEnabled}
                                onChange={(e) => {
                                    // this.setState({
                                    //     searchKeyword: e.target.value.trim().toLowerCase()
                                    // })
                                    this.searchDebounce(e.target.value.trim().toLowerCase());
                                }}
                                InputProps={{
                                    inputRef: el => this.searchField = el,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search/>
                                        </InputAdornment>),
                                    endAdornment: this.state.searchKeyword ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(a) => {
                                                    console.dir(this.searchField)
                                                    this.searchField.value = '';
                                                    this.setState({searchKeyword: ''});
                                                }}>
                                                <Close/>
                                            </IconButton>
                                        </InputAdornment>) : null
                                }}
                            />

                        </Grid>
                    </Grid>
                    {this.state.usersData ? (
                        <UsersListTable
                            rows={this.state.usersData}
                            summaryRow={this.state.usersSummary}
                            searchKeyword={this.state.searchKeyword}
                            fetchList={this.fetchList.bind(this)}
                            setListFromBackend={this.setListFromBackend.bind(this)}
                        />
                    ) : <Loader />}

                </StyledCard>

                {this.state.showCodesDialog ? (
                    <CodesDownloadDialog handleClose={() => this.showCodesDownloadDialog(false)}/>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user,
        instance: state.auth.instance,
    }
};

export default connect(mapStateToProps)(withSnackbar(UsersList));
