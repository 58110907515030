import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import SectionTitle from "../partials/SectionTitle";
import {StyledCardContent} from "../../../common/StyledContentCard";
import {Formik} from "formik";
import api from "../../../api";
import TextInput from "../partials/TextInput";
import {CommonOutlinedButton} from "../../../common/StyledButton";
import styled from "styled-components";
import store from "../../../store";
import {setError} from "../../../actions";
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";
import {DEFAULT_GRID_SPACING} from "../../../constants";
import Grid from "@material-ui/core/Grid";

const StyledTextInput = styled(TextInput)`
  && {
    margin-bottom: 20px;
  }
`;

class ChangePasswordForm extends Component {
    render() {
        return (
            <StyledCard>
                <StyledCardHeader>Zmień hasło do konta fancy.wedding</StyledCardHeader>

                <StyledCardContent>
                    <Formik
                        initialValues={{
                            old_password: '',
                            password: '',
                            password_repeat: ''
                        }}
                        onSubmit={(values, {setSubmitting, setErrors, resetForm}) => {
                            setSubmitting(true);
                            api.request({
                                url: api.suRoutes.changeInstancePassword,
                                method: 'put',
                                data: values
                            }).then((response) => {
                                let hasChanged = false;

                                try {
                                    hasChanged = response.data.changed;
                                } catch (e) {
                                    hasChanged = false;
                                }

                                if (hasChanged) {
                                    resetForm({});
                                    store.dispatch(setError('Hasło zostało zmienione, prosimy o ponowne zalogowanie.'));
                                    this.setState({
                                        passwordFormVisible: false
                                    });
                                }
                            });
                        }}
                    >
                        {({values, errors, touched, dirty, handleChange, handleBlur, submitForm, handleSubmit, isSubmitting, isValid, validateForm}) => {
                            return (
                                <Grid container spacing={DEFAULT_GRID_SPACING}>
                                    <Grid item sm={6}>
                                        <StyledTextInput
                                            name="old_password"
                                            key="old_password"
                                            onChange={handleChange}
                                            label="Podaj stare hasło"
                                            type="password"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <div>
                                            <StyledTextInput
                                                name="password"
                                                key="password"
                                                label="Nowe hasło"
                                                onChange={handleChange}
                                                type="password"
                                                fullWidth
                                            />
                                        </div>
                                        <div>
                                            <StyledTextInput
                                                name="password_repeat"
                                                key="password_repeat"
                                                label="Powtórz nowe hasło"
                                                onChange={handleChange}
                                                type="password"
                                                fullWidth
                                            />
                                        </div>
                                    </Grid>
                                    <CommonOutlinedButton type="submit"  disabled={!dirty || !isValid}
                                                          onClick={handleSubmit}>Zmień</CommonOutlinedButton>
                                </Grid>
                            )
                        }}
                    </Formik>
                </StyledCardContent>
            </StyledCard>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(ChangePasswordForm);
