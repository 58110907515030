import React, { Component } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import UsersList from './components/participants-list/UsersList';
import Statistics from './components/Statistics';
import Contents from './components/Contents';
import Instance from './components/Instance';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import ContentsEdit from './components/ContentsEdit';
import MediaSizes, { MediaHeights } from '../../constants/media-sizes.constants';
import WEDDING_CONFIG from "../../wedding-config";
import {connect} from "react-redux";
import store from "../../store";
import {setAdminChatNewMessagesCount, toggleAdminPanel} from "../../actions";
import Tasks from "./components/tasks/Tasks";
import Tables from "./components/Tables";
import ENV from "../../config/environment";
import Premium from "./components/Premium";
import Settings from "./components/Settings";
import AccommodationPlanner from "./components/AccommodationPlanner";
import {StyledFancyButton} from "../../common/StyledButton";
import {isWidthUp} from "@material-ui/core";
import ScrollToTop from "../../ScrollTop";
import {PageFullWidthWrapper, PageWrapper} from "./partials/PageWrapper";
import TopHeader from "./partials/TopHeader";
import {ChatPage} from "./pages/ChatPage";
import ChatService from '../../service/chat.service';
import {withSnackbar} from "notistack";
import BuyPremiumPage from "./pages/BuyPremiumPage";


const colors = WEDDING_CONFIG.colors;

const StyledPage = styled.div`
    background: ${colors.white};
`;

const StyledPremium = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background: rgba(255,255,255);
  
  .inner {
    position: relative;
    top: 50%;
    transform: translateY(-80%);
    
    > div {
      margin-bottom: 10px;
    }
  }
`;

const PageContent = styled.div`
  @media (max-width: ${MediaSizes.phone}px) {
    padding-top: calc(45px + env(safe-area-inset-top));
  }
`;

class AdminPanel extends Component {
    state = {
        nextButtonDisabled: false,
        chatMessagesCheckIntervalId: null
    };

    componentDidMount() {
        if (ENV.IS_PREMIUM && this.props.instance.is_chat_enabled) {
            this.loadChatMessagesCount();
            this.initializeIntervals();
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.chatMessagesCheckIntervalId);
    }

    scrollClick() {
        store.dispatch(toggleAdminPanel(false));
    }

    open() {
        window.location.href = `https://${this.props.instance.name}.${this.props.instance.domain}`;
    }

    initializeIntervals() {
        const intervalId = setInterval(() => {
            this.loadChatMessagesCount().catch((e) => {
                clearInterval(this.state.chatMessagesCheckIntervalId);
            });
        }, WEDDING_CONFIG.chatNewMessageCheckInterval);

        this.setState({
            chatMessagesCheckIntervalId: intervalId
        })
    }

    loadChatMessagesCount() {
        return ChatService.getSuChatNewMessagesCount().then((response) => {
            if (this.props.app.adminNewChatMessagesCount < response.data.count) {
                this.props.enqueueSnackbar('Masz nową wiadomość na chacie.', {
                    variant: 'success',
                });
            }
            store.dispatch(setAdminChatNewMessagesCount(response.data.count));
        });
    }

    render() {
        if (!this.props.app.adminPanelOn) {
            return null;
        }
        return (
            <BrowserRouter>
                <ScrollToTop />
                <StyledPage id="superpanel">
                    {!ENV.IS_PREMIUM && this.props.instance.is_premium && this.props.instance.name && this.props.instance.domain ? (
                        <StyledPremium>
                            <div className={'inner'}>
                                <h1>Twój pakiet Premium jest aktywny!</h1>
                                <h2>A więc czas na małą zmianę...</h2>
                                <div>
                                    Twoja instancja <b>{this.props.instance.name}.{this.props.instance.domain}</b> jest już gotowa.
                                </div>
                                <div>
                                    Od teraz używaj adresu <a href={`https://${this.props.instance.name}.${this.props.instance.domain}`}>https://{this.props.instance.name}.{this.props.instance.domain}</a> do logowania.
                                </div>
                                <div>
                                    Dane dostępowe otrzymałeś na maila.
                                </div>
                                <div style={{marginTop: '40px'}}>
                                    <StyledFancyButton className={'size-small centered inline'} onClick={this.open.bind(this)}>Otwórz nowy panel</StyledFancyButton>
                                </div>
                            </div>
                        </StyledPremium>
                    ) : null}
                    <TopHeader />

                    <PageContent>
                        <PageFullWidthWrapper className="font-primary">
                            <Switch>
                                <Route path='/admin/tables' component={Tables} />
                            </Switch>
                        </PageFullWidthWrapper>

                        <PageWrapper className="font-primary content">
                            <Grid container spacing={isWidthUp('sm') ? 8 : 0}>
                                <Grid item xs={12} sm={12}>
                                    <Switch>
                                        <Route path='/admin/instance' component={Instance} />
                                        <Route exact path='/admin/contents' component={Contents} />
                                        <Route path='/admin/contents/edit/:name' component={ContentsEdit} />
                                        <Route path='/admin/users' component={UsersList} />
                                        <Route path='/admin/tasks' component={Tasks} />
                                        <Route path='/admin/statistics' component={Statistics} />
                                        <Route path='/admin/chat' component={ChatPage} />
                                        <Route path='/admin/accommodation-planner' component={AccommodationPlanner} />
                                        <Route path='/admin/settings' component={Settings} />
                                        <Route path='/admin/premium' exact component={Premium} />
                                        <Route path='/admin/premium/activate' component={BuyPremiumPage} />
                                        <Route path='/admin/tables' component={null} />

                                        <Route component={Instance}/>
                                    </Switch>
                                </Grid>
                            </Grid>
                        </PageWrapper>
                    </PageContent>

                </StyledPage>
            </BrowserRouter>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        instance: state.auth.instance,
    }
};

export default withSnackbar(connect(mapStateToProps)(AdminPanel));
