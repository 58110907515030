import React, {Component, Fragment, useEffect, useState} from 'react';
import WEDDING_CONFIG from '../wedding-config';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';
import DialogActions from "@material-ui/core/DialogActions";
import {MediaHeights, MediaRule} from "../constants/media-sizes.constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {CommonOutlinedButton} from "./StyledButton";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Icon from "@material-ui/core/Icon";


const colors = WEDDING_CONFIG.colors;

export const SimpleDialogTitle = styled(DialogTitle)`
  && {
    padding: 26px 36px;
    //background-color: ${colors.primary};
    //color: white;
    
    & > *:not(.subtitle) {
        font-weight: bold;
        font-size: 1.5rem;
    }
    
    .subtitle {
        font-weight: normal;
        font-size: 1rem;
    }
    
    
  }
`;

const StyledContent = styled(DialogContent)`
  && {
    padding: 13px 36px;
    
    &.mobile {
      padding: 13px 24px;
    }
    
    .MuiDialogActions-root {
      margin: 15px 0;
      
      button {
        border-radius: 0;
      }
    }
    
    .MuiDialogContent-root {
      padding: 0;
    }
  }
`;

const StyledActions = styled(DialogActions)`
  && {
    margin: 15px;
    padding-bottom: 0;
    //padding-bottom: env(safe-area-inset-bottom);
      
    button {
      border-radius: 0;
    }
    
    &.mobile {
      //margin: 0;
      //box-shadow: -1px -1px 2px #e8e8e8;
      
        .action-hidden-mobile {
          display: none;
        }
    }
    
  }
`;

export const SimpleDialogModalLikeTitle = styled.div`
  padding: 10px;
  display: block;
  font-size: 3rem;
  color: white;
  margin: 0 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0 2px 2px rgba(0,0,0,0.8);
  
    @media (max-height: ${MediaHeights.sm}px) {
      padding: 0 10px;
    }
`;

export const SimpleDialogModalLikeOverlay = styled.div`
  background: white;
  display: inline-block;
  padding: 20px 30px;
  margin: 20px;
  box-shadow: 0 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  position: relative;
  
  &.full-width {
    width: 100%;
    box-sizing: border-box;
  }
  
    @media (max-height: ${MediaHeights.sm}px) {
        //margin: 0 20px;
    }
`;

export const SimpleDialogModalLikeActions = styled.div`
    margin: 0 20px;
    box-sizing: border-box;
    width: 100%;
`;

const StyledAppBar = styled(AppBar)`
  && {
    background-image: url(/images/bubbles-bg3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 0;
    padding-top: env(safe-area-inset-top);
  }
`;

const StyledDialog = styled(Dialog)`
  && {
    .custom-paper {
        background: transparent;
        box-shadow: none;
        max-width: 95vw;
        max-height: 100%;
    }
    & > div:first-child {
      background: rgba(37, 2, 12, 0.8);
    }
    .mobile-paper {
      ${SimpleDialogModalLikeTitle} {
        padding: 0;
        color: #333;
        text-shadow: none;
        font-size: 2rem;
        margin: 0 0 1rem 0;
      }
      
      ${SimpleDialogModalLikeOverlay} {
        padding: 0;
        margin: 0;
        box-shadow: none;
        background: transparent;
      }
      
      ${SimpleDialogModalLikeActions} {
        margin: 0;
        
        ${CommonOutlinedButton} {
          font-weight: bold;
          width: 100%;
          padding-left: 10px;
          padding-right: 10px;
          border-width: 0;
          border-bottom: 2px solid #b7b7b7;
          border-right: 1px solid #ddd;
          
          &:not(:disabled).white {
            background-color: #eee;
            color: ${colors.primary};
          }
        }
      }
      
      ${StyledContent} {
        //padding-bottom: 130px;
        padding-left: 15px;
        padding-right: 15px;
      }

      &:not(.MuiDialog-paperFullWidth) {
        
      }
    }
  }
`;

function SimpleDialog({open, title, subTitle, handleClose, onClose, actions, children, noAppBar, ...rest}) {
    const isMobile = useMediaQuery(MediaRule.tablet);

    return (
        <StyledDialog
            open={open}
            onClose={handleClose || onClose}
            maxWidth={'lg'}
            fullScreen={isMobile}
            aria-labelledby="form-dialog-title"
            {...rest}>
            {(!isMobile || noAppBar) && (title || subTitle) ? (<SimpleDialogTitle className={'title'} id="form-dialog-title">
                {title}
                {subTitle ? (
                    <div className={'subtitle'}>
                        {subTitle}
                    </div>
                ) : null}
            </SimpleDialogTitle>) : null}
            {isMobile && !noAppBar ? <StyledAppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={'mobile-title'}>
                        {title}
                    </Typography>
                    <span className={'fill-space'}></span>
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleClose || onClose}>
                        <Icon>close</Icon>
                    </IconButton>
                </Toolbar>
            </StyledAppBar> : null}
            <StyledContent className={'simpledialog-content ' + (isMobile ? 'mobile' : '')}>
                {children}
            </StyledContent>
            {actions ? <StyledActions className={isMobile ? 'mobile' : ''}>
                {actions}
            </StyledActions> : null}
        </StyledDialog>
    )
}

export default SimpleDialog;
