import React, {Component, useState} from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from "../../../../wedding-config";
import participantService from "../../../../service/participant.service";
import {Icon, TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Link from "react-router-dom/Link";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import {StyledTooltip} from "../../../../common/TooltipTitle";
import {useSelector} from "react-redux";

const colors = WEDDING_CONFIG.colors;

const StyledContainer = styled.div`
  position: absolute;
  background: #fff;
  padding: 10px;
  width: 300px;
  height: 500px;
  max-height: 90vh;
  z-index: 20;
  border-radius: 5px;
  border: 1px solid ${colors.primaryLight};
  overflow: hidden;
  box-shadow: 2px 2px 4px #555;
`;

const StyledUsersList = styled.div`
  padding-top: 10px;
  margin-bottom: 50px;
  overflow-y: scroll;
  height: 400px;
`;

const StyledSearch = styled.div`
  
`;

const StyledUser = styled.div`
  padding: 6px 0;
  font-size: 0.8rem;
  color: #666;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  
  &:hover {
    color: ${colors.primaryLight};
  }
`;

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(40,40,40,.5);
  z-index: 10
`;

const StyledIcons = styled.span`
  float: right;
`;

const StyledMark = styled.span`
  display: inline-block;
  width: 16px;
  text-align: center;
  vertical-align: text-bottom;
  height: 14px;
  color: white;
  border-radius: 2px;
  padding-bottom: 2px;
  margin-left: 3px;
  background-color: ${colors.primary};
  
  &.inactive {
    background-color: ${colors.gray};
  }
  &.not-defined {
    background-color: #ccc;
  }
`;

const StyledNoUsers = styled.div`
  text-align: center;
  color: ${colors.textRead};
  padding-top: 20px;
  font-size: 1rem;
`;

export const UserSelectBoxType = {
    Tables: 'tables',
    Accommodation: 'accommodation'
}

export function UserSelectBox({ type, x, y, usersList, hideUserPanel, topActions, assigned, handleSelect, handleRemove }) {
    const [searchKeyword, setSearchKeyword] = useState('');
    const instance = useSelector(state => state.auth.instance);

    function filterUser(user) {
        if (!user) {
            return false;
        }

        return user.first_name.toLowerCase().startsWith(searchKeyword.toLowerCase()) ||
            (user.last_name || '').toLowerCase().startsWith(searchKeyword.toLowerCase());
    }

    function onUserClick(user) {
        handleSelect(user);
    }

    function onClearClick(assignment) {
        handleRemove(assignment);
    }

    function renderIcons(user) {
        return <React.Fragment>
            <StyledIcons>
            {user.present_first_day === null ?
                <StyledTooltip
                    placement={'top'}
                    title={'Deklaracja niewypełniona.'}>
                    <StyledMark
                        className={'not-defined'}>?</StyledMark>
                </StyledTooltip> : null}
            {user.present_first_day !== null && type === UserSelectBoxType.Tables ? (
                <React.Fragment>
                    <StyledTooltip
                        placement={'top'}
                        title={user.present_first_day ? 'Obecny(a) na weselu' : 'Nieobecny(a) na weselu'}>
                        <StyledMark
                            className={!user.present_first_day ? 'inactive' : ''}>W</StyledMark>
                    </StyledTooltip>
                    {instance.is_two_day_party ? <StyledTooltip
                        placement={'top'}
                        title={user.present_second_day ? 'Obecny(a) na poprawinach' : 'Nieobecny(a) na poprawinach'}>
                        <StyledMark
                            className={!user.present_second_day ? 'inactive' : ''}>P</StyledMark>
                    </StyledTooltip> : null}
                </React.Fragment>
            ) : null}
            {user.present_first_day !== null && type === UserSelectBoxType.Accommodation ? (
                <StyledTooltip
                    placement={'top'}
                    title={user.accommodation ? 'Nocleg zaznaczony' : 'Nie zaznaczono noclegu'}>
                    <StyledMark
                        className={!user.accommodation ? 'inactive' : ''}>N</StyledMark>
                </StyledTooltip>
            ) : null}
            </StyledIcons>
        </React.Fragment>
    }


    return (
        <StyledBackdrop id={'backdrop'} onClick={hideUserPanel}>
            <StyledContainer style={{ left: x + 'px', top: y + 'px' }}>
                {topActions ?
                    <div style={{ marginBottom: '10px'}}>
                        {topActions.map((action) => (
                            <CommonOutlinedButton className={'size-small'} disabled={action.disabled} onClick={action.action}>{action.label}</CommonOutlinedButton>
                        ))}
                    </div>
                : null}
                {assigned ? (
                    <div style={{ marginBottom: '20px'}}>
                        <div>Aktualne przypisanie</div>
                        <StyledUser onClick={onClearClick.bind(this, assigned)}>
                            <b>Wyczyść:</b> {assigned.name}
                            {renderIcons(assigned)}
                        </StyledUser>
                    </div>
                ) : null}
                <StyledSearch>
                    <div>Lista gości</div>
                    <TextField
                        fullWidth={true}
                        placeholder={'Wpisz imię, aby zawęzić listę'}
                        autoFocus={true}
                        value={searchKeyword}
                        InputProps={{
                            endAdornment: (
                                !searchKeyword ?
                                    (<InputAdornment position="end"><Icon>search</Icon></InputAdornment>) : (
                                    <InputAdornment position="end"
                                        onClick={() => {
                                            setSearchKeyword('');
                                        }}>
                                        <IconButton disableRipple={true} size={'small'}>
                                            <Icon>clear</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            )
                        }}
                        onChange={(e) => {
                            setSearchKeyword(e.target.value);
                        }}
                    />
                </StyledSearch>
                <StyledUsersList>
                    {usersList
                        .filter((user) => searchKeyword ? filterUser(user) : true)
                        .map((user) => (
                        <StyledUser key={user.id + '_' + user.participant_type + '_' + user.participant_index}
                                    onClick={onUserClick.bind(this, user)}>
                            {user.first_name} {user.last_name}
                            {renderIcons(user)}
                        </StyledUser>
                    ))}
                    {!usersList.length ? (
                        <StyledNoUsers>
                            Aby dodać gości, przejdź na <Link to={'/admin/users'}>listę</Link>.
                        </StyledNoUsers>
                    ): null}
                </StyledUsersList>
            </StyledContainer>
        </StyledBackdrop>
    );
}
