import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from "@material-ui/core";

const StyledTooltipW = withStyles({
    tooltip: {
        background: 'black'
    }
})(Tooltip);

const StyledTooltipTitle = styled.div`
    && {
        font-size: 0.9rem;
        line-height: 1.2rem;
        display: inline-block;
        padding: 0;
        background: #000;
        border-radius: 0;
    }
`;

const StyledTooltipSubText = styled.div`
    && {
        font-size: 0.8rem;
    }
`;

export function StyledTooltip({ title, subText, children, ...props}) {
    return (
        <StyledTooltipW
            {...props}
            title={<StyledTooltipTitle>{title}
                {subText ? (
                    <StyledTooltipSubText>{subText}</StyledTooltipSubText>
                ) : ''}
            </StyledTooltipTitle>}>
            {children}
        </StyledTooltipW>
    );
}