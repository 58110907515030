import React from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import NavLink from "react-router-dom/NavLink";
import {CONTENTS} from "../../../constants";
import {StyledTooltip} from "../../../common/TooltipTitle";

const colors = WEDDING_CONFIG.colors;

const StyledBlock = styled(ListItem)`
    && {
        padding: 10px 0;
        width: 100%;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin-bottom: 1rem;
        position: relative;
        
        &.inactive {
          opacity: 0.5;
        }

        &.variant-small {
          padding: 8px 0;
          margin-bottom: 5px;

          .label {
            margin-bottom: 0;
            font-size: 1rem;
          }
          .block-left {
            padding: 5px 80px 5px 5px;
          }
        }
    }
`;

const StyledBlockLeft = styled.div`
    padding: 15px 80px 15px 15px;
`;

const StyledLabel = styled.div`
    font-size: 1.3rem;
    font-weight: 100;
    margin-bottom: 0.5rem;
`;

const StyledText = styled.div`
    font-size: 0.9rem;
    font-weight: 100;
`;

const StyledRightContainer = styled.div`
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
`;

export default function FancyBlock(props) {
    const { mainProps, small, inactive, tooltip } = props;

    const blockContent = <StyledBlockLeft className={'block-left'}>
            <StyledLabel className={'label'}>{props.mainLabel}</StyledLabel>
            <StyledText className={'text'}>{props.text}</StyledText>
            <StyledRightContainer>
                {props.children}
            </StyledRightContainer>
        </StyledBlockLeft>;
    return (
        <StyledBlock
            className={`${inactive ? 'inactive' : ''} ${small ? 'variant-small' : ''}`}
            button
            {...mainProps}>
            {tooltip ?
                <StyledTooltip
                    placement="top"
                    title={tooltip}>
                    <span>{blockContent}</span>
                </StyledTooltip> : blockContent}
        </StyledBlock>
    )
}
