import React, {Component, Fragment} from 'react';
import './App.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import Home from './modules/guest-area/pages/Home';
import AdminPanel from './modules/admin/AdminPanel';
import styled, {createGlobalStyle, keyframes} from 'styled-components';
import {connect} from "react-redux";
import {authService} from './service/auth.service';
import MainLoading from './common/MainLoading';
import api from './api';
import store from './store';
import {updateUserAndInstance, toggleAdminPanel} from './actions';
import $ from 'jquery';
import ErrorDialog from './common/ErrorDialog';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import TRANSLATE from "./translation";
import MediaSizes from "./constants/media-sizes.constants";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import WEDDING_CONFIG from "./wedding-config";
import {lighten, darken} from 'polished';
import ENV from './config/environment';
import LoginPage from "./modules/auth/pages/LoginPage";
import ResetPasswordPage from "./modules/auth/pages/ResetPasswordPage";
import BrowserRouter from 'react-router-dom/BrowserRouter';
import RequestResetPasswordPage from "./modules/auth/pages/RequestResetPasswordPage";
import SuccessDialog from "./common/SuccessDialog";
import ThankYouPage from "./modules/guest-area/pages/ThankYouPage";
import UpdateBar from "./common/UpdateBar";
import {clearCaches} from "./utilities";
import {version} from '../package.json';

const colors = WEDDING_CONFIG.colors;

window.$ = $;

const LOADING_DELAY = 100;

const GlobalStyles = createGlobalStyle`
  body {
    min-height: 100vh;
    //background: url('/bride.jpg') no-repeat top right;
    //background-size: cover;
    background-color: #fbfbfb;
    
    @media (max-width: ${MediaSizes.desktop}px) {
        background-position-x: calc(100% + 210px);
    }
  }
  
  .tippy-tooltip {
    background: white;
    color: #666;
    padding: 20px;
  }
  
  .shepherd-active {
    &.custom-shepherd-active {
      overflow: hidden;
      max-height: 100vh;
    }
    
    .shepherd-element .shepherd-content footer .shepherd-buttons li .shepherd-button {
      border: 2px solid ${lighten(0.1, colors.primary)};
      color: ${lighten(0.1, colors.primary)};
      margin: 6px;
      font-size: 0.8rem;
      padding: 10px;
      background: white;
      border-radius: 3px;
      cursor: pointer;
      text-shadow: none;
      letter-spacing: 1px;
      transition: background 0.2s ease-in-out;
      
      &:focus {
        background: white;
        outline: none;
        background: ${darken(0.1, colors.primary)};
      }
      
      &:hover {
          border-color: ${lighten(0, colors.primary)};
          color: white;
          background: ${lighten(0, colors.primary)};
      }
      
    }
    
    .shepherd-element.shepherd-has-title .shepherd-content {
      border-radius: 5px;
      background: white;
    }
    
    .tippy-popper.shepherd-popper .tippy-tooltip {
      width: 560px;
    }
    
    .tippy-tooltip {
      max-width: 100%!important;
    }
    
    .shepherd-element.shepherd-has-title .shepherd-content header {
      background: white;
      padding: 30px 80px 0 30px;
      
      .shepherd-title {
        font-size: 1.4rem;
        color: ${colors.primary};
      }
    }
    
    .shepherd-element .shepherd-content .shepherd-text {
      text-align: left;
      padding: 30px;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
    
    .custom-shepherd-step-disable * {
      pointer-events: none!important;
    }
  }
    
`;

const StyledContainer = styled.div`
  min-height: 100vh;
`;

const Wrapper = styled.div`
  // width: 870px;
  max-width: calc(100vw);
  padding: 0px;
  text-align: center;
  border-radius: 2px;
  //min-height: 100vh;
`;

const StyledLogoContainer = styled.div`
    margin: 60px 0;
    text-align: center;
    
    @media (max-width: ${MediaSizes.tablet}px) {
        margin: 30px 0;
    }
`;

const StyledLogo = styled.img`
    width: 250px;
    
    @media (max-width: ${MediaSizes.tablet}px) {
        width: 180px;
    }
`;

const StyledSmallScreenMsg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 100;
  font-size: 3rem;
  
  & > div {
    margin: 0 auto;
    max-width: 280px;
    position: relative;
    top: 45%;
    transform: translateY(-50%);
  }
  
  p {
    margin-top: 0;
  }
  
  h4 {
      color: ${colors.primary};
      margin: 0;
      font-size: 2rem;
      margin-bottom: 10px;
  }
  
  .icon {
    font-size: 5rem;
    color: ${colors.primary};
  }
`;

const animateLoading = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const StyledBottomInfo = styled.div`
    display: none;
    text-align: left;
    color: #555;
    margin: 100px 20px 20px;
    -webkit-font-smoothing: antialiased;
    opacity: 0;
    animation: ${animateLoading} 0.5s ease-in forwards;
    animation-delay: 1s;
    
    a {
        color: ${colors.primary};
        text-decoration: none;
    }
    
    @media (max-width: ${MediaSizes.tablet}px) {
        text-align: center;
    }
`;

class App extends Component {
    state = {
        isLoading: true,
        isInstanceBlocked: false,
        isFading: false,
        isAuthenticated: false
    };

    componentDidMount() {
        let token = authService.getLocalJWT();

        if (this.hasCodeInUrl()) {
            token = undefined;
            localStorage.removeItem('token');
        }

        this.windowResize();
        window.addEventListener('resize', this.windowResize.bind(this));

        if (!token) {
            this.setState({
                isAuthenticated: false,
                isLoading: false
            });
            if (window.location.pathname.startsWith('/admin')) {
                window.location = '/';
            }
            return;
        }

        api.setDefaults({token});

        if (window.location.pathname.startsWith('/admin/') || !ENV.IS_PREMIUM) {
            store.dispatch(toggleAdminPanel(true));
        }

        authService.checkLoginState()
            .then(this.handleStateCallback.bind(this))
            .catch(this.handleStateErrorCallback.bind(this));
    }

    windowResize() {
        let showMsg = false;

        if (!ENV.IS_PREMIUM && window.innerWidth < 960) {
            showMsg = true;
        }
    }

    newVersionAction() {
        clearCaches();

        window.location.reload();
    }

    hasCodeInUrl() {
        const queryString = window.location.search ? window.location.search.slice(1) : null;
        let queryParams, queryParamsObj = {};

        if (queryString) {
            queryParams = queryString.split('&');
            queryParams.forEach((paramString) => {
                queryParamsObj[paramString.split('=')[0]] = paramString.split('=')[1];
            });

            if (queryParamsObj && queryParamsObj.code) {
                return true;
            }
        }
        return false;
    }

    handleStateCallback(response) {
        const responseData = response.data;

        if (responseData && responseData.instance_error) {
            this.setState({
                isInstanceBlocked: true,
                isLoading: false
            });
            return;
        }

        this.setState({
            isAuthenticated: true,
            isLoading: false
        });

        if (responseData.user.is_admin === true) {
            store.dispatch(toggleAdminPanel(true));
        }

        store.dispatch(updateUserAndInstance(responseData.user, responseData.instance));

        setTimeout(() => { // a timeout to visualize the loading screen
            this.setState({
                isLoading: false
            });
        }, LOADING_DELAY);
    }

    handleStateErrorCallback(error) {
        const responseData = error.response ? error.response.data : error.message,
            returnResult = error.response;

        if (responseData && responseData.instance_error) {
            this.setState({
                isInstanceBlocked: true,
                isLoading: false
            });
            return returnResult;
        }

        if (!responseData.authenticated) {
            authService.clearToken();
            this.setState({
                isAuthenticated: false,
                isLoading: false
            });
        }

        return returnResult;
    }


    render() {
        return (
            <Wrapper>
                <GlobalStyles />
                {this.props.app.apiParams.frontend_version > version ? (
                    <UpdateBar text={'Nowa wersja jest dostępna. Kliknij tutaj aby odświeżyć.'} variant={'action'} onClick={this.newVersionAction} />
                ) : null}
                {this.props.app.apiParams.update_ongoing ? (
                    <UpdateBar text={'Trwają prace serwisowe nad aplikacją, niektóre funkcje mogą działać z opóźnieniem lub być chwilowo niedostępne.'} variant={'warning'} />
                ) : null}
                {this.props.app.errorMessage ? (
                    <ErrorDialog
                        open={!!this.props.app.errorMessage}
                        text={this.props.app.errorMessage}
                    />
                ) : null}
                {this.props.app.successMessage ? (
                    <SuccessDialog
                        open={!!this.props.app.successMessage}
                        text={this.props.app.successMessage}
                    />
                ) : null}

                {this.props.errorMessage ? (
                    <Fragment>
                        <StyledLogoContainer>
                            <a href={ENV.WEBSITE_URL} target="_blank">
                                <StyledLogo src="/logo-fw.png" />
                            </a>
                        </StyledLogoContainer>
                        <p>
                            {TRANSLATE[this.props.errorMessage] || TRANSLATE['unknown.error']}
                        </p>
                    </Fragment>
                ) : null}

                {!this.props.errorMessage ? (

                    this.state.isLoading ?
                        (
                            <MainLoading />
                        ) : (
                        <BrowserRouter>
                            <StyledContainer>
                                {
                                    this.props.user ? (
                                        <Fragment>
                                            {this.props.app.isAfterWedding && !this.props.user.is_admin ?
                                                <Route path="/" component={ThankYouPage}/> :
                                                <Route path="/" component={Home}/>}
                                            <PrivateRoute path="/" component={AdminPanel}/>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Route path="/" exact component={LoginPage}/>
                                            <Route path="/request-reset-password" component={RequestResetPasswordPage}/>
                                            <Route path="/reset-password" component={ResetPasswordPage}/>
                                            <StyledBottomInfo>Uruchom aplikację dla siebie na <a href={ENV.WEBSITE_URL} target="_blank">www.fancy.wedding</a></StyledBottomInfo>
                                        </Fragment>
                                    )
                                }
                            </StyledContainer>
                        </BrowserRouter>
                    )

                    ) : ''}
            </Wrapper>
        );
    }
}

export default App;


const mapStateToProps = (store) => {
    return {
        app: store.app,
        user: store.auth.user,
        instance: store.auth.instance
    }
};

const ConnectedApp = connect(mapStateToProps)(App);
export {ConnectedApp as App};
