import React, {Fragment, useState} from 'react';
import { useForm } from 'react-hook-form';
import WEDDING_CONFIG from '../../../../wedding-config';
import TextInput from "../../partials/TextInput";
import SimpleDialog from "../../../../common/SimpleDialog";
import {CommonOutlinedButton} from "../../../../common/StyledButton";
import {addPlace, addSet} from "../../../../actions/accommodation.actions";
import store from "../../../../store";
import {editTable} from "../../../../actions/table.actions";
import {useSnackbar} from "notistack";

const colors = WEDDING_CONFIG.colors;

const TableNameDialog = ({setId, tableId, tableName, handleClose, ...props}) => {
    const { register, handleSubmit, errors, getValues, setValue, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: tableName,
        }
    }); // initialise the hook
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onSubmit = data => {
        setIsLoading(true);
        store.dispatch(editTable(setId, tableId, data.name))
            .then(() => {
                setIsLoading(false);
                enqueueSnackbar('Zmieniono nazwę stołu', {
                    variant: 'success'
                });
                handleClose();
            }).catch((e) => {
            setIsLoading(false);
            throw e;
        })
    };
    let formRef;

    return (
        <SimpleDialog
            open={true}
            title={'Zmień nazwę stołu'}
            subTitle={<div>Podaj nową, krótką nazwę stołu.</div>}
            handleClose={() => handleClose()}
            actions={<Fragment>
                <CommonOutlinedButton onClick={() => handleClose()} color="secondary">
                    Anuluj
                </CommonOutlinedButton>
                <CommonOutlinedButton className={'fill-primary'} disabled={!formState.isValid || isLoading} onClick={() => formRef.dispatchEvent(new Event('submit', { cancelable: true }))} color="primary">
                    Zapisz
                </CommonOutlinedButton>
            </Fragment>}
          {...props}>
            <form onSubmit={handleSubmit(onSubmit)} ref={(ref) => formRef = ref}>
                <TextInput
                    inputRef={register({required: true})}
                    name="name"
                    key="name"
                    autoFocus
                    label={'Nazwa'}
                />
            </form>
        </SimpleDialog>
    )
};

export default TableNameDialog;
