import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import api from '../../../api';
import { authService } from '../../../service/auth.service';
import store from '../../../store';
import { toggleMainModal, updateUserAndInstance, toggleDeclarationThankYouModal } from '../../../actions';
import MainFormSectionGreeting from './main-form/section-greeting';
import MainFormSectionPartners from './main-form/section-partners';
import MainFormSectionParticipation from './main-form/section-participation';
import MainFormSectionAdditional from './main-form/section-additional';
import MainFormSectionSummary from './main-form/section-summary';

class AttendeesForm extends Component {
    state = {
        keyInvalid: false,
        isSubmitting: false,
        isLeavingAfterSuccess: false,
        withoutPartner: true,
        kidFormOpen: false,
        kid: null,
        disabledSteps: [],
        introContent: {}
    };

    componentDidMount() {
        this.getContents().then((response) => {
            this.setState({
                introContent: response.data
            })
        });
    }

    getContents() {
        return api.request({
            url: api.routes.getContent.replace(':name', 'declaration_intro')
        });
    }

    saveForm(values) {
        return api.request.put(api.routes.saveParticipantData, values);
    }

    isActive(propsStep, thisStep) {
        return propsStep === thisStep;
    }

    render() {
        let kidsData;
        try {
            kidsData = JSON.parse(this.props.user.kids);
        } catch(e) {
            kidsData = [];
        }
        return (
            <div>
                <Formik
                    ref={node => (this.form = node)}
                    initialValues={{
                        present_first_day: this.props.user.present_first_day,
                        present_second_day: this.props.user.present_second_day,
                        first_name: this.props.user.first_name,
                        last_name: this.props.user.last_name,
                        partner_first_name: this.props.user.partner_first_name,
                        partner_last_name: this.props.user.partner_last_name,
                        is_with_partner: this.props.user.is_with_partner,
                        is_with_kids: this.props.user.is_with_kids,
                        main_special_menu: this.props.instance.special_menus && this.props.instance.special_menus.length ? this.props.user.main_special_menu : null,
                        partner_special_menu: this.props.instance.special_menus && this.props.instance.special_menus.length ? this.props.user.partner_special_menu : null,
                        participant_note: this.props.user.participant_note,
                        accommodation: this.props.user.accommodation,
                        email: this.props.user.email,
                        kids: kidsData,
                    }}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        setSubmitting(true);

                        values.kids = values.kids.filter((kid) => kid.kid_first_name && kid.kid_last_name);

                        this.setState({ isSubmitting: true });

                        this.saveForm(values).then(() => {
                            this.setState({ isLeavingAfterSuccess: true });
                            this.setState({ isSubmitting: false });
                            store.dispatch(toggleMainModal(false));

                            authService.checkLoginState()
                                .then(response => response.data)
                                .then(response => {
                                    store.dispatch(updateUserAndInstance(response.user, response.instance));
                                    setSubmitting(false);
                                    store.dispatch(toggleDeclarationThankYouModal(true));
                                })

                        });
                    }}
                >
                    {(props) => {
                        return (
                            <div>
                                <div>
                                    <MainFormSectionGreeting isActive={this.isActive(this.props.activeStep, 0)} formProps={props} content={this.state.introContent} />
                                    <MainFormSectionParticipation isActive={this.isActive(this.props.activeStep, 1)} formProps={props} />
                                    <MainFormSectionPartners isActive={this.isActive(this.props.activeStep, 2)} formProps={props} user={this.props.user} />
                                    <MainFormSectionAdditional isActive={this.isActive(this.props.activeStep, 3)} formProps={props} />
                                    <MainFormSectionSummary isActive={this.isActive(this.props.activeStep, 4)} formProps={props} />
                                </div>
                            </div>
                        )
                    }}
                </Formik>

            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user,
        instance: state.auth.instance,
    }
};

const ConnectedPartnerNameDialog = connect(mapStateToProps, null, null,{ forwardRef: true })(AttendeesForm);
export { ConnectedPartnerNameDialog as AttendeesForm };
