import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectionTitle from "../partials/SectionTitle";
import {StyledCardContent} from "../../../common/StyledContentCard";
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";

class Help extends Component {
    render() {
        return (
            <StyledCard>
                <StyledCardHeader>Masz pytanie?</StyledCardHeader>

                <StyledCardContent>
                    <p>
                        Znalazłeś błąd lub potrzebujesz pomocy? A może masz sugestię co do działania którejś funkcjonalności?<br/>
                        Jeżeli tak, napisz proszę wiadomość na e-mail: <a href="mailto:kontakt@fancy.wedding">kontakt@fancy.wedding</a>, postaramy się szybko pomóc.<br />
                    </p>
                    <p>
                        Dziękujemy!
                    </p>
                </StyledCardContent>
            </StyledCard>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(Help);
