import api from '../api';
import ENV from "../config/environment";
import axios from "axios";

const axiosInstance = axios.create();

const getNews = () => {
    return axiosInstance({
        url: `${ENV.WEBSITE_URL}/api/news`,
        method: 'get',
    });
};

export default {
    getNews
};