import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectionTitle from "../partials/SectionTitle";
import {StyledCardContent} from "../../../common/StyledContentCard";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import {CommonOutlinedButton, StyledFancyButton} from "../../../common/StyledButton";
import Icon from "@material-ui/core/Icon";
import store from "../../../store";
import {toggleAdminPanel} from "../../../actions";
import NavLink from "react-router-dom/NavLink";
import ENV from "../../../config/environment";
import {StyledCard, StyledCardHeader} from "../../../common/StyledCard";
import {DEFAULT_GRID_SPACING} from "../../../constants";
import PremiumBuyItem from "./PremiumBuyItem";
import WEDDING_CONFIG from "../../../wedding-config";
import MediaSizes from "../../../constants/media-sizes.constants";

const StyledFloatedContent = styled.div`
  position: sticky;
  top: 150px;
`;

const StyledPremiumView = styled.div`
  .main-title {
    font-size: 2rem;
  }
  img {
    max-width: 100%;
  }
`;

const StyledFeature = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 10px;

  .header {
    font-size: 1.25rem;
  }
  
  .text {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .icon {
    color: ${WEDDING_CONFIG.colors.primary};
    padding-right: 20px;
    
    span {
      font-size: 3rem;
    }
  }
  
  @media (min-width: ${MediaSizes.tablet}px) {
    margin: 40px;
  }
`;

class Premium extends Component {
    previewApp() {
        store.dispatch(toggleAdminPanel(false));
    }

    render() {
        return (
            <StyledPremiumView>
                <Grid container spacing={DEFAULT_GRID_SPACING}>
                    <Grid item xs={12} sm={8}>
                        <StyledCard>
                            <StyledCardHeader className={'main-title'}>Innowacyjny pakiet Premium</StyledCardHeader>

                            <StyledCardContent style={{ marginTop: 20}}>
                                <StyledFeature>
                                    <div className={'icon'}><Icon>language</Icon></div>
                                    <div>
                                        <div className={'header'}>Własna strona internetowa</div>
                                        <p className={'text'}>Otrzymujesz swój własny adres WWW z edytowalną stroną informacyjną dla gości, zabezpieczoną hasłem. <a href={'https://www.fancy.wedding/funkcjonalnosci/slubna-strona-www'} target={'_blank'}>Dowiedz się więcej.</a></p>
                                    </div>
                                </StyledFeature>
                                <StyledFeature>
                                    <div className={'icon'}><Icon>important_devices</Icon></div>
                                    <div>
                                        <div className={'header'}>Deklaracje Online</div>
                                        <p className={'text'}>Twoi goście w nowoczesny i prosty sposób oznaczą swoją obecność, chęć na nocleg czy np. wegetariańskie menu. <a href={'https://www.fancy.wedding/funkcjonalnosci/deklaracja-przez-internet'} target={'_blank'}>Dowiedz się więcej.</a></p>
                                    </div>
                                </StyledFeature>
                                <StyledFeature>
                                    <div className={'icon'}><Icon>verified</Icon></div>
                                    <div>
                                        <div className={'header'}>Załączniki QR, harmonogram imprezy dla gości, chat i sporo innych organizacyjnych ułatwień</div>
                                        <p className={'text'}>Pakiet Premium sprawi, że Twoje wesele zostanie na zawsze zapamiętane jako to najbardziej innowacyjne.</p>
                                    </div>
                                </StyledFeature>
                                <iframe style={{width: '100%', minHeight: 400}}
                                        src="https://www.youtube.com/embed/EwEFoZoVQLs?controls=0&amp;start=63"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </StyledCardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StyledFloatedContent>
                            <StyledCard>
                                <StyledCardContent>
                                    <PremiumBuyItem priceGross={this.props.app.premiumPrice}></PremiumBuyItem>
                                    <br />
                                    <StyledFancyButton variant={'contained'} className={'fill-primary'} component={NavLink} to={'/admin/premium/activate'}>
                                        <Icon className={'icon'}>shopping_cart</Icon> <b>Zamów pakiet Premium</b>
                                    </StyledFancyButton>
                                    <p>
                                        Cały Twój postęp (m. in. lista gości, plany stołów) zostanie nienaruszony.
                                    </p>
                                </StyledCardContent>
                            </StyledCard>
                            <StyledCard>
                                <StyledCardHeader>Zobacz widok dla gości</StyledCardHeader>
                                <StyledCardContent>
                                    <p>Przetestuj swoją stronę przed zakupem. <br/>Dane oraz treści widoczne na podglądzie są przykładowe - po zakupie będą tam prawdziwe informacje.</p>
                                    <StyledFancyButton variant={'outlined'} onClick={() => this.previewApp()}>
                                        <Icon className={'icon'}>remove_red_eye</Icon> Podgląd strony WWW
                                    </StyledFancyButton>
                                </StyledCardContent>
                            </StyledCard>
                        </StyledFloatedContent>
                    </Grid>
                </Grid>

            </StyledPremiumView>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(Premium);
