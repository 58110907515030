import React from 'react';
import styled from 'styled-components';
import WEDDING_CONFIG from '../../../wedding-config';

const colors = WEDDING_CONFIG.colors;

const StyledTitle = styled.h1`
    position: relative;
    //padding: 20px;
    font-weight: 100;
    //border-left: solid 3px ${colors.primaryLight};
    font-size: 1.5rem;
    margin: 0;
    
    &.md {
      font-size: 1.3rem;
      
      .subtitle {
        font-size: 1rem;
      }
    }
    &.lg {
      font-size: 2rem;
      
      .subtitle {
        font-size: 1.15rem;
      }
    }
`;

const StyledAction = styled.span`
    position: absolute;
    right: 0;
    top: 27px;
    font-size: 1rem;
`;

const StyledSubTitle = styled.div`
    font-size: 0.9rem;
    margin-top: 10px;
    font-weight: 100;
`;

export default function SectionTitle(props) {
    return (
        <StyledTitle fontSize={props.fontSize} className={`${props.fontSize}`} style={props.style}>
            {props.children}
            {props.action ? (
                <StyledAction>
                    {props.action}
                </StyledAction>
            ) : ''}
            {props.subtitle ? (
                <StyledSubTitle className={'subtitle'}>{props.subtitle}</StyledSubTitle>
            ) : ''}
        </StyledTitle>
    )
}
